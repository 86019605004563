import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-yesnoconfirm-dialog',
    template: `
        <h1 mat-dialog-title i18n="dialogs$$dialog question title@@are you sure">Are you sure?</h1>
        <mat-dialog-content>
            {{data.text}}<br />
            2 + 3 = <input required matInput type="number" name="calcinput" [(ngModel)]="calc" style="width: 30px">
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button class="edit-button" mat-raised-button type="submit" mat-dialog-close><mat-icon>close</mat-icon></button>
            <button class="edit-button" mat-raised-button type="button" (click)="onYesClick()" [disabled]="calc !== 5"><mat-icon>{{data.okIcon}}</mat-icon></button>
        </mat-dialog-actions>
    `
})
export class YesNoConfirmDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<YesNoConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { text: string, okIcon?: string }
    ) { }

    calc: number;

    ngOnInit(): void {
        if (!this.data.okIcon) {
            this.data.okIcon = 'delete';
        }
    }

    onYesClick(): void {
        this.dialogRef.close(true);
    }
}

import { Component, OnInit } from '@angular/core';
import { SchedulerPlannerComponent } from './scheduler-planner.component';

@Component({
    selector: 'app-scheduler-planner-placeholder',
    templateUrl: './scheduler-planner-placeholder.component.html',
    styleUrls: ['./scheduler-planner-placeholder.component.scss'],
})
export class SchedulerPlannerPlaceholderComponent extends SchedulerPlannerComponent implements OnInit {

    ngOnInit(): void {
        this.isPlaceholder = true;
    }

    // constructor(
    //     protected schedulerService: SchedulerService,
    //     protected alertService: AlertService,
    //     protected utils: Utils,
    //     protected utils2: Utils2,
    //     protected tr: TranslatorService,
    //     @Inject(LOCALE_ID) public locale: string,
    // ) {
    //     super(schedulerService, alertService, utils, utils2, tr, locale);
    // }

    // private _schedule: RoastSchedule;
    // @Input() set schedule(s: RoastSchedule) {
    //     this._schedule = s;
    //     if (!this._schedule.items) {
    //         this._schedule.items = [];
    //     }
    // }
    // get schedule(): RoastSchedule {
    //     return this._schedule;
    // }
    // @Output() addCurrentItem = new EventEmitter<string>(); // date string
    // @Output() itemReceived = new EventEmitter<{ item: RoastScheduledItem, fromDate: string, toDate: string }>();

    // readonly today = DateTime.now().toISODate();


    // ngOnDestroy(): void {
    //     this.ngUnsubscribe.next('');
    //     this.ngUnsubscribe.complete();
    // }

    // protected drop(event: CdkDragDrop<{ items?: RoastScheduledItem[], date?: string }, { items?: RoastScheduledItem[], date?: string }, { items?: RoastScheduledItem[], date?: string }>) {
    //     if (this.isMovingNow) return;

    //     if (this.schedule.date < this.today) {
    //         return;
    //     }
    //     const fromDate = event.previousContainer.data?.date;
    //     const toDate = this.schedule.date;
    //     if (fromDate === toDate && event.previousIndex === event.currentIndex) {
    //         return;
    //     }

    //     // this.dragMode has been set to NONE in dragReleased already
    //     // and when dragging to another day, this.dragMode has only been set
    //     // on the source anyway
    //     const clone = this.needsClone(fromDate, event.previousContainer.data?.items[event.previousIndex]) || event.event.altKey;

    //     if (!event.container.data?.items?.length) {
    //         if (event.container.data) {
    //             event.container.data.items = [];
    //         }
    //     }
    //     // TODO! indices
    //     if (clone) {
    //         event.container.data.items.splice(event.currentIndex, 0, { ...event.previousContainer.data.items[event.previousIndex] });
    //     } else if (fromDate === toDate) {
    //         moveItemInArray(this.schedule.items, event.previousIndex, event.currentIndex);
    //     } else {
    //         transferArrayItem(event.previousContainer.data?.items, event.container.data?.items, event.previousIndex, event.currentIndex);
    //     }

    //     this.movingTimer = setTimeout(() => this.isMoving = true, 600);
    //     this.isMovingNow = true;
    //     this.schedulerService.moveItem(event.currentIndex, this.schedule.date, event.previousIndex, fromDate, clone)
    //         .pipe(throttleTime(environment.RELOADTHROTTLE))
    //         // .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
    //         .pipe(finalize(() => { clearTimeout(this.movingTimer); this.isMoving = this.isMovingNow = false; }))
    //         .subscribe({
    //             next: response => {
    //                 if (!response || response.success === true) {
    //                     this.itemReceived.emit({ item: event.container.data?.items?.[event.currentIndex], fromDate, toDate });
    //                     // if (fromDate === toDate) {
    //                     //     this.addSummary();
    //                     // } else {
    //                     //     this.movedAcrossDays.emit({ fromDate: fromDate, toDate: this.schedule.date });
    //                     // }
    //                 } else {
    //                     this.initiateReload.emit();
    //                     this.utils.handleError('error updating the schedule', response.error);
    //                 }
    //             },
    //             error: error => {
    //                 // TODO! indices
    //                 // undo local movement
    //                 if (clone) {
    //                     event.container.data.items.splice(event.currentIndex, 1);
    //                 } else if (fromDate === toDate) {
    //                     moveItemInArray(this.schedule.items, event.currentIndex, event.previousIndex);
    //                 } else {
    //                     transferArrayItem(event.container.data?.items, event.previousContainer.data?.items, event.currentIndex, event.previousIndex);
    //                 }
    //                 this.initiateReload.emit();
    //                 this.utils.handleError('error updating the schedule', error);
    //             }
    //         });
    // }
}

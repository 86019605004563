import { TranslatorService } from 'src/app/util/services/translator.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Coffee } from 'src/app/models/Coffee';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-cant-delete-dialog',
    templateUrl: './cant-delete-dialog.component.html',
})
export class CantDeleteDialogComponent implements OnInit {

    constructor(
        // used in template
        public tr: TranslatorService,
        // used in template
        public utils: Utils,
        private dialogRef: MatDialogRef<CantDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            label: string,
            count: number,
            refs: { model: string,
                ref: {
                    hr_id?: string,
                    _id?: string,
                    label?: string,
                    crop_date?: { landed?: number[], picked?: number[] },
                    origin?: string,
                    date: string,
                    amount: number,
                    order_number: string,
                    sales_number: string,
                    reason: string,
                    price: number,
                    number: number,
                    value: number,
                    coffee: Coffee,
                    yearLabel: string,
                    location: { hr_id?: string, label?: string },
                    target: { hr_id?: string, label?: string },
                    supplier: { _id: string, label: string },
                    customer: { _id: string, label: string },

                },
            }[],
            mainUnit: UnitSystemType,
            currency: string
        }
    ) { }

    DateTime = DateTime;

    ngOnInit(): void {
        if (this.data?.refs?.length > 0) {
            for (let r = 0; r < this.data.refs.length; r++) {
                const ref = this.data.refs[r];
                if (ref?.model === 'Coffee') {
                    if (typeof ref.ref === 'object') {
                        ref.ref.yearLabel = this.utils.createBeansYearLabel(ref.ref as unknown as Coffee);
                    }
                }
                if (ref?.ref?.coffee && typeof ref.ref.coffee === 'object') {
                    ref.ref.coffee.yearLabel = this.utils.createBeansYearLabel(ref.ref.coffee);
                }
            }
        }
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }
}

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Coffee } from 'src/app/models/Coffee';
import { Blend } from 'src/app/models/Blend';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { StandardService } from 'src/app/util/services/standard.service';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Utils } from 'src/app/util/utils';

@Component({
    selector: 'app-replace-all-in-blends',
    templateUrl: './replace-all-in-blends.component.html',
    styleUrls: ['./replace-all-in-blends.component.scss']
})
export class ReplaceAllInBlendsComponent implements OnInit, OnDestroy {

    constructor(
        // used in template
        public tr: TranslatorService,
        private standardService: StandardService,
        protected utils: Utils,
        private dialogRef: MatDialogRef<ReplaceAllInBlendsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            from: Coffee,
            to: Coffee,
            currentBlend: Blend,
            affectedBlends: Blend[], // undefined or list of all existing blends
        }
    ) { }

    showAffectedBlends = false;
    loadingClicked = false;
    loading = false;

    private ngUnsubscribe = new Subject();

    ngOnInit(): void {
        this.showAffectedBlends = !!this.data.affectedBlends;
        // if (typeof this.data.affectedBlends === 'undefined') {
        //     this.loadAllBlends();
        // }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    closeDialog(): void {
        this.dialogRef.close(false);
    }

    onYesClick(): void {
        this.dialogRef.close(true);
    }

    loadAllBlends(): void {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.standardService.getAll<Blend>('blends')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.loading = false;
                    if (response.success === true) {
                        const blends = response.result;
                        // we have all blends at hand; check whether some are affected
                        const affectedBlends = [];
                        const changedCoff = this.data.from;
                        for (let b = 0; b < blends.length; b++) {
                            const blend = blends[b];
                            if (this.utils.compareObjectsFn(this.data.currentBlend, blend)) {
                                // ignore currently changed blend
                                continue;
                            }
                            for (let i = 0; i < blend.ingredients.length; i++) {
                                const coff = blend.ingredients[i].coffee;
                                if (coff && !coff.yearLabel) {
                                    coff.yearLabel = this.utils.createBeansYearLabel(coff);
                                }
                                if (this.utils.compareObjectsFn(coff, changedCoff)) {
                                    affectedBlends.push(blend);
                                    continue;
                                }
                            }
                        }
                        this.data.affectedBlends = affectedBlends;
                        this.showAffectedBlends = !!this.data.affectedBlends;
                    } else {
                        this.utils.handleError('error retrieving all blends', response.error);
                    }
                },
                error: error => {
                    this.loading = false;
                    this.utils.handleError('error retrieving all blends', error);
                }
            });
    }
}

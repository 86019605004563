import { TranslatorService } from 'src/app/util/services/translator.service';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialog } from '@angular/material/dialog';
import { Coffee } from 'src/app/models/Coffee';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CurrencyPipe, DecimalPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { Blend } from 'src/app/models/Blend';
import { OrganicIconComponent } from '../organicicon.component';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { MatOption, MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatInput } from '@angular/material/input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatButton } from '@angular/material/button';
import { GetPageOptions, StandardService } from 'src/app/util/services/standard.service';
import { throttleTime, takeUntil, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Roast } from 'src/app/models/Roast';
import { Enumerations } from 'src/app/models/Enumerations';
import { User } from 'src/app/models/User';
import { BeansSearchDialogComponent } from './beans-search-dialog.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-template-search-dialog',
    templateUrl: './template-search-dialog.component.html',
    styleUrl: './template-search-dialog.component.scss',
    standalone: true,
    // eslint-disable-next-line max-len
    imports: [OrganicIconComponent, MatFormField, FormsModule, MatDialogContent, RouterLink, DecimalPipe, CurrencyPipe, MatDialogActions, MatIcon, MatLabel, MatSelect, MatSelectTrigger, MatOption, MatProgressSpinner, MatInput, NgxMatSelectSearchModule, MatButton, MatDialogClose, MatDialogTitle, NgTemplateOutlet, NgClass, MatSlideToggle],
})
export class TemplateSearchDialogComponent implements OnInit, OnDestroy {

    constructor(
        private standardService: StandardService,
        public tr: TranslatorService,
        public utils: Utils,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<TemplateSearchDialogComponent, Roast>,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(MAT_DIALOG_DATA) public data: {
            currentUser: User,
            coffees: Coffee[],
            blends: Blend[],
            machines: string[],
        },
    ) { }

    template: Roast;
    templates: Roast[] = [];
    filteredTemplates: Roast[] = [];

    mainUnit: UnitSystemType = 'kg';

    isLoading = false;
    isLoadingTimer: ReturnType<typeof setTimeout>;
    isLoadingB = false;
    isLoadingTimerB: ReturnType<typeof setTimeout>;

    coffee: Coffee;
    coffees: Coffee[];
    filteredCoffees: Coffee[];
    blend: Blend;
    blends: Blend[];
    filteredBlends: Blend[];
    
    label: string;
    machine: string;
    machines: string[];
    filteredMachines: string[];
    batchNumber: number;
    batchPrefix: string;
    is_template: boolean;

    Math = Math;
    Number = Number;

    private ngUnsubscribe = new Subject();


    ngOnInit(): void {
        if (this.data?.currentUser?.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lb';
        }

        this.coffees = this.data.coffees ?? [];
        this.filteredCoffees = this.coffees?.slice() ?? [];
        this.blends = this.data.blends ?? [];
        this.filteredBlends = this.blends?.slice() ?? [];
        this.machines = this.data.machines ?? [];
        this.filteredMachines = this.machines?.slice() ?? [];
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.isLoadingTimer);
    }

    selectTemplate(): void {
        this.dialogRef.close(this.template);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    protected filterLabelObjects(search: string, objects: string[]): string[] {
        if (!objects) {
            return;
        }
        if (!search) {
            return objects;
        }
        search = search.toLocaleLowerCase(this.locale);
        return objects.filter(obj => (obj?.['label'] ?? obj)?.toLocaleLowerCase(this.locale)?.indexOf(search) > -1);
    }

    protected openBeansSearchDialog(searchCoffees = true, searchBlends = true): void {
        // eslint-disable-next-line max-len
        const dialogRef = this.dialog.open<BeansSearchDialogComponent, { coffees: Coffee[], blends: Blend[], searchCoffees?: boolean, searchBlends?: boolean }, { isBlend: false, item: Coffee } | { isBlend: true, item: Blend }>(BeansSearchDialogComponent, {
            closeOnNavigation: true,
            data: {
                coffees: this.coffees,
                blends: this.blends,
                searchCoffees,
                searchBlends,
            },
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res?.item) {
                if (searchBlends && res.isBlend) {
                    this.blend = res.item;
                    this.coffee = undefined;
                    if (!this.filteredBlends.some(blend => blend.label === this.blend.label)) {
                        if (!this.blends.some(blend => blend.label === this.blend.label)) {
                            this.blends.push(this.blend);
                            // this.additionalBlend = this.blend;
                        }
                        this.filteredBlends.push(this.blend);
                    }
                } else if (searchCoffees && res.isBlend === false) {
                    this.coffee = res.item;
                    this.blend = undefined;
                    if (!this.filteredCoffees.some(cof => cof.hr_id === this.coffee.hr_id)) {
                        if (!this.coffees.some(cof => cof.hr_id === this.coffee.hr_id)) {
                            this.coffees.push(this.coffee);
                            // this.additionalCoffee = this.coffee;
                        }
                        this.filteredCoffees.push(this.coffee);
                    }
                }
            }
        });
    }

    doSearch(): void {
        const filter: GetPageOptions = {};
        if (this.batchPrefix) {
            filter.batch_prefix = [this.batchPrefix];
        }
        if (this.batchNumber || this.batchNumber === 0) {
            filter.batch_number = { vals: [this.batchNumber] };
        }
        if (this.blend) {
            filter.blends = { vals: [this.blend.label as unknown as Blend] };
        }
        if (this.coffee) {
            filter.coffees = { vals: [this.coffee._id as unknown as Coffee] };
        }
        if (this.machine) {
            filter.machine = [this.machine];
        }
        if (this.label) {
            filter.label = this.label;
        }
        if (this.is_template) {
            filter.is_template = true;
        }
        filter.limit = 30;
        filter.roast_id = { notnull: true };
        
        this.isLoadingTimer = setTimeout(() => {
            this.isLoading = true;
        }, 600);
        this.standardService.getPage2<Roast>('roasts', filter)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    clearTimeout(this.isLoadingTimer);
                    this.isLoading = false;
                    if (response.success === true) {
                        this.templates = response.result;
                        this.filteredTemplates = this.templates.slice();
                        if (this.templates?.length) {
                            this.template = this.templates[0];
                        }
                    } else {
                        this.utils.handleError('error retrieving all roasts', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all roasts', error);
                    clearTimeout(this.isLoadingTimer);
                    this.isLoading = false;
                }
            });
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RoastReport } from 'src/app/models/RoastReport';
import { Roast } from 'src/app/models/Roast';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { StockChange } from 'src/app/models/StockChange';
import { BeansReport } from 'src/app/models/BeansReport';
import { Coffee } from 'src/app/models/Coffee';
import { Sale } from 'src/app/models/Sale';
import { Purchase } from 'src/app/models/Purchase';
import { Location } from 'src/app/models/Location';
import { Utils } from 'src/app/util/utils';
import { Enumerations } from 'src/app/models/Enumerations';
import { RoastReportOverview } from './roasts/RoastReportOverview';
import { Sort } from '@angular/material/sort';
import { Constants } from 'src/app/util/constants';
import { GetPageOptions, StandardService } from 'src/app/util/services/standard.service';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class ReportService {

    constructor(
        private http: HttpClient,
        private tr: TranslatorService,
        private utils: Utils,
        private standardService: StandardService,
    ) { }

    STD_LIMIT = 10;
    // MAX_LIST_ROASTS = 20;
    MAX_LIST_ROASTS = 500;

    /**
     * Returns the coffee tax in the given country
     * Currently always return 2.19 (€, for Germany) unless
     * type is soluble, then it returns 4.78 (€, for Germany)
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCoffeeTax(country?: string, type?: 'soluble'): number {
        return type === 'soluble' ? 4.78 : 2.19;
    }

    private makeParams(prms: string[]): HttpParams {
        let httpParams = new HttpParams();
        if (!prms || !prms.length) {
            return httpParams;
        }
        for (let p = 0; p < prms.length; p += 2) {
            if (prms[p + 1]) {
                httpParams = httpParams.set(prms[p], prms[p + 1]);
            }
        }
        return httpParams;
    }

    // /**
    //  * Returns new HttpParams object with the given options.
    //  * Currently uses getOrganic, limit, anpa, origins, ssf
    //  * @param httpParams HTTP parameters to add to
    //  * @param options filter options
    //  */
    // private addFilterParams(httpParams: HttpParams, options: RetrieveRoastsOptions) {
    //     if (options) {
    //         if (options.getOrganic) {
    //             httpParams = httpParams.set('certType', (options.getOrganic === 'on' ? Enumerations.CertificationTypes.ORGANIC : Enumerations.CertificationTypes.NOT_ORGANIC).toString());
    //         }
    //         if (options.limit) {
    //             httpParams = httpParams.set('limit', options.limit);
    //         }
    //         if (options.anpa) {
    //             httpParams = httpParams.set('anpa', '1');
    //         }
    //         if (options.origins?.length) {
    //             httpParams = httpParams.set('origins', options.origins ? JSON.stringify(options.origins) : undefined);
    //         }
    //         if (options.ssf?.length && options.ssf !== 'all') {
    //             const ssfStr = options.ssf?.map(l => l._id.toString()).join(Constants.SSF_SEPARATOR);
    //             if (ssfStr) {
    //                 httpParams = httpParams.set('ssf', ssfStr);
    //             }
    //         }
    //     }
    //     return httpParams;
    // }

    /**
     * Generates an XML/CSV/... file on the server and returns it for download (Content Disposition header).
     */
    getBlendsData(format: 'pdf' | 'csv' | 'sheet', ssf: string, sortValue: string, inverse: boolean, locale?: string): Observable<Blob> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/blends/' + format;
        const httpParams = this.makeParams([
            'locale', locale,
            'ssf', ssf,
            'sortValue', sortValue,
            'inverse', inverse.toString(),
        ]);
        
        return this.http.get(url, { responseType: 'blob' as const, params: httpParams });
    }

    /**
     * Generates data on the server and returns it (e.g. for copying to the clipboard).
     */
    getBlendsDataCopy(format: 'clipboardCSV', ssf: string, sortValue: string, inverse: boolean, locale?: string): Observable<{ success: boolean, result: string, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/blends/clipboardCSV';
        const httpParams = this.makeParams([
            'locale', locale,
            'ssf', ssf,
            'sortValue', sortValue,
            'inverse', inverse.toString(),
        ]);
        
        return this.http.get<{ success: boolean, result: string, error: string }>(url, { params: httpParams });
    }

    // /**
    //  * Retrieves all existing reports. If isOpen is true, gets from RoastReportsOpen
    //  * @param pageSize the number of reports to get
    //  * @param pageIndex the current page index to retrieve
    //  * @param roastPageSize the number of roasts within each report to retrieve
    //  * @param isOpenReport free report (true) or fixed report (false)
    //  */
    // getRoastReports(pageSize?: number, pageIndex?: number, roastPageSize?: number,
    //     isOpenReport = false, sortOrder?: string, inverse?: boolean): Observable<{ success: boolean, result: (RoastReport)[], count: number, error: string }> {
    //     const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting';
    //     const httpParams = this.makeParams([
    //         'isOpen', isOpenReport ? '1' : undefined,
    //         'limit', pageSize?.toString(),
    //         'page', pageIndex ? (pageIndex + 1).toString() : undefined,
    //         'sort', sortOrder,
    //         'inverse', inverse ? '1' : undefined,
    //         'roastLimit', roastPageSize?.toString(),
    //     ]);
    //     return this.http.get<{ success: boolean, result: (RoastReport)[], count: number, error: string }>(url, params: httpParams);
    // }

    getInverseFlag(currentSort?: Sort): string {
        if (!currentSort) {
            return undefined;
        }
        //  * @param {boolean} inverse if false, sorts according to the expected order (date, lastmodified, organic: desc, others: asc)
        if (['date', 'lastmodified', 'organic'].includes(currentSort.active)) {
            if (currentSort.direction !== 'asc') {
                return undefined;
            }
            return '1';
        }
        if (currentSort.direction !== 'desc') {
            return undefined;
        }
        return '1';
    }

    /**
     * Retrieves all existing reports. Does not include the roasts themselves, only roastsCount!
     * If isOpenReport is true, gets from RoastReportsOpen
     * @param pageSize the number of reports to get
     * @param pageIndex the current page index to retrieve
     * @param isOpenReport free report (true) or fixed report (false)
     * @param reportLabel label of a specific report to retrieve
     * @param currentSort sort order and direction
     */
    // eslint-disable-next-line max-len
    getRoastReportsMeta(pageSize?: number, pageIndex?: number, isOpenReport = false, reportLabel?: string, currentSort?: Sort): Observable<{ success: boolean, result: { reports: RoastReport[], pageIndex: number, lastEndDate: number | string, lastNumber: string }, count: number, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roastsmeta';
        const httpParams = this.makeParams([
            'isOpen', isOpenReport ? '1' : undefined,
            'limit', pageSize?.toString(),
            'page', pageIndex ? (pageIndex + 1).toString() : undefined,
            'sort', currentSort?.active,
            'inverse', this.getInverseFlag(currentSort),
            'reportLabel', reportLabel,
        ]);

        return this.http.get<{ success: boolean, result: { reports: (RoastReport)[], pageIndex: number, lastEndDate: number | string, lastNumber: string }, count: number, error: string }>(url, { params: httpParams });
    }

    /**
     * Moves or copies all existing "fixed" reports to "open" reports.
     * @param roastPageSize the number of roasts within each report to retrieve
     * @param makeCopy if true, copies of the reports will remain in the fixed section
     */
    moveFixedRoastReports(roastPageSize?: number, makeCopy = false): Observable<{ success: boolean, result: (RoastReport)[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/fixed/toopen';
        const httpParams = this.makeParams([
            'makeCopy', makeCopy ? '1' : undefined,
            'roastLimit', roastPageSize?.toString(),
        ]);

        return this.http.put<{ success: boolean, result: (RoastReport)[], error: string }>(url, undefined, { params: httpParams });
    }

    /**
     * Retrieves all info of a specific existing report.
     * @param {string} reportId _id of the existing report
     * @param {boolean} isOpenReport whether it is for an open report or not
     * @param {number} limit the maximum number of roasts within the report (0 for all)
     * @returns response.count is the number of roasts in the report (independent of limit)
     */
    getRoastReport(reportId: string, isOpenReport = false, limit = 0): Observable<{ success: boolean, result: RoastReport, count: number, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/report/' + reportId;
        const paramList = ['isOpen', isOpenReport ? '1' : undefined];
        if (limit) {
            paramList.push('limit');
            paramList.push(limit.toString());
        }
        const httpParams = this.makeParams(paramList);

        return this.http.get<{ success: boolean, result: RoastReport, count: number, error: string }>(url, { params: httpParams });
    }

    /**
     * Retrieves all roasts that have missing info,
     * e.g. no location, no date, no coffee/blend,
     * or no amount/end_weight if not discarded and not anpa (allowNonPositiveAmounts)
     * for a specific date range
     */
    getRoastsWithMissingInfo(isOpenReport = false, options?: GetPageOptions): Observable<{ success: boolean, result: Roast[], count: number, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/missinginfo';
        let httpParams = this.makeParams([
            'isOpen', isOpenReport ? '1' : undefined,
        ]);
        httpParams = this.standardService.addFilterOptions(httpParams, options);

        return this.http.get<{ success: boolean, result: Roast[], count: number, error: string }>(url, { params: httpParams });
    }

    /**
     * Retrieves the sets of all origins and origin regions of roasts
     * within the given date range
     */
    getOrigins(from?: DateTime, to?: DateTime): Observable<{ success: boolean, result: { origins: string[], originregions: string[] }, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/origins';
        const paramList = [
            'from', from?.valueOf()?.toString(),
            'to', to?.valueOf()?.toString(),
        ];
        const httpParams = this.makeParams(paramList);

        return this.http.get<{ success: boolean, result: { origins: string[], originregions: string[] }, count: number, error: string }>(url, { params: httpParams });
    }

    /**
     * Retrieves all roasts with a "date" between the given dates.
     * Roasts are sorted from oldest to newest
     * @param {boolean} isOpenReport whether the call is for an open report or not
     * @param {GetPageOptions} options a FilterOptions object
     * Options potentially contain
     * - whether to get only organic items (getOrganic 'on'), no organic items ('off') or all
     * - whether to allowNonPositiveAmounts (anpa)
     * - a limit for the number of returned items
     * - one or an array of origins to filter
     * @see GetPageOptions
     */
    getRoastsWithin(isOpenReport = false, options?: GetPageOptions): Observable<{ success: boolean, result: Roast[], count: number, v: string, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/within';
        let httpParams = this.makeParams([
            'isOpen', isOpenReport ? '1' : undefined,
            'sort', 'roastdate',
            'inverse', '1',
        ]);
        httpParams = this.standardService.addFilterOptions(httpParams, options);

        return this.http.get<{ success: boolean, result: Roast[], count: number, v: string, error: string }>(url, { params: httpParams });
    }

    /**
     * Retrieves summary info of a specific existing report or all roasts in a
     * specified timeframe.
     * @param {string} reportId _id of the existing report
     * @param {boolean} isOpenReport whether the call is for an open report or not
     * @param {GetPageOptions} options a FilterOptions object
     */
    getRoastReportOverview(reportId?: string, isOpenReport = false, options?: GetPageOptions): Observable<{ success: boolean, result: RoastReportOverview, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/overview';
        let httpParams = this.makeParams([
            'reportId', reportId,
            'isOpen', isOpenReport ? '1' : undefined,
        ]);
        httpParams = this.standardService.addFilterOptions(httpParams, options);

        return this.http.get<{ success: boolean, result: RoastReportOverview, error: string }>(url, { params: httpParams });
    }

    /**
     * Retrieves information on all roasts that have not yet been assigned to a report.
     * Does not need info about filters such as organic or origins since this method is
     * only called for fixed reports and those filters are not allowed on fixed reports.
     * @param {Date} from potential start date
     * @param {Date} until potential end date
     * @returns list of roasts that contain at least date, label, hr_id, amount, end_weight
     * (also coffee, blend, location but only as _ids to be able to check why it is missing)
     */
    getMissingRoasts(until?: DateTime, from?: DateTime): Observable<{ success: boolean, result: Roast[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/notreported';
        let httpParams = new HttpParams();
        if (until) {
            httpParams = httpParams.set('until', until.valueOf().toString());
        }
        if (from) {
            httpParams = httpParams.set('from', from.valueOf().toString());
        }

        return this.http.get<{ success: boolean, result: Roast[], error: string }>(url, { params: httpParams });
    }

    /**
     * Adds a given report. The server adds the roasts by looking at the options itself
     * (any given report.roasts are ignored). Relevant parameters are:
     * report: startDate, endDate;
     * options: certType, anpa, origins (limit is ignored)
     * Does not reconcile any roasts.
     * @param isOpenReport adds a RoastReportOpen if true, a RoastReport otherwise
     * @param options determines the roasts that are included in the report certType, anpa, origins
     */
    addRoastReport(report: RoastReport, isOpenReport = false, options?: GetPageOptions): Observable<{ success: boolean, result: RoastReport, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roast';
        let httpParams = this.makeParams([
            'isOpen', isOpenReport ? '1' : undefined,
        ]);
        if (options) {
            if (isOpenReport) {
                httpParams = this.standardService.addFilterOptions(httpParams, options);
            } else {
                // fixed reports don't allow any filter options other than allowNonPositiveAmounts
                if (options.anpa) {
                    httpParams = httpParams.set('anpa', '1');
                }
            }
        }

        return this.http.post<{ success: boolean, result: RoastReport, error: string }>(url, report, { params: httpParams });
    }

    /**
     * Generates a PDF file on the server. Returns the link to the file.
     */
    createRoastReportPDF(reportId: string, isOpenReport = false, overview = false, locale?: string, currentSort?: Sort): Observable<{ success: boolean, result: { path: string, date: DateTime }, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/pdf/' + reportId;
        const httpParams = this.makeParams([
            'overview', overview ? '1' : undefined,
            'isOpen', isOpenReport ? '1' : undefined,
            'locale', locale,
            'sortOrder', currentSort?.active,
            'sortDesc', currentSort?.direction === 'asc' ? undefined : '1',
        ]);

        return this.http.post<{ success: boolean, result: { path: string, date: DateTime }, error: string }>(url, undefined, { params: httpParams });
    }

    // /**
    //  * Generates a Sheet file (XLSX) on the server and returns it for download (Content Disposition header).
    //  */
    // getRoastReportSheet(reportId: string, locale?: string): Observable<Blob> {
    //     const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/sheet/' + reportId;
    //     return this.http.get(url, { responseType: 'blob' as const, params: this.makeParams(['locale', locale]) });
    // }

    /**
     * Generates text data on the server and returns it for copying to the clipboard.
     */
    getRoastReportCopy(reportId: string, isOpenReport = false, overview = false, locale?: string, currentSort?: Sort): Observable<{ success: boolean, result: string, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/copy/' + reportId;
        const httpParams = this.makeParams([
            'overview', overview ? '1' : undefined,
            'isOpen', isOpenReport ? '1' : undefined,
            'locale', locale,
            'sortOrder', currentSort?.active,
            'sortDesc', currentSort?.direction === 'asc' ? undefined : '1',
        ]);

        return this.http.get<{ success: boolean, result: string, error: string }>(url, { params: httpParams });
    }

    /**
     * Generates an XML/CSV/... file on the server and returns it for download (Content Disposition header).
     */
    getRoastReportFile(reportId: string, format: 'xml' | 'csv' | 'sheet', isOpenReport = false, overview = false, locale?: string, currentSort?: Sort): Observable<Blob> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/' + format + '/' + reportId;
        const httpParams = this.makeParams([
            'overview', overview ? '1' : undefined,
            'isOpen', isOpenReport ? '1' : undefined,
            'locale', locale,
            'sortOrder', currentSort?.active,
            'sortDesc', currentSort?.direction === 'asc' ? undefined : '1',
        ]);

        return this.http.get(url, { responseType: 'blob' as const, params: httpParams });
    }

    /**
     * Generates a Sheet file (XLSX) on the server and returns it for download (Content Disposition header).
     */
    getSheetFromArray(data: unknown[], locale?: string): Observable<Blob> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/arraysheet';

        return this.http.post(url, { data }, { responseType: 'blob', params: this.makeParams(['locale', locale]) });
    }

    /**
     * Generates a PDF file on the server and returns it for download (Content Disposition header).
     */
    getPDFFromArray(data: unknown[], title: string, locale?: string, landscape = false): Observable<Blob> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/arraypdf';

        return this.http.post(url, { data }, { responseType: 'blob', params: this.makeParams(['locale', locale, 'title', title, 'ls', landscape ? '1' : undefined])});
    }

    /**
     * Updates a roast report. If the newData.roasts array is undefined, the server will retrieve
     * the roasts that fit to the report's data itself. Otherwise, it should be a list
     * of roast _ids.
     * Limit in GetPageOptions is ignored.
     */
    updateRoastReport(reportId: string, newData: RoastReport, isOpenReport = false, options?: GetPageOptions): Observable<{ success: boolean, result: RoastReport, count: number, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/' + reportId;
        let httpParams = this.makeParams([
            'isOpen', isOpenReport ? '1' : undefined,
        ]);
        if (options) {
            if (isOpenReport) {
                httpParams = this.standardService.addFilterOptions(httpParams, options);
            } else {
                if (options.anpa) {
                    httpParams = httpParams.set('anpa', '1');
                }
            }
        }
        return this.http.put<{ success: boolean, result: RoastReport, count: number, error: string }>(url, newData, { params: httpParams });
    }

    /**
     * Finalizes the report and all roasts referenced within the report.
     */
    reconcileRoastReport(reportId: string): Observable<{ success: boolean, result: RoastReport, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/reconcile/' + reportId;

        return this.http.put<{ success: boolean, result: RoastReport, error: string }>(url, undefined);
    }

    /**
     * Deletes a given report. Should remove the .report property of all reference roasts.
     */
    deleteRoastReport(reportId: string, isOpenReport = false): Observable<{ success: boolean, result: string, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roast/' + reportId;
        const params = isOpenReport ? { params: { isOpen: '1' } } : undefined;

        return this.http.delete<{ success: boolean, result: string, error: string }>(url, params);
    }


    /**
     * Retrieves all existing reports.
     */
    getBeansReports(): Observable<{ success: boolean, result: BeansReport[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/beans';

        return this.http.get<{ success: boolean, result: BeansReport[], error: string }>(url);
    }

    /**
     * Retrieves the date fo the first transaction (of the given type)
     * @param ttypes either 'roast', 'nonroasts', undefined (all StockChanges), or a list of StockChange model names
     * such as 'Purchase,Sale'
     */
    getFirstTransactionDate(ttypes: string[]): Observable<{ success: boolean, result: number, error: string }> {
        const typesStr = ttypes.join(Constants.TYPES_SEPARATOR);
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/first';

        return this.http.get<{ success: boolean, result: number, error: string }>(url, { params: { type: typesStr } });
    }

    /**
     * Adds a given report.
     */
    addBeansReport(report: BeansReport): Observable<{ success: boolean, result: BeansReport, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/beans';

        return this.http.post<{ success: boolean, result: BeansReport, error: string }>(url, report);
    }

    /**
     * Deletes a given report. Should remove the .report property of all reference roasts.
     */
    deleteBeansReport(reportId: string): Observable<{ success: boolean, result: string, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/beans/' + reportId;

        return this.http.delete<{ success: boolean, result: string, error: string }>(url);
    }

    /**
     * Updates a beans report.
     */
    updateBeansReport(reportId: string, newData: BeansReport): Observable<{ success: boolean, result: BeansReport, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/beans/' + reportId;

        return this.http.put<{ success: boolean, result: BeansReport, error: string }>(url, newData);
    }

    /**
     * Retrieves all StockChanges other than Roasts with a "date" between the given dates.
     * Sorted newest to oldest.
     * @param {string[]} specificTypes either undefined (all StockChanges) or a list of types, e.g. 'Purchase,Sale' (returns [] if [])
     * @param {GetPageOptions} options containing from and to, each a date
     */
    getAllNonRoastsWithin(specificTypes: string[], options: GetPageOptions): Observable<{ success: boolean, result: StockChange[], count: number, error: string }> {
        if (specificTypes?.length === 0) {
            return of({ success: true, result: [], count: 0, error: '' });
        }
        const specificTypesStr = specificTypes?.join(Constants.TYPES_SEPARATOR);
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/stockchanges' + (specificTypes ? ('/' + specificTypesStr) : '');

        const httpParams = this.standardService.addFilterOptions(new HttpParams(), options);

        return this.http.get<{ success: boolean, result: StockChange[], count: number, error: string }>(url, { params: httpParams });
    }

    /**
     * Returns the list of columns that make sense to be displayed according to the given list of transactions and/or transaction types.
     * @param transactions list of transactions to display
     * @param transTypes set of transaction types (.__t) to display
     * @returns list of column names
     */
    getBeansReportColumns(transactions?: StockChange[], transTypes?: Set<string>): string[] {
        // const columnsToDisplay = ['Number', 'Date', 'Transaction-ID', 'Coffees', 'Amount', 'Cost', 'Store', 'References'];
        const columnsToDisplay = ['Number', 'Date', 'Coffees', 'Amount', 'Store', 'References'];
        let addTransactionId = false;
        let addCost = false;
        if (!transTypes) {
            transTypes = new Set<string>();
        }
        if (transactions) {
            for (let t = 0; t < transactions.length; t++) {
                transTypes.add(transactions[t].__t);
                if ((transactions[t] as Purchase).order_number || (transactions[t] as Sale).sales_number) {
                    addTransactionId = true;
                }
                if ((transactions[t] as Purchase | Sale).price) {
                    addCost = true;
                }
            }
        }
        if (transTypes.has('Purchase')) {
            columnsToDisplay.splice(columnsToDisplay.length - 2, 0, 'Supplier');
        }
        if (transTypes.has('Sale')) {
            columnsToDisplay.splice(columnsToDisplay.length - 2, 0, 'Customer');
        }
        if (transTypes.has('Transfer')) {
            columnsToDisplay.splice(columnsToDisplay.length - 1, 0, 'Target Store');
        }
        if (transTypes.has('Correction')) {
            columnsToDisplay.splice(columnsToDisplay.length - 1, 0, 'Reason');
        }
        if (addCost) {
            columnsToDisplay.splice(4, 0, 'Cost');
        }
        if (addTransactionId) {
            columnsToDisplay.splice(2, 0, 'Transaction-ID');
        }
        return columnsToDisplay;
    }


    /**
     * Returns the information needed to generate the customs report for the given country and variant.
     * The possible values can be queried using getCustomsTypes()
     */
    // eslint-disable-next-line max-len
    getCustomsReport(customsCountry: string, customsType: string, fromEpochMS: number, toEpochMS: number): Observable<{ success: boolean, result: { roasts: Roast[], sales: Sale[], purchases: Purchase[], lastPurchases: { coffee: Coffee, date: DateTime, amount: number, price: number }[] }, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/customs/' + customsCountry + '/' + customsType;
        const httpParams = this.makeParams(['from', fromEpochMS.toString(), 'to', toEpochMS.toString()]);

        return this.http.get<{ success: boolean, result: { roasts: Roast[], sales: Sale[], purchases: Purchase[], lastPurchases: { coffee: Coffee, date: DateTime, amount: number, price: number }[] }, error: string }>(url, { params: httpParams });
    }

    /**
     * Retrieves all implemented customs report types (list of country + type)
     */
    getCustomsTypes(): Observable<{ success: boolean, result: { country: string, variants: string[] }[], error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/customs/types/';

        return this.http.get<{ success: boolean, result: { country: string, variants: string[] }[], error: string }>(url);
    }

    /**
     * Returns stock data for a specific date and organic filter.
     */
    getStocksReport(dateEpochMS: number, getOrganic: 'on' | 'off' | '' = ''): Observable<{ success: boolean, result: { perCofLoc: { c: Coffee, l: Location, a: number }[] }, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/stocks';
        let httpParams = this.makeParams(['date', dateEpochMS.toString()]);
        if (getOrganic) {
            httpParams = httpParams.set('certType', (getOrganic === 'on' ? Enumerations.CertificationTypes.ORGANIC : Enumerations.CertificationTypes.NOT_ORGANIC).toString());
        }
        return this.http.get<{ success: boolean, result: { perCofLoc: { c: Coffee, l: Location, a: number }[] }, error: string }>(url, { params: httpParams });
    }


    /**
     * Generates an XML file on the server and returns it for download (Content Disposition header).
     */
    createXML(data: unknown, reportType: '1830' | '1816' | '1807' | '1807ZP'): Observable<Blob> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/xml/' + reportType;

        return this.http.post(url, data, { responseType: 'blob' });
    }


    isInvalidRoast(roast: Roast, options: { anpa: boolean } = { anpa: false }): string {
        if (!roast || (!roast.coffee && !roast.blend)) {
            return this.tr.anslate('no beans or blend');
        }
        if (!roast.date) {
            return this.tr.anslate('no roast date');
        }
        if (!roast.discarded && (!roast.amount || !roast.end_weight) && !options.anpa) {
            return this.tr.anslate('weight or yield not positive');
        }
        if (!roast.location) {
            return this.tr.anslate('no roast location');
        }
        return undefined;
    }

    isInvalidTransaction(transaction: StockChange): string {
        if (!transaction.coffee) {
            return this.tr.anslate('no beans specified');
        }
        if (!transaction.date) {
            return this.tr.anslate('Invalid date');
        }
        if (transaction.__t !== 'Correction' && (!transaction.amount || isNaN(transaction.amount))) {
            return this.tr.anslate('no amount');
        }
        if (!transaction.location) {
            return this.tr.anslate('must specify location of change');
        }
        if (transaction.__t === 'Transfer' && !transaction['target']) {
            return this.tr.anslate('must specify target of transfer');
        }
        return undefined;
    }

    createCoffeeLabel(coffee: Coffee, verbose = false, charBeforeLabel = '\n', includeId = true, mustNotContainChar?: string): string {
        if (!coffee) {
            return '';
        }
        let text = '';
        if (includeId && coffee.hr_id) {
            text += coffee.hr_id;
        }
        if (verbose) {
            if (coffee.origin) {
                text += (includeId && coffee.hr_id ? ' ' : '') + this.tr.anslate(coffee.origin);
            }
            coffee.yearLabel = coffee.yearLabel || this.utils.createBeansYearLabel(coffee);
            if (coffee.yearLabel) {
                text += ((includeId && coffee.hr_id) || coffee.origin ? ' ' : '') + coffee.yearLabel;
            }
            if (coffee.label && ((includeId && coffee.hr_id) || coffee.origin || coffee.yearLabel)) {
                text += charBeforeLabel;
            }
        }
        if (coffee.label) {
            text += (!text || (verbose && (charBeforeLabel === '\n' || charBeforeLabel === ' ')) ? '' : ' ') + coffee.label;
        }
        if (mustNotContainChar) {
            text.replace(mustNotContainChar, ' ');
        }
        return text;
    }

    createBlendLabel(blend: { label?: string, ingredients?: { coffee: Coffee, ratio: number }[] },
        verbose = false, charBeforeLabel = '\n', separator = '\n', mustNotContainChar?: string): string {
        let text = '';
        for (let i = 0; i < blend.ingredients.length; i++) {
            const ing = blend.ingredients[i];
            if (!ing.coffee || typeof ing.ratio === 'undefined') {
                text += ' - ';
                continue;
            }
            text += (ing.ratio * 100).toFixed(2) + '%'
            // text += this.numberPipe.transform(ing.ratio * 100, '1.1-1') + '%';
            if (ing.coffee.hr_id) {
                text += ' ' + ing.coffee.hr_id;
            }
            if (verbose) {
                if (ing.coffee.origin) {
                    text += ' ' + this.tr.anslate(ing.coffee.origin);
                }
                ing.coffee.yearLabel = ing.coffee.yearLabel || this.utils.createBeansYearLabel(ing.coffee);
                if (ing.coffee.yearLabel) {
                    text += ' ' + ing.coffee.yearLabel;
                }
                if (ing.coffee.label && (ing.coffee.hr_id || ing.coffee.origin || ing.coffee.yearLabel)) {
                    text += charBeforeLabel;
                }
            }
            if (ing.coffee.label) {
                text += (!text || (verbose && (charBeforeLabel === '\n' || charBeforeLabel === ' ')) ? '' : ' ') + ing.coffee.label;
            }
            if (i < blend.ingredients.length - 1) {
                text += separator;
            }
        }
        if (mustNotContainChar) {
            text.replace(mustNotContainChar, ' ');
        }
        return text;
    }

    /**
     * Retrieves summary info about all roasts within the given interval of time. E.g. the sum of all end_weights.
     */
    getRoastsSummary(from: DateTime, to: DateTime): Observable<{ success: boolean, result: { endWeight: { sum: number, count: number }, discarded: { sum: number, count: number, destroyedCnt: number } }, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/roasts/summary';
        const httpParams = this.makeParams([
            'from', from.valueOf().toString(),
            'to', to.valueOf().toString(),
        ]);

        return this.http.get<{ success: boolean, result: { endWeight: { sum: number, count: number }, discarded: { sum: number, count: number, destroyedCnt: number } }, error: string }>(url, { params: httpParams });
    }

    /**
     * Retrieves info about the last saved report of the given type.
     */
    getLastReportInfo(reportType: string): Observable<{ success: boolean, result: { endDate: DateTime, istbestand?: number, startDate?: DateTime }, error: string }> {
        const url = environment.BASE_API_URL + environment.SUB_API_URL + '/reporting/customs/last/' + reportType;

        return this.http.get<{ success: boolean, result: { endDate: DateTime, istbestand?: number, startDate?: DateTime }, error: string }>(url);
    }

    // returns a comma seperated list of transaction types, or 'nonroasts' if all are currently selected
    getTypes(selectedTypes: { Purchase: boolean, Sale: boolean, Correction: boolean, Transfer: boolean }): ['nonroasts'] | string[] {
        if (selectedTypes['Purchase'] && selectedTypes['Sale'] && selectedTypes['Correction'] && selectedTypes['Transfer']) {
            return ['nonroasts'];
        } else {
            return Object.getOwnPropertyNames(selectedTypes).filter(ttype => selectedTypes[ttype]);
        }
    }

    // calculates a combination of Enumerations.StockchangeTypes for the currently selected selectionTypes
    getTypesEnum(selectedTypes: { Purchase: boolean, Sale: boolean, Correction: boolean, Transfer: boolean }): number {
        let t = 0;
        if (selectedTypes['Purchase']) t += Enumerations.StockchangeTypes.PURCHASE;
        if (selectedTypes['Sale']) t += Enumerations.StockchangeTypes.SALE;
        if (selectedTypes['Correction']) t += Enumerations.StockchangeTypes.CORRECTION;
        if (selectedTypes['Transfer']) t += Enumerations.StockchangeTypes.TRANSFER;
        return t;
    }

    /**
     * Suggests a report label based on the dates and other info
     * @param filter filter options containing from, to, showOrganic, origins
     * @param reportName current reportName used as a fallback / default
     * @param reportNames optional list of names that must not be used (e.g. already existing ones)
     * @param fromOneStore if given, this string is appended to the created label (with a space)
     * @returns a string label
     */
    suggestLabel(filter: GetPageOptions, reportName: string, reportNames?: string[], fromOneStore?: string): string {
        let label = reportName;
        const days = Math.floor(filter.to.diff(filter.from, 'days').days);
        if (days === 0) {
            // Month name, day of month, year	LL	September 4, 1986
            label = filter.from.toLocaleString(DateTime.DATE_MED);
        } else if (days <= 7) {
            // Week of Year	w	1 2 ... 52 53
            label = this.tr.anslate('Week {{week number}}, {{year}}', { 'week number': filter.from.toFormat('W'), year: filter.from.year });
        } else if (days <= 31) {
            label = this.utils.getMonthStr(filter.from.month - 1) + ' ' + filter.from.year;
        } else if (days <= 3 * 31) {
            label = this.tr.anslate('Quarter {{quarter number}}, {{year}}', { 'quarter number': Math.floor((filter.from.month - 1) / 4) + 1, year: filter.from.year });
        } else if (days >= 360 && days < 370 && filter.to.ordinal < 2) {
            label = `${this.tr.anslate('Year')} ${filter.to.year}`;
        }

        let cnt = 2;
        const origLabel = label;
        while (reportNames?.indexOf(label) >= 0 && cnt <= 999) {
            label = `${origLabel} (${cnt})`;
            cnt += 1;
        }

        if (filter.showOrganic === 'on' || (typeof filter.showOrganic === 'undefined' && filter.showOrganic === 'on')) {
            label += ` ${this.tr.anslate('organic')}`;
        }
        if (filter.origins?.vals?.length) {
            label += ` ${filter.origins.vals.map(origin => this.tr.anslate(origin)).join('; ')}`;
        } else if (filter.origins?.notnull && filter.origins.vals?.length) {
            label += ` ${this.tr.anslate(filter.origins.vals[0])}`;
        }
        if (fromOneStore) {
            label += ` ${fromOneStore}`;
        }

        // remove consecutive whitespace which might appear bc of the translation
        return label.replace(/\s\s+/g, ' ');
    }

    /**
     * Creates a new report name based on the name of the last report and the
     * start year of the current report.
     * If the year of the last report is the same as the current year, the
     * number is increased. Else the year of the current report is used and
     * numbering starts with 001.
     * @param lastName name of last report if any; format: 2019-002
     * @param thisYear (start) year of current report
     */
    createReportName(lastName: string, thisYear: number): string {
        if (!lastName) {
            return thisYear + Constants.REPORTNUMBER_SEPARATOR + '001';
        }
        const lnsplit = lastName.split(Constants.REPORTNUMBER_SEPARATOR);
        const lastYear = Number.parseInt(lnsplit[0], 10);
        if (lastYear === thisYear) {
            const lastNum = lnsplit.length > 1 ? Number.parseInt(lnsplit[1], 10) : 0;
            return lastYear + Constants.REPORTNUMBER_SEPARATOR + (lastNum + 1).toString().padStart(3, '0');
        }
        return thisYear + Constants.REPORTNUMBER_SEPARATOR + '001';
    }

    /**
     * Calculates combination of Enumerations.CertificationTypes for the given state
     * @param showOrganic state of the organic switch
     * @returns combination of Enumerations.CertificationTypes
     */
    getCertInfo(showOrganic: 'on' | 'off' | ''): number {
        if (showOrganic === 'on') return Enumerations.CertificationTypes.ORGANIC;
        if (showOrganic === 'off') return Enumerations.CertificationTypes.NOT_ORGANIC;
        return 0;
    }
}

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-container {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 30px;
}

.text-container h2 {
  font-size: 18px;
  font-weight: 300;
}

.text-container h3 {
  font-size: 16px;
  font-weight: 200;
}

.text-container h4 {
  font-size: 16px;
  font-weight: 100;
}

.text-container .text-heading {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  width: 100%;
  color: #0c6aa6;
  font-weight: bold;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

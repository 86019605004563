// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-group {
  display: flex;
  margin: 10px 0;
  align-items: center;
}

.beans-search-beans {
  margin-top: 20px;
  margin-bottom: 10px;
}

.beans-search-beans,
.beans-search-blends,
.beans-search-formfields {
  margin-right: 15px;
  min-width: 300px;
}

.beans-search-beans,
.beans-search-blends {
  min-height: 61px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

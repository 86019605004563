import { TransService } from './trans.service';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Location } from 'src/app/models/Location';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { StockChange } from 'src/app/models/StockChange';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Coffee } from 'src/app/models/Coffee';
import { Subject } from 'rxjs';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ShareMostRecentTransactionsService } from 'src/app/util/services/share-mostrecenttransactions.service';
import { Blend } from 'src/app/models/Blend';
import { Router } from '@angular/router';
import { StandardService } from 'src/app/util/services/standard.service';
import { MatDialog } from '@angular/material/dialog';
import { CantDeleteDialogComponent } from '../ui/dialog/cant-delete-dialog.component';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-store-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss'],
    providers: [],
})
export class TransactionsComponent implements OnInit, OnDestroy {

    constructor(
        public utils: Utils,
        private userService: UserService,
        private standardService: StandardService,
        private dialog: MatDialog,
        // needed in template:
        public tr: TranslatorService,
        private shareMostRecentTransactions: ShareMostRecentTransactionsService,
        private transService: TransService,
        private router: Router,
    ) { }

    @Input() store: Location;
    @Input() currentUser: UserType;
    @Input() coffee: Coffee;
    @Input() readOnly = false;

    @Output() allLoaded = new EventEmitter();

    sumByCoffeeRoasts: { coffee: Coffee, amount: number, fromDate: string, toDate: string }[] = [];

    transactions: StockChange[] = [];
    loading = false;
    checkingIfDeletable: string = undefined;

    Enumerations = Enumerations;
    DateTime = DateTime;

    mainUnit: UnitSystemType = 'kg';
    currency = 'EUR';

    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }

        if ((this.store && this.store._id) || (this.coffee && this.coffee._id)) {
            this.loading = true;
            this.getAllTransactions();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    getAllTransactions(): void {
        this.transService.getTransactions(this.store ? this.store._id : undefined, this.coffee ? this.coffee._id : undefined)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.transactions = this.utils.dateifyStockChanges(response.result);
                        this.transactions.sort((t1, t2) => +t2.date - +t1.date);
                        this.recalcBeansYearLabel();
                        this.getMostRecentPerCoffee();
                        this.sumByCoffeeRoasts = this.getSumByCoffeeRoasts();
                        if (this.store?.hr_id) {
                            this.allLoaded.emit(this.store.hr_id);
                        }
                    } else {
                        this.utils.handleError('error retrieving stock of a store', response.error);
                    }
                    this.loading = false;
                },
                error: error => {
                    this.utils.handleError('error retrieving stock of a store', error);
                    this.loading = false;
                }
            });
    }

    recalcBeansYearLabel(): void {
        if (this.transactions) {
            for (let t = 0; t < this.transactions.length; t++) {
                const trans = this.transactions[t];
                if (trans.coffee) {
                    trans.coffee.yearLabel = this.utils.createBeansYearLabel(trans.coffee);
                    if (trans.coffee.origin) {
                        trans.coffee['translated_origin'] = this.tr.anslate(trans.coffee.origin);
                    }
                }
                trans.translated_type = this.tr.anslate(trans.type);
            }
        }
    }

    deleteTransaction(transaction: StockChange): void {
        this.checkingIfDeletable = transaction._id;
        this.standardService.getRefs('stockchanges', transaction._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.checkingIfDeletable = undefined;
                    if (response.success === true) {
                        if (response.result.count > 0) {
                            const ondate = this.tr.anslate('on {{date}}', { date: transaction.date.toLocaleString(DateTime.DATE_MED) });
                            const dialogRef = this.dialog.open(CantDeleteDialogComponent, {
                                closeOnNavigation: true,
                                data: {
                                    label: `${transaction.translated_type}: ${(transaction.amount * this.utils.getUnitFactor(this.mainUnit)).toFixed(2)}${this.mainUnit} ${ondate}`,
                                    count: response.result.count,
                                    refs: this.utils.dateifyObjects(response.result.refs, ['date']),
                                    mainUnit: this.mainUnit,
                                    currency: this.currency
                                }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDelete(transaction);
                                }
                            });

                        } else {
                            this.doDelete(transaction);
                        }
                    } else {
                        this.utils.handleError('could not delete the data', response.error);
                    }
                },
                error: error => {
                    this.checkingIfDeletable = undefined;
                    this.utils.handleError('could not delete the data', error);
                }
            });
    }

    private doDelete(transaction: StockChange): void {
        this.transService.deleteTransaction(transaction, this.ngUnsubscribe, trans => {
            // make change locally
            let tIdx = -1;
            for (let t = 0; t < this.transactions.length; t++) {
                if ((this.transactions[t]._id || this.transactions[t]).toString() === (trans._id || trans).toString()) {
                    tIdx = t;
                    break;
                }
            }
            if (tIdx >= 0) {
                this.transactions.splice(tIdx, 1);
            }
            if (this.store) {
                const cId = transaction.coffee.hr_id ? transaction.coffee.hr_id : 'C' + transaction.coffee.internal_hr_id;
                const sId = this.store.hr_id ? this.store.hr_id : 'L' + this.store.internal_hr_id;
                const exTrans = this.shareMostRecentTransactions.getTransaction(cId, sId);
                if ((exTrans._id || exTrans).toString() === (transaction._id || transaction).toString()) {
                    // was most recent => update
                    this.shareMostRecentTransactions.removeTransaction(cId, sId);
                    this.getMostRecentPerCoffee();
                }
                // this.notifyChange(transaction);
            }
        });
    }

    editTransaction(transaction: StockChange): void {
        this.transService.editTransaction(transaction, this.ngUnsubscribe, trans => {
            transaction = trans;
            // shareTansactions are only interesting for the stores tab
            if (this.store) {
                const cId = trans.coffee.hr_id ? trans.coffee.hr_id : 'C' + trans.coffee.internal_hr_id;
                const sId = this.store.hr_id ? this.store.hr_id : 'L' + this.store.internal_hr_id;
                const exTrans = this.shareMostRecentTransactions.getTransaction(cId, sId);
                if (exTrans && (exTrans._id ?? exTrans).toString() === (transaction._id ?? transaction).toString()) {
                    // is most recent => update
                    this.shareMostRecentTransactions.setTransaction(cId, sId, transaction);
                }
                // this.notifyChange(transaction);
            }
        });
    }

    getMostRecentPerCoffee(): void {
        // shareTansactions are only interesting for the stores tab
        if (this.store) {
            // current shared list of transactions
            const map = this.shareMostRecentTransactions.getTransactions();

            // for all non-roast transactions, ensure that the latest is in the map
            const nonRoasts = this.transactions.filter(t => t.type !== 'Roast');
            for (let t = 0; t < nonRoasts.length; t++) {
                const trans = nonRoasts[t];
                if (trans.coffee) {
                    const cId = trans.coffee.hr_id ? trans.coffee.hr_id : 'C' + trans.coffee.internal_hr_id;
                    const sId = this.store.hr_id ? this.store.hr_id : 'L' + this.store.internal_hr_id;
                    const key = cId + '_' + sId;
                    const exTrans = map.get(key);
                    if (!exTrans || exTrans.date <= trans.date) {
                        map.set(key, trans);
                    }
                }
            }

            // update the shared map
            map.forEach((sc: StockChange, key: string) => {
                this.shareMostRecentTransactions.setTransactionPerKey(key, sc);
            });
        }
    }

    insertTransactionToList(t: StockChange): void {
        this.transactions.unshift(t);
        this.transactions.sort((t1, t2) => +t2.date - +t1.date);
        this.getMostRecentPerCoffee();
    }

    getNoRoasts(): StockChange[] {
        return this.transactions.filter(t => t.type !== 'Roast');
    }

    private getSumByCoffeeRoasts(): { coffee: Coffee, amount: number, fromDate: string, toDate: string }[] {
        if (this.transactions?.length > 0) {
            return Array.from(this.transactions.filter(t => t.type === 'Roast').reduce(
                (transCats, t) => {
                    const index = t.coffee ? t.coffee.label : (t['blend'] ? t['blend'].label : t.toString());

                    // aggregate by coffee or blend label
                    // if a coffee is used in a blend, the according roast is NOT shown at this coffee, only at the blend label!
                    if (transCats.has(index)) {
                        const trans = transCats.get(index);
                        trans.amount += t.amount;
                        trans.count += 1;
                        // calculate date interval within which the found roasts are ("from ... to ...")
                        if (t.date) {
                            // TODO could prob do without the new Date since the strings are comparable
                            if (!trans.fromDate || DateTime.fromISO(trans.fromDate) > t.date) {
                                trans.fromDate = t.date.toString();
                            }
                            if (!trans.toDate || DateTime.fromISO(trans.toDate) < t.date) {
                                trans.toDate = t.date.toString();
                            }
                        }
                    } else {
                        transCats.set(index, { coffee: t.coffee, blend: t['blend'], amount: t.amount, fromDate: t.date.toString(), toDate: t.date.toString(), count: 1 });
                    }
                    return transCats;
                }
                , new Map<string, { coffee: Coffee, blend: Blend, amount: number, fromDate: string, toDate: string, count: number }>())
                .values())
                // .filter((a) => a.coffee || a.blend)
                .sort((a, b) => b.amount - a.amount);
        }
        return [];
    }

    getAmount(transaction: StockChange): string {
        const unit = transaction.coffee && transaction.coffee.default_unit ? transaction.coffee.default_unit : { name: Enumerations.CoffeeUnits._NONE, size: 1 };
        return this.utils.formatAmount(transaction.amount, unit, this.currentUser.unit_system);
    }

    getBlendRatio(coffee: Coffee, blend: Blend): number {
        if (!blend?.ingredients || !coffee) {
            return 1;
        }
        for (let i = 0; i < blend.ingredients.length; i++) {
            const ing = blend.ingredients[i];
            if (!ing.coffee) {
                return 1;
            }
            if ((ing.coffee._id || ing.coffee).toString() === (coffee._id || coffee).toString()) {
                return ing.ratio;
            }
        }
        return 1;
    }
}

import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[appExistingEmail]',
    // eslint-disable-next-line no-use-before-define
    providers: [{ provide: NG_VALIDATORS, useExisting: ExistingEmailDirective, multi: true }]
})
export class ExistingEmailDirective implements Validator {

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() { }

    @Input('appExistingEmail') existingEmails: string[];

    validate(control: AbstractControl): { [key: string]: unknown } | null {
        return this.existingEmails.indexOf(control.value) >= 0 ? { existingEmail: { value: control.value } } : null;
    }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserType } from './modules/frame/services/user.service';

@Injectable()
export class ReadonlyaccountInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.indexOf('.php') === -1) {
            // add parameter 'account' if looking at a different account than the user's own account
            const currentUser = JSON.parse(localStorage.getItem('currentUser')) as UserType;
            if (currentUser?.token) {
                // logged in
                if (currentUser.readonly_account_idx >= 0 && currentUser.other_accounts[currentUser.readonly_account_idx]) {
                    if (request.method === 'GET' || request.method === 'DELETE') {
                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${currentUser.token}`
                            },
                            setParams: {
                                _account: currentUser.other_accounts[currentUser.readonly_account_idx]._id,
                            }
                        });
                    } else {
                        const newBody = Object.assign({}, request.body) as { _account: string };
                        newBody._account = currentUser.other_accounts[currentUser.readonly_account_idx]._id;
                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${currentUser.token}`
                            },
                            body: newBody,
                        });
                    }
                }
            }
        }

        return next.handle(request);
    }
}

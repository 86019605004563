/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, } from 'rxjs';
import { map } from 'rxjs/operators';
import { Enumerations } from 'src/app/models/Enumerations';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { GraphService } from 'src/app/modules/graph/graph.service';
import { Subject } from 'rxjs';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';


@Component({
    selector: 'app-store-graphs',
    templateUrl: './store-graphs.component.html',
    styleUrls: ['./store-graphs.component.scss'],
})
export class StoreGraphsComponent implements OnInit, OnDestroy {

    constructor(
        private breakpointObserver: BreakpointObserver,
        private userService: UserService,
        private graphService: GraphService,
        public utils: Utils,
        private router: Router,
    ) {
        Chart.registry.add(ChartDataLabels);
    }

    showstockfrom: { _id: string, hr_id?: string, label?: string }[] | 'all' = 'all';
    disableSSF = false;
    private _stores: { _id: string, hr_id?: string, label?: string }[] = [];
    get stores(): { _id: string, hr_id?: string, label?: string }[] { return this._stores; }
    @Input() set stores(s: { _id: string, hr_id?: string, label?: string }[]) {
        this._stores = s;
        this.showstockfrom = this.utils.readShowStockFrom(this._stores, this.currentUser);
        if (this._stores) {
            this.reloadData();
        }
    }

    data: any[];
    haveAgeGraphData = true;
    retrievingData = false;

    mainUnit: UnitSystemType = 'kg';
    currency = 'EUR';
    currentUser: any;

    isDarkmode = false;

    isSmall$: Observable<boolean>;

    loadSubscription = new Subject<string>();
    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();


    ngOnInit(): void {
        this.isSmall$ = this.breakpointObserver.observe('(max-width: 599px)')
            .pipe(map(result => result.matches));

        this.loadSubscription
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                if (this.stores) {
                    this.showstockfrom = this.utils.readShowStockFrom(this.stores, this.currentUser);
                }
                // doesn't work since set stores is first called with empty stores
                // and does nothing; the subsequent call with stores set should
                // do something but does nothing because of the throttling:
                // if (_val !== 'init') {
                //     this.getGraphData();
                // }
            }
            );

        this.currentUser = this.userService.getCurrentUser();
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }

        // only showstockfrom, does not call getGraphData; this is done in set stores
        this.loadSubscription.next('init');

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.setColor(this.isDarkmode);
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => {
                this.isDarkmode = this.userService.isDarkModeEnabled(dm);
                this.setColor(this.isDarkmode);
            }
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    private setColor(darkmode: boolean) {
        if (darkmode) {
            // (chart.js 4 update)
            if (Chart.defaults.color !== '#bdbdbd') {
                Chart.defaults.color = '#bdbdbd';
            }
        } else {
            if (Chart.defaults.color !== 'rgba(0, 0, 0, 0.67)') {
                Chart.defaults.color = 'rgba(0, 0, 0, 0.67)';
            }
        }
    }

    reloadData(): void {
        this.getGraphData();
    }

    getGraphData(): void {
        if (!this.showstockfrom) {
            this.showstockfrom = 'all';
        }
        this.retrievingData = true;
        this.graphService.getGraphData('store', { ssf: this.showstockfrom }, { maxNumberOfMonths: 12 })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.retrievingData = false;
                    if (response.success === true && response.result?.length) {
                        this.data = response.result;
                        // assume we now have some data and let age graph decide itself
                        this.haveAgeGraphData = true;

                    } else {
                        this.utils.handleError('error retrieving graph data', response.error);
                    }
                },
                error: error => {
                    this.retrievingData = false;
                    this.utils.handleError('error retrieving graph data', error);
                }
            });
    }

    hasAgeGraphDataChanged(haveData: boolean) {
        setTimeout(() => this.haveAgeGraphData = haveData);
    }

    showstockfromChanged($event: { value: { _id: string, hr_id?: string, label?: string }[] | 'all' }) {
        if (!$event.value?.length || $event.value === 'all' || $event.value.length === this.stores.length) {
            this.utils.storeShowStockFrom('all', this.currentUser);
            $event.value = 'all';
        } else {
            this.utils.storeShowStockFrom($event.value, this.currentUser);
        }
        this.showstockfrom = $event.value;

        if (this.showstockfrom.length === 0) {
            // workaround: age graph sometimes throws exception when quickly selecting no store and then one store again!
            // probably because the animation hasn't finished yet
            this.disableSSF = true;
            setTimeout(() => {
                this.disableSSF = false;
            }, 2000);
        }

        this.reloadData();
    }
}

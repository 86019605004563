import { Utils } from 'src/app/util/utils';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountChangedService } from './util/services/accountchanged.service';
import { DateTime } from 'luxon';

@Injectable()
export class AccountInterceptor implements HttpInterceptor {

    constructor(
        private accountChangedService: AccountChangedService,
        private userService: UserService,
        private utils: Utils,
    ) { }

    private lastPu: Date;
    private lastOl: { rlimit: number, rused: number };

    olEqual(newOl: { rlimit?: number, rused?: number }, oldOl: { rlimit?: number, rused?: number }): boolean {
        return newOl === oldOl
            || (newOl?.rlimit === oldOl?.rlimit && newOl?.rused === oldOl?.rused);
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(map(event => {
            // ignore if pu not given
            // (call to public API; response does not inlude .pu since request does not include user)
            if (event instanceof HttpResponse && event.body && (event.body.pu || event.body.ol || event.body.rem || event.body.notifications)) {
                if (event.body.pu !== this.lastPu
                    || (!this.olEqual(event.body.ol, this.lastOl))) {
                    this.lastPu = event.body.pu;
                    this.lastOl = event.body.ol;
                    // setTimeout(() => {
                    const user = this.userService.getCurrentUser();
                    if (!user) {
                        return event;
                    }
                    let needsUpdate = false;
                    if (this.utils.paidDaysLeft(event.body.pu) < 0 || this.utils.overLimit(event.body.ol)) {
                        // make sure readonly mode is set (if not already)
                        if (!user.readonly) {
                            user.readonly = true;
                            needsUpdate = true;
                        }
                    } else {
                        // disable readonly mode if not looking at a readonly_account
                        if (!(user.readonly_account_idx >= 0) && user.readonly) {
                            user.readonly = false;
                            needsUpdate = true;
                        }
                    }
                    if (event.body.pu.toString() !== user.account?.paidUntil?.toString()) {
                        user.account.paidUntil = event.body.pu ? DateTime.fromISO(event.body.pu) : undefined;
                        needsUpdate = true;
                    }
                    if (!this.olEqual(event.body.ol, user.account?.limit)) {
                        if (!user.account) {
                            user.account = {};
                        }
                        user.account.limit = event.body.ol;
                        needsUpdate = true;
                    }
                    if (needsUpdate) {
                        this.userService.storeCurrentUser(user);
                    }
                    // });
                }
                // always update since the reminder count could be changed in the DB
                this.accountChangedService.accountChanged({
                    paidUntil: event.body.pu ? DateTime.fromISO(event.body.pu) : undefined,
                    limit: event.body.ol,
                    reminders: event.body.rem,
                    notifications: event.body.notifications,
                });
            }
            return event;
        }));
    }
}

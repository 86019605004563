/* eslint-disable max-len */
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, Inject, LOCALE_ID } from '@angular/core';
import { map, Observable, Subject, takeUntil, throttleTime } from 'rxjs';
// import { UserType } from 'src/app/modules/frame/services/user.service';
import { Reminder } from 'src/app/models/Reminder';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { StandardService } from 'src/app/util/services/standard.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { RemindersService } from './reminder.service';
import { RenameMachineNamesDialogComponent } from './renamemachinenames-dialog.component';
// import { MatSelectChange } from '@angular/material/select';
import { Enumerations } from 'src/app/models/Enumerations';
import cloneDeep from 'lodash-es/cloneDeep';
import { YesNoDialogComponent } from '../ui/dialog/yesno-dialog.component';
import { InvitedUserInfo, UserService, UserType } from '../frame/services/user.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NotificationService } from '../frame/services/notification.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NgForm } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { formatNumber } from '@angular/common';
import { User } from 'src/app/models/User';
import { Utils2 } from 'src/app/util/utils2';
import { DateTime, Info } from 'luxon';
import { Ordinals } from 'src/app/locale/intl/ordinal';

const ANIMATIONDIRATION = '0.6s';
const ANIMATIONDIRATIONINMS = 600;

@Component({
    selector: 'app-reminder',
    templateUrl: './reminder.component.html',
    styleUrls: ['./reminder.component.scss'],
    animations: [
        trigger('createLog', [
            state('start', style({
                opacity: 0.95,
                top: 0,
            })),
            state('end', style({
                opacity: 0,
                top: '300px',
            })),
            transition('start => end', [
                animate(ReminderComponent.ANIMATIONDURATION)
            ]),
        ]),
        trigger('deleteEntry', [
            state('deleted',
                style({ opacity: 0 })),
            transition('* => deleted', [
                animate(ANIMATIONDIRATION)
            ]),
        ]),
    ],
})
export class ReminderComponent implements OnInit, OnDestroy {

    static readonly ANIMATIONDURATION = '1.5s';
    static readonly ANIMATIONDURATIONINMS = 1500;

    constructor(
        public tr: TranslatorService,
        private alertService: AlertService,
        public utils: Utils,
        private utils2: Utils2,
        private standardService: StandardService,
        private remindersService: RemindersService,
        private logger: NGXLogger,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private notificationService: NotificationService,
        private userService: UserService,
        private ordinal: Ordinals,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        //
    }

    @Input() index: number;
    @Input() idToHighlight: string;
    @Input() editMode = -1;
    @Output() editModeChange = new EventEmitter<number>();
    @Input() isNew = -1;
    @Output() isNewChange = new EventEmitter<number>();
    @Output() addCancelled = new EventEmitter<void>();
    @Output() deleted = new EventEmitter<void>();
    @Output() updated = new EventEmitter<Reminder>();
    // notify that a log entry has been added / removed; passes time to wait in ms
    @Output() logChanged = new EventEmitter<number>();
    @Output() machinesChanged = new EventEmitter<void>();
    @Input() readOnly = false;
    machinesToRenameCount = 0;
    filteredMachines: { label: string, cnt: number }[] = [];
    private machines: { label: string, cnt: number }[] = [];
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('machines') set _machines(ms: { label: string, cnt: number }[]) {
        this.machines = ms;
        this.filteredMachines = this.machines;
        this.machinesToRenameCount = this.machines?.filter(m => m.cnt).length;
    }
    @Input() currentUser: UserType;
    @Input() reminder: Reminder;
    @Input() targetUsers: InvitedUserInfo[];
    @Input() isExpanded = false;
    @Output() isExpandedChange = new EventEmitter<boolean>();

    readableString: { strings: string[], snooze?: string };

    expiredStrs: {
        expired2ForSort?: number, expired2Pre?: string, expired2Number?: string, expired2Unit?: string,
        expiredForSort?: number, expiredPre?: string, expiredNumber?: string, expiredUnit?: string
    } = {};
    private _gasUnit = 'kg';
    @Input() set gasUnit(gu: string) {
        if (gu !== this._gasUnit) {
            this._gasUnit = gu;
            this.updateReadableString();
        } else {
            this._gasUnit = gu;
        }
    }
    get gasUnit(): string {
        return this._gasUnit;
    }
    @Output() gasUnitChange = new EventEmitter<string>();

    remindercopy: Reminder;
    expired = false;
    submitPressed = false;
    waitingForChanges = false;
    isSaving = false;
    conditionUnchanged = true;
    mainUnit: UnitSystemType = 'kg';
    energyUnit = 'BTU';
    defaultNotifInfo: { default?: boolean, user: InvitedUserInfo, for_email?: boolean, not_for_web?: boolean, not_for_artisan?: boolean } = { default: true, user: undefined }; // email: no, web/artisan: yes

    fixedProperties = ['_id', 'hr_id', 'created_at', 'created_by', 'deleted', 'internal_hr_id', 'owner'];

    animatePanel = false;
    showAnimatedPanel = false;
    checkSnoozeTimer: ReturnType<typeof setTimeout>;
    isdeleted: string;

    loadSubscription = new Subject<string>();
    private ngUnsubscribe = new Subject();

    Enumerations = Enumerations;
    today = DateTime.now();

    filteredTasks: { label: string, interval_unit?: string, example?: Reminder }[] = [];
    intervals: { label: string, value: string, onelabel?: string, singularlabel?: string, plurallabel?: string }[];
    // decides whether to show a number input field or a dropdown
    intervalChooserMap: Map<string, { type: string, strings: (string | number)[], values: number[] }> = new Map();

    isVeryLarge$: Observable<boolean>;
    isQuiteLarge$: Observable<boolean>;
    isLarge$: Observable<boolean>;
    isMiddleOrLarge$: Observable<boolean>;

    // after an image changed, need to wait for the local server to reload; only for localhost setup
    waitForLocalServer = false;

    helptipEmailShown = false;
    showHelptipEMail = true;

    weekdays: string[] = [];

    @ViewChild('scrolltarget', { static: false }) scrolltarget: ElementRef;

    isValid = false;
    _editForm: NgForm;
    @ViewChild('editForm') set editForm(myform: NgForm) {
        this._editForm = myform;
        if (this._editForm?.form?.valid != null && this.isValid !== this._editForm.form.valid) {
            setTimeout(() => {
                this.isValid = this._editForm.form.valid;
            }, 0);
        }
        if (this._editForm) {
            this._editForm.statusChanges.subscribe(
                result => this.isValid = result === 'VALID'
            );
        }
    }

    ngOnInit(): void {
        if (this.currentUser?.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        this.energyUnit = this.currentUser?.energy_unit || 'BTU';
        this.helptipEmailShown = !!((this.currentUser?.hts ?? Enumerations.HELPTIP.EMAILNOTIF) & Enumerations.HELPTIP.EMAILNOTIF);

        this.weekdays = Info.weekdays('short', { locale: this.locale });
        // // const weekdays = moment.weekdaysShort(true);
        // // find out iso weekday values (Mon=1, Sun=7) of locale-specific weekday order
        // const mom = DateTime.now();
        // const wdvalues = [];
        // for (let w = 0; w < weekdays.length; w++) {
        //     mom.isoWeekday(weekdays[w]); // parses locale-aware weekday string
        //     wdvalues.push(mom.isoWeekday());
        // }
        // this.intervalChooserMap.set('day_of_week', { type: 'dropdown', strings: weekdays, values: wdvalues });

        // weekdays from luxon is always Mon -> Sun
        // TODO should maybe use Info.features() //=> { relative: false, localeWeek: true }
        this.intervalChooserMap.set('day_of_week', { type: 'dropdown', strings: this.weekdays, values: Array(7).fill(1).map((_, i) => i) });

        const monthdays = Array(28).fill(1).map((_, i) => i + 1);
        this.intervalChooserMap.set('day_of_month', { type: 'dropdown', strings: monthdays.map(i => `${i}.`), values: monthdays });

        this.intervals = [
            { label: this.tr.anslate('{{mainUnit}} roasted', { mainUnit: this.mainUnit }).trim(), onelabel: this.mainUnit === 'lbs' || this.mainUnit === 'lb' ? this.tr.anslate('{{mainUnit}} roasted', { mainUnit: 'lb' }).trim() : undefined, value: 'roasted_amount' },
            { label: this.tr.anslate('hours of roasting'), value: 'roast_hours', onelabel: this.tr.anslate('hour of roasting') },
            { label: this.tr.anslate('Roasts'), value: 'batches', onelabel: this.tr.anslate('Roast') },
            { label: this.tr.anslate('Date'), value: 'date', onelabel: this.tr.anslate('Date') },
            { label: this.tr.anslate('days'), value: 'days', onelabel: this.tr.anslate('daily'), singularlabel: this.tr.anslate('Day'), plurallabel: this.tr.anslate('(every n>=2) days') },
            { label: this.tr.anslate('weeks'), value: 'weeks', onelabel: this.tr.anslate('weekly'), singularlabel: this.tr.anslate('Week'), plurallabel: this.tr.anslate('(every n>=2) weeks') },
            { label: this.tr.anslate('Months'), value: 'months', onelabel: this.tr.anslate('monthly'), singularlabel: this.tr.anslate('month'), plurallabel: this.tr.anslate('(every n>=2) months') },
            { label: this.tr.anslate('years'), value: 'years', onelabel: this.tr.anslate('yearly'), singularlabel: this.tr.anslate('Year'), plurallabel: this.tr.anslate('(every n>=2) years') },
            { label: this.tr.anslate('day of the week'), value: 'day_of_week' },
            { label: this.tr.anslate('day of the month'), value: 'day_of_month' },
            { label: this.tr.anslate('propane consumed').trim(), value: 'gas_consumed' },
        ];

        // if (this.expPanel && this.idToHighlight && (this.reminder.hr_id === this.idToHighlight)) {
        //     this.expPanel.open();
        // }

        this.isVeryLarge$ = this.breakpointObserver.observe('(min-width: 1250px)')
            .pipe(map(result => result.matches));
        this.isQuiteLarge$ = this.breakpointObserver.observe('(min-width: 1100px)')
            .pipe(map(result => result.matches));
        this.isLarge$ = this.breakpointObserver.observe('(min-width: 900px)')
            .pipe(map(result => result.matches));
        this.isMiddleOrLarge$ = this.breakpointObserver.observe('(min-width: 600px)')
            .pipe(map(result => result.matches));

        if (this.editMode === this.index && typeof this.remindercopy === 'undefined' && this.reminder) {
            this.scrolltarget?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            this.edit();
        }

        if (this.reminder.snooze_until) {
            const snuntil = this.utils.getDateFromMaybeString(this.reminder.snooze_until);
            if (snuntil > this.today) {
                const time = snuntil.diff(DateTime.now(), 'milliseconds').milliseconds + 1000;
                this.checkSnoozeTimer = setTimeout(() => {
                    this.updated.emit(this.reminder);
                }, time);
            }
        }

        this.expiredStrs = {
            expired2ForSort: this.reminder.expired2ForSort,
            expired2Pre: this.reminder.expired2Pre,
            expired2Number: this.reminder.expired2Number,
            expired2Unit: this.reminder.expired2Unit,
            expiredForSort: this.reminder.expiredForSort,
            expiredPre: this.reminder.expiredPre,
            expiredNumber: this.reminder.expiredNumber,
            expiredUnit: this.reminder.expiredUnit,
        }

        this.updateReadableString();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.checkSnoozeTimer);
    }

    panel(opened: boolean): void {
        if (this.isExpanded !== opened) {
            this.isExpanded = opened;
            this.isExpandedChange.emit(this.isExpanded);
        }
    }

    translate(label: string, num: number, unit: string): string[] {
        if (label === 'alwaysafter') {
            let trans = this.tr.anslate('Every /n/ {{PLACEHOLDER}}', { PLACEHOLDER: '/PLACEHOLDER/' });
            trans = trans.replace('/PLACEHOLDER/', this.getIntervalUnitLabel(unit, num, true));
            const transSpl = trans.split('/n/');
            return transSpl;
        } if (label === 'every') {
            if (unit === 'day_of_week') {
                const trans = this.tr.anslate('Every /WEEKDAY/');
                const transSpl = trans.split('/WEEKDAY/');
                return transSpl;
            } else if (unit === 'day_of_month') {
                const trans = this.tr.anslate('Every /nthDAYOFMONTH/ day of the month');
                const transSpl = trans.split('/nthDAYOFMONTH/');
                return transSpl;
            }
        }
        return ['', ''];
    }

    // calculateExpired(reminder: Reminder): boolean {
    //     if (reminder?.conditions?.length) {
    //         for (let r = 0; r < reminder.conditions.length; r++) {
    //             const condition = reminder.conditions[r];
    //             if (condition.interval_done > 0) {
    //                 return true;
    //             }
    //         }
    //     }
    //     return false;
    // }

    // calls notifications/reminder/done on the server which
    // - sets done_date / done_user
    // - updates conditions.start_date
    // - generates Log entry
    // - removes any pending notifications for this reminder
    done(event: MatDatepickerInputEvent<DateTime>): void {
        const now = DateTime.now();
        let date = now;
        if (event?.value) {
            date = event.value;
            // ensure the time is the time now; date picker does not always update the time
            date = date.set({ hour: now.get('hour'), minute: now.get('minute'), second: now.get('second') });
        }
        this.notificationService.setReminderDone(this.reminder, date)
            .pipe(throttleTime(environment.RELOADTHROTTLE))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        // animate that an entry is pushed to the reminder log table
                        this.showAnimatedPanel = true;
                        setTimeout(() => {
                            this.animatePanel = true;
                        }, 10);
                        this.logChanged.emit(ReminderComponent.ANIMATIONDURATIONINMS - 500);
                        setTimeout(() => {
                            this.showAnimatedPanel = false;
                            this.animatePanel = false;
                            this.reminder = this.utils.dateifyReminders([response.result])?.[0] || this.reminder;
                            this.updated.emit(this.reminder);
                        }, ReminderComponent.ANIMATIONDURATIONINMS + 100);
                    } else {
                        this.utils.handleError('error updating the information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the information', error);
                }
            });
    }

    doUpdate(reminder: Reminder, params?: { [p: string]: string }, cb?: (response: { success: boolean, result?: Reminder, error?: string }) => void): void {
        if (this.reminder._id) {
            reminder._id = this.reminder._id;
            delete reminder.hr_id;
        } else if (this.reminder.hr_id) {
            reminder.hr_id = this.reminder.hr_id;
            delete reminder._id;
        } else {
            this.alertService.error('error updating the information');
            return;
        }

        this.standardService.update<Reminder>('reminders', reminder, params)
            .pipe(throttleTime(environment.RELOADTHROTTLE))
            .subscribe({
                next: response => {
                    if (!response) {
                        this.alertService.success(this.tr.anslate('Nothing to change'));
                    } else if (response && response.success === true) {
                        this.updated.emit(this.reminder);
                    } else {
                        this.utils.handleError('error updating the information', response.error);
                        this.reminder.active = false;
                    }
                    if (cb) {
                        cb(response);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the information', error);
                    this.reminder.active = false;
                    if (cb) {
                        cb({ success: false });
                    }
                }
            });
    }

    /**
     * Snoozes the reminder, i.e. removes any related notifications and
     * sets snooze_until for the reminder.
     * Replaces an existing snooze-until date.
     * @param event event.value contains the date
     */
    snooze(event?: MatDatepickerInputEvent<DateTime>): void {
        if (!event || this.reminder.snooze_until) {
            // remove an existing snooze date
            this.reminder.snooze_until = null;
            this.doUpdate({ snooze_until: this.reminder.snooze_until, label: undefined }, { removeNotifications: '1' },
                response => {
                    if (response?.success === true) {
                        this.logger.trace('reminder updated - snooze removed');
                        // TODO update reminder (conditions) with calculated interval_done etc. ?
                    }
                }
            );
            return;
        }

        if (event.value.hasSame(this.today, 'day')) {
            // if the user chose today as date, snooze for one hour
            event.value = DateTime.now().plus({ hours: 1 });
        }
        this.reminder.snooze_until = event.value;
        this.doUpdate({ snooze_until: this.reminder.snooze_until, label: undefined }, { removeNotifications: '1' },
            response => {
                if (response?.success === true) {
                    this.logger.trace('reminder updated - snooze added');
                    // TODO update reminder (conditions) with calculated interval_done etc. ?
                }
            }
        );
    }

    /**
     * Sets reminder.active = true
     */
    activate(isactive = true): void {
        if (this.readOnly) { return; }

        this.reminder.active = isactive;
        this.doUpdate({ active: this.reminder.active, label: undefined },);
    }

    edit(): void {
        if (this.readOnly) { return; }

        // this.remindercopy = Object.assign({}, this.reminder);
        // this.remindercopy.conditions = this.reminder.conditions?.slice();
        this.remindercopy = cloneDeep(this.reminder);
        this.editMode = this.index;
        if (!this.remindercopy.label && this.remindersService.allTasks?.[0]?.label) {
            this.remindercopy.label = this.remindersService.allTasks[0].label;
        }
        if (!this.remindercopy.conditions) {
            this.remindercopy.conditions = [{ interval: undefined, interval_unit: undefined }];
        }

        // use some default condition for a new reminder
        if (this.isNew === this.index) {
            this.remindercopy.active = true;
            this.remindercopy.machine = this.machines?.[0]?.label;
            this.remindercopy.conditions[0].interval = this.remindersService.allTasks[0].interval || 1;
            this.remindercopy.conditions[0].on_date = this.remindersService.allTasks[0].on_date;
            this.remindercopy.conditions[0].interval_unit = this.getIntervalUnit(this.remindersService.allTasks[0]?.interval_unit);
            this.remindercopy.conditions[0].start_date = DateTime.now();
            if (this.remindersService.allTasks[0]?.interval_unit2) {
                const condition2 = {
                    interval_unit: this.remindersService.allTasks[0]?.interval_unit2,
                    interval: this.remindersService.allTasks[0]?.interval,
                    on_date: this.remindersService.allTasks[0]?.on_date2,
                    start_date: DateTime.now(),
                };
                this.remindercopy.conditions[1] = condition2;
            }
        }

        // set defaults for who gets notifications (if different from all false)
        if (!this.remindercopy.notify_users?.length
            && (this.defaultNotifInfo.for_email || this.defaultNotifInfo.not_for_artisan || this.defaultNotifInfo.not_for_web)) {
            this.remindercopy.notify_users = [Object.assign({}, this.defaultNotifInfo)];
        }
        this.conditionUnchanged = true;
        this.isExpanded = true;
        this.editModeChange.emit(this.editMode);
    }

    conditionIntervalUnitChanged(condition: Reminder['conditions'][0]) {
        // set condition.interval to a valid value for this interval_unit
        // probably only for day_of_week
        const valconfig = this.intervalChooserMap.get(condition?.interval_unit);
        if (valconfig) {
            if (valconfig.values?.length && !valconfig.values.includes(condition.interval)) {
                condition.interval = valconfig.values[0];
            }
        }
        this.cleanConditions(this.remindercopy, false);
        for (let r = 0; r < this.remindercopy.conditions?.length; r++) {
            const condition = this.remindercopy.conditions[r];
            if (condition.interval_unit === 'date' && !condition.on_date) {
                condition.on_date = DateTime.now().plus({ day: 1 });
            } else {
                if (typeof condition.interval === 'undefined') {
                    condition.interval = 1;
                }
                if (!condition.start_date) {
                    condition.start_date = DateTime.now();
                }
            }
        }
        this.conditionChanged();
    }

    conditionChanged(): void {
        this.conditionUnchanged = false;
    }

    getIntervalUnit(intervalName: string): string {
        return intervalName || 'weeks';
    }

    // retrieves the onelabel or label (depending on num) for the given unit (see intervals)
    getIntervalUnitLabel(unit: string, num: number, useSingle = false): string {
        // minutes is not normally part of the predefined intervals array
        if (unit === 'roast_minutes') {
            return this.tr.anslate('min');
        }
        for (let i = 0; i < this.intervals?.length; i++) {
            const interval = this.intervals[i];
            if (interval.value === unit) {
                if (interval.label.indexOf(this.mainUnit) >= 0) {
                    const realnum = Math.round(num * this.utils.getUnitFactor(this.mainUnit) * 1000);
                    return realnum === 1000 ? (interval.onelabel ?? interval.label) : interval.label;
                }
                return num === 1 ? ((useSingle && interval.singularlabel) || interval.onelabel || interval.label) : interval.plurallabel ?? interval.label;
            }
        }
        return ' ';
    }

    updateReadableString(): void {
        if (!this.reminder.conditions?.length) {
            this.readableString = { strings: [] };
            return;
        }
        const strs: { strings: string[], snooze?: string } = { strings: [] };
        for (let r = 0; r < this.reminder.conditions?.length; r++) {
            const condition = this.reminder.conditions[r];
            if (!condition || ((!condition.interval || !condition.interval_unit) && !condition.on_date)) {
                continue;
            }
            const expiredStrs = this.utils.calcExpired(condition.interval_unit || 'days', this.reminder.conditions[r].interval_done, condition.interval_done_forsort, this.mainUnit, this.energyUnit, this._gasUnit)
            Object.assign(this.expiredStrs, expiredStrs);

            if (condition.interval_unit === 'date') {
                let ondate = this.tr.anslate('on {{date}}', { date: condition.on_date.toLocaleString(DateTime.DATE_MED) });
                ondate = ondate[0].toUpperCase() + ondate.slice(1);
                strs.strings.push(`${ondate} - ${expiredStrs.expiredPre}${expiredStrs.expiredNumber} ${expiredStrs.expiredUnit}`);
            } else {
                if (condition.interval_unit === 'roasted_amount') {
                    strs.strings.push(this.tr.anslate('Once {{kg}} have been roasted', { kg: `${this.utils.formatNumber(condition.interval, 1, this.currentUser?.unit_system)} ${this.mainUnit}` }));
                } else if (condition.interval_unit === 'roast_hours') {
                    if (condition.interval === 1) {
                        strs.strings.push(this.tr.anslate('After each roast hour'));
                    } else if (condition.interval < 2) {
                        strs.strings.push(this.tr.anslate('After {{minutes}} roast minutes', { minutes: condition.interval * 60 }));
                    } else {
                        strs.strings.push(this.tr.anslate('After {{hours}} roast hours', { hours: condition.interval }));
                    }
                } else if (condition.interval_unit === 'batches') {
                    if (condition.interval === 1) {
                        strs.strings.push(this.tr.anslate('After each roast'));
                    } else {
                        strs.strings.push(this.tr.anslate('After {{n}} roasts', { n: condition.interval }));
                    }
                } else if (condition.interval_unit === 'days'
                    || condition.interval_unit === 'weeks'
                    || condition.interval_unit === 'months'
                    || condition.interval_unit === 'years') {
                    if (condition.interval === 1) {
                        let str = this.getIntervalUnitLabel(condition.interval_unit, 1);
                        str = str[0].toUpperCase() + str.slice(1);
                        strs.strings.push(str);
                    } else {
                        // TODO maybe for different languages "Alle 2 Tage" is different from "Alle 2 Wochen"
                        strs.strings.push(this.tr.anslate('Every {{interval}} {{intervalUnit}}', { interval: `${condition.interval} `, intervalUnit: this.getIntervalUnitLabel(condition.interval_unit, condition.interval) }));
                    }
                } else if (condition.interval_unit === 'day_of_week') {
                    strs.strings.push(this.tr.anslate('Every {{weekday}}', { weekday: this.weekdays[condition.interval] }));
                } else if (condition.interval_unit === 'day_of_month') {
                    strs.strings.push(this.tr.anslate('Every {{dayOfMonth}}', { dayOfMonth: `${this.ordinal.getOrdinal(condition.interval, this.locale)} ${this.getIntervalUnitLabel(condition.interval_unit, condition.interval)}` }));
                } else if (condition.interval_unit === 'gas_consumed') {
                    const gasEnergy = this.utils.convertGasToEnergy(condition.interval, this.energyUnit);
                    let digits = 0;
                    if (gasEnergy < 10) {
                        digits = 2;
                    } else if (gasEnergy < 100) {
                        digits = 1;
                    }
                    // eslint-disable-next-line max-len
                    strs.strings.push(this.tr.anslate('Once {{kg}} {{gasUnit}} propane consumed ({{btu}} {{btuUnit}})', { kg: formatNumber(this.utils.convertGasSize(condition.interval, 'kg', this._gasUnit), this.locale, '1.0-2'), gasUnit: this._gasUnit, btu: formatNumber(gasEnergy, this.locale, `1.0-${digits}`), btuUnit: this.energyUnit }));
                } else {
                    this.alertService.error('unknown condition type - please write a short email to admin@artisan.plus');
                    this.readableString = strs;
                }

                strs.strings[strs.strings.length - 1] += `, ${this.tr.anslate('from {{date}}', { date: condition.start_date.toLocaleString(DateTime.DATE_FULL) })}`;

                if (expiredStrs.expiredForSort < 0) {
                    if (condition.interval_unit === 'roasted_amount' || condition.interval_unit === 'batches' || condition.interval_unit === 'roast_hours' || condition.interval_unit === 'gas_consumed') {
                        // eslint-disable-next-line max-len
                        strs.strings[strs.strings.length - 1] += `; ${this.tr.anslate('expired since {{kg}}', { kg: `${expiredStrs.expiredPre}${expiredStrs.expiredNumber?.replace('-', '')} ${expiredStrs.expiredUnit} ${expiredStrs.expired2Unit ? `(${expiredStrs.expired2Unit})` : ''}` })}`;
                    } else {
                        // eslint-disable-next-line max-len
                        strs.strings[strs.strings.length - 1] += `; ${this.tr.anslate('expired {{time}}', { time: `${expiredStrs.expiredPre}${expiredStrs.expiredNumber?.replace('-', '')} ${expiredStrs.expiredUnit} ${expiredStrs.expired2Unit ? `(${expiredStrs.expired2Unit})` : ''}` })}`;
                    }
                } else {
                    if (condition.interval_unit === 'days' || condition.interval_unit === 'weeks' || condition.interval_unit === 'months' || condition.interval_unit === 'years' || condition.interval_unit === 'day_of_week' || condition.interval_unit === 'day_of_month') {
                        strs.strings[strs.strings.length - 1] += `; ${this.tr.anslate('next {{time}}', { time: `${expiredStrs.expiredPre}${expiredStrs.expiredNumber} ${expiredStrs.expiredUnit}`})}`;
                    } else {
                        strs.strings[strs.strings.length - 1] += `; ${this.tr.anslate('{{interval}} to go', { interval: `${expiredStrs.expiredPre}${expiredStrs.expiredNumber} ${expiredStrs.expiredUnit}` })}`;
                    }
                }
            }
        }
        if (this.reminder.snooze_until) {
            if (DateTime.now().hasSame(this.reminder.snooze_until, 'day')) {
                // show relative time (mostly for snooze)
                // const hrs = this.reminder.snooze_until.diffNow('hours').hours;
                // const dur = moment.duration(hrs, 'hours');
                // strs.snooze = dur.humanize(true);
                // TODO check if / how well this works:
                strs.snooze = this.reminder.snooze_until.toRelative();
            } else {
                strs.snooze = this.reminder.snooze_until.toLocaleString(DateTime.DATE_MED);
            }
            if (this.reminder.snooze_until < DateTime.now()) {
                // snooze expired, update
                this.updated.emit(this.reminder);
            }
        }
        this.readableString = strs;
    }

    conditionsToString(reminder: Reminder): { strings: { interval_unit: string, text: string }[], snooze?: string } {
        if (!reminder.conditions || !reminder.conditions.length) {
            return { strings: [] };
        }
        // let str = `${reminder.machine ? `${reminder.machine}: ` : ''}`;

        const strs: { strings: { interval_unit: string, text: string }[], snooze?: string } = { strings: [] };
        for (let r = 0; r < reminder.conditions.length; r++) {
            const condition = reminder.conditions[r];
            if (!condition || ((!condition.interval || !condition.interval_unit) && !condition.on_date)) {
                continue;
            }
            if (condition.interval_unit) {
                if (condition.interval_unit === 'roasted_amount') {
                    strs.strings.push({ interval_unit: 'roasted_amount', text: `${this.utils.formatNumber(condition.interval, 1, this.currentUser?.unit_system)} ${this.getIntervalUnitLabel('roasted_amount', condition.interval)}` });
                } else if (condition.interval_unit === 'day_of_week') {
                    strs.strings.push({ interval_unit: 'day_of_week', text: Info.weekdays('short', { locale: this.locale })[condition.interval] });
                } else if (condition.interval_unit === 'day_of_month') {
                    strs.strings.push({ interval_unit: 'day_of_month', text: `${this.ordinal.getOrdinal(condition.interval, this.locale)} ${this.getIntervalUnitLabel('day_of_month', condition.interval)}` });
                } else if (condition.interval_unit === 'date') {
                    strs.strings.push({ interval_unit: 'date', text: condition.on_date.toLocaleString(DateTime.DATE_MED) });
                } else if (condition.interval_unit === 'gas_consumed') {
                    // eslint-disable-next-line max-len
                    strs.strings.push({ interval_unit: 'gas_consumed', text: `${condition.interval !== 1 ? formatNumber(this.utils.convertGasSize(condition.interval, 'kg', this._gasUnit), this.locale, '1.0-2') : ''} ${this._gasUnit} ${this.getIntervalUnitLabel('gas_consumed', condition.interval)}` });
                } else if (condition.interval_unit === 'roast_hours') {
                    if (condition.interval === 1) {
                        strs.strings.push({ interval_unit: 'roast_hours', text: `${this.getIntervalUnitLabel('roast_hours', 1)}` });
                    } else if (condition.interval < 2) { // show in minutes
                        strs.strings.push({ interval_unit: 'roast_hours', text: `${condition.interval * 60} ${this.getIntervalUnitLabel('roast_minutes', condition.interval * 60)}` });
                    } else {
                        strs.strings.push({ interval_unit: 'roast_hours', text: `${condition.interval !== 1 ? condition.interval : ''} ${this.getIntervalUnitLabel('roast_hours', condition.interval)}` });
                    }
                } else {
                    strs.strings.push({ interval_unit: condition.interval_unit, text: `${condition.interval !== 1 ? formatNumber(condition.interval, this.locale, '1.0-2') : ''} ${this.getIntervalUnitLabel(condition.interval_unit, condition.interval)}` });
                }
            } else {
                this.alertService.error('unknown condition type - please write a short email to admin@artisan.plus');
                return strs;
            }
        }
        if (reminder.snooze_until) {
            if (DateTime.now().hasSame(reminder.snooze_until, 'day')) {
                // show relative time (mostly for snooze)
                const hrs = reminder.snooze_until.diffNow('hours').hours;
                strs.snooze = this.utils.humanizeInterval(hrs, 'hours');;
            } else {
                strs.snooze = reminder.snooze_until.toLocaleString(DateTime.DATE_MED);
            }
            if (reminder.snooze_until < DateTime.now()) {
                // snooze expired, update
                this.updated.emit(this.reminder);
            }
        }
        return strs;
    }

    cancel(notifyParent = true): void {
        this.editMode = -1;
        this.editModeChange.emit(this.editMode);
        this.remindercopy = undefined;
        if (notifyParent && this.isNew === this.index) {
            this.addCancelled.emit();
        }
        this.isNew = -1;
        this.isNewChange.emit(this.isNew);
        this.scrolltarget?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }

    addCondition(): void {
        if (!this.remindercopy) {
            return;
        }
        if (!this.remindercopy.conditions) {
            this.remindercopy.conditions = [{ interval: undefined, interval_unit: undefined }];
        }
        const condition = {
            on_date: DateTime.now(),
            interval: 1,
            interval_unit: this.getIntervalUnit(this.remindersService.allTasks[0]?.interval_unit),
            start_date: DateTime.now(),
        }
        this.remindercopy.conditions.push(condition);
    }

    deleteCondition(idx: number): void {
        if (!this.remindercopy || !this.remindercopy.conditions) {
            return;
        }
        this.remindercopy.conditions.splice(idx, 1);
    }

    isEqual(obj: Reminder, updateObj: Reminder): boolean {
        let equal = true;

        const props = Object.getOwnPropertyNames(updateObj);
        for (let p = 0; p < props.length; p++) {
            const prop = props[p];

            if (this.fixedProperties.indexOf(prop) >= 0) {
                // these can be ignored
                if (prop !== 'hr_id' && prop !== '_id') {
                    delete updateObj[prop];
                }
                continue;

            } else {
                if ((obj[prop] == null || obj[prop]?.length === 0 || (Object.keys(obj[prop]).length === 0 && obj[prop].constructor === Object))
                    && (updateObj[prop] == null || updateObj[prop]?.length === 0 || (Object.keys(updateObj[prop]).length === 0 && updateObj[prop].constructor === Object))) {
                    // both are empty (undefined and null and [] and {} are considered equal here)
                    delete updateObj[prop];
                    continue;
                }

                if (obj[prop] == null || obj[prop]?.length === 0 || (Object.keys(obj[prop]).length === 0 && obj[prop].constructor === Object)
                    || updateObj[prop] == null || updateObj[prop]?.length === 0 || (Object.keys(updateObj[prop]).length === 0 && updateObj[prop].constructor === Object)) {
                    // only one is set
                    equal = false;
                    continue;
                }

                // if (prop === 'done_date') {
                //     if (new Date(obj[prop]) === new Date(updateObj[prop])) {
                //         delete updateObj.done_date;
                //     } else {
                //         // don't "return false" here since we want to delete all other equal properties
                //         equal = false;
                //     }
                //     continue;
                // }

                if (prop === 'conditions') {
                    // compare whole condition object
                    const r1s = obj.conditions;
                    const r2s = updateObj.conditions;
                    if (r1s.length !== r2s.length) {
                        equal = false;
                        continue;
                    }
                    for (let r = 0; r < r1s.length; r++) {
                        const r1 = r1s[r];
                        const r2 = r2s[r];
                        if (r1.interval !== r2.interval
                            || r1.interval_unit !== r2.interval_unit
                            || !(+r1.on_date === +r2.on_date)
                            || !(+r1.start_date === +r2.start_date)) {

                            equal = false;
                            break;
                        }
                    }
                    if (!equal) {
                        continue;
                    }
                    delete updateObj.conditions;
                    continue;
                }
                if (prop === 'notify_users') {
                    const u1s = obj.notify_users;
                    const u2s = updateObj.notify_users;
                    if (u1s.length !== u2s.length) {
                        equal = false;
                        continue;
                    }
                    for (let u = 0; u < u1s.length; u++) {
                        const u1 = u1s[u];
                        const u2 = u2s[u];
                        if (u1.user?._id?.toString() !== u2.user?.toString()
                            || (!!u1.default !== !!u2.default)
                            || (!!u1.for_email !== !!u2.for_email)
                            || (!!u1.not_for_artisan !== !!u2.not_for_artisan)
                            || (!!u1.not_for_web !== !!u2.not_for_web)) {
                            equal = false;
                            break;
                        }
                    }
                    if (!equal) {
                        continue;
                    }
                    delete updateObj.notify_users;
                    continue;
                }

                if (this.utils.compareObjectsFn(obj[prop], updateObj[prop]) || JSON.stringify(obj[prop]) === JSON.stringify(updateObj[prop])) {
                    delete updateObj[prop];
                } else {
                    equal = false;
                }
            }
        }
        return equal;
    }

    /**
     * Remove the notify_users entries that do not carry any information
     * @param reminder the reminder object to apply "cleaning"
     */
    cleanNotifyUsers(reminder: Reminder): void {
        if (!reminder || !reminder.notify_users?.length) {
            return;
        }
        let haveAtLeastOneWithNotif = false;
        for (let i = 0; i < reminder.notify_users.length; i++) {
            const info = reminder.notify_users[i];
            if (info.for_email || !info.not_for_artisan || !info.not_for_web) {
                haveAtLeastOneWithNotif = true;
                break;
            }
        }
        if (haveAtLeastOneWithNotif) {
            // can remove any "empty" infos
            for (let i = 0; i < reminder.notify_users.length; i++) {
                const info = reminder.notify_users[i];
                if (!info.for_email && info.not_for_artisan && info.not_for_web) {
                    reminder.notify_users.splice(i, 1);
                    i -= 1;
                }
            }
        }
    }

    /**
     * Remove the condition attributes that are not necessary for storing in the DB.
     * Sets on_date (if present) to the start of day.
     * @param reminder the reminder object to apply "cleaning"
     * @param sortConditions if true, the conditions will be sorted according to interval_unit
     */
    cleanConditions(reminder: Reminder, sortConditions = true): void {
        if (!reminder) {
            return;
        }
        if (sortConditions) {
            reminder.conditions?.sort((r1, r2) => r1.interval_unit.localeCompare(r2.interval_unit, this.locale));
        }
        for (let r = 0; r < reminder.conditions?.length; r++) {
            const condition = reminder.conditions[r];
            // if (condition.interval_unit === 'gas_consumed' && this.gasUnit !== 'kg') {
            //     condition.interval = this.utils.convertGasSize(condition.interval, this.gasUnit);
            // }
            if (condition.interval_unit === 'date') {
                delete condition.interval;
                // delete condition.interval_unit;
                delete condition.start_date;
                if (condition.on_date.hasSame(this.today, 'day')) {
                    // if the user chose today as date, set in one hour
                    condition.on_date = DateTime.now().plus({ hours: 1 });
                } else if (condition.on_date > DateTime.now()) {
                    // if the date is in the future, pick start of day
                    condition.on_date = condition.on_date.startOf('day');
                } else {
                    // date is in the past, use current time
                    const now = DateTime.now();
                    condition.on_date = condition.on_date.set({ hour: now.hour, minute: now.minute, second: now.second });
                }
            } else {
                delete condition.on_date;
            }
            delete condition.interval_done;
            delete condition.interval_done_forsort;
        }
    }

    // called from template; used to identify ENTER that should save the form
    save(): void {
        if (this.readOnly) { return; }

        this.submitPressed = true;
        if (!this.waitingForChanges) {
            this.doSave();
        }
    }

    // checks some preconditions and then saves the reminder
    doSave(): void {
        if (!this.submitPressed) {
            return;
        }
        this.submitPressed = false;
        this.isSaving = true;

        // need a copy of the copy as we change that for sending to the server
        // and we would otherwise change the object that is currently displayed
        // need deep copy since notify_users array must also be cloned
        const myrcopy = cloneDeep(this.remindercopy);

        if (myrcopy._id && myrcopy.hr_id) {
            delete myrcopy.hr_id;
        }

        // set notify_users
        if (!myrcopy.notify_users?.length && this.defaultNotifInfo) {
            // no selection of users, i.e. "notify all", use default settings object (if different from all false)
            if (this.defaultNotifInfo.for_email || this.defaultNotifInfo.not_for_artisan || this.defaultNotifInfo.not_for_web) {
                myrcopy.notify_users = [this.defaultNotifInfo];
            }
        } else {
            if (myrcopy.notify_users?.length === this.targetUsers?.length) {
                // all users are selected
                let allSame = true;
                for (let i = 1; i < myrcopy.notify_users?.length; i++) {
                    if (myrcopy.notify_users[i].for_email !== myrcopy.notify_users[0].for_email
                        || myrcopy.notify_users[i].not_for_artisan !== myrcopy.notify_users[0].not_for_artisan
                        || myrcopy.notify_users[i].not_for_web !== myrcopy.notify_users[0].not_for_web) {
                        allSame = false;
                        break;
                    }
                }
                if (allSame) {
                    // all have the same settings, combine them to one default entry
                    myrcopy.notify_users = [myrcopy.notify_users[0]];
                    myrcopy.notify_users[0].default = true;
                    myrcopy.notify_users[0].user = undefined;
                }
            }
            // no special case, use _id string as user entry
            for (let i = 0; i < myrcopy.notify_users?.length; i++) {
                if (!myrcopy.notify_users[i].default) {
                    myrcopy.notify_users[i].user = (myrcopy.notify_users[i].user?._id?.toString() as unknown as User);
                }
            }
        }

        this.cleanNotifyUsers(myrcopy);
        this.cleanConditions(myrcopy);
        // note that isEqual removes all equal properties from myrcopy
        if (this.isNew !== this.index) {
            if (this.isEqual(this.reminder, myrcopy)) {
                this.alertService.success(this.tr.anslate('Nothing to change'));
                // if the gas unit changed
                this.updateReadableString();
                this.editMode = -1;
                this.editModeChange.emit(this.editMode);
                this.isSaving = false;
                // to scroll
                this.updated.emit(this.reminder);
                return;
            }
        } else {
            this.utils2.cleanResult(myrcopy);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let obs: Observable<{ success: boolean, result: any, error: string }>;
        if (this.isNew === this.index) {
            obs = this.standardService.add<Reminder>('reminders', myrcopy);
        } else {
            // if an existing item is updated, isEqual deleted _id and hr_id; re-add one of them (prefer _id)
            myrcopy._id = this.reminder._id;
            obs = this.standardService.update<Reminder>('reminders', myrcopy);
        }

        obs.pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response) {
                        // nothing changed
                        this.alertService.success(this.tr.anslate('Nothing to change'));

                    } else if (response.success === true) {
                        this.logger.debug('reminder save received', response);

                        if (response.result) {
                            this.alertService.success(this.tr.anslate('Successfully updated'));
                            this.reminder = this.utils.dateifyReminders([response.result])?.[0];
                            this.updated.emit(this.reminder);

                            // live change of active notification
                            const notifData = this.utils.activeNotificationSnackBar?.instance?.textdata;
                            if (notifData?.notification?.source?.toString() === this.reminder?._id?.toString()) {
                                this.utils.activeNotificationSnackBar.instance.textdata.title = this.reminder.label;
                                this.utils.activeNotificationSnackBar.instance.textdata.notification.machine = this.reminder.machine;
                                this.utils.activeNotificationSnackBar.instance.textdata.text = this.reminder.notes;
                                this.utils.activeNotificationSnackBar.instance.textdata.html = this.reminder.notes;
                            }
                        }
                    } else {
                        this.utils.handleError('error updating the reminder information', response.error);
                    }
                    this.editMode = -1;
                    this.editModeChange.emit(this.editMode);
                    this.isNew = -1;
                    this.isNewChange.emit(this.isNew);
                    this.isSaving = false;
                    this.remindercopy = undefined;
                },
                error: error => {
                    this.utils.handleError('error updating the reminder information', error);
                    this.isSaving = false;
                }
            });
    }

    delete(): void {
        if (this.readOnly) { return; }

        const dialogRef = this.dialog.open(YesNoDialogComponent, {
            closeOnNavigation: true,
            data: { text: this.tr.anslate('Do you really want to delete {{name}}?', { name: (this.reminder.machine ? (this.reminder.machine + ' - ') : '') + this.reminder.label }) },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.standardService.remove('reminders', this.reminder._id || this.reminder.hr_id)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.alertService.success(this.tr.anslate('Successfully removed'));
                                this.isExpanded = false;
                                this.isdeleted = 'deleted';
                                setTimeout(() => {
                                    this.isdeleted = undefined;
                                    this.reminder.deleted = true;
                                    this.editMode = -1;
                                    this.editModeChange.emit(this.editMode);
                                    this.isNew = -1;
                                    this.isNewChange.emit(this.isNew);
                                    this.deleted.emit();
                                }, ANIMATIONDIRATIONINMS);
                            } else {
                                this.utils.handleError('Error updating the information', response.error);
                            }
                        },
                        error: error => {
                            this.utils.handleError('Error updating the information', error);
                        }
                    });
            }
        });
    }

    private renameMachineNames(replacements: { val: string, replaceWith: string }[]): void {
        replacements = replacements?.filter(r => r.val !== r.replaceWith) ?? [];
        if (replacements?.length) {
            this.logger.debug('replacing: ' + JSON.stringify(replacements));
            this.remindersService.renameMachineNames(replacements)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: () => {
                        this.machinesChanged.emit();
                    },
                    error: error => {
                        this.utils.handleError('Rename machine names', error);
                    }
                });
        } else {
            this.alertService.success('Nothing to change');
        }
    }

    protected openRenameMachineDialog(): void {
        const dialogRef = this.dialog.open(RenameMachineNamesDialogComponent, {
            closeOnNavigation: true,
            data: { machines: this.machines },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let anychanges = false;
                for (let r = 0; r < result.length; r++) {
                    const rep = result[r];
                    if (rep.val !== rep.replaceWith) {
                        anychanges = true;
                        break;
                    }
                }
                if (anychanges) {
                    this.renameMachineNames(result);
                } else {
                    this.alertService.success('Nothing to change');
                }
            }
        });
    }

    changeMachineFilter(machine: string): void {
        if (!machine) {
            this.filteredMachines = this.machines.slice();
            return;
        }
        if (this.machines) {
            const upId = machine.toUpperCase();
            this.filteredMachines = this.machines.filter(m => m && m.label.toUpperCase().indexOf(upId) >= 0);
        }
    }

    changeLabelFilter(): void {
        if (!this.remindercopy?.label) {
            this.filteredTasks = this.remindersService.allTasks;
            return;
        }
        const foundTask = this.remindersService.allTasks.find(task => task.label === this.remindercopy.label);
        if (foundTask) {
            // clicked on a predefined one (or entered it exactly)
            this.filteredTasks = [foundTask];
            this.labelChanged({ target: { value: this.remindercopy.label } } as unknown as FocusEvent);
        } else if (this.remindersService.allTasks) {
            const filterValue = this.remindercopy.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            this.filteredTasks = this.remindersService.allTasks.filter(task => task && task.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0);
        }
    }

    labelChanged(event?: FocusEvent, macEvent?: MatAutocompleteSelectedEvent): void {
        if (event?.target?.['value'] || macEvent?.option?.value) {
            this.remindercopy.label = event?.target?.['value'] || macEvent?.option?.value;
            // if we allow changing conditions for existing reminders, users might
            // not see the change (esp. if the start_date is changed)
            if (this.conditionUnchanged && this.isNew === this.index) {
            // if (this.isNew === this.index && this.remindercopy.conditions.length === 1 && this.remindercopy.conditions[0].interval === 1) {
                for (let t = 0; t < this.remindersService.allTasks.length; t++) {
                    const task = this.remindersService.allTasks[t];
                    if (task.label === this.remindercopy.label) {
                        if (task.interval_unit) {
                            this.remindercopy.conditions[0].interval_unit = this.getIntervalUnit(task.interval_unit);
                            this.remindercopy.conditions[0].interval = task.interval;
                            this.remindercopy.conditions[0].on_date = task.on_date;
                            this.remindercopy.conditions[0].start_date = DateTime.now();
                            if (task.interval_unit2) {
                                if (!this.remindercopy.conditions[1]) {
                                    this.remindercopy.conditions[1] = { interval: undefined, interval_unit: undefined };
                                }
                                this.remindercopy.conditions[1].interval_unit = this.getIntervalUnit(task.interval_unit2);
                                this.remindercopy.conditions[1].interval = task.interval2;
                                this.remindercopy.conditions[1].on_date = task.on_date2;
                                this.remindercopy.conditions[1].start_date = DateTime.now();
                            } else {
                                this.remindercopy.conditions.length = 1;
                            }
                            return;
                        }
                    }
                }
            }
        }
    }

    gasUnitChanged(): void {
        this.userService.updateUser({ gas_unit: this._gasUnit })
            .pipe(throttleTime(environment.RELOADTHROTTLE))
            .subscribe({
                next: () => {
                    Object.assign(this.currentUser, { gas_unit: this._gasUnit });
                    this.gasUnitChange.emit(this._gasUnit);
                    this.userService.storeCurrentUser(this.currentUser);
                },
            });
    }

    checkChangesUnits(parent: unknown, variable: string, oldValue: number, newValueStr: string, useGasUnit: boolean): void {
        parent[variable] = undefined;
        this.waitingForChanges = true;
        setTimeout(() => {
            const { val, changed } = this.utils.checkChangedValue(useGasUnit ? (this.utils.convertGasSize(oldValue, 'kg', this._gasUnit)) : oldValue * this.utils.getUnitFactor(this.mainUnit), newValueStr, 3);
            parent[variable] = Math.round((useGasUnit ? this.utils.convertGasSize(val, this._gasUnit) : val / this.utils.getUnitFactor(this.mainUnit)) * 1000) / 1000;
            this.waitingForChanges = false;
            if (changed) {
                this.conditionChanged();
                if (this.submitPressed === true && parent[variable] !== oldValue) {
                    this.doSave();
                }
            }
        });
    }

    // checks whether to use roast_hours or roast_minutes
    // sets conditions to dirty (conditionChanged)
    checkIntervalAfterNumberInput(condition: Reminder['conditions'][0]): void {
        if (condition.interval_unit === 'roast_hours' && condition.interval < 2 && condition.interval !== 1) {
            this.intervals[1] = { label: this.tr.anslate('minutes of roasting'), value: 'roast_minutes' };
            condition.interval_unit = 'roast_minutes';
            condition.interval *= 60;
        } else if (condition.interval_unit === 'roast_minutes' && condition.interval > 120) {
            this.intervals[1] = { label: this.tr.anslate('hours of roasting'), value: 'roast_hours', onelabel: this.tr.anslate('hour of roasting') };
            condition.interval_unit = 'roast_hours';
            condition.interval = Math.round(condition.interval / 6) / 10.0;
        }
        this.conditionChanged();
    }

    addImage(inEditMode = false): void {
        if (this.readOnly || !(inEditMode ? this.remindercopy : this.reminder)) {
            return;
        }
        this.utils.addImage(inEditMode ? this.remindercopy : this.reminder, 'reminders', 'image', this.ngUnsubscribe,
            () => { this.isSaving = true; }, // dialog closed
            imagePath => { // saving done (or no changes)
                if (typeof imagePath !== 'undefined') {
                    if (imagePath && environment.BASE_API_URL.indexOf('localhost') >= 0) {
                        if (inEditMode && this.remindercopy) {
                            this.remindercopy.image = null;
                            this.waitForLocalServer = true;
                            setTimeout(() => {
                                this.waitForLocalServer = false;
                                this.remindercopy.image = imagePath;
                                // this.logger.debug('image reloaded for remindercopy');
                            }, 7250);
                        }
                        this.reminder.image = null;
                        this.waitForLocalServer = true;
                        setTimeout(() => {
                            this.waitForLocalServer = false;
                            this.reminder.image = imagePath;
                            // this.logger.debug('image reloaded for reminder');
                        }, 6750);
                    } else {
                        if (inEditMode && this.remindercopy) {
                            this.remindercopy.image = imagePath;
                        }
                        this.reminder.image = imagePath;
                    }
                }
                this.isSaving = false
            },
        );
    }

    compareNotifUsersFn(info1: { user: InvitedUserInfo }, info2: { user: InvitedUserInfo }): boolean {
        return info1 && info2 ? info1.user?._id === info2.user?._id : info1 === info2;
    }

    removeHelptip(): void {
        if (this.showHelptipEMail && !this.helptipEmailShown) {
            this.showHelptipEMail = false;
            this.helptipEmailShown = true;
            this.utils.storeHelptipShown(Enumerations.HELPTIP.EMAILNOTIF);
        }
    }

    getCurrentNotifInfo(idx: number): { user: InvitedUserInfo, for_email?: boolean, not_for_web?: boolean, not_for_artisan?: boolean } {
        // find user (idx in targetUsers) in notify_users array
        for (let i = 0; i < this.remindercopy.notify_users?.length; i++) {
            const notifInfo = this.remindercopy.notify_users[i];
            if (notifInfo.user?._id?.toString() === this.targetUsers[idx]?._id?.toString()) {
                return { user: this.targetUsers[idx], not_for_web: notifInfo?.not_for_web ?? false, not_for_artisan: notifInfo?.not_for_artisan ?? false, for_email: notifInfo?.for_email ?? false }
            }
        }
        // no info for the selected user, use defaults        
        return { user: this.targetUsers[idx], not_for_web: this.defaultNotifInfo?.not_for_web ?? false, not_for_artisan: this.defaultNotifInfo?.not_for_artisan ?? false, for_email: this.defaultNotifInfo?.for_email ?? false }
    }

    /**
     * Checks whether there is any notification to be created. Returns fale otherwise.
     * @param notify_users notification info array
     * @returns true if any notification for any user is specified
     */
    hasNotification(notify_users: { for_email?: boolean, not_for_web?: boolean, not_for_artisan?: boolean }[]): boolean {
        if (!notify_users) {
            // assume default settings (artisan + web)
            return true;
        }
        for (let i = 0; i < notify_users?.length; i++) {
            const info = notify_users[i];
            if (info.for_email || !info.not_for_artisan || !info.not_for_web) {
                return true;
            }
        }
        return false;
    }
}

import { Injectable } from '@angular/core';
import { StockChange } from 'src/app/models/StockChange';

@Injectable({
    providedIn: 'root'
})
export class ShareMostRecentTransactionsService {

    // maps "C1000_L1000" to StockChange
    private transactions: Map<string, StockChange> = new Map();

    resetTransactions(): void {
        this.transactions.clear();
    }

    setTransaction(coffee_hr_id: string, store_hr_id: string, sc: StockChange): void {
        if (!sc.type) {
            sc.type = sc.__t;
        }
        this.transactions.set(coffee_hr_id + '_' + store_hr_id, sc);
    }

    setTransactionPerKey(key: string, sc: StockChange): void {
        if (!sc.type) {
            sc.type = sc.__t;
        }
        this.transactions.set(key, sc);
    }

    removeTransaction(coffee_hr_id: string, store_hr_id: string, store_id?: string): void {
        const s_hr_id = store_hr_id;
        if (!s_hr_id) {
            // have _id instead, need to search
            for (const [key, trans] of this.transactions.entries()) {
                if (key.indexOf(coffee_hr_id + '_') === 0 && trans['target']?._id.toString() === store_id.toString()) {
                    this.transactions.delete(key);
                    return;
                }
            }
        }
        this.transactions.delete(coffee_hr_id + '_' + store_hr_id);
    }

    getTransactions(): Map<string, StockChange> {
        return this.transactions;
    }

    getTransaction(coffee_hr_id: string, store_hr_id: string): StockChange {
        if (!coffee_hr_id || !store_hr_id) {
            return undefined;
        }
        return this.transactions.get(coffee_hr_id + '_' + store_hr_id);
    }

    setTransactions(map: Map<string, StockChange>): void {
        map.forEach(sc => {
            if (!sc.type) {
                sc.type = sc.__t;
            }
        });
        this.transactions = map;
    }
}

import { Component, OnInit, OnDestroy, Output, EventEmitter, Inject, LOCALE_ID, Input, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Enumerations } from 'src/app/models/Enumerations';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { GraphService } from 'src/app/modules/graph/graph.service';
import { Subject } from 'rxjs';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FilterOptions, GetPageOptions, StandardService } from 'src/app/util/services/standard.service';
import { Router } from '@angular/router';
import { Location } from 'src/app/models/Location';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { MatSelectChange } from '@angular/material/select';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Constants } from 'src/app/util/constants';
import cloneDeep from 'lodash-es/cloneDeep';
import { Utils2 } from 'src/app/util/utils2';
import { Chart } from 'chart.js';
import { DateTime } from 'luxon';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-roast-graphs',
    templateUrl: './roast-graphs.component.html',
    styleUrls: ['./roast-graphs.component.scss'],
})
export class RoastGraphsComponent implements OnInit, OnDestroy {

    constructor(
        private breakpointObserver: BreakpointObserver,
        private userService: UserService,
        private graphService: GraphService,
        private standardService: StandardService,
        public utils: Utils,
        private utils2: Utils2,
        public tr: TranslatorService,
        private router: Router,
        @Inject(LOCALE_ID) public locale: string
    ) {
        // Chart.pluginService.register(ChartDataLabels);
    }

    private _filters: FilterOptions = {};
    get filters(): FilterOptions { return this._filters; }
    @Input() set filters(fo: FilterOptions) {
        if (!fo) {
            fo = {};
        }
        if (fo && !fo.ssf) {
            fo.ssf = 'all';
        }
        this._filters = fo;
        if (this.filters.origins?.notnull) {
            this.translatedOrigins = this.allOrigins.map(entry => entry.translated);
        } else {
            this.translatedOrigins = this.filters.origins?.vals?.map((entry: string) => this.tr.anslate(entry)) ?? [];
        }
    }
    @Output() filterChanged = new EventEmitter<FilterOptions>();

    // showstockfrom: { _id: string, hr_id?: string, label?: string }[] | 'all' = 'all';
    disableSSF = false;
    stores: { _id: string, hr_id?: string, label?: string }[] = [];
    // all available origins (ever roasted by this account)
    allOrigins: { translated: string, english: string }[] = [];
    // those origins tha match the text typed by the user in the dropdown
    filteredOrigins: { translated: string, english: string }[] = [];
    // the selected origins
    // origins: string[] | 'notnull' = [];
    // translated version of origins; for display as trigger in the dropdown
    translatedOrigins: string[] = [];

    data: unknown[];
    haveSomeData = false;
    waitingForGraphLongRunning = false;
    waitingForGraph = false;
    waitingForGraphTimer: ReturnType<typeof setTimeout>;
    // filterStartDate: DateTime;
    // filterEndDate: DateTime;

    // showOrganic: 'on' | 'off' | '' = '';
    organicButtonInactive = false;
    helptipOrganicShown = false;
    showHelptipOrganic = true;

    justSentChanges = false;

    mainUnit: UnitSystemType = 'kg';
    currency = 'EUR';
    temperatureUnit = '°C';
    currentUser: UserType;

    isDarkmode = false;

    @ViewChild('myDatepickerEnd') datePickerEndDate: MatDatepicker<DateTime>;

    isSmall$: Observable<boolean>;

    loadSubscription = new Subject<string>();
    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();


    ngOnInit(): void {
        this.isSmall$ = this.breakpointObserver.observe('(max-width: 599px)')
            .pipe(map(result => result.matches));

        // // must be before loadSubscription
        // this.myFilterChanged.pipe(
        //     debounceTime(1250),
        //     distinctUntilKeyChanged('val'))
        //     .subscribe(
        //         val => {
        //             this.filter = val.val;
        //             if (!val.fromExternal) {
        //                 if (!this.filter || !this.utils.isUnfinishedFilterString(this.filter)) {
        //                     this.getGraphData();
        //                 }
        //             }
        //             if (!val.fromExternal) {
        //                 emitChanges(val.val);
        //             }
        //         }
        //     );

        this.loadSubscription
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe(
                () => {
                    if (this.stores) {
                        this.filters.ssf = this.utils.readShowStockFrom(this.stores, this.currentUser);
                    }
                    this.getGraphData();
                    // get all stores for the ssf dropdown
                    this.getAllStores();
                    // get all origins/origin_regions that have been used in roasts for the 
                    this.updateOriginSet();
                }
            );

        this.currentUser = this.userService.getCurrentUser();
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }
        if (this.currentUser.temperature_system === Enumerations.TEMPERATURE_SYSTEM.FAHRENHEIT) {
            this.temperatureUnit = '°F';
        }
        this.helptipOrganicShown = !!(this.currentUser.hts & Enumerations.HELPTIP.ORGANICBEANS);

        this.loadSubscription.next('init');

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.setColor(this.isDarkmode);
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                dm => {
                    const newDM = this.userService.isDarkModeEnabled(dm);
                    if (this.isDarkmode !== newDM) {
                        this.isDarkmode = newDM;
                        this.setColor(this.isDarkmode);
                    }
                }
            );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.waitingForGraphTimer);
    }

    private setColor(darkmode: boolean) {
        if (darkmode) {
            if (Chart.defaults.color !== '#bdbdbd') {
                Chart.defaults.color = '#bdbdbd';
            }
        } else {
            if (Chart.defaults.color !== 'rgba(0, 0, 0, 0.67)') {
                Chart.defaults.color = 'rgba(0, 0, 0, 0.67)';
            }
        }
    }

    reloadData(): void {
        this.loadSubscription.next('reload');
    }

    getAllStores(): void {
        this.standardService.getAll<Location>('stores')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stores = response.result.map(s => {
                            return { _id: s._id, hr_id: s.hr_id, label: s.label }
                        });
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                        if (!this.stores?.length) {
                            this.stores = [];
                        }
                    }
                    this.stores.sort((s1, s2) => s1.hr_id > s2.hr_id ? -1 : s1.hr_id > s2.hr_id ? 1 : 0);
                    this.filters.ssf = this.utils.readShowStockFrom(this.stores, this.currentUser);
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                    if (!this.stores?.length) {
                        this.stores = [];
                    }
                }
            });
    }

    getGraphData(): void {
        this.waitingForGraph = true;
        this.waitingForGraphTimer = setTimeout(() => {
            this.waitingForGraphLongRunning = true;
        }, 600);
        if (this.stores?.length) {
            this.filters.ssf = this.utils.readShowStockFrom(this.stores, this.currentUser);
        } else {
            // directly read ssf; cannot use this.utils.readShowStockFrom bc we don't have this.stores yet
            const ssf = localStorage.getItem('stockfrom_' + this.currentUser.user_id);
            if (ssf === 'all') {
                this.filters.ssf = 'all';
            } else if (ssf) {
                this.filters.ssf = ssf.split(Constants.SSF_SEPARATOR).map(s => { return { _id: s, label: '' }; });
            }
        }
        this.doGetGraphData();
    }

    private doGetGraphData(): void {
        if (!this.filters.ssf) {
            this.filters.ssf = 'all';
        }
        // this._filters = { origins: this.filters.origins, showOrganic: this.filters.showOrganic, ssf: this.filters.ssf, from: this.filters.from, to: this.filters.to };
        this.utils2.cleanResult(this._filters);
        this.graphService.getGraphData('roast', this.filters, { maxNumberOfMonths: 12 })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    clearTimeout(this.waitingForGraphTimer);
                    setTimeout(() => {
                        this.waitingForGraphLongRunning = false;
                    }, 600);
                    this.waitingForGraph = false;
                    this.organicButtonInactive = false;
                    if (response.success === true && response.result?.length) {
                        this.data = response.result;
                        // find out if there is (a high chance of) some data for graphs
                        // this cannot be 100% correct since the graphs can decide themselves whether they show something or not
                        // most important is that this is false for new users (no roasts)
                        let hsd = false;
                        for (let d = 0; d < this.data.length; d++) {
                            if (this.data[d]) {
                                hsd = true;
                                break;
                            }
                        }
                        this.haveSomeData = hsd;

                    } else {
                        this.utils.handleError('error retrieving graph data', response.error);
                    }
                },
                error: error => {
                    clearTimeout(this.waitingForGraphTimer);
                    setTimeout(() => {
                        this.waitingForGraphLongRunning = false;
                    }, 600);
                    this.waitingForGraph = false;
                    this.organicButtonInactive = false;
                    this.utils.handleError('error retrieving graph data', error);
                }
            });
    }

    emitChanges(data: FilterOptions) {
        this.justSentChanges = true;
        this.filterChanged.emit(cloneDeep(data));
    }

    // called from the template when the dropdown value changed
    showstockfromChanged($event: { value: { _id: string, hr_id?: string, label?: string }[] | 'all' }): void {
        if (!$event.value?.length || $event.value === 'all' || $event.value.length === this.stores.length) {
            this.utils.storeShowStockFrom('all', this.currentUser);
            $event.value = 'all';
        } else {
            this.utils.storeShowStockFrom($event.value, this.currentUser);
        }
        this.filters.ssf = $event.value;

        // TODO check if necessary (or for other graph(s))
        if (this.filters.ssf?.length === 0) {
            // workaround: age graph sometimes throws exception when quickly selecting no store and then one store again!
            // probably because the animation hasn't finished yet
            this.disableSSF = true;
            setTimeout(() => {
                this.disableSSF = false;
            }, 2000);
        }

        this.emitChanges({ ssf: this.filters.ssf });
        this.getGraphData();
    }

    // called from outside if filter value changed
    tellFilterChanged(options: GetPageOptions): void {
        if (this.justSentChanges) {
            // ignore
            this.justSentChanges = false;
            return;
        }
        this.justSentChanges = false;
        // Object.assign(this.filters, options);
        this.filters = this.utils2.cleanResult(cloneDeep(options));
        if (this.filters?.origins?.notnull) {
            this.translatedOrigins = this.allOrigins.map(entry => entry.translated);
        } else {
            this.translatedOrigins = this.filters?.origins?.vals?.map((entry: string) => this.tr.anslate(entry)) ?? [];
        }

        this.getGraphData();
    }

    // called from template without arg or from outside with arg newState
    showOrganicChanged(newState?: 'on' | 'off' | ''): void {
        if (this.organicButtonInactive) {
            return;
        }
        this.organicButtonInactive = true;
        this.showHelptipOrganic = false;
        if (typeof newState !== 'undefined') {
            this.filters.showOrganic = newState;
        } else {
            this.filters.showOrganic = this.utils.getNextShowOrganicState(this.filters.showOrganic);
        }
        this.getGraphData();
        this.emitChanges({ showOrganic: this.filters.showOrganic });
    }

    removeHelptip(): void {
        if (this.showHelptipOrganic && !this.helptipOrganicShown) {
            this.showHelptipOrganic = false;
            this.helptipOrganicShown = true;
            this.utils.storeHelptipShown(Enumerations.HELPTIP.ORGANICBEANS);
        }
    }

    updateOriginSet() {
        this.allOrigins = [];
        this.standardService.getOriginsOfRoasts()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && response.result) {
                        const oregions = response.result.origin_regions?.sort().map(oreg => ({ english: oreg, translated: this.tr.anslate(oreg).toLocaleUpperCase(this.locale) }));
                        this.allOrigins = (oregions || []).concat((response.result.origins || []).sort().map(origin => ({ english: origin, translated: this.tr.anslate(origin) })));
                        this.filteredOrigins = this.allOrigins;
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving information', error);
                }
            });
    }

    originFilterChanged(changeEvent: MatSelectChange): void {
        this.translatedOrigins = changeEvent.value.map((entry: string) => this.tr.anslate(entry));
        this.filters.origins = changeEvent.value;
        this.emitChanges({ origins: this.filters.origins });
        this.getGraphData();
    }

    filterOrigins(value: string): { translated: string, english: string }[] {
        if (!value) {
            // return [];
            return this.allOrigins;
            // return this.allOrigins.map(entry => entry.english);
        }
        const filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return this.allOrigins.filter(region => region.translated.toLocaleLowerCase(this.locale).normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0);
    }

    filterDatesChanged($event: MatDatepickerInputEvent<DateTime>, isStartDate: boolean): void {
        let startDate: DateTime;
        let endDate: DateTime;
        if (isStartDate) {
            startDate = $event.value;
            endDate = this.filters.to;
            if (endDate && endDate < startDate) {
                endDate = startDate;
            }
        } else {
            startDate = this.filters.from;
            endDate = $event.value;
        }
        if (startDate && endDate) {
            this.filters.from = startDate.startOf('day');
            this.filters.to = endDate.endOf('day');
            this.emitChanges({ from: startDate, to: endDate });
            this.getGraphData();
        } else if (!startDate && !endDate) {
            this.cancelFilterDates();
        } else {
            this.filters.from = startDate;
            this.filters.to = endDate;
            if (this.filters.from && this.datePickerEndDate) {
                this.datePickerEndDate.open();
            }
        }
    }

    cancelFilterDates(): void {
        // cannot use undefined for dates as addDefaultsToFilterOptions would reset them
        this.filters.from = DateTime.invalid('no date given');
        this.filters.to = DateTime.invalid('no date given');
        this.emitChanges({ from: this.filters.from, to: this.filters.to });
        this.getGraphData();
    }
}

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.energy-widg {
  text-align: center;
}

.widg-title {
  font-weight: bold;
  font-size: 12px;
}

.widg-frame {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 50%;
  display: inline-block;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}

.widg-box {
  border-radius: 10px;
  margin: 5px auto 5px auto;
  height: 120px;
  max-width: 350px;
  display: block;
}

.widg-main {
  font-size: 55px;
  padding-top: 14px;
  line-height: 1.42857143;
}

.widg-main-small {
  font-size: 35px;
  padding-top: 25px;
}

.widg-secondary-small {
  padding-top: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { RoastReport } from 'src/app/models/RoastReport';
import { Subject, takeUntil, throttleTime } from 'rxjs';
import { ReportService } from '../report.service';
import { ClipboardService } from 'ngx-clipboard';
import { AlertService } from 'src/app/util/alert/alert.service';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/util/utils';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { CompanyInfoDialogComponent } from 'src/app/modules/report/company-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService, UserType } from '../../frame/services/user.service';
import { Enumerations } from 'src/app/models/Enumerations';
import merge from 'deepmerge';
import isPlainObject from 'is-plain-object';
import { Sort } from '@angular/material/sort';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-report-export-ui',
    templateUrl: './report-export-ui.component.html',
    styleUrls: ['./report-export-ui.component.scss']
})
export class ReportExportUiComponent implements OnDestroy {

    constructor(
        public utils: Utils,
        private tr: TranslatorService,
        private reportService: ReportService,
        private clipboardService: ClipboardService,
        private alertService: AlertService,
        private dialog: MatDialog,
        private userService: UserService,
    ) { }

    @Input() currentUser: UserType;
    @Input() readOnly: boolean;
    @Input() show1808: boolean;
    @Input() report: RoastReport;
    @Input() isOpenReport: boolean;
    @Input() overview: boolean;

    @Input() currentSort: Sort = { active: 'Weight', direction: 'desc' };

    exportFormat: string;

    creating1808 = false;
    justCopied = false;
    copyFailed = false;
    creatingPdf = false;
    creatingCsv = false;
    creatingSheet = false;
    creatingXML = false;

    @ViewChild('maindiv') maindiv: ElementRef;

    DateTime = DateTime;

    private ngUnsubscribe = new Subject();

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    doGeneratePDF(): void {
        this.creatingPdf = true;
        this.reportService.createRoastReportPDF(this.report._id, this.isOpenReport, this.overview, undefined, this.currentSort)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && response.result) {
                        if (this.overview) {
                            this.report.pdfOvLink = response.result.path;
                            this.report.pdfOvLinkDate = response.result.date ? DateTime.fromISO(response.result.date.toString()) : DateTime.now();
                        } else {
                            this.report.pdfLink = response.result.path;
                            this.report.pdfLinkDate = response.result.date ? DateTime.fromISO(response.result.date.toString()) : DateTime.now();
                        }
                        this.alertService.success(this.tr.anslate('Successfully added'));
                    } else {
                        this.utils.handleError('error creating PDF', response.error);
                    }
                    this.creatingPdf = false;
                },
                error: error => {
                    this.creatingPdf = false;
                    this.utils.handleError('error creating PDF', error);
                }
            });
    }

    saveCompanyInfoAndGeneratePDF(name: string, number: string, dontShowAgain: boolean): void {
        if (this.readOnly) {
            return;
        }
        if (!this.currentUser) {
            this.utils.handleError('error updating user information', '');
            return;
        }

        if (this.currentUser.account?.company?.name === name
            && this.currentUser.account?.company?.number === number) {

            if (dontShowAgain) {
                this.currentUser.dsa = this.currentUser.dsa | Enumerations.DIALOG.COMPANYINFO;
                this.utils.storeDontShownAgain(Enumerations.DIALOG.COMPANYINFO);
            }
            // ignoring, no change
            this.doGeneratePDF();
            return;
        }

        // store in local variable to be sure to ignore multiple calls with same change
        // will be saved on success and undone on error
        if (dontShowAgain && !(this.currentUser.dsa & Enumerations.DIALOG.COMPANYINFO)) {
            this.currentUser.dsa = (this.currentUser.dsa || 0) + Enumerations.DIALOG.COMPANYINFO;
        }
        const company = { name, number };
        this.currentUser = merge(this.currentUser, { account: { company } }, { isMergeableObject: isPlainObject });

        const user = { _id: this.currentUser.user_id, dsa: this.currentUser.dsa, account: { company, _id: undefined, active: undefined, customer_code: undefined } };
        this.userService.updateUser(user)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true && response.result) {
                        this.userService.storeCurrentUser(this.currentUser);
                        this.doGeneratePDF();
                    } else {
                        this.utils.handleError('error updating user information', response.error);
                        this.currentUser = this.userService.getCurrentUser();
                    }
                },
                error: error => {
                    this.utils.handleError('error updating user information', error);
                    this.currentUser = this.userService.getCurrentUser();
                }
            });
    }

    /**
     * Lets the server generate a PDF and retrieves the link.
     */
    generatePDF(): void {
        if (this.readOnly) { return; }

        // ask for company info (which is included in the report PDF) if not set
        if (!(this.currentUser.dsa & Enumerations.DIALOG.COMPANYINFO)
            && (!this.currentUser.account?.company 
            || !this.currentUser.account?.company.name || !this.currentUser.account?.company.number)) {
            const dialogRef = this.dialog.open(CompanyInfoDialogComponent, {
                closeOnNavigation: true,
                data: { company: this.currentUser.account?.company }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result?.company || result?.dontShowAgain) {
                    this.saveCompanyInfoAndGeneratePDF(result.company.name, result.company.number, result.dontShowAgain);
                }
            });
        } else {
            this.doGeneratePDF();
        }
    }

    export(format: 'csv' | 'xml' | 'sheet' | 'clipboardCSV'): void {
        switch (format) {
            case 'csv':
                this.generateCSV();                
                break;
            case 'xml':
                this.generateXML();
                break;
            case 'sheet':
                this.generateSheet();
                break;
            case 'clipboardCSV':
                this.copy();
                break;
            default:
                break;
        }
        setTimeout(() => this.exportFormat = null, 1000);
    }

    /**
     * Lets the server generate a CSV representation and copy it to the clipboard.
     */
    copy(): void {
        if (this.readOnly || this.justCopied) { return; }

        this.reportService.getRoastReportCopy(this.report._id, this.isOpenReport, this.overview, undefined, this.currentSort)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response?.success && response.result && this.clipboardService.copyFromContent(response.result, this.maindiv.nativeElement)) {
                        this.justCopied = true;
                        setTimeout(() => {
                            this.justCopied = false;
                        }, 2000);
                    } else {
                        this.copyFailed = true;
                        setTimeout(() => {
                            this.copyFailed = false;
                        }, 2000);
                    }
                    this.alertService.success(this.tr.anslate('Successfully added'));
                },
                error: error => {
                    this.creatingXML = false;
                    this.utils.handleError('error creating CSV', error);
                }
            });
    }

    /**
     * Lets the server generate a CSV representation and save it to a file.
     */
    generateCSV(): void {
        if (this.readOnly || this.creatingCsv) { return; }

        this.reportService.getRoastReportFile(this.report._id, 'csv', this.isOpenReport, this.overview, undefined, this.currentSort)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: blob => {
                    this.utils.saveBlobToFileSystem(blob, this.report.label + '.csv');
                    this.creatingCsv = false;
                    this.alertService.success(this.tr.anslate('Successfully added'));
                },
                error: error => {
                    this.creatingCsv = false;
                    this.utils.handleError('error creating CSV', error);
                }
            });
    }

    /**
     * Lets the server generate a .xlsx representation and save it to a file.
     */
    generateSheet(): void {
        if (this.readOnly || this.creatingSheet) { return; }

        this.creatingSheet = true;
        this.reportService.getRoastReportFile(this.report._id, 'sheet', this.isOpenReport, this.overview, undefined, this.currentSort)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: blob => {
                    this.utils.saveBlobToFileSystem(blob, this.report.label + '.xlsx');
                    this.creatingSheet = false;
                    this.alertService.success(this.tr.anslate('Successfully added'));
                },
                error: error => {
                    this.creatingSheet = false;
                    this.utils.handleError('error creating Sheet', error);
                }
            });
    }

    /**
     * Lets the server generate an XML representation and save it to a file.
     */
    generateXML(): void {
        if (this.readOnly || this.creatingXML) { return; }

        this.reportService.getRoastReportFile(this.report._id, 'xml', this.isOpenReport, this.overview, undefined, this.currentSort)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: blob => {
                    this.utils.saveBlobToFileSystem(blob, this.report.label + '.xml');
                    this.creatingXML = false;
                    this.alertService.success(this.tr.anslate('Successfully added'));
                },
                error: error => {
                    this.creatingXML = false;
                    this.utils.handleError('error creating XML', error);
                }
            });
    }

//     generate1808(): void {
//         if (this.readOnly || this.creating1808) { return; }

//         this.creating1808 = true;
//         const tax = this.reportService.getCoffeeTax();
//         let sum = 0;
//         for (let r = 0; r < this.report?.roasts?.length; r++) {
//             const roast = this.report.roasts[r];
//             sum += roast.discarded ? 0 : (roast.end_weight || 0);
//         }
//         const taxSum = tax * sum;

//         let data = '<?xml version="1.0" encoding="UTF-8"?>\n\
// <xml-data xmlns="http://www.lucom.com/ffw/xml-data-1.0.xsd">\n\
//     <form>catalog://Unternehmen/vst/kaffee/1808</form>\n\
//     <instance>\n\
//         <datarow>\n\
//             <element id="ID_USER">.anonymous</element>\n\
// ';
//         data += '            <element id="datum1">' + DateTime.now().format('DD.MM.YYYY HH:mm:ss') + '</element>\n';
//         data += '            <element id="menge1">' + sum.toFixed(3).replace(',', '.') + '</element>\n';
//         data += '            <element id="steuerb1">' + taxSum.toFixed(2).replace(',', '.') + '</element>\n';
//         data += '            <element id="text1">' + this.intToStr(Math.floor(taxSum)) + '</element>\n';
//         data += '            <element id="summe">' + taxSum.toFixed(2).replace(',', '.') + '</element>\n';
//         data += '            <element id="summe2">0.00</element>\n';
//         data += '            <element id="summe3">0.00</element>\n';
//         data += '            <element id="gesamt">' + taxSum.toFixed(2).replace(',', '.') + '</element>\n';
//         data += '        </datarow>\n\
//     </instance>\n\
// </xml-data>\n';

//         this.utils.saveToFileSystem(data, 'xml');
//         this.creating1808 = false;
//     }

//     // adapted from https://atnotes.de/index.php?topic=50118.msg322413#msg322413
//     private kToStr(arg_zahl: string) {
//         let zahl = arg_zahl;
//         if (zahl.substring(0, 1) === '0') { zahl = zahl.substring(1, 1 + zahl.length); }
//         if (zahl.substring(0, 1) === '0') { zahl = zahl.substring(1, 1 + zahl.length); }
//         if (zahl.substring(0, 1) === '0') { zahl = zahl.substring(1, 1 + zahl.length); }
//         let final = '';
//         function sonderzahlen(sonder: string) {
//             const sonderInt = parseInt(sonder, 10);
//             if (sonderInt === 10) { return 'zehn'; }
//             if (sonderInt === 11) { return 'elf'; }
//             if (sonderInt === 12) { return 'zwölf'; }
//             if (sonderInt === 13) { return 'dreizehn'; }
//             if (sonderInt === 14) { return 'vierzehn'; }
//             if (sonderInt === 15) { return 'fünfzehn'; }
//             if (sonderInt === 16) { return 'sechszehn'; }
//             if (sonderInt === 17) { return 'siebzehn'; }
//             if (sonderInt === 18) { return 'achtzehn'; }
//             if (sonderInt === 19) { return 'neunzehn'; }
//             return false;
//         }
//         const einzeln = ['null', 'eins', 'zwei', 'drei', 'vier', 'fuenf', 'sechs', 'sieben', 'acht', 'neun'];
//         const zehner = ['null', 'zehn', 'zwanzig', 'dreißig', 'vierzig', 'fuenfzig', 'sechzig', 'siebzig', 'achtzig', 'neunzig'];
//         if (zahl.length === 3) {
//             if (zahl === '000') { return ''; }
//             if (zahl.substring(0, 1) === '1') {
//                 final += 'einhundert';
//             } else {
//                 final += einzeln[zahl.substring(0, 1)] + 'hundert';
//             }
//             if (zahl.substring(1, 3) !== '00') {
//                 if (zahl.substring(1, 2) !== '0') {
//                     if (((parseInt(zahl.substring(1, 3), 10) > 19) || (parseInt(zahl.substring(1, 3), 10) < 11)) && zahl.substring(2, 3) !== '0') {
//                         einzeln[1] = 'ein';
//                         final += einzeln[parseInt(zahl.substring(2, 3), 10)] + 'und' + zehner[parseInt(zahl.substring(1, 2), 10)];
//                     } else if ((parseInt(zahl.substring(1, 3), 10) < 20) && (parseInt(zahl.substring(1, 3), 10) > 10)) {
//                         final += sonderzahlen(zahl.substring(1, 3));
//                     } else if (zahl.substring(2, 3) === '0') {
//                         final += zehner[parseInt(zahl.substring(1, 2), 10)];
//                     } else {
//                         final += einzeln[parseInt(zahl.substring(2, 3), 10)];
//                     }
//                 } else {
//                     final += einzeln[parseInt(zahl.substring(2, 3), 10)];
//                 }
//             }
//         } else if (zahl.length === 2) {
//             if (zahl === '00') { return ''; }
//             if (zahl.substring(0, 1) !== '0') {
//                 if (((parseInt(zahl.substring(0, 2), 10) > 19) || (parseInt(zahl.substring(0, 2), 10) < 11)) && zahl.substring(1, 2) !== '0') {
//                     einzeln[1] = 'ein';
//                     final += einzeln[parseInt(zahl.substring(1, 2), 10)] + 'und' + zehner[parseInt(zahl.substring(0, 1), 10)];
//                 } else if ((parseInt(zahl.substring(0, 2), 10) < 20) && (parseInt(zahl.substring(0, 2), 10) > 10)) {
//                     final += sonderzahlen(zahl.substring(0, 2));
//                 } else if (zahl.substring(1, 2) === '0') {
//                     final += zehner[parseInt(zahl.substring(0, 1), 10)];
//                 } else {
//                     final += einzeln[parseInt(zahl.substring(1, 2), 10)];
//                 }
//             } else {
//                 final += einzeln[zahl];
//             }
//         } else if (zahl.length === 1) {
//             if (zahl === '0') { return ''; }
//             final += einzeln[parseInt(zahl.substring(0, 1), 10)];
//         }
//         return final;
//     }

//     intToStr(num: number): string {
//         const number = num.toString();
//         let ff = '';
//         if (number.length < 4) {
//             return this.kToStr(number);
//         } else if (number.length < 7) {
//             const f1 = this.kToStr(number.substring(0, number.length - 3));
//             const f2 = this.kToStr(number.substring(number.length - 3, number.length));
//             return f1 + 'tausend' + f2;
//         } else if (number.length < 10) {
//             const f1 = this.kToStr(number.substring(0, number.length - 6));
//             const f2 = this.kToStr(number.substring(number.length - 6, number.length - 3));
//             const f3 = this.kToStr(number.substring(number.length - 3, number.length));
//             if (f1 === 'eins') { ff += 'einemillion'; } else { ff += f1 + 'millionen'; }
//             if (f2 === 'eins') { ff += 'eintausend'; } else if (f2 !== '') { ff += f2 + 'tausend'; }
//             ff += f3;
//             return ff;
//         } else if (number.length < 13) {
//             const f1 = this.kToStr(number.substring(0, number.length - 9));
//             const f2 = this.kToStr(number.substring(number.length - 9, number.length - 6));
//             const f3 = this.kToStr(number.substring(number.length - 6, number.length - 3));
//             const f4 = this.kToStr(number.substring(number.length - 3, number.length));
//             if (f1 === 'eins') { ff += 'einemilliarde'; } else { ff += f1 + 'milliarden'; }
//             if (f2 === 'eins') { ff += 'einemillion'; } else if (f2 !== '') { ff += f2 + 'millionen'; }
//             if (f3 === 'eins') { ff += 'eintausend'; } else if (f3 !== '') { ff += f3 + 'tausend'; }
//             ff += f4;
//             return ff;
//         }
//     }
}

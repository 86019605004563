import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { version } from 'src/environments/version';
import { nanoid } from 'nanoid'

@Injectable()
export class WebClientInterceptor implements HttpInterceptor {
    constructor(
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.indexOf('.php') === -1) {
            const webstr = `web_${version.latestWebVersion}`;
            if (request.method === 'GET' || request.method === 'DELETE') {
                request = request.clone({
                    setParams: {
                        _cl: webstr,
                    }
                });
            } else {
                if (request.method === 'POST') {
                    request = request.clone({
                        setHeaders: {
                            'Idempotency-Key': nanoid(),
                        }
                    });
                }
                if (!(request.body instanceof FormData)) {
                    const newBody = Object.assign({}, request.body) as { _cl: string };
                    newBody._cl = webstr;
                    request = request.clone({
                        body: newBody,
                    });
                } else {
                    (request.body as FormData).append('_cl', webstr);
                    // newBody = new FormData();
                    // (request.body as FormData).forEach((value, key) => newBody.append(key, value));
                }
            }
            request = request.clone({
                setHeaders: {
                    'Content-Language': this.locale,
                }
            });
        }

        return next.handle(request);
    }
}

import { Component, OnInit, NgZone, ViewChild, ElementRef, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

    constructor(
        private router: Router,
        private userService: UserService,
        private scrollDispatcher: ScrollDispatcher,
        private zone: NgZone,
        private route: ActivatedRoute,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        this.scrollDispatcher.scrolled().subscribe(
            // need to explicitly run the callback in the Angular NgZone to use the live binding
            content => this.zone.run(() => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const msc = content as any;
                if (msc) {
                    const top = msc.elementRef.nativeElement.scrollTop;
                    if (top > 30) {
                        this.opaque = true;
                    } else if (this.opaque && top < 5) {
                        this.opaque = false;
                    }
                }
            })
        );
    }

    currentUser: UserType;
    returnUrl: string;
    isDarkmode = '';

    picSuffix = '';
    opaque = false;
    paidUntil = DateTime.fromObject({ year: 2222, month: 2, day: 2 });

    private ngUnsubscribe = new Subject();

    @ViewChild('pic0') pic0: ElementRef;
    @ViewChild('pic1') pic1: ElementRef;
    @ViewChild('pic2') pic2: ElementRef;
    @ViewChild('pic3') pic3: ElementRef;
    @ViewChild('pic4') pic4: ElementRef;
    @ViewChild('pic5') pic5: ElementRef;
    @ViewChild('pic6') pic6: ElementRef;
    @ViewChild('pic7') pic7: ElementRef;


    ngOnInit(): void {
        if (this.locale && this.locale !== 'en') {
            this.picSuffix = '_' + this.locale;
        }

        this.isDarkmode = this.userService.isDarkModeEnabled() ? 'd' : '';
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => 
                this.isDarkmode = this.userService.isDarkModeEnabled(dm) ? 'd' : ''
            );

        // get return url from route parameters or default to '/stores'
        this.returnUrl = '/stores';
        if (this.route.snapshot.queryParams['returnUrl']) {
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
        }

        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (this.currentUser) {
            this.router.navigate(['/stores']);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    scrollDown(toPic: number): void {
        if (!this.pic0 || !this.pic1 || !this.pic2 || !this.pic3 || !this.pic4 || !this.pic5 || !this.pic6 || !this.pic7) {
            return;
        }

        switch (toPic) {
            case 0:
                this.pic0.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                break;
            case 1:
                this.pic1.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
                break;
            case 2:
                this.pic2.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
                break;
            case 3:
                this.pic3.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
                break;
            case 4:
                this.pic4.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
                break;
            case 5:
                this.pic5.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
                break;
            case 6:
                this.pic6.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
                break;
            case 7:
                this.pic7.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
                break;
        }
    }

}

import { FileService } from 'src/app/util/services/file.service';
// (ElementRef only used as type):
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, OnInit, AfterViewChecked, ViewChildren, QueryList, OnDestroy, Inject, LOCALE_ID, ElementRef, ViewChild } from '@angular/core';
import { StandardService } from 'src/app/util/services/standard.service';
import { MatDialog } from '@angular/material/dialog';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Subject, Observable } from 'rxjs';
import { YesNoDialogComponent } from 'src/app/modules/ui/dialog/yesno-dialog.component';
import { NGXLogger } from 'ngx-logger';
import { Supplier } from 'src/app/models/Supplier';
import { Customer } from 'src/app/models/Customer';
import { Producer } from 'src/app/models/Producer';
import { Location } from 'src/app/models/Location';
import { Enumerations } from 'src/app/models/Enumerations';
import { throttleTime, takeUntil, takeWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PropertiesService } from 'src/app/util/services/properties.service';
import { UserType, UserService } from 'src/app/modules/frame/services/user.service';
import { Property } from 'src/app/models/Property';
import { CantDeleteDialogComponent } from 'src/app/modules/ui/dialog/cant-delete-dialog.component';
import { ImageUpload2Component } from 'src/app/modules/ui/image-upload/image-upload2.component';
import { SizeTooLargeError } from 'src/app/util/exceptions/SizeTooLargeException';
import { NgForm } from '@angular/forms';
import { ServerLogService } from 'src/app/util/services/server-log.service';
import { ContactInfoDialogComponent } from './contact-info-dialog.component';
import { SelectFloidDialogComponent } from './select-floid-dialog.component';
import { Floid } from 'src/app/models/Floid';
import { SupplierPartner } from 'src/app/models/SupplierPartner';
import { SupplierPartnersComponent } from './supplierpartners.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import cloneDeep from 'lodash-es/cloneDeep';

export enum LocTypes {
    // same numbering as in Enumerations.LocationTypes:
    SUPPLIER = 1,
    PRODUCER = 2,
    CUSTOMER = 3
}

// export interface FloIdDetails {
//     name: string;
//     webpage: string;
//     functions: string;
//     address: string;
//     position: string;
//     statuses: string;
//     floid: string;
// }

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy, AfterViewChecked {
    constructor(
        protected userService: UserService,
        private route?: ActivatedRoute,
        private router?: Router,
        protected standardService?: StandardService,
        protected dialog?: MatDialog,
        public utils?: Utils,
        public tr?: TranslatorService,
        private propertiesService?: PropertiesService,
        protected alertService?: AlertService,
        protected logger?: NGXLogger,
        private fileService?: FileService,
        private serverLogService?: ServerLogService,
        @Inject(LOCALE_ID) private locale?: string,
    ) { }

    contacts: (SupplierPartner | Customer | Producer)[][];
    // contacts[LocTypes.SUPPLIER]: Supplier[];
    // contacts[LocTypes.CUSTOMER]: Customer[];
    // contacts[LocTypes.PRODUCER]: Producer[];
    supplierPartners: SupplierPartner[] = [];
    scrollToSupPartner: string;

    contactcopy: (SupplierPartner | Customer | Producer);
    places: Location[];
    editPlaceMode = false;
    // used if the user adds a new location
    newLocation = false;
    // used to temporarily store a new / edited location
    editingLocation: Location;
    filteredCountries = [];
    isSaving = false;
    loadingFloId = -1;
    floIdEdit = false;

    editContactType: string;
    editMode = -1;
    newLabel: string;

    currentUser: UserType;
    readOnly = false;
    mainUnit: UnitSystemType = 'kg';
    currency = 'EUR';
    isDarkmode = false;
    checkingIfDeletable = false;
    // isExpanded: boolean[] = [];
    // getIsExpanded = (ctp: number, c: number): boolean => {
    //     return this.isExpanded[this.calcIdx(ctp, c)];
    // }

    loadSubscription = new Subject<string>();
    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();
    // avoid reloading called twice
    private static lastReloadId = -1;

    LocTypes = LocTypes;
    private countGetAll = 0;

    orgforms: Property[];

    @ViewChildren('contactLabel') contactLabels: QueryList<ElementRef>;
    @ViewChildren('expPanel') expansionPanels: QueryList<MatExpansionPanel>;
    @ViewChild('mainForm') form: NgForm;

    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm));

        this.loadSubscription
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe(
                () => {
                    // this.logger.debug('reload contacts because of', _val);
                    this.getAll();
                }
            );

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: unknown) => {
                if (e instanceof NavigationEnd && e.id !== ContactsComponent.lastReloadId && e.url.indexOf('contacts') >= 0) {
                    // only pass and debounce interesting events
                    ContactsComponent.lastReloadId = e.id;
                    this.loadSubscription.next('reload');
                }
            });

        this.loadProperties();

        this.loadSubscription.next('init');
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    ngAfterViewChecked(): void {
        if (this.newLabel) {
            // scroll
            const cls = this.contactLabels?.toArray();
            for (let c = 0; c < cls?.length; c++) {
                if (cls[c]?.nativeElement?.innerText === this.newLabel) {
                    cls[c].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                    break;
                }
            }
            // expand
            for (let ctp = 1; ctp < this.contacts.length; ctp++) {
                for (let c = 0; c < this.contacts[ctp].length; c++) {
                    if (this.contacts[ctp][c].label === this.newLabel) {
                        setTimeout(() => {
                            this.expansionPanels?.toArray()[this.calcIdx(ctp, c)]?.open();
                        }, 0);
                        this.newLabel = undefined;
                        break;
                    }
                }
            }
        }
        if (this.route.snapshot.params.id && this.countGetAll === 3) {
            // find label for id
            // and expand
            let label: string;
            for (let ctp = 1; ctp < this.contacts.length; ctp++) {
                for (let c = 0; c < this.contacts[ctp].length; c++) {
                    if (this.contacts[ctp][c]._id === this.route.snapshot.params.id) {
                        label = this.contacts[ctp][c].label;
                        setTimeout(() => {
                            this.expansionPanels?.toArray()[this.calcIdx(ctp, c)]?.open();
                        }, 0);
                        break;
                    }
                }
            }
            // now scroll
            if (!label) {
                // check supplier partners
                for (let s = 0; s < this.supplierPartners.length; s++) {
                    if (this.supplierPartners[s]._id === this.route.snapshot.params.id) {
                        // open and scroll to
                        setTimeout(() => {
                            this.countGetAll = 0;
                            this.scrollToSupPartner = this.supplierPartners[s].label;
                        }, 0);
                        return;
                    }
                }
            }
            this.scrollToSupPartner = undefined;

            if (label) {
                // scroll to element with that label
                const cls = this.contactLabels.toArray();
                for (let c = 0; c < cls.length; c++) {
                    if (cls[c].nativeElement.innerText === label) {
                        cls[c].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                        this.countGetAll = 0;
                        return;
                    }
                }
            }
        }
    }

    calcIdx(ctp: number, c: number): number {
        let idx = 0;
        for (let i = 1; i < ctp; i++) {
            idx += this.contacts[i]?.length || 0;
        }
        idx += c;
        return idx;
    }

    // called from template
    checkLink(contact: SupplierPartner | Customer | Producer): void {
        if (contact.web && contact.web.indexOf('http') < 0) {
            contact.web = 'https://' + contact.web;
        }
    }

    correctLinks(contacts: (SupplierPartner | Customer | Producer)[]): void {
        // correct link
        for (let cc = 0; cc < contacts?.length; cc++) {
            const contact = contacts[cc];
            if (contact.web && contact.web.indexOf('http') < 0) {
                contact.web = 'https://' + contact.web;
            }
        }
    }

    getAll(): void {
        this.standardService.getAll<Supplier>('suppliers')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (response.result) {
                            this.readOnly = this.userService.isReadOnly();
                            if (!this.contacts) { this.contacts = []; }
                            this.contacts[LocTypes.SUPPLIER] = [];
                            this.supplierPartners = [];
                            for (let s = 0; s < response.result.length; s++) {
                                const supp = response.result[s];
                                if (supp['__t'] === 'SupplierPartner') {
                                    this.supplierPartners.push(supp as SupplierPartner);
                                } else {
                                    this.contacts[LocTypes.SUPPLIER].push(supp);
                                }
                            }
                            this.correctLinks(this.contacts[LocTypes.SUPPLIER]);
                            this.scrollTo();
                        }                    } else {
                        this.utils.handleError('Are you connected to the Internet?', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Are you connected to the Internet?', error);
                }
            });
        this.standardService.getAll<Customer>('customers')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (response.result) {
                            if (!this.contacts) { this.contacts = []; }
                            this.contacts[LocTypes.CUSTOMER] = response.result;
                            this.correctLinks(this.contacts[LocTypes.CUSTOMER]);
                            this.scrollTo();
                        }
                    } else {
                        this.utils.handleError('Are you connected to the Internet?', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Are you connected to the Internet?', error);
                }
            });
        this.standardService.getAll<Producer>('producers')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (response.result) {
                            if (!this.contacts) { this.contacts = []; }
                            this.contacts[LocTypes.PRODUCER] = response.result;
                            this.correctLinks(this.contacts[LocTypes.PRODUCER]);
                            this.scrollTo();
                        }
                    } else {
                        this.utils.handleError('Are you connected to the Internet?', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Are you connected to the Internet?', error);
                }
            });

        this.newLabel = undefined;
        this.editPlaceMode = false;
        this.editMode = -1;
    }

    // panel(ctypeidx: number, idx: number, opened: boolean): void {
    //     // this.isExpanded[this.calcIdx(ctypeidx, idx)] = opened;
    // }

    scrollTo(): void {
        if (!this.route.snapshot.params.id) {
            return;
        }
        this.countGetAll++;
    }

    loadProperties(): void {
        this.propertiesService.getProperties(['producer'], 'Organizational form')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.orgforms = response.result;
                        this.orgforms.forEach(p => {
                            if (!p.value) {
                                p.value = p.label;
                            }
                            p.label = this.tr.anslate(p.label);
                        });
                        this.orgforms.sort((prop1, prop2) => !prop1?.label ? -1 : prop1.label.localeCompare(prop2.label, this.locale))
                    } else {
                        this.utils.handleError('error retrieving all organizational forms', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all organizational forms', error);
                }
            });
    }

    getLocType(cType: number): Enumerations.LocationTypes[] {
        switch (cType) {
            case this.LocTypes.SUPPLIER:
                return [Enumerations.LocationTypes.SUPPLIER];
            case this.LocTypes.PRODUCER:
                return [Enumerations.LocationTypes.PRODUCER];
            case this.LocTypes.CUSTOMER:
                return [Enumerations.LocationTypes.CUSTOMER];
            default:
                return [];
        }
    }

    getCountries(): string[] {
        return this.utils.getCountries();
    }

    getPlaces(cType: number): Location[] {
        if (!this.places) {
            return undefined;
        }
        const tstr = Object.values(Enumerations.LocationTypes)[cType];
        const filteredPlaces = this.places.filter(p =>
            (p.type || p['__t'] || '').length === 1 && (p.type || p['__t'] || '').indexOf(tstr) >= 0
        );
        filteredPlaces.sort((p1, p2) => p1.internal_hr_id - p2.internal_hr_id);
        return filteredPlaces;
    }

    compareLocationsFn(l1: Location, l2: Location): boolean {
        return l1 && l2 ? l1._id === l2._id : l1 === l2;
    }

    getAllPlaces(): void {
        this.standardService.getAll<Location>('locations')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.places = response.result;
                    } else {
                        this.utils.handleError('error retrieving all locations', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all locations', error);
                }
            });
    }

    placeChanged(cType: number): void {
        if (this.readOnly) { return; }

        if (this.contactcopy?.location?.toString() === '######') {
            this.editPlaceMode = true;
            this.editingLocation = new Location();
            this.editingLocation.type = this.getLocType(cType);
            this.newLocation = true;
        } else {
            this.newLocation = false;
            this.editingLocation = undefined;
        }
    }

    editPlace(): void {
        if (this.readOnly) { return; }

        this.editPlaceMode = true;

        // don't create new place if a new place has been added-closed-edited:
        if (!this.editingLocation && this.contactcopy?.location && this.contactcopy.location.toString() !== '######') {
            // edit an existing place
            this.editingLocation = Object.assign({}, this.contactcopy.location);
        }
        if (this.editingLocation) {
            this.editingLocation.country = this.editingLocation.country && this.tr.anslate(this.editingLocation.country);
        }
    }

    isEmptyLocation(loc: Location, cType: number): boolean {
        const emptyLoc = new Location();
        emptyLoc.type = this.getLocType(cType);
        const props = Object.getOwnPropertyNames(loc);
        for (let p = 0; p < props.length; p++) {
            const prop = props[p];
            if (prop === 'type') {
                if (loc.type?.length !== 1 || !loc.type.length || loc.type[0] !== emptyLoc.type[0]) {
                    return false;
                }
            } else if ((loc[prop] || emptyLoc[prop]) && loc[prop] !== emptyLoc[prop]) {
                return false;
            }
        }
        return true;
    }

    closePlace(cType: number): void {
        if (this.editingLocation?.label
            || !this.editingLocation.street && !this.editingLocation.address && !this.editingLocation.zip_code && !this.editingLocation.city && !this.editingLocation.region && !this.editingLocation.subregion && !this.editingLocation.country) {
            if (this.contactcopy?.location?.toString() === '######') {
                if (this.isEmptyLocation(this.editingLocation, cType)) {
                    // closed new place without adding any info, remove
                    this.contactcopy.location = undefined;
                    this.editingLocation = undefined;
                    this.newLocation = false;
                }
            } else {
                // update changed place
                for (let f = 0; f < this.places.length; f++) {
                    if ((this.places[f]._id || this.places[f]).toString() === (this.editingLocation._id || this.editingLocation).toString()) {
                        // "un-translate" country
                        this.places[f] = this.editingLocation;
                        this.places[f].country = this.utils.getCountryValue(this.editingLocation.country);
                        break;
                    }
                }
                // this.contactcopy.location = this.editingLocation;
                // this.editingLocation = undefined;
            }
            this.editPlaceMode = false;
        } else {
            this.form.controls.placelabel.markAsTouched();
        }
    }

    /**
     * returns the values of the LocTypes, i.e. [1, 2, 3]
     */
    getTypes(): number[] {
        const keys = Object.keys(LocTypes).filter(k => typeof LocTypes[k] === 'number');
        return keys.map(k => LocTypes[k]);
    }

    /**
     * returns the translated respective LocationTypes element
     */
    getTypeString(cType: number): string {
        return this.tr.anslate(Object.values(Enumerations.LocationTypes)[cType]);
    }

    cancel(): void {
        if (!this.contactcopy._id) {
            this.contacts[Math.floor(this.editMode / 10000)].shift();
        }
        this.newLabel = undefined;
        this.editPlaceMode = false;
        this.editingLocation = undefined;
        this.editMode = -1;
        this.contactcopy = undefined;
        this.floIdEdit = false;
    }

    getContactType(cType: number): string {
        switch (cType) {
            case LocTypes.PRODUCER: return 'producers';
            case LocTypes.SUPPLIER: return 'suppliers';
            case LocTypes.CUSTOMER: return 'customers';
        }
        return '';
    }

    save(cType: number, cIdx: number, isPartner = false): void {
        this.floIdEdit = false;
        if (this.readOnly) { return; }

        this.isSaving = true;
        // need a copy of the copy as we change that for sending to the server
        // and we would otherwise change the object that is currently displayed
        const myccopy = cloneDeep(this.contactcopy);

        delete myccopy.refs;
        if (myccopy['hr_id'] && myccopy._id) {
            // make sure only one of _id and hr_id is present in the update object
            delete myccopy['hr_id'];
        }

        // need to de-populate the objects
        // don't depopulate location since this might have changed
        // if (myccopy.location?._id) {
        //     myccopy.location = myccopy.location._id as any;
        // }

        this.newLocation = false;
        if (this.editingLocation && JSON.stringify(this.editingLocation) !== JSON.stringify(myccopy.location || '')) {
            myccopy.location = Object.assign({}, this.editingLocation);
            // update all contacts with this place
            for (let c = LocTypes.SUPPLIER; this.contacts && c <= LocTypes.CUSTOMER; c++) {
                const contacts = this.contacts[c];
                for (let cc = 0; cc < contacts?.length; cc++) {
                    const contact = contacts[cc];
                    if (contact?.location?._id === (this.editingLocation._id || this.editingLocation).toString()) {
                        contact.location = Object.assign({}, this.editingLocation);
                    }
                }
            }
            for (let s = 0; s < this.supplierPartners?.length; s++) {
                const contact = this.supplierPartners[s];
                if (contact?.location?._id === (this.editingLocation._id || this.editingLocation).toString()) {
                    contact.location = Object.assign({}, this.editingLocation);
                }
            }
            const spc = this as unknown as SupplierPartnersComponent;
            for (let s = 0; s < spc.supPartners?.length; s++) {
                const contact = spc.supPartners[s];
                if (contact?.location?._id === (this.editingLocation._id || this.editingLocation).toString()) {
                    contact.location = Object.assign({}, this.editingLocation);
                }
            }
            this.editingLocation = undefined;

            // need to make sure to store the original (not the translated) country name
            if (myccopy.location?.country) {
                const origCountry = this.utils.getCountryValue(myccopy.location.country);
                if (origCountry) {
                    myccopy.location.country = origCountry;
                }
            }
        } else {
            // depopulate existing location
            if (myccopy.location?._id) {
                myccopy.location = myccopy.location._id as unknown as Location;
            }
        }

        let obs: Observable<{ success: boolean, result: SupplierPartner | Customer | Producer, error: string }>;
        if (!myccopy._id && !myccopy['hr_id']) {
            // new
            obs = this.standardService.add(this.getContactType(cType), myccopy);
        } else {
            obs = this.standardService.update(this.getContactType(cType), myccopy);
        }
        obs.pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        if (!isPartner) {
                            this.contacts[cType][cIdx] = response?.result ? response.result : cloneDeep(this.contactcopy);
                        } else {
                            const spc = this as unknown as SupplierPartnersComponent;
                            if (spc?.supPartners?.[cIdx]) {
                                spc.supPartners[cIdx] = (response?.result ? response.result : cloneDeep(this.contactcopy)) as SupplierPartner;
                            }
                        }
                        this.contactcopy = undefined;
                        this.editMode = -1;
                        this.editPlaceMode = false;
                        this.newLabel = undefined;
                        // expand
                        setTimeout(() => {
                            this.expansionPanels?.toArray()[this.calcIdx(cType, cIdx)]?.open();
                            (this as unknown as SupplierPartnersComponent).panels?.toArray()[this.calcIdx(cType, cIdx)]?.open();
                        }, 0);

                    } else {
                        this.utils.handleError('error updating the information', response.error);
                    }
                    this.isSaving = false;
                },
                error: error => {
                    this.utils.handleError('error updating the information', error);
                    this.isSaving = false;
                }
            });
    }

    private labelExists(label: string, cType: number): boolean {
        for (const contact of this.contacts[cType]) {
            if (contact.label === label) {
                return true;
            }
        }
        return false;
    }

    add(cType: number): void {
        if (this.readOnly) { return; }

        if (!this.contacts) {
            this.contacts = [];
        }
        let cnt = 0;
        let label = this.tr.anslate('NEW');
        while (this.labelExists(label, cType)) {
            cnt++;
            label = this.tr.anslate('NEW') + cnt;
        }
        this.contacts[cType].unshift({ label } as SupplierPartner | Customer | Producer);
        this.edit(cType, 0);
        this.newLabel = label;

        // this.standardService.add(this.getContactType(cType), {label: label})
        // .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
        // .subscribe({ next: response => {
        //         if (response.success === true) {
        //             this.contacts[cType].unshift(response.result);
        //             this.edit(cType, 0);
        //             this.newLabel = label;
        //         } else {
        //             this.utils.handleError('error adding a new store', response.error);
        //         }
        //     },
        //     error: error => {
        //         this.utils.handleError('error adding a new store', error);
        //     }
        // });
    }

    delete(cType: number, cIdx: number): void {
        if (this.readOnly) { return; }

        if (!this.contacts || !this.contacts[cType] || !this.contacts[cType][cIdx]) {
            this.logger.fatal('delete called on wrong state', this.contacts);
            return;
        }

        this.checkingIfDeletable = true;
        const model = this.getContactType(cType);
        this.standardService.getRefs(model, this.contacts[cType][cIdx]._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.checkingIfDeletable = false;
                    if (response.success === true) {
                        if (response.result.count > 0) {
                            const dialogRef = this.dialog.open(CantDeleteDialogComponent, {
                                closeOnNavigation: true,
                                data: {
                                    label: this.contacts[cType][cIdx].label,
                                    count: response.result.count,
                                    refs: this.utils.dateifyObjects(response.result.refs, ['date']),
                                    mainUnit: this.mainUnit,
                                    currency: this.currency
                                }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDelete(cType, cIdx);
                                }
                            });

                        } else {
                            const dialogRef = this.dialog.open(YesNoDialogComponent, {
                                closeOnNavigation: true,
                                data: { text: this.tr.anslate('Do you really want to delete {{name}}?', { name: this.contacts[cType][cIdx].label }) }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDelete(cType, cIdx);
                                }
                            });
                        }
                    } else {
                        this.utils.handleError('could not delete the data', response.error);
                    }
                },
                error: error => {
                    this.checkingIfDeletable = false;
                    this.utils.handleError('could not delete the data', error);
                }
            });
    }

    doDelete(cType: number, cIdx: number): void {
        this.standardService.remove(this.getContactType(cType), this.contacts[cType][cIdx]._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.newLabel = undefined;
                        this.editPlaceMode = false;
                        this.editMode = -1;
                        this.contacts[cType].splice(cIdx, 1);
                        this.alertService.success(this.tr.anslate('Successfully removed'));
                    } else {
                        this.utils.handleError('Error updating the contact information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Error updating the contact information', error);
                }
            });
    }

    edit(cType: number, cIdx: number): void {
        if (this.readOnly) { return; }

        this.getAllPlaces();

        this.contactcopy = cloneDeep(this.contacts[cType][cIdx]);
        // if (this.contactcopy.location?.country) {
        //     this.contactcopy.location.country = this.tr.anslate(this.contactcopy.location?.country);
        // }
        // if (typeof this.contactcopy.location === 'undefined') {
        //     this.contactcopy.location = {};
        // }
        if (typeof this.contactcopy.tags === 'undefined') {
            this.contactcopy.tags = [];
        }
        this.editMode = cType * 10000 + cIdx;
    }

    // called from template on each change of the country string
    // updates the autocomplete list accordingly
    // TODO would be more efficient to pre-calculate the list of translated countries
    changeCountryFilter(value: string): void {
        if (!value) {
            value = '';
        }
        const filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const countries = this.utils.getCountries(true);
        // if (this.contactcopy?.location && this.contactcopy.location.toString() !== '######') {
        //     this.contactcopy.location.country = value;
        // }
        if (this.editingLocation) {
            this.editingLocation.country = value;
        }
        this.filteredCountries = countries.filter(country =>
            (country.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0));
    }

    /**
     * Used to check whether an entered country exists. Remove if not.
     * @param value (part of) country name in user's language
     */
    checkCountry(value: string): void {
        const cValue = this.utils.getCountryValue(value);
        // this.contactcopy.location.country = cValue;
        if (!cValue) {
            if (this.contactcopy?.location && this.contactcopy.location.toString() !== '######') {
                // edit an existing place
                this.contactcopy.location.country = undefined;
            }
            if (this.editingLocation) {
                this.editingLocation.country = undefined;
            }
        }
    }

    updateImage(imageProp: string, cType: number, cIdx: number, ownCopyCreated: boolean): void {
        const contactModel = Object.values(Enumerations.LocationTypes)[cType].toLowerCase();
        this.standardService.update(contactModel, { _id: this.contactcopy._id, [imageProp]: this.contactcopy[imageProp] })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.logger.debug('update successful');
                        this.contacts[cType][cIdx][imageProp] = this.contactcopy[imageProp];
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                    } else {
                        this.utils.handleError('Could not set new image', response.error);
                    }
                    if (ownCopyCreated) {
                        this.contactcopy = undefined;
                    }
                },
                error: error => {
                    this.utils.handleError('Could not set new image', error);
                    if (ownCopyCreated) { this.contactcopy = undefined; }
                }
            });
    }

    // TODO merge changeAvatar and addImage (as in stores.component.ts)
    changeAvatar(cType: number, cIdx: number): void {
        if (this.readOnly || cType < 0 || cIdx < 0) {
            return;
        }
        let ownCopyCreated = false;
        if (!this.contactcopy) {
            // not in edit mode, allow changing picture anyway; only need _id and logo
            this.contactcopy = { _id: this.contacts[cType][cIdx]._id, logo: this.contacts[cType][cIdx].logo } as Supplier;
            ownCopyCreated = true;
        }
        if (!this.contactcopy._id) {
            // better to not do anything than to fail (and not do anything); notify:
            this.serverLogService.sendError({ message: 'changeAvatar: this.contactcopy._id not set', details: `cType=${cType}, cIdx=${cIdx}, contact=${this.contacts[cType][cIdx]}` }, 'ContactsComponent.changeAvatar')
            return;
        }
        const dialogRef = this.dialog.open(ImageUpload2Component, {
            closeOnNavigation: true, minWidth: '300px', autoFocus: false,
            data: { url: this.contactcopy.logo, avatar: true, default: 'assets/icons/main/contact' + (this.isDarkmode ? '-dark' : '') + '.png' }
        });

        dialogRef.afterClosed().subscribe(fileString => {
            if (!fileString) {
                if (fileString === null) {
                    // user explicitly removed image
                    this.logger.debug('removing image ' + this.contactcopy.logo);
                    this.contactcopy.logo = null;
                    this.updateImage('logo', cType, cIdx, ownCopyCreated);
                } else if (ownCopyCreated) {
                    this.contactcopy = undefined;
                }
                return;
            }
            try {
                const file: Blob = this.utils.dataURItoBlob(fileString);
                this.fileService.uploadFile(file, 'IMAGE', 'CONTACT', this.contactcopy._id.toString())
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                // this.contactcopy.logo = fileString;
                                if (environment.BASE_API_URL.indexOf('localhost') >= 0) {
                                    const locale2 = this.locale === 'en-GB' ? 'gb' : (this.locale || 'en');
                                    this.contactcopy.logo = '/' + locale2 + response.result;
                                } else {
                                    this.contactcopy.logo = response.result;
                                }
                                this.logger.debug('using image at ' + this.contactcopy.logo);
                                this.updateImage('logo', cType, cIdx, ownCopyCreated);
                            } else {
                                this.utils.handleError('Could not set new image', response.error);
                                if (ownCopyCreated) { this.contactcopy = undefined; }
                            }
                        },
                        error: error => {
                            this.utils.handleError('Could not set new image', error);
                            if (ownCopyCreated) { this.contactcopy = undefined; }
                        }
                    });
            } catch (err) {
                if (err instanceof SizeTooLargeError) {
                    this.utils.handleError('File size too large (must be <2MB)', undefined);
                } else {
                    throw err;
                }
            }
        });
    }

    addImage(cType: number, cIdx: number): void {
        if (this.readOnly || cType < 0 || cIdx < 0) {
            return;
        }
        let ownCopyCreated = false;
        if (!this.contactcopy) {
            // not in edit mode, allow changing picture anyway; only need _id and image
            this.contactcopy = { _id: this.contacts[cType][cIdx]._id, image: this.contacts[cType][cIdx].image } as Supplier;
            ownCopyCreated = true;
        }
        const dialogRef = this.dialog.open(ImageUpload2Component, {
            closeOnNavigation: true, autoFocus: false,
            data: { url: this.contactcopy.image, avatar: false, default: undefined }
        });

        dialogRef.afterClosed().subscribe(fileString => {
            if (!fileString) {
                if (fileString === null) {
                    // user explicitly removed image
                    this.contactcopy.image = null;
                    this.updateImage('image', cType, cIdx, ownCopyCreated);
                } else if (ownCopyCreated) {
                    this.contactcopy = undefined;
                }
                return;
            }
            try {
                const file: Blob = this.utils.dataURItoBlob(fileString);
                this.fileService.uploadFile(file, 'IMAGE', 'CONTACT', this.contactcopy._id.toString())
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                if (environment.BASE_API_URL.indexOf('localhost') >= 0) {
                                    const locale2 = this.locale === 'en-GB' ? 'gb' : (this.locale || 'en');
                                    this.contactcopy.image = '/' + locale2 + response.result;
                                } else {
                                    this.contactcopy.image = response.result;
                                }
                                this.logger.debug('using image at ' + this.contactcopy.image);
                                this.updateImage('image', cType, cIdx, ownCopyCreated);
                            } else {
                                this.utils.handleError('Could not set new image', response.error);
                                if (ownCopyCreated) { this.contactcopy = undefined; }
                            }
                        },
                        error: error => {
                            this.utils.handleError('Could not set new image', error);
                            if (ownCopyCreated) { this.contactcopy = undefined; }
                        }
                    });
            } catch (err) {
                if (err instanceof SizeTooLargeError) {
                    this.utils.handleError('File size too large (must be <2MB)', undefined);
                } else {
                    throw err;
                }
            }
        });
    }

    filesChanged(cType: number, cIdx: number, newFiles: string[]): void {
        if (this.readOnly) { return; }

        this.contacts[cType][cIdx].files = newFiles;
        this.standardService.update(this.getContactType(cType), { _id: this.contacts[cType][cIdx]._id, files: this.contacts[cType][cIdx].files })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        this.logger.debug('update successful');
                    } else {
                        this.utils.handleError('Could not update documents', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Could not update documents', error);
                }
            });
    }

    findFloCertCountry(country: string): string {
        switch (country) {
            case 'Congo(Democratic Republic)':
                return 'Congo, DR';
            case 'Czech Republic':
                return 'Czech Republic';
            case 'East Timor':
                return 'Timor, East';
            case 'Ireland {Republic}':
                return 'Ireland';
            case 'Papua New Guinea':
                return 'PNG';
            case 'Russia':
                return 'Russian Federation';
            case 'Taiwan':
                return 'Taiwan';
            case 'United Kingdom':
                return 'UK';
            default:
                return country;
        }
    }

    // called from template, click on FLO ID or search icon
    searchFloId(cType: number, cIdx: number, label = '', floId = ''): void {
        if (this.loadingFloId === cType * 10000 + cIdx) return;

        if (floId && floId[0] === '#') {
            floId = floId.substring(1).trim();
        }
        if (floId?.length >= 3 || label?.length >= 3) {
            if (floId?.length >= 3) {
                // don't search using the label if we have a FLO ID
                label = '';
            }
            this.loadingFloId = cType * 10000 + cIdx;
            const returnAllMatches = label?.length >= 3;
            this.propertiesService.getFloIdInfo(floId.trim(), label.trim(), returnAllMatches)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        this.loadingFloId = -1;
                        this.logger.debug(response);
                        if (response.success === true && response.result?.length) {
                            if (response.result.length === 1) {
                                // found exactly one result
                                const res = response.result[0];
                                const data = [];
                                if (res.floid) {
                                    data.push({ label: 'FLO ID', value: res.floid });
                                }
                                if (res.name) {
                                    data.push({ label: this.tr.anslate('Name'), value: res.name });
                                }
                                if (res.aka) {
                                    data.push({ label: this.tr.anslate('Name') + ' 2', value: res.aka });
                                }
                                if (res.functions) {
                                    data.push({ label: this.tr.anslate("Notes"), value: res.functions });
                                }
                                if (res.address || res.street || res.city || res.zip_code || res.country) {
                                    let str = '';
                                    if (res.address) str += res.address + ', ';
                                    if (res.street) str += res.street + ', ';
                                    if (res.zip_code) str += res.zip_code + ', ';
                                    if (res.city) str += res.city + ', ';
                                    if (res.country) str += res.country + ', '
                                    if (str) {
                                        str = str.substring(0, str.length - 2);
                                    }
                                    data.push({ label: this.tr.anslate('Address'), value: str.trim() });
                                }
                                if (res.web) {
                                    data.push({ label: this.tr.anslate("Web"), value: res.web, isLink: true });
                                }

                                const title = { text: `flocert.net: ${returnAllMatches ? '' : res.statuses}`, wait: returnAllMatches };
                                const dialogRef = this.dialog.open(ContactInfoDialogComponent, {
                                    closeOnNavigation: true,
                                    data: { info: data, title },
                                });

                                let dialogOpen = true;
                                if (res.floid && returnAllMatches) {
                                    // TODO takeuntil dialog closed
                                    this.propertiesService.getFloIdInfo(res.floid, '', returnAllMatches)
                                        .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe), takeWhile(() => dialogOpen))
                                        .subscribe({
                                            next: response2 => {
                                                if (response2.success === true && response2?.result?.length === 1) {
                                                    dialogRef.componentInstance.data.title = { text: `flocert.net: ${response2.result[0].statuses}`, wait: false };
                                                }
                                            },
                                            error: () => {
                                                dialogRef.componentInstance.data.title.wait = false;
                                            }
                                        });
                                }

                                dialogRef.afterClosed().subscribe(result => {
                                    dialogOpen = false;
                                    if (result === true) {
                                        this.assignFloIdDetails(cType, cIdx, response.result[0]);
                                    }
                                });
                            } else if (response.result.length) {
                                // multiple results; show dialog for the user to choose the correct entry
                                const dialogRef = this.dialog.open(SelectFloidDialogComponent, {
                                    closeOnNavigation: true,
                                    data: response.result,
                                });
                                dialogRef.afterClosed().subscribe((result: Floid) => {
                                    if (result) {
                                        this.assignFloIdDetails(cType, cIdx, result);
                                    }
                                });
                            } else {
                                let errorMsg = response.error;
                                if (response.success === true) {
                                    errorMsg = `${this.tr.anslate('no match for filter')} "${floId ? floId.trim() : label.trim()}"`;
                                }
                                const dialogRef = this.dialog.open(ContactInfoDialogComponent, {
                                    closeOnNavigation: true,
                                    data: { error: 'flocert.net:', errorMsg },
                                });

                                dialogRef.afterClosed().subscribe();
                            }
                        } else {
                            let errorMsg = response.error;
                            if (response.success === true) {
                                errorMsg = `${this.tr.anslate('no match for filter')} "${floId ? floId.trim() : label.trim()}"`;
                            }
                            const dialogRef = this.dialog.open(ContactInfoDialogComponent, {
                                closeOnNavigation: true,
                                data: { error: 'flocert.net:', errorMsg },
                            });

                            dialogRef.afterClosed().subscribe();
                        }
                    },
                    error: error => {
                        this.loadingFloId = -1;
                        this.utils.handleError('Could not reach server.', error);
                    }
                });
        }
    }

    // differentToFloIdDetails(cType: number, cIdx: number): boolean {
    //     const contact = this.contacts[cType][cIdx];
    //     if (contact.label !== (contact as Supplier).floIdDetails?.name
    //         // difficult to check address etc.
    //         || contact.notes.indexOf((contact as Supplier).floIdDetails?.functions) < 0
    //         || contact.notes.indexOf((contact as Supplier).floIdDetails?.functions) < 0) {
    //         return true;
    //     }
    //     return false;
    // }

    // applies this.contacts[cType][cIdx].floIdDetails to the respective contact
    // overwrites label with flo.name, web with flo.webpage (checks for https://)
    // potentially adds a location, adds city, ... and sets address to ''
    // tries to get the correct country
    // overwrites .location.coordinates with flo.position
    // adds flo.functions to .notes
    // ignores "aka" abbreviation
    assignFloIdDetails(cType: number, cIdx: number, floIdDetails: Floid): void {
        this.floIdEdit = true;
        this.edit(cType, cIdx);
        this.editPlace();
        this.contactcopy.label = (floIdDetails?.name + (floIdDetails?.aka ? ` (${floIdDetails.aka})` : '')) || this.contactcopy.label;
        if (floIdDetails?.web) {
            this.contactcopy.web = floIdDetails?.web;
        }
        if (floIdDetails?.functions) {
            if (this.contactcopy.notes
                && this.contactcopy.notes.indexOf(floIdDetails?.functions) < 0) {
                this.contactcopy.notes += '; ';
            } else {
                this.contactcopy.notes = '';
            }
            this.contactcopy.notes += floIdDetails?.functions;
        }
        if (floIdDetails?.floid) {
            (this.contactcopy as Supplier).floId = floIdDetails.floid;
        }
        if (floIdDetails?.country || floIdDetails?.zip_code || floIdDetails?.city || floIdDetails?.street) {
            if (!this.editingLocation) {
                this.editingLocation = new Location();
                this.newLocation = true;
            }
            this.editingLocation.label = this.contactcopy.label;
            if (floIdDetails.country) {
                this.editingLocation.country = floIdDetails.country;
            }
            if (floIdDetails.zip_code) {
                this.editingLocation.zip_code = floIdDetails.zip_code;
            }
            if (floIdDetails.city) {
                this.editingLocation.city = floIdDetails.city;
            }
            if (floIdDetails.street) {
                this.editingLocation.street = floIdDetails.street;
            }
            this.editingLocation.address = '';
        }
        if (floIdDetails?.coordinates) {
            this.editingLocation.coordinates = floIdDetails.coordinates;
        }
    }

    formatPhone(numberStr: string): string {
        if (!numberStr) {
            return '';
        }
        return numberStr.replace(/[^+\d]/g, '');
    }
}

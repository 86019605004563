import { Component, OnInit, Input } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { Router, ActivatedRoute } from '@angular/router';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-stocksreports',
    templateUrl: './stocksreports.component.html',
    styleUrls: ['./stocksreports.component.scss']
})
export class StocksreportsComponent implements OnInit {

    constructor(
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private utils: Utils,
    ) { }

    @Input() currentUser: UserType;
    readOnly = false;

    mainUnit: UnitSystemType = 'kg';
    currency = 'EUR';
    date: DateTime;
    month: number; // 1-12
    months: string[];

    showOrganic: 'on' | 'off' | '' = '';

    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        this.readOnly = this.userService.isReadOnly();

        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }

        this.date = DateTime.now();
        this.dateChanged();

        this.months = this.utils.getAllMonthsLocalized();
    }

    monthChanged(): void {
        if (this.month >= 1 && this.month <= 12) {
            let now = DateTime.now();
            if (this.month > now.month) {
                now = now.set({ year: now.year - 1 });
            }
            this.date = now.set({ month: this.month }).endOf('month');
        }
    }

    dateChanged(): void {
        if (!this.date) {
            return;
        }
        this.date = this.date.endOf('day');
        if (this.date.day !== this.date.daysInMonth) {
            this.month = undefined;
        } else {
            this.month = this.date.month;
        }
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';
import { TooltipItem } from 'chart.js';

@Component({
    standalone: true,
    imports: [BaseChartDirective],
    selector: 'app-varietals-graph',
    templateUrl: './varietals-graph.component.html',
    providers: [DecimalPipe]
})
export class VarietalsGraphComponent implements OnInit {

    constructor(
        private tr: TranslatorService,
        private userService: UserService,
        private decimalPipe: DecimalPipe,
        private utils: Utils,
        private router: Router,
    ) { }

    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() currentUser: UserType;
    @Input() isDarkmode = false;

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    haveData = false;

    data = {
        labels: [],
        datasets: []
    };
    options = {
        plugins: {
            title: {
                display: true,
                text: this.tr.anslate('Varietals'),
                position: 'top' as const,
            },
            legend: {
                display: false,
                position: 'bottom' as const,
            },
            tooltip: {
                mode: 'index' as const,
                displayColors: false,
                backgroundColor: 'rgba(66,66,66,0.8)', // '#424242',
                titleColor: '#fff',
                bodyColor: 'rgba(0, 0, 0, 0)',

                callbacks: {
                    title: (tooltipItems: TooltipItem<'doughnut'>[]) => {
                        const index = tooltipItems[0].dataIndex;
                        const datasetIndex = tooltipItems[0].datasetIndex;
                        const percent = Math.round(this.data.datasets[datasetIndex].data[index] * 10) / 10;
                        return `${percent}% ` + this.data.labels[index];
                    },
                    label: (tooltipItem: TooltipItem<'doughnut'>) => {
                        const datasetIndex = tooltipItem.datasetIndex;
                        const index = tooltipItem.dataIndex;
                        const datasetLabel = tooltipItem.label;
                        const amount = this.data.datasets[datasetIndex].amount[index];
                        // originalValue is in kg (no need to convert it for the graph)
                        const amount_formated = this.utils.formatAmountForPipe(amount, undefined, this.currentUser.unit_system);
                        const amount_formated_full = this.decimalPipe.transform(amount_formated.value, '1.0-1') + amount_formated.post;
                        const coffees = this.data.datasets[datasetIndex].coffees[index];
                        let coffeesString = this.tr.anslate('one lot');
                        if (coffees !== 1) {
                            coffeesString = this.tr.anslate('{{nrCoffees}} lots', { nrCoffees: coffees }).trim();
                        }
                        return `${amount_formated_full} ` + datasetLabel + ` (${coffeesString})`;
                    }
                }
            },
            // rough: false,,
            datalabels: {
                color: '#FFFFFF',
                display: true,
                align: 'center' as const,
                anchor: 'center' as const,
                font: function (): { size: number, weight: number } {
                    const size = 12;
                    return {
                        size: size,
                        weight: 400
                    };
                },
                formatter: function (value, context) {
                    const data = context.chart.data;
                    const dataIndex = context.dataIndex;
                    const rateValue = context.chart.data.datasets[0].percentages[dataIndex];
                    const datasetLabel = data.labels[dataIndex];
                    const space = (context.chart.width / 100) * rateValue;
                    if ((rateValue > 15) && (datasetLabel.length * 12.35 < space)) {
                        return datasetLabel;
                    } else {
                        return '';
                    }
                }
            }
        },
        rotation: 270,
        cutout: '30%',
        circumference: 180,
        color: '#FFFF',
        // scaleFontColor: '#CCCCCC',
        animation: {
            animateRotate: false,
            animateScale: true
        },
        layout: {
            padding: {
                left: 20,
                right: 20,
                top: 20,
                bottom: 20
            }
        },
        aspectRatio: 1.4,
        maintainAspectRatio: false
    };

    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
    }

    setNewData(nd: any) {
        this.data.labels = [];
        this.data.datasets = [];
        this.haveData = false;

        if (nd?.data) {
            if (nd.labels?.length &&
                nd.labels.length === nd.data?.length &&
                nd.data.length === nd.amount?.length &&
                nd.amount.length === nd.coffees?.length) {

                this.haveData = true;

                const total = nd.data.reduce((a: number, b: number) => a + b, 0);
                const percentages = [];
                for (let i = 0; i < nd.data.length; i++) {
                    percentages[i] = nd.data[i] * 100 / total;
                }

                if (this.isDarkmode) {
                    this.options.plugins.datalabels.color = '#eeeeee';
                    this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
                    this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
                    this.options.plugins.tooltip.backgroundColor = '#eeeeeeEE'; // G200/DD => '#eeeeee'
                } else {
                    this.options.plugins.datalabels.color = '#FFFFFF';
                    this.options.plugins.tooltip.titleColor = '#fff';
                    this.options.plugins.tooltip.bodyColor = '#fff';
                    this.options.plugins.tooltip.backgroundColor = 'rgba(66,66,66,0.8)'; // G800, '#424242'
                }

                this.data.labels = nd.labels;
                this.data.datasets = [{
                    data: nd.data,
                    amount: nd.amount,
                    coffees: nd.coffees,
                    percentages: percentages,
                    backgroundColor: [
                        '#db5785A0', // secondary-300
                        '#43a7cfA0', // primary-400
                        '#91cce3A0', // primary-200
                        '#9e9e9eA0', // grey-500
                        '#bdbdbdA0', // grey-400
                        '#e0e0e0A0'  // grey-300
                    ],
                    hoverBackgroundColor: [
                        '#db5785DD', // secondary-300
                        '#43a7cfEE', // primary-400
                        '#91cce3DD', // primary-200
                        '#9e9e9eFF', // grey-500
                        '#bdbdbdFF', // grey-400
                        '#e0e0e0FF'  // grey-300
                    ],
                    borderColor: this.isDarkmode ? '#303030' : '#fafafa',
                    hoverBorderColor: this.isDarkmode ? '#303030' : '#fafafa',
                    borderWidth: 4
                }];
            }
        }
        if (this.chart?.chart) {
            this.chart.chart.update();
        }
    }

}

// adapted from https://stackoverflow.com/questions/51650916/angular-5-how-to-detect-user-inactivity

import { Injectable } from "@angular/core";
import { fromEvent, BehaviorSubject } from "rxjs";

@Injectable()
/**
 * Service that emits idle$ if no mousemove/touchstart/keydown event is received
 * for longer than some seconds (must be set using setIdleAfterSeconds).
 */
export class IdleService {

    public idle$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    // public wake$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    // isIdle = false;
    private idleAfterSeconds: number;
    private countDown: NodeJS.Timeout;

    constructor() {
        // setup events
        fromEvent(document, 'mousemove').subscribe(() => this.onInteraction());
        fromEvent(document, 'touchstart').subscribe(() => this.onInteraction());
        fromEvent(document, 'keydown').subscribe(() => this.onInteraction());
    }

    setIdleAfterSeconds(ias: number): void {
        this.idleAfterSeconds = ias;
        this.onInteraction();
    }

    onInteraction() {
        // // is idle and interacting, emit wake
        // if (this.isIdle) {
        //     this.isIdle = false;
        //     this.wake$.next(true);
        // }

        if (!this.idleAfterSeconds) {
            return;
        }

        // user interaction, reset start-idle-timer
        clearTimeout(this.countDown);
        this.countDown = setInterval(() => {
            // countdown done without interaction - emit idle
            // this.isIdle = true;
            this.idle$.next(true);
        }, this.idleAfterSeconds * 1000)
    }
}
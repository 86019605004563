/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import 'chartjs-plugin-stacked100';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, TooltipItem } from 'chart.js';

@Component({
    standalone: true,
    imports: [BaseChartDirective],
    selector: 'app-processing-graph',
    templateUrl: './processing-graph.component.html',
    providers: [DecimalPipe]
})
export class ProcessingGraphComponent implements OnInit {

    constructor(
        private tr: TranslatorService,
        private userService: UserService,
        private decimalPipe: DecimalPipe,
        private utils: Utils,
        private router: Router,
    ) { }

    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

    // needed to have the correct order of ngOnInit and newData setter
    inited = false;
    updData: any;

    @Input() mainUnit: UnitSystemType = 'kg';
    @Input() currentUser: UserType;
    @Input() isDarkmode = false;

    haveData = false;

    data = {
        labels: ['Wet', 'Hybrid', 'Dry'],
        datasets: [{
            data: [0, 0, 0], // percentage of total stock
            amount: [0, 0, 0],
            coffees: [0, 0, 0],
            borderWidth: 4,
            backgroundColor: '#db578555',
            borderColor: '#db5785',
            hoverBackgroundColor: '#db578588',
            hoverBorderColor: '#db5785',
        }]
    };

    options: ChartConfiguration<'bar'>['options'] = {
        plugins: {
            //        animation: {
            //          duration: 0, // general animation time
            //        },
            // hover: {
            //     animationDuration: 0, // duration of animations when hovering an item
            // },
            title: {
                display: true,
                text: this.tr.anslate('Processing'),
                position: 'top'
            },
            legend: {
                display: false,
                position: 'bottom',
            },
            datalabels: {
                display: false,
            },
            // rough: false,
            tooltip: {
                displayColors: false,
                backgroundColor: 'rgba(66,66,66,0.8)', // '#424242',
                titleColor: '#fff',
                bodyColor: 'rgba(0, 0, 0, 0)',

                callbacks: {
                    title: (tooltipItems: TooltipItem<'bar'>[]) => {
                        const datasetIndex = tooltipItems[0].datasetIndex;
                        const index = tooltipItems[0].dataIndex;
                        const datasetLabel = tooltipItems[0].label;
                        const value = Math.round(this.data.datasets[datasetIndex].data[index]);
                        return `${value}% ` + datasetLabel;
                    },
                    label: (tooltipItem: TooltipItem<'bar'>) => {
                        const datasetIndex = tooltipItem.datasetIndex;
                        const index = tooltipItem.dataIndex;
                        const convFactor = this.utils.getUnitFactor(this.mainUnit); // to convert values back before formatting
                        const amount = this.data.datasets[datasetIndex].amount[index] / convFactor;
                        const amount_formated = this.utils.formatAmountForPipe(amount, undefined, this.currentUser.unit_system);
                        const amount_formated_full = this.decimalPipe.transform(amount_formated.value, '1.0-1') + amount_formated.post;
                        const coffees = this.data.datasets[datasetIndex].coffees[index];
                        if (coffees === 1) {
                            return `${amount_formated_full} (` + this.tr.anslate('one lot') + ')';
                        } else {
                            return `${amount_formated_full} (` + this.tr.anslate('{{nrCoffees}} lots', { nrCoffees: coffees }).trim() + ')';
                        }

                    }
                }
            },
        },
        layout: {
            padding: {
                left: 20,
                right: 20,
                top: 20,
                bottom: 20
            }
        },
        scales: {
            x: {
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: 'rgba(0, 0, 0, 0.25)',
                },
            },
            y: {
                suggestedMin: 0,
                // suggestedMax: 25,
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 8
                },
                title: {
                    display: true,
                    text: '%',
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)',
                    // zeroLineColor: 'rgba(0, 0, 0, 0.25)',
                },
            },
        },
        aspectRatio: 1.5,
        maintainAspectRatio: false,
    };

    @Input() set newData(nd: any) {
        this.setNewData(nd);
    }


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }

        this.inited = true;
        if (this.updData) {
            this.setNewData(this.updData);
            this.updData = undefined;
        }
    }

    setNewData(nd: any) {
        this.haveData = false;
        if (nd) {
            if (!this.inited) {
                // store new data until inited
                this.updData = nd;
            }
            this.haveData = nd.amount && nd.amount.reduce((prev: number, curr: number) => prev + curr, 0) > 0;
            if (this.haveData) {
                // this.data.labels = nd.labels.map(l => {
                //     return this.tr.anslate(l);
                // });
                // labels Wet, Hybrid, Dry are not translated:
                this.data.labels = nd.labels;

                if (this.inited && this.data?.datasets?.length) {
                    this.data.datasets[0].data = nd.data;
                    this.data.datasets[0].amount = nd.amount;
                    if (this.mainUnit === 'lbs') {
                        // convert the data
                        const dataset = this.data.datasets[0].amount;
                        for (let d = 0; d < dataset.length; d++) {
                            dataset[d] *= this.utils.getUnitFactor(this.mainUnit);
                        }
                    }
                    this.data.datasets[0].coffees = nd.coffees;

                    if (this.isDarkmode) {
                        this.data.datasets[0].borderWidth = 1;
                        this.data.datasets[0].backgroundColor = '#db578599';
                        this.data.datasets[0].borderColor = '#db5785EE';
                        this.data.datasets[0].hoverBackgroundColor = '#db5785DD';
                        this.data.datasets[0].hoverBorderColor = '#db5785';
                    } else {
                        this.data.datasets[0].borderWidth = 4;
                        this.data.datasets[0].backgroundColor = '#db578555';
                        this.data.datasets[0].borderColor = '#db5785';
                        this.data.datasets[0].hoverBackgroundColor = '#db578588';
                        this.data.datasets[0].hoverBorderColor = '#db5785';
                    }
                }
            }
        }

        if (this.isDarkmode) {
            this.options.scales.x.grid.color = 'rgba(255, 255, 255, 0.2)';
            // this.options.scales.x.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
            this.options.scales.y.grid.color = 'rgba(255, 255, 255, 0.2)';
            // this.options.scales.y.grid.zeroLineColor = 'rgba(255, 255, 255, 0.2)';
            this.options.plugins.tooltip.titleColor = '#212121DD'; // G900/BB
            this.options.plugins.tooltip.bodyColor = '#212121DD'; // G900/BB
            this.options.plugins.tooltip.backgroundColor = '#eeeeeeEE'; // G200/DD => '#eeeeee'
        } else {
            this.options.scales.x.grid.color = 'rgba(0, 0, 0, 0.1)';
            // this.options.scales.x.grid.zeroLineColor = 'rgba(0, 0, 0, 0.25)';
            this.options.scales.y.grid.color = 'rgba(0, 0, 0, 0.1)';
            // this.options.scales.y.grid.zeroLineColor = 'rgba(0, 0, 0, 0.25)';
            this.options.plugins.tooltip.titleColor = '#fff';
            this.options.plugins.tooltip.bodyColor = '#fff';
            this.options.plugins.tooltip.backgroundColor = 'rgba(66,66,66,0.8)'; // G800, '#424242'
        }

        if (this.chart?.chart) {
            this.chart.chart.update();
        }
    }

}

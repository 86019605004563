// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-bar {
  display: flex;
  /* justify-content: flex-end; */
  margin-top: 5px;
  align-items: center;
}

.select-bar {
  margin-top: 8px;
  text-align: right;
  margin-right: 10px;
}

.select-bar .mat-mdc-form-field {
  max-width: 200px;
}

.graph-spinner {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.filter-input-div {
  margin-left: 5px;
}

.organic-filter-icon {
  position: relative;
  margin-right: 6px;
  margin-left: 17px;
  margin-top: 17px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

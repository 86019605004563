import { Component, OnInit, Inject, OnDestroy, LOCALE_ID } from '@angular/core';
import { MatSnackBarRef, SimpleSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Notification } from 'src/app/models/Notification';
import { NotificationService } from '../../frame/services/notification.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { Utils } from 'src/app/util/utils';
import { ServerLogService } from 'src/app/util/services/server-log.service';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-message-snack',
    templateUrl: './message-snack.component.html',
    styleUrls: ['./message-snack.component.scss'],
})
export class MessageSnackComponent extends SimpleSnackBar implements OnInit, OnDestroy {

    textdata: { title: string, html: string, text: string, link: string, action: string, date: DateTime, notification: Notification };

    isLocalLink = false;
    localRouterLink: (string | { [str: string]: string })[];
    today = DateTime.now();

    constructor(
        public snackBarRef: MatSnackBarRef<SimpleSnackBar>,
        @Inject(MAT_SNACK_BAR_DATA) mydata: {
            title: string, html: string, text: string, link: string, action: string, date: DateTime, notification: Notification,
        },
        private userService: UserService,
        private notificationService: NotificationService,
        private utils: Utils,
        private serverLogService: ServerLogService,
        private logger: NGXLogger,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        super(snackBarRef, { message: '', action: mydata.action });
        // this.data.message = message;
        this.textdata = mydata;
    }

    private ngUnsubscribe = new Subject();

    DateTime = DateTime;

    // private _isDarkmode = false;
    public set isDarkmode(idm: boolean) {
        // use dark / light mode links to artisan.plus doc site
        if (idm) {
            if (this.textdata?.link?.includes('https://doc.artisan.plus')) {
                this.textdata.link = this.textdata.link.replace('https://doc.artisan.plus', 'https://ddoc.artisan.plus');
            }
            if (this.textdata?.html?.includes('https://doc.artisan.plus')) {
                this.textdata.html = this.textdata.html.replace('https://doc.artisan.plus', 'https://ddoc.artisan.plus');
            }
        } else {
            if (this.textdata?.link?.includes('https://ddoc.artisan.plus')) {
                this.textdata.link = this.textdata.link.replace('https://ddoc.artisan.plus', 'https://doc.artisan.plus');
            }
            if (this.textdata?.html?.includes('https://ddoc.artisan.plus')) {
                this.textdata.html = this.textdata.html.replace('https://ddoc.artisan.plus', 'https://doc.artisan.plus');
            }
        }
        // this._isDarkmode = idm;
    }

    ngOnInit(): void {
        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm)
            );
        // use correct locale in links to artisan.plus itself
        if (this.textdata?.link?.includes('artisan.plus/en/')) {
            this.textdata.link = this.textdata.link.replace('artisan.plus/en/', `artisan.plus/${this.locale === 'en-GB' ? 'gb' : this.locale}/`);
        }
        if (this.textdata?.html?.includes('artisan.plus/en/')) {
            this.textdata.html = this.textdata.html.replace('artisan.plus/en/', `artisan.plus/${this.locale === 'en-GB' ? 'gb' : this.locale}/`);
        }
        this.isLocalLink = this.matchesLocalLink(this.textdata?.link);
        if (this.isLocalLink) {
            this.localRouterLink = this.parseLocalLink(this.textdata.link);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    get hasAction(): boolean {
        return true;
    }

    matchesLocalLink(link: string) {
        // https://artisan.plus/${locale}/reminders;id=${rem.hr_id}
        // reminders;id=${rem.hr_id}
        return /(https:\/\/artisan\.plus\/..\/)?reminders;id=.*/.test(link);
    }

    parseLocalLink(link: string): (string | { [str: string]: string })[] {
        const semIdx = link.indexOf(';');
        if (semIdx > -1) {
            // https://artisan.plus/${locale}/reminders;id=${rem.hr_id}
            // reminders;id=${rem.hr_id}
            const linkPart = link.substring(link.lastIndexOf('/'), semIdx);
            // id=${rem.hr_id}
            const paramPart = link.substring(semIdx + 1);
            const params = paramPart.split(';')
            const linkArr: (string | { [str: string]: string })[] = [linkPart];
            for (let p = 0; p < params.length; p++) {
                const param = params[p];
                const parSpl = param.split('=');
                const paramObj: { [str: string]: string } = {};
                paramObj[parSpl[0]] = parSpl[1];
                linkArr.push(paramObj);
            }
            return linkArr;
        }
        return [link];
    }

    /**
     * Confirms the notification, i.e. calls setNotificationSeen on the server
     */
    done(): void {
        if (this.textdata?.notification) {
            // notify server that the notification has been seen
            this.notificationService.setNotificationSeen(this.textdata.notification)
                .pipe(throttleTime(environment.RELOADTHROTTLE))
                .subscribe({
                    next: () => {
                        this.logger.debug(`setNotificationSeen for ${this.textdata.notification.hr_id}`);
                        this.action();
                        this.utils.getNotifications();
                    },
                    error: error => {
                        this.serverLogService.sendError(error, 'MessageSnackComponent.done');
                        this.action();
                    }
                });
        } else if (this.textdata?.notification !== null) {
            this.serverLogService.errorOccurred({
                message: 'notification undefined',
                details: JSON.stringify(this.textdata),
                time: DateTime.now(),
                line: 146,
                file: 'message-snack.component.ts',
            }, 'MessageSnackComponent.done2')
                .pipe(throttleTime(environment.RELOADTHROTTLE))
                .subscribe({
                    next: response => {
                        if (response.success !== true) {
                            this.logger.debug('could not send error, error: ' + response.error);
                        }
                    },
                    error: error => {
                        this.logger.debug('could not send error, error: ' + error);
                    }
                });
            this.action();
        }
    }

    /**
     * Snoozes the notification, closes it and sets snooze_until on the server (snoozeNotification)
     * @param event event.value contains the date
     */
    snooze(event: MatDatepickerInputEvent<DateTime>): void {
        if (this.textdata?.notification) {
            // notify server that the notification has been snoozed
            if (event.value.hasSame(this.today, 'day')) {
                // if the user chose today as date, snooze for one hour
                event.value = DateTime.now().plus({ hours: 1 });
            }
            this.notificationService.snoozeNotification(this.textdata.notification, event.value)
                .pipe(throttleTime(environment.RELOADTHROTTLE))
                .subscribe({
                    next: () => {
                        this.logger.debug(`snoozeNotification for ${this.textdata.notification.hr_id}`);
                        this.action();
                        this.utils.getNotifications();
                    },
                    error: error => {
                        this.serverLogService.sendError(error, 'MessageSnackComponent.snooze');
                        this.action();
                    }
                });
        } else {
            this.serverLogService.errorOccurred({
                message: 'notification undefined',
                details: JSON.stringify(this.textdata),
                time: DateTime.now(),
                line: 193,
                file: 'message-snack.component.ts',
            }, 'MessageSnackComponent.snooze2')
                .pipe(throttleTime(environment.RELOADTHROTTLE))
                .subscribe({
                    next: response => {
                        if (response.success !== true) {
                            this.logger.debug('could not send error, error: ' + response.error);
                        }
                    },
                    error: error => {
                        this.logger.debug('could not send error, error: ' + error);
                    }
                });
        }
    }
}

import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { ReportService } from 'src/app/modules/report/report.service';
import { Utils } from 'src/app/util/utils';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Roast } from 'src/app/models/Roast';
import { Subject } from 'rxjs';
import { RoastreportTableComponent } from './roastreport-table.component';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RoastReport } from 'src/app/models/RoastReport';
import { Router } from '@angular/router';
import merge from 'deepmerge';
import cloneDeep from 'lodash-es/cloneDeep';
// import { Enumerations } from 'src/app/models/Enumerations';
import { YesNoConfirmDialogComponent } from '../../ui/dialog/yesnoconfirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RoastReportOverview } from './RoastReportOverview';
import { GetPageOptions } from 'src/app/util/services/standard.service';
import { Utils2 } from 'src/app/util/utils2';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-editroastreport',
    templateUrl: './editroastreport.component.html',
    styleUrls: ['./editroastreport.component.scss']
})
export class EditroastreportComponent implements OnInit, OnDestroy {

    constructor(
        private reportService: ReportService,
        public utils: Utils,
        public utils2: Utils2,
        private alertService: AlertService,
        public tr: TranslatorService,
        private dialog: MatDialog,
        private userService: UserService,
        private router: Router,
    ) { }

    @Input() currentUser: UserType;
    @Input() isNewest = false;
    @Input() isOnly = false;
    @Input() isOpenReport = false;
    @Input() lastEndDate: Date;
    @Input() isDarkmode: boolean;
    @Input() readOnly: boolean;
    @Input() report: RoastReport;
    @Input() reportOverview: RoastReportOverview;

    private _stores: { _id: string, hr_id?: string, label?: string }[]
    get stores() { return this._stores; }
    @Input() set stores(newStores: { _id: string, hr_id?: string, label?: string }[]) {
        this._stores = newStores;
        const ssf = this.utils.readShowStockFrom(this._stores, this.currentUser);
        this.currentFilter.ssf = ssf;
    }

    @Output() editModeChanged = new EventEmitter<boolean>();
    @Output() reportChanged = new EventEmitter<RoastReport>();
    @Output() reportOverviewChanged = new EventEmitter<RoastReportOverview>();

    reportCopy: RoastReport;

    invalidRoasts: Roast[];
    notInRangeRoasts: Roast[];

    columnsToDisplay = ['Number', 'Date', 'Roast-ID', 'Coffees', 'In', 'Out', 'Loss', 'References'];
    columnsToDisplayInvalids = ['InvalidReason', 'Date', 'Roast-ID', 'Coffees', 'In', 'Out', 'Loss'];
    invalidsReport: RoastReport;
    notInRangeReport: RoastReport;

    // showOrganic: 'on' | 'off' | '' = '';
    // organicButtonInactive = false;

    // allOrigins: { translated: string, english: string }[] = [];
    // filteredOrigins: { translated: string, english: string }[] = [];
    // origins: string[] | 'notnull' = [];
    // translatedOrigins: string[] = [];
    // curOriginsFilter: string;
    // showStockFrom: { _id: string, hr_id?: string, label?: string }[] | 'all' = 'all';

    waiting = false;
    waiting2 = false;
    waiting3 = false;
    waiting4 = false;

    energyUnit = 'BTU';
    currentFilter: GetPageOptions = {};

    private ngUnsubscribe = new Subject();

    @ViewChild('table') table: RoastreportTableComponent;
    // @ViewChild('invalidsTable') invalidsTable: RoastreportTableComponent;
    @ViewChild('notinrangeTable') notInRangeTable: RoastreportTableComponent;
    @ViewChild('hiddenElems') hiddenElems: ElementRef;


    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }

        this.energyUnit = this.currentUser.energy_unit || 'BTU';

        if (this.report) {
            this.reportCopy = cloneDeep(this.report);
            // treat it as a new report (until save)
            delete this.reportCopy._id;

            // date range is stored at the report
            if (!this.reportCopy.filter) {
                this.reportCopy.filter = {};
            }
            this.reportCopy.filter.from = this.reportCopy.startDate;
            this.reportCopy.filter.to = this.reportCopy.endDate;
            this.currentFilter = this.reportCopy.filter;

            // need to reload all since there might have been changes
            // (e.g. a roast is no longer organic)
            this.getRoastsWithin();
            // if (this.reportCopy?.roasts?.length === this.reportCopy?.roastsCount) {
            //     // all roasts are already loaded
            //     // only load invalids
            //     this.getRoastsWithin(false, undefined, undefined, undefined, false, 'invalids', false);
            // } else {
            //     // load all
            //     this.getRoastsWithin(false);
            // }

            // don't get missing roasts before that date; user already decided not to include them #383
            // if (!this.isOpenReport || this.isOnly) {
            if (this.isOnly) {
                this.getAllBefore();
            }
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    filterChanged($event: { options: GetPageOptions }): void {
        this.currentFilter = $event.options;
        // this.report.number = this.reportService.createReportName(this.lastReportNumber, this.currentFilter.from.year());
        this.getRoastsWithin();
    }

    datesChanged(): void {
        if (!this.currentFilter.from || !this.currentFilter.to) {
            this.alertService.error(this.tr.anslate('Invalid date'));
            return;
        }
        this.currentFilter.from = this.currentFilter.from.startOf('day');
        if (this.currentFilter.to < this.currentFilter.from) {
            this.currentFilter.to = this.currentFilter.from.endOf('month');
        }
        this.currentFilter.to = this.currentFilter.to.endOf('day');
        this.getRoastsWithin();
        // don't get missing roasts before that date; user already decided not to include them #383
        // also, it would only be interesting if the start date has changed
        // but this is not possible for fixed (i.e. !isOpenReport) reports
        if (this.isOnly) {
            this.getAllBefore();
        }
    }

    allowablesChanged(): void {
        this.getRoastsWithin();
        // this.updateTables(this.reportCopy.roasts.concat(this.invalidRoasts));
    }

    // called on loadMoreClick
    loadAllRoastsForReport(toLoad: 'normal' | 'invalids' | 'both' = 'both', reason: 'forSort') {
        if (!toLoad && reason && this.reportCopy?.roastsCount > this.reportService.MAX_LIST_ROASTS) {
            const dialogRef = this.dialog.open(YesNoConfirmDialogComponent, {
                closeOnNavigation: true,
                data: { text: this.tr.anslate('This will load all {{nrItems}} items. This can slow down your browser. Proceed?', { nrItems: this.reportCopy.roastsCount }) }
            });

            dialogRef.afterClosed().subscribe((result: boolean) => {
                if (result === true) {
                    this.getRoastsWithin(true, toLoad, true);
                }
            });
        } else {
            this.getRoastsWithin(true, toLoad, false, false);
        }
    }

    updateTables(roastList: Roast[], invalids = false, count = 0): void {
        if (!invalids) {
            // make a copy to have the table update itself
            const rep = Object.assign({}, this.reportCopy);
            rep.startDate = this.currentFilter.from;
            rep.endDate = this.currentFilter.to;
            rep.roasts = roastList?.filter(r => r) ?? [];
            rep.roastsCount = count ?? rep.roasts?.length ?? 0;
            this.reportCopy = rep;
        } else {
            this.invalidRoasts = [];
            for (let r = 0; r < roastList?.length; r++) {
                const roast = roastList[r];
                if (!roast) {
                    continue;
                }
                const reason = this.reportService.isInvalidRoast(roast, { anpa: this.currentFilter.anpa });
                roast.invalidReason = reason;
                this.invalidRoasts.push(roast);
            }
            this.invalidsReport = {
                roasts: this.invalidRoasts,
                _id: undefined,
                startDate: this.reportCopy.startDate,
                endDate: this.reportCopy.endDate,
                certInfo: this.reportCopy.certInfo,
            };
        }
    }

    // called (maybe from children) when the report needs to be reloaded
    reloadOverviewReport() {
        this.waiting2 = true;
        this.reportService.getRoastReportOverview(undefined, this.isOpenReport, this.currentFilter)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.reportOverview = response.result;
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                    }
                    this.waiting2 = false;
                },
                error: error => {
                    this.utils.handleError('error retrieving information', error);
                    this.waiting2 = false;
                }
            });
    }

    getRoastsWithin(loadAll = false, toLoad: 'normal' | 'invalids' | 'both' = 'both', unlimited = false, reloadOverview = true): void {
        let limit = unlimited ? undefined : (loadAll ? this.reportService.MAX_LIST_ROASTS : this.reportService.STD_LIMIT);
        if (toLoad === 'normal' || toLoad === 'both') {
            this.waiting = true;
            this.reportCopy.fullyLoaded = false;

            if (reloadOverview) {
                this.reloadOverviewReport();
            }

            if (unlimited) {
                // user has been warned
                limit = 0;
            } else {
                // we allow NaN as a result here
                const toLoad = this.reportCopy.roastsCount - this.reportCopy.roasts?.length;
                if (loadAll && toLoad > 150) {
                    // need to load at least 150 items; limit to 100 more each time
                    // up to MAX_LIST_ROASTS
                    limit = Math.min(100 + (this.reportCopy.roasts?.length || 0), this.reportService.MAX_LIST_ROASTS);
                }
            }
            const filterCopy = { ...this.currentFilter };
            filterCopy.limit = limit;
            // this does not return roasts with missing information
            this.reportService.getRoastsWithin(this.isOpenReport, filterCopy)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.updateTables(this.utils.dateifyRoasts(response.result), false, response.count);
                        } else {
                            this.utils.handleError('error retrieving reports', response.error);
                        }
                        this.reportCopy.fullyLoaded = response.count === response.result.length;
                        // save last response.count so the next loadMore call can
                        // decide how many to load
                        this.reportCopy.roastsCount = response.count;
                        this.reportCopy.filter = this.currentFilter;
                        this.waiting = false;
                    },
                    error: error => {
                        this.utils.handleError('error retrieving reports', error);
                        this.reportCopy.fullyLoaded = false;
                        this.waiting = false;
                    }
                });
        }

        if (toLoad === 'invalids' || toLoad === 'both') {
            this.waiting3 = true;
            if (this.invalidsReport) {
                this.invalidsReport.fullyLoaded = false;
            }
            this.reportService.getRoastsWithMissingInfo(this.isOpenReport, this.currentFilter)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.updateTables(this.utils.dateifyRoasts(response.result), true);
                            // we don't have response.count here
                            // this.fullyLoadedInvalids = response.count === response.result.length;
                            // cannot be sure if response.result.length === limit:
                            this.invalidsReport.fullyLoaded = !limit || response.result?.length < limit;
                        } else {
                            this.utils.handleError('error retrieving reports', response.error);
                        }
                        this.waiting3 = false;
                    },
                    error: error => {
                        this.utils.handleError('error retrieving reports', error);
                        this.waiting3 = false;
                    }
                });
        }
    }

    // TODO check when to call this; e.g. when dates are changed
    // TODO !! check the from and to!
    getAllBefore(date = this.reportCopy?.startDate): void {
        if (date) {
            this.waiting4 = true;
            // TODO need to filter r => !r.report on the server!
            const filterCopy = { ...this.currentFilter };
            // TODO !! check, this was filterCopy.from = moment(new Date())
            filterCopy.from = DateTime.fromMillis(0);
            filterCopy.to = date;
            this.reportService.getRoastsWithin(this.isOpenReport, filterCopy)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.notInRangeRoasts = this.utils.dateifyRoasts(response.result.filter(r => !r.report));
                            for (let r = 0; r < this.notInRangeRoasts.length; r++) {
                                this.notInRangeRoasts[r].invalidReason = this.tr.anslate('before selected period');
                            }
                            this.notInRangeReport = {
                                roasts: this.notInRangeRoasts,
                                _id: undefined,
                                startDate: this.reportCopy.startDate,
                                endDate: this.reportCopy.endDate,
                                certInfo: this.reportCopy.certInfo,
                            };
                            setTimeout(() => {
                                if (this.notInRangeTable) {
                                    this.notInRangeTable.recalc();
                                }
                                this.waiting4 = false;
                            });
                        } else {
                            this.utils.handleError('error retrieving early reports', response.error);
                            this.waiting4 = false;
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving early reports', error);
                        this.waiting4 = false;
                    }
                });
        }
    }

    saveReport(): void {
        // server doesn't return the list of included roasts, save them
        const cachedroasts = this.reportCopy.roasts;
        this.reportCopy.certInfoOnCreation = this.reportService.getCertInfo(this.currentFilter.showOrganic);
        const filterCopy = this.utils2.cleanResult(cloneDeep(this.currentFilter));
        this.utils2.cleanLoadedFilter(filterCopy);
        // those are stored directly at the report (startDate, endDate)
        delete filterCopy.from;
        delete filterCopy.to;
        this.reportCopy.filter = filterCopy;

        // TODO check if this should not always be done by the server!
        if (this.reportCopy.roasts?.length === this.reportCopy.roastsCount) {
            // have all roasts loaded, save report with those
            // replace full roast objects by their _id
            this.reportCopy.roasts = this.reportCopy.roasts.filter(r => r).map(r => r._id as unknown as Roast);
        } else {
            // have server retrieve the list of roasts
            delete this.reportCopy.roasts;
        }

        // TODO filter is sent twice (reportCopy.filter and filterCopy as HTTP params)
        // reportCopy does not have an _id anymore, need to use report._id
        this.reportService.updateRoastReport(this.report._id, this.reportCopy, this.isOpenReport, filterCopy)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        this.editModeChanged.emit(false);
                        // the returned report doesn't contain the roasts
                        this.reportCopy = this.utils.dateifyRoastReports([response.result])?.[0];
                        this.reportCopy.roasts = cachedroasts;
                        this.reportCopy.fullyLoaded = this.reportCopy.roasts?.length === response.count;
                        this.reportCopy.roastsCount = response.count || 0;
                        this.reportChanged.emit(this.reportCopy);
                        this.reportOverviewChanged.emit(this.reportOverview);

                        this.currentUser = merge(this.currentUser, { account: { settings: { pref_roastreport_type: this.isOpenReport ? 'open' : 'fixed' } } });
                        this.userService.storeCurrentUser(this.currentUser);
                    } else {
                        this.reportCopy.roasts = cachedroasts;
                        this.utils.handleError('error updating report', response.error);
                    }
                },
                error: error => {
                    this.reportCopy.roasts = cachedroasts;
                    this.utils.handleError('error updating report', error);
                }
            });
    }

    scrollToMissing(): void {
        this.hiddenElems?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
}

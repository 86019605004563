import { AfterViewChecked, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { SupplierPartner } from 'src/app/models/SupplierPartner';
import { environment } from 'src/environments/environment';
import { CantDeleteDialogComponent } from 'src/app/modules/ui/dialog/cant-delete-dialog.component';
import { YesNoDialogComponent } from 'src/app/modules/ui/dialog/yesno-dialog.component';
import { ContactsComponent } from './contacts.component';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'app-supplierpartners',
    templateUrl: './supplierpartners.component.html',
    styleUrls: ['./supplierpartners.component.scss']
})
export class SupplierPartnersComponent extends ContactsComponent implements OnInit, AfterViewChecked {

    // we do have access to the supplierPartners in the parent ContactsComponent
    // however, the supplierPartners of this component had a different value from
    // the supplierPartners of the parent component after a reload (this.getAll())
    @Input() supPartners: SupplierPartner[] = [];

    expanded: boolean[] = [];
    isExpanding: boolean[] = [];
    wholeExpanded = 2;
    @Input() set activeLabel(stl: string) {
        if (stl) {
            // open and scroll to element with that label
            if (this.wholeExpanded === 2) {
                this.scrollToLabel(stl);
            } else {
                this.expandWhole(2, true);
                setTimeout(() => {
                    this.scrollToLabel(stl);
                }, 350);
            }
        }
    }

    subscribedToPanelChanges = false;
    private ngUnsubscribe2 = new Subject();

    @ViewChild('dragscroll', { read: DragScrollComponent }) ds: DragScrollComponent;
    @ViewChildren('contactLabel', { read: ElementRef }) subcontactLabels: QueryList<ElementRef>;
    @ViewChildren('expansionpanel') panels: QueryList<MatExpansionPanel>;
    lastPanels: QueryList<MatExpansionPanel>;

    ngOnInit(): void {
        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe2))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm));

        try {
            this.wholeExpanded = Number.parseInt(this.userService.getSetting(this.userService.SUPPLIERPARTNERSSTATE, this.wholeExpanded.toString()));
        } catch {
            // ignore;
        }
    }

    // overwrite the scrolling logic
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngAfterViewChecked(): void {
        // this.panels.changes.subscribe({
        //     next: (list: QueryList<MatExpansionPanel>) => {
        //         if (list?.length) {
        //             list.forEach((panel, idx) => {
        //                 if (this.expanded[idx] && !panel.expanded && !this.isExpanding[idx]) {
        //                     this.isExpanding[idx] = true;
        //                     setTimeout(() => {
        //                         console.log('opening');
        //                         panel.open();
        //                         this.isExpanding[idx] = false;
        //                     }, 10);
        //                     // panel.open();
        //                 }
        //             });
        //         }
        //     }
        // })
        if (!this.subscribedToPanelChanges) {
            this.panels.changes.subscribe({
                next: (list: QueryList<MatExpansionPanel>) => {
                    this.lastPanels = list;
                }
            });
            this.subscribedToPanelChanges = true;
        }
    }

    scrollToLabel(label: string): void {
        const cls = this.subcontactLabels?.toArray();
        for (let c = 0; c < cls?.length; c++) {
            if (cls[c]?.nativeElement?.innerText === label) {
                cls[c]?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                return;
            }
        }
    }

    edit(_cType: number, cIdx: number): void {
        if (this.readOnly) { return; }

        this.getAllPlaces();

        this.contactcopy = cloneDeep(this.supPartners[cIdx]);
        if (typeof this.contactcopy.tags === 'undefined') {
            this.contactcopy.tags = [];
        }
        this.editMode = cIdx;
    }

    delete(cType: number, cIdx: number): void {
        if (this.readOnly) { return; }

        if (!this.supPartners || !this.supPartners[cIdx]) {
            this.logger.fatal('delete called on wrong state', this.supPartners);
            return;
        }

        if (this.supPartners[cIdx].refs?.count > 0) {
            const dialogRef = this.dialog.open(CantDeleteDialogComponent, {
                closeOnNavigation: true,
                data: {
                    label: this.supPartners[cIdx].label,
                    count: this.supPartners[cIdx].refs.count,
                    refs: this.supPartners[cIdx].refs.objs,
                    mainUnit: this.mainUnit,
                    currency: this.currency
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                    this.doDelete(cType, cIdx);
                }
            });

        } else {
            const dialogRef = this.dialog.open(YesNoDialogComponent, {
                closeOnNavigation: true,
                data: { text: this.tr.anslate('Do you really want to delete {{name}}?', { name: this.supPartners[cIdx].label }) }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                    this.doDelete(cType, cIdx);
                }
            });
        }
    }

    doDelete(_cType: number, cIdx: number): void {
        this.standardService.remove(this.getContactType(this.LocTypes.SUPPLIER), this.supPartners[cIdx]._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe2))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.newLabel = undefined;
                        this.editPlaceMode = false;
                        this.editMode = -1;
                        this.supPartners.splice(cIdx, 1);
                        this.alertService.success(this.tr.anslate('Successfully removed'));
                    } else {
                        this.utils.handleError('Error updating the contact information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Error updating the contact information', error);
                }
            });
    }

    filesChanged(_cType: number, cIdx: number, newFiles: string[]): void {
        if (this.readOnly) { return; }

        this.supPartners[cIdx].files = newFiles;
        this.standardService.update('suppliers', { _id: this.supPartners[cIdx]._id, files: this.supPartners[cIdx].files })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe2))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        this.logger.debug('update successful');
                    } else {
                        this.utils.handleError('Could not update documents', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Could not update documents', error);
                }
            });
    }

    panelClick(idx: number): void {
        this.expanded[idx] = !this.expanded[idx];
    }

    /**
     * Expands or collapses the whole view. If setDirectAndDontRemember is false, saves to localStorage.
     * @param val usually +1 or -1 to expand or collapse; can be 0-2 if setDirectAndDontRemember is true
     * @param setDirectAndDontRemember set to true to temporarily set an explicit value
     */
    expandWhole(val: number, setDirectAndDontRemember = false): void {
        if (setDirectAndDontRemember) {
            this.wholeExpanded = val;
        } else {
            this.wholeExpanded += val;
            this.userService.storeSetting(this.userService.SUPPLIERPARTNERSSTATE, this.wholeExpanded.toString());
        }
        if (this.wholeExpanded < 2) {
            if (this.lastPanels?.length) {
                this.lastPanels.forEach(panel => panel.close());
            } else if (this.panels?.length) {
                this.panels.forEach(panel => panel.close());
            }
        }
    }

    moveLeft(): void {
        this.ds.moveLeft();
    }

    moveRight(): void {
        this.ds.moveRight();
    }
}

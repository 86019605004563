import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransService } from 'src/app/modules/transaction/trans.service';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { Router } from '@angular/router';
import { Coffee } from 'src/app/models/Coffee';
import { Purchase } from 'src/app/models/Purchase';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-show-purchases-dialog',
    templateUrl: './show-purchases-dialog.component.html',
    styleUrls: ['./show-purchases-dialog.component.scss']
})
export class ShowPurchasesDialogComponent implements OnInit, OnDestroy {

    constructor(
        private dialogRef: MatDialogRef<ShowPurchasesDialogComponent>,
        private transService: TransService,
        // used in template
        public tr: TranslatorService,
        private utils: Utils,
        private userService: UserService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: {
            coffee: string, totalAmount: number, maxDate: DateTime, readOnly: boolean,
        }
    ) { }

    purchases: Purchase[];
    fifoData: { amount: number, cost: number }[];
    mainUnit: UnitSystemType = 'kg';
    mainUnitSingular = 'kg';
    unitFactor = 1;
    currency = 'EUR';
    currentUser: UserType;
    totalCost = 0;
    averageCost = 0;
    showCorrectionHint = false;

    Number = Number;
    DateTime = DateTime;

    private ngUnsubscribe = new Subject();

    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser();
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
            this.mainUnitSingular = 'lb';
        }
        this.unitFactor = this.utils.getUnitFactor(this.mainUnit);
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }

        if (this.data?.coffee) {
            this.showCorrectionHint = false;
            this.transService.getPurchases(this.data.coffee, this.data.totalAmount || undefined, this.data.maxDate)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.purchases = this.utils.dateifyStockChanges(response.result);
                            this.fifoData = [];
                            let lastPricePerKg: number;
                            let amountLeft = this.data.totalAmount || 0;
                            for (let p = 0; p < this.purchases.length; p++) {
                                const purch = this.purchases[p];
                                // needed when editing the transaction
                                purch.coffee = this.data.coffee as unknown as Coffee;
                                lastPricePerKg = purch.amount ? ((purch.price || 0) / purch.amount) : (purch.price || 0);
                                if ((purch.amount || 0) < amountLeft) {
                                    this.fifoData.push({ amount: (purch.amount || 0) * this.unitFactor, cost: purch.price || 0 });
                                } else {
                                    this.fifoData.push({ amount: amountLeft * this.unitFactor, cost: amountLeft * lastPricePerKg });
                                }
                                amountLeft -= purch.amount || 0;
                                // convert here so we don't need to do it in the html template
                                purch.amount *= this.unitFactor;
                                // if the current stock amount was negative, this uses the most recent purchase
                                if (amountLeft <= 0) {
                                    break;
                                }
                            }
                            if (amountLeft > 0) {
                                // have more in stock than ever bought; most probably bc of correction(s)
                                // calculate with oldest purchase price (of those purchases that have been looked at)
                                this.showCorrectionHint = true;
                                if (this.fifoData[0]) {
                                    this.fifoData[0].amount += amountLeft * this.unitFactor;
                                    this.fifoData[0].cost += amountLeft * lastPricePerKg;
                                    amountLeft = 0;
                                }
                            }
                            this.totalCost = this.fifoData.reduce((prev, data) => prev + data.cost, 0) || 0;
                            this.averageCost = this.data.totalAmount ? this.totalCost / this.data.totalAmount : this.totalCost;
                        } else {
                            this.utils.handleError('error retrieving all purchases for the beans', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving all purchases for the beans', error);
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    editTransaction(purchase: Purchase): void {
        // need to correct the amount to the internal kg for further processing
        purchase.amount /= this.unitFactor;
        this.dialogRef.close(purchase);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Floid } from 'src/app/models/Floid';

@Component({
    selector: 'app-select-floid-dialog',
    templateUrl: './select-floid-dialog.component.html',
})
export class SelectFloidDialogComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<SelectFloidDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Floid[]
    ) { }

    selected = -1;

    ngOnInit(): void {
        for (let f = 0; f < this.data.length; f++) {
            const item = this.data[f];
            if (item.address || item.street || item.city || item.zip_code || item.country) {
                let str = '';
                if (item.address) str += item.address + ', ';
                if (item.street) str += item.street + ', ';
                if (item.zip_code) str += item.zip_code + ', ';
                if (item.city) str += item.city + ', ';
                if (item.country) str += item.country + ', '
                if (str) {
                    str = str.substring(0, str.length - 2);
                }
                item.address = str;
            }
        }
    }

    applyInfo(): void {
        this.dialogRef.close(this.data && this.data[this.selected]);
    }
}

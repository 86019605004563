// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field.form-group.timesize {
  width: 55px;
  margin-bottom: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.form-group.timesize .mat-mdc-form-field-label {
  text-align: left;
}

.mat-mdc-form-field.form-group.datesize {
  width: 110px;
  margin-right: 4px;
  margin-bottom: 0;
}

input[type=number].noarrows {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number].noarrows::-webkit-inner-spin-button,
input[type=number].noarrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Inject, LOCALE_ID } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ReportService } from 'src/app/modules/report/report.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Subject } from 'rxjs';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { Coffee } from 'src/app/models/Coffee';
import { Location } from 'src/app/models/Location';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { CorrectDialogComponent, CorrectDialogResultType } from 'src/app/modules/transaction/dialogs/correct-dialog.component';
import { TransService } from 'src/app/modules/transaction/trans.service';
import { UserType } from 'src/app/modules/frame/services/user.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { AlertService } from 'src/app/util/alert/alert.service';
import { ExportToCsv } from 'src/app/util/export-to-csv';
import { getCurrencySymbol } from '@angular/common';
import { ShowPurchasesDialogComponent } from '../../store/show-purchases-dialog.component';
import { StockChange } from 'src/app/models/StockChange';
import { DateTime } from 'luxon';


interface PerCofLoc {
    c: Coffee; // incl. c.average_cost (average cost per unit)
    l: Location;
    a: number; // amount
    p?: number; // total average_cost
    fp?: number; // FIFO cost
    cs?: Coffee[];
    ls?: Location[];
}

@Component({
    selector: 'app-stocksreport',
    templateUrl: './stocksreport.component.html',
    styleUrls: ['./stocksreport.component.scss']
})
export class StocksreportComponent implements OnInit, OnDestroy {

    constructor(
        private clipboardService: ClipboardService,
        private reportService: ReportService,
        public utils: Utils,
        public tr: TranslatorService,
        private alertService: AlertService,
        private logger: NGXLogger,
        private dialog: MatDialog,
        private transService: TransService,
        @Inject(LOCALE_ID) public locale: string
    ) {
        this.currencySymbol = getCurrencySymbol(this.currency, 'narrow', this.locale);
    }

    @Input() currentUser: UserType;
    @Input() readOnly = false;
    mainUnit: UnitSystemType = 'kg';
    mainUnitSingular = 'kg';
    currency = 'EUR';
    currencySymbol: string;

    private _date: DateTime;
    get date(): DateTime { return this._date; }
    @Input() set date(d: DateTime) {
        this._date = d;
        this.getStocksReport();
    }

    showOrganic: 'on' | 'off' | '' = '';
    organicButtonInactive = false;

    data: PerCofLoc[];
    total = 0;
    data_stock: MatTableDataSource<PerCofLoc> = new MatTableDataSource();
    columnsToDisplay = [];

    FIELDSEPARATOR = '\t';
    LINESEPARATOR = '\r\n';
    DECIMALSEPARATOR = '.';

    exportFormat: string;
    creatingPdf = false;
    creatingCsv = false;
    creatingSheet = false;
    csvOptions = {
        fieldSeparator: ';',
        filename: 'Stock Report',
        quoteStrings: '',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Stock Report',
        useTextFile: false,
        useBom: false,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        // need to use the PR from https://github.com/cyrilselasi/export-to-csv
        useBlanksForUndefined: true,
    };
    justCopied = false;
    copyFailed = false;

    @ViewChild('stockTable') copyContainer: ElementRef;

    haveZeroes = true;
    showZeroAmounts = false;
    // haveHidden = true;
    showHidden: 'on' | 'off' | '' = '';
    haveMultipleCoffees = true;
    haveMultipleCoffeesWithZeroAmounts = true;
    combineCoffees = false;
    haveMultipleLocations = true;
    haveMultipleLocationsWithZeroAmounts = true;
    combineLocations = false;

    totalCost = 0;
    totalAverageCost = 0;

    loading = false;
    loadingTimer: ReturnType<typeof setTimeout>;
    private ngUnsubscribe = new Subject();

    @ViewChild(MatSort, { static: true }) sort: MatSort;

    ngOnInit(): void {
        this.locale = this.locale ?? 'en';

        this.data_stock.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'Amount': return item.a;
                case 'Coffee': return item.c ? item.c.hr_id : item.cs ? item.cs.reduce((prev, cur) => prev + cur.hr_id, '') : '';
                case 'Store': return item.l ? item.l.hr_id : item.ls ? item.ls.reduce((prev, cur) => prev + cur.hr_id, '') : '';
                case 'Cost': return item.p;
                case 'CostPer': return item.p && item.a ? item.p / item.a : 0;
                default: return item[property];
            }
        };

        if (this.currentUser) {
            if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
                this.mainUnit = 'lbs';
                this.mainUnitSingular = 'lb';
            }
            if (this.currentUser.account) {
                this.currency = this.currentUser.account.currency || 'EUR';
                this.currencySymbol = getCurrencySymbol(this.currency, 'narrow', this.locale);
            }
            if (this.currentUser.export) {
                this.FIELDSEPARATOR = this.currentUser.export.sep;
                this.LINESEPARATOR = this.currentUser.export.linesep;
                this.DECIMALSEPARATOR = this.currentUser.export.decsep;
                this.csvOptions.fieldSeparator = this.currentUser.export.sep;
                // this.csvOptions.lineSeparator = this.currentUser.export.linesep;
                this.csvOptions.decimalSeparator = this.currentUser.export.decsep;
            }
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.loadingTimer);
    }

    getStocksReport(): void {
        if (!this.date) {
            return;
        }
        this.loadingTimer = setTimeout(() => {
            this.loading = true;
        }, 600);
        const lastDate = this.date;
        this.reportService.getStocksReport(this.date.valueOf(), this.showOrganic)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response?.success === true) {
                        this.data = response.result?.perCofLoc || [];
                        this.data.sort((pcl1, pcl2) => pcl2.a - pcl1.a);
                        for (let p = 0; p < this.data.length; p++) {
                            const pcl = this.data[p];
                            if (pcl.c && !pcl.c.yearLabel) {
                                pcl.c.yearLabel = this.utils.createBeansYearLabel(pcl.c);
                                pcl.c['certInfo'] = this.utils.isOrganicCoffee(pcl.c) ? 1 : 0;
                            }
                            if (pcl.cs) {
                                for (let c = 0; c < pcl.cs.length; c++) {
                                    const cof = pcl.cs[c];
                                    if (cof && !cof.yearLabel) {
                                        cof.yearLabel = this.utils.createBeansYearLabel(cof);
                                        cof['certInfo'] = this.utils.isOrganicCoffee(cof) ? 1 : 0;
                                    }
                                }
                            }
                        }

                        this.haveZeroes = false;
                        // this.haveHidden = false;
                        // check which toggle buttons to show
                        for (let i = 0; i < this.data.length; i++) {
                            if (this.data[i].a === 0) {
                                this.haveZeroes = true;
                                // if (this.haveHidden) {
                                break;
                                // }
                            }
                            // if (this.data[i].c?.hidden) {
                            //     this.haveHidden = true;
                            //     if (this.haveZeroes) {
                            //         break;
                            //     }
                            // }
                        }

                        // make sure this.date is not undefined
                        if (!this.date) {
                            this.date = lastDate;
                        }
                        let tv = this.getToggleVisibility(this.data, this.date);
                        this.haveMultipleCoffeesWithZeroAmounts = tv.haveMultipleCoffees;
                        this.haveMultipleLocationsWithZeroAmounts = tv.haveMultipleLocations;

                        const noZeroesData = this.data.slice().filter(pcl => pcl.a !== 0);
                        tv = this.getToggleVisibility(noZeroesData, this.date);
                        this.haveMultipleCoffees = tv.haveMultipleCoffees;
                        this.haveMultipleLocations = tv.haveMultipleLocations;

                        this.settingsChanged();

                    } else {
                        this.logger.debug('error retrieving reports');
                        this.utils.handleError(undefined, response.error);
                    }
                    clearTimeout(this.loadingTimer);
                    this.loading = false;
                    this.organicButtonInactive = false;
                },
                error: error => {
                    this.logger.debug('error retrieving reports: ' + error);
                    this.utils.handleError(undefined, error);
                    this.loading = false;
                    clearTimeout(this.loadingTimer);
                    this.organicButtonInactive = false;
                }
            });
    }

    getToggleVisibility(data: PerCofLoc[], date: DateTime): { haveMultipleCoffees: boolean, haveMultipleLocations: boolean } {
        if (!data) {
            return { haveMultipleCoffees: false, haveMultipleLocations: false };
        }
        if (!date.hasSame(DateTime.now(), 'day')) {
            return { haveMultipleCoffees: true, haveMultipleLocations: true };
        }
        let hc = false;
        let hl = false;
        if (data.length >= 1) {
            const firstLoc = (data[0].l || { hr_id: '---' }).hr_id;
            const firstCof = (data[0].c || { hr_id: '---' }).hr_id;
            for (let i = 1; i < data.length; i++) {
                const acl = data[i];
                if (!hc && firstCof !== (acl.c || { hr_id: '---' }).hr_id) {
                    hc = true;
                }
                if (!hl && firstLoc !== (acl.l || { hr_id: '---' }).hr_id) {
                    hl = true;
                }
                if (hc && hl) {
                    break;
                }
            }
        }
        return { haveMultipleCoffees: hc, haveMultipleLocations: hl };
    }

    sortData(): void {
        if (!this.data_stock.sort) {
            this.data_stock.sort = this.sort;
        }
    }

    hiddenChanged(): void {
        if (this.showHidden === 'off') {
            this.showHidden = 'on';
        } else if (this.showHidden === 'on') {
            this.showHidden = '';
        } else {
            this.showHidden = 'off';
        }
        this.settingsChanged();
    }

    settingsChanged(type = ''): void {
        if (this.combineLocations && this.combineCoffees) {
            if (type === 'combineCoffees') {
                this.combineLocations = false;
            } else {
                this.combineCoffees = false;
            }
        }

        // make a copy so that we don't need to go to the server when a setting changes
        let mydata = this.data.slice();

        if (this.showHidden === 'off') {
            mydata = mydata.filter(acl => !acl.c?.hidden);
        } else if (this.showHidden === 'on') {
            mydata = mydata.filter(acl => acl.c?.hidden);
        } // else no filter, show all, independent of .hidden

        if (!this.showZeroAmounts) {
            mydata = mydata.filter(acl => acl.a !== 0);
            if (this.haveMultipleLocations) {
                this.columnsToDisplay = ['Coffee', 'Store', 'Amount', 'Cost', 'CostPer'];
            } else {
                this.columnsToDisplay = ['Coffee', 'Amount', 'Cost', 'CostPer'];
            }
        } else {
            if (this.haveMultipleLocationsWithZeroAmounts) {
                this.columnsToDisplay = ['Coffee', 'Store', 'Amount', 'Cost', 'CostPer'];
            } else {
                this.columnsToDisplay = ['Coffee', 'Amount', 'Cost', 'CostPer'];
            }
        }

        if (this.combineLocations) {
            // sum up all coffees independent of locations
            const cofToIdx = new Map<string, number>();
            const mydataTmp = [];
            for (let i = 0; i < mydata.length; i++) {
                const acl = mydata[i];
                if (!this.showZeroAmounts && acl.a === 0) {
                    continue;
                }
                const cofIdx = cofToIdx.get(acl.c.hr_id);
                // add amount to location to display in list
                acl.l['a'] = acl.a;
                if (typeof cofIdx !== 'undefined') {
                    // same coffee exists in several locations
                    mydataTmp[cofIdx].ls.push(acl.l);
                    // mydata[cofIdx].ls.sort((l1, l2) => l1.hr_id < l2.hr_id ? -1 : l1.hr_id > l2.hr_id ? 1 : 0);
                    mydataTmp[cofIdx].ls.sort((l1: { a: number }, l2: { a: number }) => l2.a - l1.a);
                    mydataTmp[cofIdx].l = undefined;
                    mydataTmp[cofIdx].a += acl.a;
                    mydataTmp[cofIdx].p += acl.fp;
                } else {
                    mydataTmp[i] = { c: acl.c, l: acl.l, a: acl.a, ls: [acl.l], p: acl.fp };
                    cofToIdx.set(acl.c.hr_id, i);
                }
            }
            mydata = mydataTmp;
        } else if (this.combineCoffees) {
            // sum up all coffees at each unique location

            // // remove 'Cost', 'CostPer' columns - needs to be shown for each coffee
            // this.columnsToDisplay.pop();
            // this.columnsToDisplay.pop();

            const locToIdx = new Map<string, number>();
            const mydataTmp = [];
            for (let i = 0; i < mydata.length; i++) {
                const acl = mydata[i];
                if (!this.showZeroAmounts && acl.a === 0) {
                    continue;
                }
                const locIdx = locToIdx.get(acl.l?.hr_id);
                // add amount to coffee to display in list
                acl.c['a'] = acl.a;
                if (typeof locIdx !== 'undefined') {
                    // same location has several coffees
                    mydataTmp[locIdx].cs.push(acl.c);
                    // mydata[locIdx].cs.sort((c1, c2) => c1.hr_id < c2.hr_id ? -1 : c1.hr_id > c2.hr_id ? 1 : 0);
                    mydataTmp[locIdx].cs.sort((c1, c2) => c2['a'] - c1['a']);
                    mydataTmp[locIdx].c = undefined;
                    mydataTmp[locIdx].a += acl.a;
                    mydataTmp[locIdx].p += acl.fp;
                } else {
                    mydataTmp[i] = { c: acl.c, l: acl.l, a: acl.a, cs: [acl.c], p: acl.fp };
                    locToIdx.set(acl.l.hr_id, i);
                }
            }
            mydata = mydataTmp;
        } else {
            for (let i = 0; i < mydata.length; i++) {
                const acl = mydata[i];
                acl.p = acl.fp;
                // don't remove as it is needed for the combineCoffees/combineLocations cases
                // delete acl.fp;
            }
        }

        this.total = 0;
        this.totalCost = 0;
        this.totalAverageCost = 0;
        for (let i = 0; i < mydata.length; i++) {
            const acl = mydata[i];
            if (!acl) {
                continue;
            }
            this.total += acl.a;
            if (acl.l && acl.ls) {
                if (this.combineLocations) {
                    acl.ls = undefined;
                } else {
                    acl.l = undefined;
                }
            }
            if (acl.c && acl.cs) {
                if (this.combineCoffees) {
                    acl.cs = undefined;
                } else {
                    acl.c = undefined;
                }
            }

            if (!acl.ls) {
                if (Number.isFinite(acl.p)) {
                    this.totalCost += acl.p;
                }
            } else {
                // have some locations, need to calculate the cost from each amount
                for (let l = 0; l < acl.ls.length; l++) {
                    const loc = acl.ls[l];
                    if (Number.isFinite(loc['a']) && Number.isFinite(acl.c?.average_fifo_cost)) {
                        this.totalCost += loc['a'] * acl.c.average_fifo_cost;
                    }
                }
            }
        }
        if (this.total) {
            this.totalAverageCost = this.totalCost / this.total;
        }

        this.data_stock.data = mydata.filter(acl => acl);
        if (this.sort) {
            this.data_stock.sort = this.sort;
            // this.logger.debug('sort set');
        }
        // if (this.sort) {
        //     this.sort.sort({ id: this.sort.active, start: this.sort.start, disableClear: false });
        // } else {
        //     this.logger.debug('cannot sort yet');
        // }
    }

    changeAmount(sIdx: number, cof?: Coffee, loc?: Location, amount?: number): void {
        if (this.readOnly) { return; }

        // this.logger.debug('changeAmount', this.data[sIdx], sIdx, cIdx, lIdx);
        const now = DateTime.now();
        const dialogRef = this.dialog.open(CorrectDialogComponent, {
            closeOnNavigation: true,
            data: {
                stores: [loc],
                stocks: [[{ coffeeId: cof._id, amount: amount }]],
                preSelected: 0,
                date: this.date.set({ hour: now.hour, minute: now.minute, second: now.second }),
                coffees: [{
                    label: cof.label, internal_hr_id: cof.internal_hr_id,
                    origin: cof.origin, _id: cof._id, hr_id: cof.hr_id,
                    default_unit: cof.default_unit
                },
                ],
            }
        });

        dialogRef.componentInstance.finished.subscribe(dialogResult => {
            if (dialogResult instanceof CorrectDialogResultType) {
                this.transService.handleCorrectionDialogClosed(dialogResult, dialogRef, undefined, undefined, sIdx, undefined,
                    cof._id as unknown as Coffee,
                    () => this.getStocksReport(), this.ngUnsubscribe);
                }
        });
    }

    changeAmount2(stock: PerCofLoc, sIdx: number, cs?: Coffee[], ls?: Location[]): void {
        if (this.readOnly) { return; }

        this.logger.debug('changeAmount2', this.data[sIdx], sIdx, cs, ls);
        const now = DateTime.now();
        let locs;
        let cofs;
        let stocks;
        if (typeof cs !== 'undefined') {
            cofs = cs;
            locs = [stock.l];
            stocks = [cs.map(c => { return { location_id: stock.l._id, coffeeId: c._id, amount: c['a'] } })];
        } else if (typeof ls !== 'undefined') {
            cofs = [stock.c];
            locs = ls;
            stocks = ls.map(l => { return [{ location_id: l._id, coffeeId: stock.c._id, amount: l['a'] }] });
        } else {
            // err
            this.logger.info('changeAmount2 called without any cs or ls');
            return;
        }

        const dialogRef = this.dialog.open(CorrectDialogComponent, {
            closeOnNavigation: true,
            data: {
                stores: locs,
                stocks: stocks,
                preSelected: 0,
                date: this.date.set({ hour: now.hour, minute: now.minute, second: now.second }),
                coffees: cofs,
            }
        });

        dialogRef.componentInstance.finished.subscribe(dialogResult => {
            if (dialogResult instanceof CorrectDialogResultType) {
                this.transService.handleCorrectionDialogClosed(dialogResult, dialogRef, undefined, undefined, sIdx, undefined,
                    dialogResult.trans.coffee._id as unknown as Coffee,
                    () => this.getStocksReport(), this.ngUnsubscribe);
                }
        });
    }

    getIdStr(one_c: { hr_id?: string }, cs: { hr_id?: string }[], useNewLine = false): string {
        if (!one_c && !cs?.length) {
            return '';
        }
        let ret = '';
        if (cs?.length > 0) {
            for (let i = 0; i < cs.length; i++) {
                const c = cs[i];
                ret += c ? c.hr_id : '';
                if (i < cs.length - 1) {
                    ret += useNewLine ? '\n' : this.FIELDSEPARATOR.indexOf(';') >= 0 ? ' / ' : '; ';
                }
            }
        } else {
            ret += one_c ? one_c.hr_id : '';
        }
        return ret;
    }

    getBeansStr(one_c: Coffee, cs: Coffee[], useNewLine = false, includeId = true, dontUseFieldSep = false): string {
        if (!one_c && !cs?.length) {
            return '';
        }
        let ret = '';
        if (cs?.length > 0) {
            for (let i = 0; i < cs.length; i++) {
                const c = cs[i];
                ret += this.reportService.createCoffeeLabel(c, true, (dontUseFieldSep && this.FIELDSEPARATOR.indexOf(',') >= 0) ? ' ' : ',', includeId, dontUseFieldSep ? this.FIELDSEPARATOR : undefined) + ': ' +
                    this.utils.fixDigits(this.utils.formatNumber(c['a'], 3, this.currentUser?.unit_system), this.mainUnit, 3);
                if (i < cs.length - 1) {
                    ret += useNewLine ? '\n' : (dontUseFieldSep && this.FIELDSEPARATOR.indexOf(';') >= 0) ? ' / ' : '; ';
                }
            }
        } else {
            ret += this.reportService.createCoffeeLabel(one_c, true, useNewLine ? '\n' : ((dontUseFieldSep && this.FIELDSEPARATOR.indexOf(',') >= 0) ? ' ' : ','), includeId, dontUseFieldSep ? this.FIELDSEPARATOR : undefined);
        }
        return ret;
    }

    getStoreStr(one_l: Location, ls: Location[], useNewLine = false, includeId = true, dontUseFieldSep = false): string {
        if (!one_l && !ls?.length) {
            return '';
        }
        let ret = '';
        if (ls?.length > 0) {
            for (let i = 0; i < ls.length; i++) {
                const l = ls[i];
                ret += (includeId && l.hr_id ? l.hr_id + ' ' : '') + (l.label ? l.label : '- ') + ': ' +
                    this.utils.fixDigits(this.utils.formatNumber(l['a'], 3, this.currentUser?.unit_system), this.mainUnit, 3);
                if (i < ls.length - 1) {
                    ret += useNewLine ? '\n' : (dontUseFieldSep && this.FIELDSEPARATOR.indexOf(';') >= 0) ? ' / ' : '; ';
                }
            }
        } else {
            ret += (includeId && one_l.hr_id ? one_l.hr_id + ' ' : '') + (one_l.label ? one_l.label : '-');
        }
        if (dontUseFieldSep) {
            return ret.replace(this.FIELDSEPARATOR, ' ');
        }
        return ret;
    }

    export(format: 'csv' | 'sheet' | 'clipboardCSV'): void {
        switch (format) {
            case 'csv':
                this.generateCSV();
                break;
            case 'sheet':
                this.generateSheet();
                break;
            case 'clipboardCSV':
                this.copy();
                break;
            default:
                break;
        }
        setTimeout(() => this.exportFormat = null, 1000);
    }

    copy(): void {
        if (!this.data_stock?.data) {
            return;
        }
        let text = '';
        // headers
        text += this.tr.anslate('Beans') + ' ' + this.tr.anslate('ID') + this.FIELDSEPARATOR +
            this.tr.anslate('Beans') + this.FIELDSEPARATOR +
            this.tr.anslate('Store') + ' ' + this.tr.anslate('ID') + this.FIELDSEPARATOR +
            this.tr.anslate('Store') + this.FIELDSEPARATOR +
            this.tr.anslate('Amount') + ' (' + this.mainUnit + ')' + this.FIELDSEPARATOR +
            this.tr.anslate('Cost') + ' (' + this.currencySymbol + ')' + this.FIELDSEPARATOR +
            this.tr.anslate('Cost per') + ' ' + this.mainUnit + this.FIELDSEPARATOR +
            this.LINESEPARATOR;

        this.data_stock.sortData(this.data_stock.filteredData, this.data_stock.sort);
        for (let i = 0; i < this.data_stock.data.length; i++) {
            const cofdata = this.data_stock.data[i];
            // Coffee Id and Coffee
            text += this.getIdStr(cofdata.c, cofdata.cs) + this.FIELDSEPARATOR;
            text += this.getBeansStr(cofdata.c, cofdata.cs, false, false, true) + this.FIELDSEPARATOR;
            // Store Id and store
            text += this.getIdStr(cofdata.l, cofdata.ls) + this.FIELDSEPARATOR;
            text += this.getStoreStr(cofdata.l, cofdata.ls, false, false, true) + this.FIELDSEPARATOR;

            // amount
            let num = this.utils.formatNumber(cofdata.a, 3, this.currentUser?.unit_system).toString();
            text += (this.DECIMALSEPARATOR !== '.' ? num.replace('.', this.DECIMALSEPARATOR) : num) + this.FIELDSEPARATOR;

            // cost
            num = (Math.round(cofdata.p * 100) / 100).toString();
            text += (this.DECIMALSEPARATOR !== '.' ? num.replace('.', this.DECIMALSEPARATOR) : num) + this.FIELDSEPARATOR;

            // cost per
            num = cofdata.p && cofdata.a ? (Math.round(cofdata.p / cofdata.a / this.utils.getUnitFactor(this.mainUnit) * 100) / 100).toString() : '0';
            text += (this.DECIMALSEPARATOR !== '.' ? num.replace('.', this.DECIMALSEPARATOR) : num) + this.FIELDSEPARATOR;

            if (i < this.data_stock.data.length - 1) {
                text += this.LINESEPARATOR;
            }
        }
        if (this.clipboardService.copyFromContent(text, this.copyContainer?.nativeElement)) {
            this.justCopied = true;
            setTimeout(() => {
                this.justCopied = false;
            }, 2000);
        } else {
            this.copyFailed = true;
            setTimeout(() => {
                this.copyFailed = false;
            }, 2000);
        }
    }

    generateDataArray(dontUseFieldSep = false): unknown[] {
        const stockData = [];
        this.data_stock.sortData(this.data_stock.filteredData, this.data_stock.sort);
        for (let d = 0; d < this.data_stock.data.length; d++) {
            const row = this.data_stock.data[d];
            stockData.push({
                [this.tr.anslate('Beans') + ' ' + this.tr.anslate('ID')]: this.getIdStr(row.c, row.cs),
                [this.tr.anslate('Beans')]: this.getBeansStr(row.c, row.cs, false, false, dontUseFieldSep),
                [this.tr.anslate('Store') + ' ' + this.tr.anslate('ID')]: this.getIdStr(row.l, row.ls),
                [this.tr.anslate('Store')]: this.getStoreStr(row.l, row.ls, false, false, dontUseFieldSep),
                [this.tr.anslate('Amount') + ' (' + this.mainUnit + ')']: this.utils.formatNumber(row.a, 3, this.currentUser?.unit_system),
                [this.tr.anslate('Cost') + ' (' + this.currencySymbol + ')']: Math.round(row.p * 100) / 100,
                [this.tr.anslate('Cost per') + ' ' + this.mainUnit]: row.p && row.a ? Math.round((row.p / row.a / this.utils.getUnitFactor(this.mainUnit)) * 100) / 100 : 0,
            });
        }
        return stockData;
    }

    generateCSV(): void {
        if (this.readOnly || this.creatingCsv) { return; }

        this.creatingCsv = true;
        // the downloading is async and we can't get a handle to when it finished; just guess here
        setTimeout(() => {
            this.creatingCsv = false;
        }, 3000);

        try {
            const stockData = this.generateDataArray(true);

            if (stockData.length > 0) {
                this.csvOptions.title = this.tr.anslate('Stock') + ' ' + this.date.toLocaleString(DateTime.DATE_MED);
                this.csvOptions.filename = this.tr.anslate('Stock') + '_' + this.date.toFormat('dd_MMMM_yyyy');
                const csvExporter = new ExportToCsv(this.csvOptions);
                csvExporter.generateCsv(stockData);
                this.alertService.success(this.tr.anslate('Successfully added'));
            } else {
                this.alertService.success(this.tr.anslate('nothing to show'));
            }
        } catch (error) {
            this.utils.handleError('error creating CSV', error);
        }
    }

    generateSheet(): void {
        if (this.readOnly || this.creatingSheet) { return; }

        this.creatingSheet = true;
        const stockData = this.generateDataArray();

        this.reportService.getSheetFromArray(stockData)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: blob => {
                    this.creatingSheet = false;

                    const filename = this.tr.anslate('Stock') + '_' + this.date.toFormat('dd_MMMM_yyyy');
                    this.utils.saveBlobToFileSystem(blob, filename + '.xlsx');

                    this.alertService.success(this.tr.anslate('Successfully added'));
                },
                error: error => {
                    this.creatingSheet = false;
                    this.utils.handleError('error creating Sheet', error);
                }
            });
    }

    generatePDF(): void {
        if (this.readOnly || this.creatingPdf) { return; }

        this.creatingPdf = true;
        const stockData = [];
        this.data_stock.sortData(this.data_stock.filteredData, this.data_stock.sort);
        for (let d = 0; d < this.data_stock.data.length; d++) {
            const row = this.data_stock.data[d];

            const amount = row.a ? (row.a * this.utils.getUnitFactor(this.mainUnit)).toFixed(2) : '';
            const amountStr = this.DECIMALSEPARATOR !== '.' ? amount.replace('.', this.DECIMALSEPARATOR) : amount;
            const cost = row.p ? row.p.toFixed(2) : '';
            const costStr = this.DECIMALSEPARATOR !== '.' ? cost.replace('.', this.DECIMALSEPARATOR) : cost;
            const costPer = row.p && row.a ? (row.p / row.a / this.utils.getUnitFactor(this.mainUnit)).toFixed(2) : '';
            const costPerStr = this.DECIMALSEPARATOR !== '.' ? costPer.replace('.', this.DECIMALSEPARATOR) : costPer;

            stockData.push({
                [this.tr.anslate('Beans')]: this.getBeansStr(row.c, row.cs, true),
                [this.tr.anslate('Store')]: this.getStoreStr(row.l, row.ls, true),
                [this.tr.anslate('Amount') + ' (' + this.mainUnit + ')']: { text: amountStr, alignment: 'right' },
                // [this.tr.anslate('Amount') + ' (' + this.mainUnit + ')']: { text: this.utils.fixDigits(
                //    this.utils.formatNumber(row.a, 3, this.currentUser?.unit_system), '', 3), alignment: 'right' },
                // [this.tr.anslate('Cost') + ' (' + this.currencySymbol + ')']: { text: formatCurrency(row.p, this.locale, this.currencySymbol, this.currencySymbol, '1.2-2'), alignment: 'right' },
                // [this.tr.anslate('Cost per') + ' ' + this.mainUnit]: { text: formatCurrency(row.p / row.a / this.utils.getUnitFactor(this.mainUnit), this.locale, this.currencySymbol, this.currencySymbol, '1.2-2'), alignment: 'right' },
                // [this.tr.anslate('Cost') + ' (' + this.currencySymbol + ')']: { text: this.utils.fixDigits(this.utils.formatNumber(row.p, 2), '', 2), alignment: 'right' },
                // [this.tr.anslate('Cost per') + ' ' + this.mainUnit + ' (' + this.currencySymbol + ')']: { text: this.utils.fixDigits(
                //    this.utils.formatNumber(row.p / row.a / this.utils.getUnitFactor(this.mainUnit), 2), '', 2), alignment: 'right' }
                [this.tr.anslate('Cost') + ' (' + this.currencySymbol + ')']: { text: costStr, alignment: 'right' },
                [this.tr.anslate('Cost per') + ' ' + this.mainUnit + ' (' + this.currencySymbol + ')']: { text: costPerStr, alignment: 'right' }
            });
        }
        // sum footer
        const amount = this.total ? (this.total * this.utils.getUnitFactor(this.mainUnit)).toFixed(2) : '';
        const amountStr = this.DECIMALSEPARATOR !== '.' ? amount.replace('.', this.DECIMALSEPARATOR) : amount;
        const cost = (Math.round(this.totalCost * 100) / 100).toFixed(2);
        const costStr = this.DECIMALSEPARATOR !== '.' ? cost.replace('.', this.DECIMALSEPARATOR) : cost;
        const costPer = (Math.round(this.totalAverageCost * 100 / this.utils.getUnitFactor(this.mainUnit)) / 100).toFixed(2);
        const costPerStr = this.DECIMALSEPARATOR !== '.' ? costPer.replace('.', this.DECIMALSEPARATOR) : costPer;
        stockData.push({
            [this.tr.anslate('Beans')]: '',
            [this.tr.anslate('Store')]: { text: this.tr.anslate('Sum'), alignment: 'right', bold: true },
            [this.tr.anslate('Amount') + ' (' + this.mainUnit + ')']: { text: amountStr + ' ' + this.mainUnit, alignment: 'right', bold: true },
            // [this.tr.anslate('Amount') + ' (' + this.mainUnit + ')']:
            //     { text: this.utils.fixDigits(this.utils.formatNumber(this.data.total, 0, this.currentUser?.unit_system), this.mainUnit, 3), alignment: 'right' },
            // [this.tr.anslate('Cost') + ' (' + this.currencySymbol + ')']: { text: formatCurrency(this.totalCost, this.locale, this.currencySymbol, this.currencySymbol, '1.2-2'), alignment: 'right' },
            // [this.tr.anslate('Cost per') + ' ' + this.mainUnit + ' (' + this.currencySymbol + ')']: { text: formatCurrency(
            //    this.totalAverageCost, this.locale, this.currencySymbol, this.currencySymbol, '1.2-2') + '/' + this.mainUnit, alignment: 'right' },
            [this.tr.anslate('Cost') + ' (' + this.currencySymbol + ')']: { text: costStr + ' ' + this.currencySymbol, alignment: 'right', bold: true },
            [this.tr.anslate('Cost per') + ' ' + this.mainUnit + ' (' + this.currencySymbol + ')']: { text: costPerStr + ' ' + this.currencySymbol + '/' + this.mainUnit, alignment: 'right', bold: true },
        });

        const title = this.tr.anslate('Stock') + ' ' + this.date.toFormat('dd_MMMM_yyyy');
        this.reportService.getPDFFromArray(stockData, title, undefined, true)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: blob => {
                    this.creatingPdf = false;

                    const filename = this.tr.anslate('Stock') + '_' + this.date.toFormat('dd_MMMM_yyyy');
                    this.utils.saveBlobToFileSystem(blob, filename + '.pdf');

                    this.alertService.success(this.tr.anslate('Successfully added'));
                },
                error: error => {
                    this.creatingPdf = false;
                    this.utils.handleError('error creating PDF', error);
                }
            });
    }

    // called from template
    showOrganicChanged(): void {
        if (this.organicButtonInactive) {
            return;
        }
        this.organicButtonInactive = true;
        this.showOrganic = this.utils.getNextShowOrganicState(this.showOrganic);
        this.getStocksReport();
    }

    editTransaction(transaction: StockChange): void {
        if (this.readOnly) { return; }

        this.transService.editTransaction(transaction, this.ngUnsubscribe, () => {
            this.getStocksReport();
        });
    }

    changeCost(stock: PerCofLoc): void {
        if (!this.data?.length) {
            this.logger.error('changeCost called but we have no stocks info: ' + this.data);
            return;
        }
        if (!stock) {
            stock = this.data[0];
        }
        let totalAmount = 0;
        const coffId = (stock.c ?? stock.cs?.[0])?._id?.toString();
        for (let i = 0; i < this.data.length; i++) {
            const pcl = this.data[i];
            if ((pcl?.c ?? pcl?.cs?.[0])?._id?.toString() === coffId) {
                totalAmount += pcl.a || 0;
            }
        }
        const dialogRef = this.dialog.open(ShowPurchasesDialogComponent, {
            closeOnNavigation: true,
            data: {
                readOnly: this.readOnly,
                coffee: (stock.c ?? stock.cs?.[0])?._id,
                totalAmount,
                maxDate: this.date,
            }
        });

        dialogRef.afterClosed().subscribe(trans => {
            if (!trans || this.readOnly) {
                return;
            }
            this.editTransaction(trans);
        });
    }
}

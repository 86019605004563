import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, CurrencyPipe } from '@angular/common';

import { ReportingComponent } from './reporting.component';
import { RoastreportComponent } from './roasts/roastreport.component';
import { SelectRoastreportsComponent } from './roasts/select-roastreports.component';
import { NewroastreportComponent } from './roasts/newroastreport.component';
import { RoastreportTableComponent } from './roasts/roastreport-table.component';
import { EditroastreportComponent } from './roasts/editroastreport.component';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { FormsModule } from '@angular/forms';
import { BeansreportsComponent } from './beans/beansreports.component';
import { RoastreportsComponent } from './roasts/roastreports.component';
import { RoastreportOverviewComponent } from './roasts/roastreport-overview.component';
import { NewbeansreportComponent } from './beans/newbeansreport.component';
import { BeansreportTableComponent } from './beans/beansreport-table.component';
import { BeansreportComponent } from './beans/beansreport.component';
import { EditbeansreportComponent } from './beans/editbeansreport.component';
import { CustomsreportsComponent, OnlyMonthDateFormatDirective } from './customs/customsreports.component';
import { CrGermanyRosenheimComponent } from './customs/ger/cr-germany-rosenheim.component';
import { CrGermanyHamburgComponent } from './customs/ger/cr-germany-hamburg.component';
import { StocksreportsComponent } from './stocks/stocksreports.component';
import { StocksreportComponent } from './stocks/stocksreport.component';
import { CrGermanyBerlinComponent } from './customs/ger/cr-germany-berlin.component';
import { CrGermanyKoelnComponent } from './customs/ger/cr-germany-koeln.component';
import { RouterModule } from '@angular/router';
import { UiModule } from 'src/app/modules/ui/ui.module';
import { CompanyInfoDialogComponent } from './company-info-dialog.component';
import { ReportExportUiComponent } from './roasts/report-export-ui.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RoastModule } from '../roast/roast.module';

@NgModule({
    declarations: [
        ReportingComponent,
        SelectRoastreportsComponent,
        RoastreportComponent,
        NewroastreportComponent,
        RoastreportTableComponent,
        EditroastreportComponent,
        RoastreportOverviewComponent,
        RoastreportsComponent,
        BeansreportsComponent,
        NewbeansreportComponent,
        BeansreportTableComponent,
        BeansreportComponent,
        EditbeansreportComponent,
        CustomsreportsComponent,
        CrGermanyRosenheimComponent,
        CrGermanyHamburgComponent,
        StocksreportsComponent,
        StocksreportComponent,
        CrGermanyBerlinComponent,
        CrGermanyKoelnComponent,
        CompanyInfoDialogComponent,
        ReportExportUiComponent,
        OnlyMonthDateFormatDirective,
    ],
    imports: [
        CommonModule,
        MyMaterialModule,
        FormsModule,
        RouterModule,
        UiModule,
        NgxMatSelectSearchModule,
        RoastModule,
    ],
    providers: [
        DecimalPipe,
        CurrencyPipe,
    ],
})
export class ReportModule { }

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop.animated {
  /* -webkit-filter: grayscale(100%) !important; */
  /* filter: blur(10px) grayscale(50%) opacity(70%) !important; */
  filter: blur(2px) !important;
  /* filter: gray !important; */
  transition: 0.5s filter linear;
  /* -webkit-animation: filter-animation 0.5s; */
}

.backdrop {
  z-index: -1;
  width: 100%;
  height: 100%;
  min-height: 485px;
  position: absolute;
  /* filter: blur(10px) grayscale(30%) opacity(80%); */
  left: 0;
  background-image: url('backdrop.5e6d8c47d5309184.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.background-mask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-color: rgba(0, 0, 0, 0.3);
}

.mainbutton {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.mat-mdc-input-element {
  height: 30px;
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-form-field.form-group {
  margin-bottom: 0;
}

div.form-group {
  margin-bottom: 0;
}

mat-spinner {
  left: 50%;
  margin-left: -50px;
}

.reset-password {
  z-index: 10;
  position: relative;
  display: block;
  text-align: right;
}

.ap-popuplike {
  padding-bottom: 10px;
}

.login-doc {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

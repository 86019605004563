import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Blend } from 'src/app/models/Blend';
import { Coffee } from 'src/app/models/Coffee';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Roast } from 'src/app/models/Roast';
import { Enumerations } from 'src/app/models/Enumerations';
import { Location } from 'src/app/models/Location';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Constants } from 'src/app/util/constants';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'app-roast-blend-edit',
    templateUrl: './roast-blend-edit.component.html',
    styleUrls: ['./roast-blend-edit.component.scss']
})
export class RoastBlendEditComponent implements OnInit, OnDestroy {

    constructor(
        public utils: Utils,
        public tr: TranslatorService,
        private userService: UserService,
    ) { }

    @Input() currentUser: UserType;
    @Input() readOnly = false;
    @Input() reconciled = false;
    private _amount: number
    get amount(): number { return this._amount; }
    @Input() set amount(a: number) {
        this._amount = a;
        this.recalcAmounts();
    }
    @Input() showAmounts = false;
    private _store: Location;
    get store(): Location { return this._store; }
    @Input() set store(s: Location) {
        this._store = s;
        this.recalcAmounts();
    }

    currentBlend: Roast['blend'];
    private _blend: Blend;
    get blend(): Blend { return this._blend; }
    @Input() set blend(b: Blend) {
        this._blend = b;
        if (b) {
            this.currentBlend = this.blend;
        }
        this.myBlendChanged();
    }
    filteredCoffees: Coffee[][] = [];
    private _coffees: Coffee[];
    get coffees(): Coffee[] { return this._coffees; }
    @Input() set coffees(cs: Coffee[]) {
        this._coffees = cs;
        for (let c = 0; c < this.coffees?.length; c++) {
            const cof = this.coffees[c];
            if (cof) {
                cof.yearLabel = cof.yearLabel || this.utils.createBeansYearLabel(cof);
            }
        }
        this.recalcFilteredCoffees();
    }

    origReplacement = new Map<string, Coffee>();
    amountStr: { pre: string, value: number, origVal?: number, post: string, enough?: boolean }[];
    sortedIngredients: Blend['ingredients'];
    num: number[] = [];
    denom: number[] = [];
    mainUnit: UnitSystemType = 'kg';

    private ngUnsubscribe = new Subject();
    isDarkmode = false;

    round = Math.round;
    Constants = Constants;

    @Output() blendChanged = new EventEmitter<Roast['blend']>();

    ngOnInit(): void {
        if (this.currentUser) {
            if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
                this.mainUnit = 'lbs';
            }
            this.isDarkmode = this.userService.isDarkModeEnabled();
            this.userService.darkmodeMode$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm)
                );
        }

        this.recalcAmounts();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    private recalcAmounts(): void {
        this.amountStr = [];
        if (this.currentBlend) {
            this.amountStr.length = this.currentBlend.ingredients?.length;
            for (let i = 0; i < this.currentBlend.ingredients.length; i++) {
                this.amountStr[i] = this.getAmountStr(i);
            }
        }
    }

    private recalcFilteredCoffees(): void {
        if (this.coffees && this.currentBlend) {
            for (let i = 0; i < this.currentBlend.ingredients.length; i++) {
                this.filteredCoffees[i] = this.getCoffees(i);
            }
        } else {
            this.filteredCoffees = [];
        }
    }

    private myBlendChanged(): void {
        if (this.blend) {
            if (this.blend.ingredients) {
                this.sortedIngredients = this.blend.ingredients.sort((i1, i2) => i2.ratio - i1.ratio);
                // read fraction ratios if present
                // store original replacements
                this.origReplacement.clear();
                for (let i = 0; i < this.blend.ingredients.length; i++) {
                    const ing = this.blend.ingredients[i];
                    if (ing.coffee && !ing.coffee.yearLabel) {
                        ing.coffee.yearLabel = this.utils.createBeansYearLabel(ing.coffee);
                    }
                    if (ing.ratio_num && ing.ratio_denom) {
                        this.num[i] = ing.ratio_num;
                        this.denom[i] = ing.ratio_denom;
                    } else if (ing.ratio) {
                        // check if fraction ratios can be displayed
                        this.utils.calculateFraction(this.num, this.denom, i, ing.ratio);
                        ing.ratio_num = this.num[i];
                        ing.ratio_denom = this.denom[i];
                        if (ing.ratio_denom) {
                            ing.ratio = ing.ratio_num / ing.ratio_denom;
                        }
                    }
                    if (ing.replace_coffee?._id) {
                        ing.replace_coffee.yearLabel = ing.replace_coffee.yearLabel || this.utils.createBeansYearLabel(ing.replace_coffee);
                        this.origReplacement.set((ing.coffee._id || ing.coffee).toString(), ing.replace_coffee);
                    }
                }
            }
        }
        this.recalcAmounts();
        this.recalcFilteredCoffees();
    }

    // update list for filters
    private updateFilters(): void {
        for (let i = 0; i < this.currentBlend?.ingredients?.length; i++) {
            this.filteredCoffees[i] = this.getCoffees(i);
        }
    }

    addIngredient(): void {
        if (this.readOnly) { return; }

        if (!this.currentBlend) {
            this.currentBlend = new Blend();
            this.currentBlend.ingredients = [];
        }

        let rest = 1.0;
        for (let i = 0; i < this.currentBlend.ingredients.length; i++) {
            if (this.currentBlend.ingredients[i].ratio) {
                rest -= this.currentBlend.ingredients[i].ratio;
            }
        }
        // the given idx doesn't exist yet but that's ok
        const possibleCoffees = this.getCoffees(this.currentBlend.ingredients.length);
        if (possibleCoffees.length === 1) {
            this.currentBlend.ingredients.push({ coffee: possibleCoffees[0], ratio: rest });
        } else {
            this.currentBlend.ingredients.push({ coffee: undefined, ratio: rest });
        }
        this.utils.checkFractions(this.currentBlend, this.num, this.denom, this.currentBlend.ingredients.length - 1, rest);
        const newing = this.currentBlend.ingredients[this.currentBlend.ingredients.length - 1];
        newing.ratio_num = this.num[this.currentBlend.ingredients.length - 1];
        newing.ratio_denom = this.denom[this.currentBlend.ingredients.length - 1];

        this.updateFilters();

        this.blend = null;
        this.currentBlend.label = undefined;
        this.currentBlend['internal_hr_id'] = undefined;
        this.recalcAmounts();
        this.blendChanged.emit(this.currentBlend);
    }

    deleteIngredient(idx: number): void {
        if (this.readOnly) { return; }

        this.currentBlend.ingredients.splice(idx, 1);
        this.num.splice(idx, 1);
        this.denom.splice(idx, 1);
        this.blend = null;
        if (this.currentBlend.ingredients.length === 0) {
            this.currentBlend = undefined;
        } else {
            this.currentBlend.label = undefined;
            this.currentBlend['internal_hr_id'] = undefined;
            this.updateFilters();
            this.blendChanged.emit(this.currentBlend);
        }
        this.recalcAmounts();
    }

    checkChangesRatio(parent: unknown, variable: string, oldValue: number, newValueStr: string, digits: number, idx?: number, divideByAmount = false, round = true, changingOutOfStock = false): void {
        parent[variable] = undefined;
        // TODO implement - otherwise, pressing enter will not use the entered value but the oldValue
        // this.waitingForChanges = true;
        setTimeout(() => {
            const { val, changed } = this.utils.checkChangedValue(oldValue, newValueStr, digits, !divideByAmount);
            let val2 = val;
            if (divideByAmount && this.amount) {
                val2 = val2 / this.amount;
            }
            if (round) {
                // round to max digits; https://stackoverflow.com/a/23560569
                val2 = +(Math.round(+(val2 + 'e' + (digits + 2))) + 'e' + -(digits + 2));
                if (val2 < 0) {
                    val2 = -val2;
                }
            }
            parent[variable] = val2;
            this.utils.calculateFraction(this.num, this.denom, idx, val2);
            // this.waitingForChanges = false;
            if (changed) {
                if (typeof idx === 'number') {
                    this.ratioChanged(idx, changingOutOfStock);
                }
                // if (this.submitPressed === true && parent[variable] !== oldValue) {
                //     this.doSave();
                // }
            }
            this.recalcAmounts();
            // this.submitPressed = false;
        });
    }

    // checkChangesAmount(parent: any, variable: string, oldValue: number, newValueStr: string, digits: number, idx?: number) {
    //     this[variable] = undefined;
    //     // TODO implement - otherwise, pressing enter will not use the entered value but the oldValue
    //     // this.waitingForChanges = true;
    //     setTimeout(() => {
    //         const { val, changed } = this.utils.checkChangedValue(oldValue, newValueStr, digits, true);
    //         // round to max digits; https://stackoverflow.com/a/23560569
    //         let val2 = +(Math.round(+(val + 'e' + (digits + 2))) + 'e' + -(digits + 2));
    //         if (val2 < 0) {
    //             val2 = -val2;
    //         }
    //         this[variable] = val2;
    //         this.utils.calculateFraction(this.num, this.denom, idx, val2);
    //         // this.waitingForChanges = false;
    //         if (changed) {
    //             if (typeof idx === 'number') {
    //                 this.ratioChanged(idx);
    //             }
    //             // if (this.submitPressed === true && parent[variable] !== oldValue) {
    //             //     this.doSave();
    //             // }
    //         }
    //         // this.submitPressed = false;
    //     });
    // }

    /**
     * get all the coffees that are possible for this idx
     * (i.e. all that are not already taken by the other indexes)
     * @param idx index of the desired blend.ingredients
     * @returns list of coffees that are not already used as ingredient
     */
    getCoffees(idx: number): Coffee[] {
        if (!this.coffees) {
            return [];
        }
        const coffs = this.coffees.filter((c: Coffee) => {
            for (let i = 0; i < this.currentBlend.ingredients.length; i++) {
                if (i !== idx
                    && this.currentBlend.ingredients[i]?.coffee?._id === c._id
                    // do not remove the current coffee at idx from the list (used multiple times in the blend, see #397)
                    && this.currentBlend.ingredients[idx]?.coffee?._id !== c._id) {
                    return false;
                }
            }
            return true;
        });
        // prepend the current coffee if hidden
        if (this.currentBlend.ingredients[idx]?.coffee?.hidden) {
            coffs.unshift(this.currentBlend.ingredients[idx].coffee);
        }
        return coffs;
    }

    ratioChanged(idx: number, dontFixFractions = false): void {
        const ing = this.currentBlend.ingredients[idx];
        ing.ratio_num = this.num[idx];
        ing.ratio_denom = this.denom[idx];
        if (this.currentBlend.ingredients.length === 1 || dontFixFractions) {
            // don't fix to 100%, user probably just starting || fixing an out of stock ingredient
        } else if (this.currentBlend.ingredients.length === 2) {
            // fix the other value such that the sum is 100%
            this.currentBlend.ingredients[1 - idx].ratio = 1 - this.currentBlend.ingredients[idx].ratio;
            this.utils.checkFractions(this.currentBlend, this.num, this.denom, 1 - idx);
            const newing = this.currentBlend.ingredients[1 - idx];
            newing.ratio_num = this.num[1 - idx];
            newing.ratio_denom = this.denom[1 - idx];
        } else {
            // cannot decide which other value(s) to change, ignore
        }
        this.currentBlend.label = undefined;
        this.currentBlend['internal_hr_id'] = undefined;
        this.blendChanged.emit(this.currentBlend);
    }

    fractionChanged(idx: number, isNum: boolean, value: string): void {
        let n = parseInt(value, 10);
        if (!Number.isNaN(n)) {
            if (n < 0) {
                n = -n;
            }
            if (isNum) {
                this.num[idx] = n;
            } else {
                this.denom[idx] = n;
            }
            if (this.num[idx] && this.denom[idx]) {
                // set ratio
                this.currentBlend.ingredients[idx].ratio = this.num[idx] / this.denom[idx];
                this.ratioChanged(idx);
            }
            this.recalcAmounts();
        }
    }

    ingredientChanged(idx: number): void {
        this.blend = null;
        this.currentBlend.label = undefined;
        this.currentBlend['internal_hr_id'] = undefined;
        this.currentBlend.ingredients[idx].replace_coffee = this.origReplacement.get((this.currentBlend.ingredients[idx].coffee._id || this.currentBlend.ingredients[idx].coffee).toString());
        if (this.currentBlend.ingredients[idx].replace_coffee && this.getCoffees(idx).map(c => c._id.toString()).indexOf(this.currentBlend.ingredients[idx].replace_coffee._id) < 0) {
            // this coffee is (by now) already selected as ingredient
            this.currentBlend.ingredients[idx].replace_coffee = undefined;
        }
        this.mergeSameIngs(idx);
        this.updateFilters();
        this.recalcAmounts();
        this.blendChanged.emit(this.currentBlend);
    }

    getAmountStr(idx: number): { pre: string, value: number, origVal?: number, post: string, enough?: boolean } {
        if (!this.currentBlend?.ingredients || !this.currentBlend?.ingredients[idx]?.coffee) {
            return undefined;
        }
        let coffee = this.currentBlend.ingredients[idx].coffee;
        if (!coffee.stock && this.coffees) {
            for (let c = 0; c < this.coffees.length; c++) {
                const cof = this.coffees[c];
                if (this.utils.compareObjectsFn(cof, coffee)) {
                    coffee = cof;
                }
            }
        }
        if (!coffee?.stock) {
            return undefined;
        }
        // this would sum up stock in _all_ locations:
        // let totalStock = coffee.stock?.reduce((prev, cur) => prev + cur.amount, 0);
        // return this.utils.formatAmountForPipe(totalStock, undefined, this.currentUser.unit_system);
        for (let s = 0; s < coffee.stock?.length; s++) {
            if (this.utils.compareObjectsFn(coffee.stock[s].location, this.store)) {
                const ret: { pre: string, value: number, origVal?: number, post: string, enough?: boolean } = this.utils.formatAmountForPipe(coffee.stock[s].amount, undefined, this.currentUser.unit_system);
                ret.origVal = coffee.stock[s].amount;
                ret.enough = Math.round(this.amount * this.currentBlend.ingredients[idx].ratio * 1000) <= Math.round(coffee.stock[s].amount * 1000);
                return ret;
            }
        }
        return { pre: '', value: 0, origVal: 0, post: '', enough: false };
    }

    useReplaceCoffee(idx: number, forceReplace = false): void {
        if (!this.currentBlend?.ingredients || !this.currentBlend?.ingredients[idx]?.replace_coffee) {
            this.utils.handleError('error updating the blend information', undefined);
            return;
        }

        const ing = this.currentBlend.ingredients[idx];
        if (!this.amountStr?.[idx]?.origVal) {
            this.recalcAmounts();
        }
        const available = this.amountStr?.[idx]?.origVal || 0;

        if (forceReplace || available <= Constants.EPSILON) {
            // fully replace ingredient
            ing.coffee = ing.replace_coffee;
            ing.replace_coffee = this.origReplacement.get((ing.coffee._id || ing.coffee).toString());
            this.mergeSameIngs(idx);

        } else {
            // split the ratio of the current ingredient such that it is 100% used and add a new ingredient with the rest of the ratio

            // calculate new ratio for existing ingredient
            const newRatio = available / this.amount;
            // calculate ratio for replacement ingredient
            const replRatio = ing.ratio - newRatio;

            // recalc fraction
            ing.ratio = newRatio;
            this.utils.calculateFraction(this.num, this.denom, idx, ing.ratio);
            ing.ratio_num = this.num[idx];
            ing.ratio_denom = this.denom[idx];

            // add new ingredient;
            this.currentBlend.ingredients.splice(idx + 1, 0, { coffee: ing.replace_coffee, ratio: replRatio });
            this.num.splice(idx + 1, 0, undefined);
            this.denom.splice(idx + 1, 0, undefined);

            const newing = this.currentBlend.ingredients[idx + 1];
            this.utils.calculateFraction(this.num, this.denom, idx + 1, newing.ratio);
            newing.ratio_num = this.num[idx + 1];
            newing.ratio_denom = this.denom[idx + 1];

            this.mergeSameIngs(idx + 1);
        }

        this.updateFilters();

        this.blend = null;
        this.currentBlend.label = undefined;
        this.currentBlend['internal_hr_id'] = undefined;
        this.recalcAmounts();
        // TODO if this is not done, sometimes the beans dropdown shows the wrong coffee :(
        this.currentBlend.ingredients = cloneDeep(this.currentBlend.ingredients);
        this.blendChanged.emit(this.currentBlend);
    }

    // check if any other ingredients needs to be merged with the one at index idx
    private mergeSameIngs(idx: number) {
        // check if need to merge with another ingredient
        const ing = this.currentBlend.ingredients[idx];
        for (let i = 0; i < this.currentBlend.ingredients.length; i++) {
            if (i === idx) {
                continue;
            }
            const iiing = this.currentBlend.ingredients[i];
            if (this.utils.compareObjectsFn(iiing.coffee, ing.coffee)) {
                // sum, delete second, and update first occurrence
                ing.ratio += iiing.ratio;
                if (this.currentBlend.ingredients[i].replace_coffee) {
                    this.currentBlend.ingredients[idx].replace_coffee = this.currentBlend.ingredients[i].replace_coffee;
                    // // TODO cannot figure out why this would otherwise throw an ExpressionChanged error ...
                    // const nidx = idx < i ? idx : idx - 1;
                    // const replc = this.currentBlend.ingredients[i].replace_coffee;
                    // setTimeout(() => {
                    //     this.currentBlend.ingredients[nidx].replace_coffee = replc;
                    // });
                }
                this.currentBlend.ingredients.splice(i, 1);
                this.num.splice(i, 1);
                this.denom.splice(i, 1);
                this.utils.calculateFraction(this.num, this.denom, idx, ing.ratio);
                ing.ratio_num = this.num[idx];
                ing.ratio_denom = this.denom[idx];
                // assume that only 2 can have same coffee at any point in time
                break;
            }
        }
    }
}

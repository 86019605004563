import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Utils } from 'src/app/util/utils';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Subject, takeUntil, throttleTime } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { RoastReportOverview, RoastReportOverviewEntry } from './RoastReportOverview';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { RoastReport } from 'src/app/models/RoastReport';
import { Enumerations } from 'src/app/models/Enumerations';
import { ReportService } from '../report.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { ServerLogService } from 'src/app/util/services/server-log.service';
import { CurrencyPipe } from '@angular/common';

@Component({
    selector: 'app-roastreport-overview',
    templateUrl: './roastreport-overview.component.html',
    styleUrls: ['./roastreport-overview.component.scss']
})
export class RoastreportOverviewComponent implements OnInit, OnDestroy {

    constructor(
        public utils: Utils,
        public tr: TranslatorService,
        private userService: UserService,
        private reportService: ReportService,
        private router: Router,
        private logger: NGXLogger,
        private currencyPipe: CurrencyPipe,
        private serverLogService: ServerLogService,
    ) { }

    NOINFO = '---';
    readonly NRDIGITS = 1;
    FIELDSEPARATOR = '\t';
    LINESEPARATOR = '\r\n';
    DECIMALSEPARATOR = '.';

    // passed to the export component; used to potentially update organic state
    @Input() report: RoastReport;
    // @Output() reportChange = new EventEmitter<RoastReport>();

    @Input() currentUser: UserType;
    reportOverview: RoastReportOverview;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('reportOverview') set _reportOverview(rr: RoastReportOverview) {
        this.reportOverview = rr;
        if (rr) {
            this.recalc();
        }
    }
    @Input() isOpenReport = false;
    @Input() readOnly = false;
    @Input() showExportUI = true;

    unsortedData: RoastReportOverviewEntry[] = [];
    dataSource: MatTableDataSource<RoastReportOverviewEntry> = new MatTableDataSource();

    readonly allColumns = ['NrRoasts', 'Title', 'Weight', 'Yield', 'Loss', 'NrBlends', 'BlendWeight', 'Total', 'Cost'];
    readonly noBlendsColumns = ['NrRoasts', 'Title', 'Weight', 'Yield', 'Loss', 'Cost'];
    columnsToDisplay = this.allColumns;
    columnsToDisplayFooterAll = ['NrRoasts', 'Weight', 'Yield', 'Loss', 'NrBlends', 'BlendWeight', 'Total', 'Cost'];
    columnsToDisplayFooterNoBlends = ['NrRoasts', 'Weight', 'Yield', 'Loss', 'Cost'];
    columnsToDisplayFooter = this.columnsToDisplayFooterAll;
    readonly columnsUntilBlendsPart = 5;
    readonly coffeeColumnsOnly = ['NrRoasts', 'Title', 'NrBlends', 'BlendWeight', 'Total', 'Cost'];
    topHeaderColumns: string[] = [];
    // columnsToDisplayWithoutLabel = this.columnsToDisplay.filter(col => col !== 'Title');

    currency = 'EUR';

    totalRoasted = 0;
    totalYield = 0;
    totalUsed = 0;
    totalCost = 0;
    totalRoastedStr: string;
    totalUsedStr: string;
    totalYieldStr: string;

    sumIn = 0;
    sumOut = 0;
    sumTotal = 0;
    avgLoss = 0;
    days = 0;
    sumInForLoss = 0;
    sumOutForLoss = 0;
    sumInForAvg = 0;
    sumOutForAvg = 0;
    sumInMissing = false;
    sumOutMissing = false;
    // totalCostHasWarning = false;

    mainUnitSingular = 'kg';

    opened = true;
    nrEntries = 0;
    lastCoffeeIndex = -1;

    currentSort: Sort = { active: 'Weight', direction: 'desc' };

    private ngUnsubscribe = new Subject();

    Number = Number;
    @ViewChild('overviewTable') copyContainer: ElementRef;

    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser();
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnitSingular = 'lb';
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    // decides which columns to show (columnsToDisplay or coffeeColumnsOnly)
    isBlendRow(_index: number, rowData: RoastReportOverviewEntry) {
        // return Number.isFinite(rowData.amount) || Number.isFinite(rowData.endWeight);
        // return rowData.amount || rowData.endWeight;
        return !!rowData.nrRoasts;
    }

    strcmp(a: string, b: string): number {
        return a.localeCompare(b);
        // return (a < b) ? -1 : (a > b ? 1 : 0);
    }

    sortData(sort: Sort) {
        this.currentSort = sort;
        if (!sort.active || sort.direction === '') {
            // always sort leaving coffees on top; this means the lastCoffeeIndex is always at the same row index
            // this.setLastCoffeeRow(this.unsortedData, true);
            this.dataSource.data = this.unsortedData;
            return;
        }

        const sortedData = this.unsortedData.slice();

        this.dataSource.data = sortedData.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            // we assume that the default sort (weight) is intended and kept
            // by the sorting algorithm for entries with equal "sort.active" values
            switch (sort.active) {
                case 'NrRoasts':
                    if (a.blend && b.blend || !a.blend && !b.blend) {
                        if (a.nrRoasts === b.nrRoasts) return ((a.amount || 0) - (b.amount || 0)) * (isAsc ? 1 : -1);
                        return ((a.nrRoasts || 0) - (b.nrRoasts || 0)) * (isAsc ? 1 : -1);
                    }
                    if (a.blend) return 1; else return -1;
                case 'Weight':
                    if (a.blend && b.blend || !a.blend && !b.blend) {
                        if (a.amount === b.amount) return ((a.nrRoasts || 0) - (b.nrRoasts || 0)) * (isAsc ? 1 : -1);
                        return ((a.amount || 0) - (b.amount || 0)) * (isAsc ? 1 : -1);
                    }
                    if (a.blend) return 1; else return -1;
                case 'Yield':
                    if (a.blend && b.blend || !a.blend && !b.blend) {
                        if (a.endWeight === b.endWeight) {
                            if (a.amount === b.amount) return ((a.nrRoasts || 0) - (b.nrRoasts || 0)) * (isAsc ? 1 : -1);
                            return ((a.amount || 0) - (b.amount || 0)) * (isAsc ? 1 : -1);
                        }
                        return ((a.endWeight || 0) - (b.endWeight || 0)) * (isAsc ? 1 : -1);
                    }
                    if (a.blend) return 1; else return -1;
                case 'Loss':
                    if (a.blend && b.blend || !a.blend && !b.blend) {
                        if (a.loss === b.loss) {
                            if (a.amount === b.amount) return ((a.nrRoasts || 0) - (b.nrRoasts || 0)) * (isAsc ? 1 : -1);
                            return ((a.amount || 0) - (b.amount || 0)) * (isAsc ? 1 : -1);
                        }
                        return ((a.loss || 0) - (b.loss || 0)) * (isAsc ? 1 : -1);
                    }
                    if (a.blend) return 1; else return -1;
                case 'Cost':
                    if (a.blend && b.blend || !a.blend && !b.blend) {
                        if (a.cost?.val === b.cost?.val) {
                            if (a.amount === b.amount) return ((a.nrRoasts || 0) - (b.nrRoasts || 0)) * (isAsc ? 1 : -1);
                            return ((a.amount || 0) - (b.amount || 0)) * (isAsc ? 1 : -1);
                        }
                        return ((a.cost?.val || 0) - (b.cost?.val || 0)) * (isAsc ? 1 : -1);
                    }
                    if (a.blend) return 1; else return -1;
                case 'Title':
                    return a.blend ? (b.blend ? this.strcmp(a.blend, b.blend) * (isAsc ? 1 : -1) : 1)
                        : (b.blend ? -1 : this.strcmp(a.coffee?.hr_id, b.coffee?.hr_id) * (isAsc ? 1 : -1));
                case 'NrBlends':
                    if (a.blend && b.blend) {
                        if (a.amount === b.amount) return ((b.nrRoasts || 0) - (a.nrRoasts || 0));
                        return ((b.amount || 0) - (a.amount || 0));
                    }
                    if (!a.blend && !b.blend) {
                        if (a.nrOfBlendRoasts === b.nrOfBlendRoasts) return ((a.amount || 0) - (b.amount || 0)) * (isAsc ? 1 : -1);
                        return ((a.nrOfBlendRoasts || 0) - (b.nrOfBlendRoasts || 0)) * (isAsc ? 1 : -1);
                    }
                    if (a.blend) return 1; else return -1;
                case 'BlendWeight':
                    if (a.blend && b.blend) {
                        if (a.amount === b.amount) return ((b.nrRoasts || 0) - (a.nrRoasts || 0));
                        return ((b.amount || 0) - (a.amount || 0));
                    }
                    if (!a.blend && !b.blend) {
                        if (a.amountInBlends === b.amountInBlends) {
                            if (a.nrOfBlendRoasts === b.nrOfBlendRoasts) {
                                if (a.amount === b.amount) {
                                    return ((a.nrRoasts || 0) - (b.nrRoasts || 0)) * (isAsc ? 1 : -1);
                                }
                                return ((a.amount || 0) - (b.amount || 0)) * (isAsc ? 1 : -1);
                            }
                            return ((a.nrOfBlendRoasts || 0) - (b.nrOfBlendRoasts || 0)) * (isAsc ? 1 : -1);
                        }
                        return ((a.amountInBlends || 0) - (b.amountInBlends || 0)) * (isAsc ? 1 : -1);
                    }
                    if (a.blend) return 1; else return -1;
                case 'Total':
                    if (a.blend && b.blend) {
                        if (a.amount === b.amount) return ((b.nrRoasts || 0) - (a.nrRoasts || 0));
                        return ((b.amount || 0) - (a.amount || 0));
                    }
                    if (!a.blend && !b.blend) {
                        const atotal = (a.amount || 0) + (a.amountInBlends || 0);
                        const btotal = (b.amount || 0) + (b.amountInBlends || 0);
                        if (atotal === btotal) {
                            if (a.amount === b.amount) return ((a.nrRoasts || 0) - (b.nrRoasts || 0)) * (isAsc ? 1 : -1);
                            return ((a.amount || 0) - (b.amount || 0)) * (isAsc ? 1 : -1);
                        }
                        return (atotal - btotal) * (isAsc ? 1 : -1);
                    }
                    if (a.blend) return 1; else return -1;
                default:
                    return 0;
            }
        });
        // always sort leaving coffees on top; this means the lastCoffeeIndex is always at the same row index
        // this.setLastCoffeeRow(this.dataSource.data, true);
    }

    updateRoastReportCertInfo(): void {
        this.reportService.updateRoastReport(this.report._id, { _id: this.report._id, certInfoOnCreation: this.report.certInfoOnCreation }, this.isOpenReport)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        // this.reportChange.emit(this.report);
                    } else {
                        const msg = `could not update roast report ${this.report._id?.toString()} with certInfoOnCreation ${this.report.certInfoOnCreation}, ${response.error}`;
                        this.logger.error(msg);
                        this.serverLogService.errorOccurred({
                            message: msg,
                            name: 'Error',
                            stack: 'roastreport-overview.component.ts:359',
                        }, 'RoastreportOverviewComponent.updateRoastReportCertInfo').subscribe();
                    }
                },
                error: error => {
                    const msg = `could not update roast report ${this.report._id?.toString()} with certInfoOnCreation ${this.report.certInfoOnCreation}, ${error}`;
                    this.logger.error(msg);
                    this.serverLogService.errorOccurred({
                        message: msg,
                        name: 'Error',
                        stack: 'roastreport-overview.component.ts:359',
                    }, 'RoastreportOverviewComponent.updateRoastReportCertInfo2').subscribe();
                }
            });
    }

    recalc(): void {
        const list: RoastReportOverviewEntry[] = [];

        // both should be the same value:
        this.totalRoasted = 0;
        this.totalUsed = 0;

        this.totalCost = 0;
        this.totalYield = 0;
        this.sumInForAvg = 0;
        this.sumOutForAvg = 0;
        this.sumInForLoss = 0;
        this.sumOutForLoss = 0;
        // this.totalCostHasWarning = false;
        this.sumInMissing = false;
        this.sumOutMissing = false;

        let allOrganic = true;

        // format values
        const formatEntry = (val: RoastReportOverviewEntry) => {
            // we potenitally allow roasts without amount or end_weight; ignore those for avg
            // don't use Number.isFinite() since all undefined values will have been aggregated to 0
            if (val.amount && val.endWeight) {
                this.sumInForAvg += val.amount;
                this.sumOutForAvg += val.endWeight;
            }
            if (val.sumInMissing) {
                this.sumInMissing = true;
            }
            if (val.sumOutMissing) {
                this.sumOutMissing = true;
            }
            this.sumInForLoss += val.sumInForLoss || 0;
            this.sumOutForLoss += val.sumOutForLoss || 0;
            // if (val.sumInForLoss) {
            //     this.sumInMissing = true;
            // }
            // if (val.sumOutForLoss) {
            //     this.sumOutMissing = true;
            // }
            val.amountStr = val.amount ? this.utils.formatAmount(val.amount, undefined, this.currentUser.unit_system, this.NRDIGITS) : '';
            val.endWeightStr = val.endWeight ? this.utils.formatAmount(val.endWeight, undefined, this.currentUser.unit_system, this.NRDIGITS) : '';
            // val.loss is used for sorting only
            if (!val.sumOutForLoss && !val.sumInForLoss) {
                val.loss = 0;
            } else if (!val.sumOutForLoss) {
                val.loss = 1;
            } else {
                val.loss = (val.sumInForLoss - val.sumOutForLoss) / val.sumInForLoss;
                // val.loss = (val.amount - val.endWeight) / val.amount;
            }
            if (val.nrOfBlendRoasts) {
                val.amountInBlendsStr = this.utils.formatAmount(val.amountInBlends, undefined, this.currentUser.unit_system, this.NRDIGITS);
            }
            val.totalAmountStr = this.utils.formatAmount(val.amount + (val.amountInBlends || 0), undefined, this.currentUser.unit_system, this.NRDIGITS);
            if (val.cost?.val) {
                val.cost.valPerStr = this.currencyPipe.transform((val.cost.val || 0) / this.utils.convertToUserUnit(val.endWeight, this.currentUser.unit_system), this.currency, 'symbol-narrow', '1.2-2');
            }
            this.totalRoasted += val.amount || 0;
            this.totalYield += val.endWeight || 0;
            if (val.coffee) {
                this.totalUsed += (val.amount || 0) + (val.amountInBlends || 0);
                this.totalCost += val.cost?.val || 0;
                // if (val.cost?.amountLeft) {
                //     val.cost.amountLeftStr = this.utils.formatAmount(val.cost.amountLeft, undefined, this.currentUser.unit_system, this.NRDIGITS);
                //     // this.totalCostHasWarning = true;
                // }
            }
            if (!val.organic) {
                allOrganic = false;
            }
            list.push(val);
        }
        this.reportOverview.coffees?.forEach(formatEntry);
        this.reportOverview.blends?.forEach(formatEntry);

        this.totalRoastedStr = this.totalRoasted ? this.utils.formatAmount(this.totalRoasted, undefined, this.currentUser.unit_system, this.NRDIGITS) : '';
        this.totalYieldStr = this.totalYield ? this.utils.formatAmount(this.totalYield, undefined, this.currentUser.unit_system, this.NRDIGITS) : '';
        this.totalUsedStr = this.totalUsed ? this.utils.formatAmount(this.totalUsed, undefined, this.currentUser.unit_system, this.NRDIGITS) : '';

        // data is sorted on the server
        // // sort according to totalAmount but put blends first
        // list.sort((c1, c2) =>
        //     c1.blend && !c2.blend ? -1 : (c2.blend && !c1.blend ? 1 :
        //         c2.amount !== c1.amount ? c2.amount - c1.amount :
        //             c2.nrRoasts !== c1.nrRoasts ? c2.nrRoasts - c1.nrRoasts :
        //                 c2.nrOfBlendRoasts !== c1.nrOfBlendRoasts ? c2.nrOfBlendRoasts - c1.nrOfBlendRoasts : 
        //                     c2.amountInBlends - c1.amountInBlends));

        this.setLastCoffeeRowIndex(list);

        if (this.reportOverview.blends?.length) {
            this.topHeaderColumns = ['top-header-none', 'top-header-blends', 'top-header-none2'];
            this.columnsToDisplay = this.allColumns;
            this.columnsToDisplayFooter = this.columnsToDisplayFooterAll;
        } else {
            this.topHeaderColumns = [];
            this.columnsToDisplay = this.noBlendsColumns;
            this.columnsToDisplayFooter = this.columnsToDisplayFooterNoBlends;
        }

        this.nrEntries = list.length;
        // this.opened = forceOpen || this.nrEntries <= 5;

        this.unsortedData = list;
        this.dataSource.data = this.unsortedData.slice();

        // check whether organic info is still up to date; with normal roast report
        // this was not possible since not all info (all roasts) were loaded
        // but the overview table has info on all roasts within the report
        if (this.report) {
            const reportMarkedAsOrganic = this.utils.isOrganic(this.report.certInfoOnCreation);
            if (!allOrganic && reportMarkedAsOrganic) {
                // some entry must have changed from organic to non-organic (e.g. some beans)
                setTimeout(() => {
                    this.report.certInfo &= ~Enumerations.CertificationTypes.ORGANIC;
                    this.report.certInfoOnCreation &= ~Enumerations.CertificationTypes.ORGANIC;
                    this.updateRoastReportCertInfo();
                }, 0);
            } else if (allOrganic && !reportMarkedAsOrganic) {
                // some entry must have changed from non-organic to organic (e.g. some beans)
                setTimeout(() => {
                    this.report.certInfo |= Enumerations.CertificationTypes.ORGANIC;
                    this.report.certInfoOnCreation |= Enumerations.CertificationTypes.ORGANIC;
                    this.updateRoastReportCertInfo();
                }, 0);
            }
        }
    }

    /**
     * Sets the lastCoffeeIndex flag.
     * @note Input list needs to be sorted coffees first.
     * @param list the list of entries; coffees are assumed to be first
     */
    setLastCoffeeRowIndex(list: RoastReportOverviewEntry[]) {
        if (list?.[0]?.blend) {
            // only blends
            return;
        }
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (item.blend && i > 0) {
                this.lastCoffeeIndex = i - 1;
                break;
            }
        }
    }
}

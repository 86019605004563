import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { LoginChangedService } from 'src/app/modules/frame/services/loginchanged.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-legal-frame',
    templateUrl: './legal-frame.component.html',
    styleUrls: ['./legal-frame.component.scss']
})
export class LegalFrameComponent implements OnInit, OnDestroy {

    constructor(
        private userService: UserService,
        private loginChangedService: LoginChangedService
    ) { }

    currentUser: UserType;
    loggedInUser: UserType = null;
    loginChangedSubscription: Subscription;

    ngOnInit(): void {
        this.loginChangedSubscription = this.loginChangedService.loginStatus.subscribe(
            status => {
                this.loginStateChanged(status);
            }
        );
    }

    ngOnDestroy(): void {
        if (this.loginChangedSubscription) {
            this.loginChangedSubscription.unsubscribe();
        }
    }

    loginStateChanged(status: boolean): void {
        let currentUser: UserType;
        if (status) {
            currentUser = this.userService.getCurrentUser();
        }

        if (status && currentUser) {
            this.loggedInUser = currentUser;
        } else {
            this.loggedInUser = null;
        }
    }
}

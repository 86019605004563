import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { ReportService } from 'src/app/modules/report/report.service';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
// import { trigger, state, style, transition, sequence, animate } from '@angular/animations';
import { Observable, Subject } from 'rxjs';
import { throttleTime, takeUntil, map } from 'rxjs/operators';
import { Enumerations } from 'src/app/models/Enumerations';
import { Roast } from 'src/app/models/Roast';
import { RoastReport } from 'src/app/models/RoastReport';
import { environment } from 'src/environments/environment';

import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NGXLogger } from 'ngx-logger';
import { PageEvent } from '@angular/material/paginator';
import { RoastReportOverview } from './RoastReportOverview';
import { Sort, SortDirection } from '@angular/material/sort';
import { Location } from 'src/app/models/Location';
import { StandardService } from 'src/app/util/services/standard.service';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-roastreports',
    templateUrl: './roastreports.component.html',
    styleUrls: ['./roastreports.component.scss'],
})
export class RoastreportsComponent implements OnInit, OnDestroy {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private utils: Utils,
        private userService: UserService,
        private standardService: StandardService,
        private reportService: ReportService,
        private alertService: AlertService,
        private tr: TranslatorService,
        private breakpointObserver: BreakpointObserver,
        private logger: NGXLogger,
    ) { }

    readonly NROFROASTSPERREPORT = 0;

    @Input() currentUser: UserType;
    readOnly = false;

    // currently dispalyed reports
    roastreports: (RoastReport)[];
    roastreportOverviews: (RoastReportOverview)[] = [];
    // reports that have been loaded - TODO currently these are all
    displayCache: (RoastReport)[];
    newReportMode = false;
    editReportMode = -1;
    reportNames: string[] = [];
    missingRoasts: Roast[][] = [];
    idToHighlight: string;

    stores: { _id: string, hr_id?: string, label?: string }[];

    company: { name: string, number: string } = { name: undefined, number: undefined };
    companyInfoChanged = new Subject<{ name: string, number: string }>();
    showHint = true;

    loadSubscription = new Subject<string>();
    private ngUnsubscribe = new Subject();

    mainUnit: UnitSystemType = 'kg';
    currency = 'EUR';

    isOpenReport = false;
    suggestCopyFromFixed = false;
    copying = false;

    currentSort: Sort = { active: 'date', direction: 'desc' };
    lastSortOrder = 'date';
    lastDirection: SortDirection = 'desc';
    // firstSort = true;

    pageSize = 10;
    pageIndex = 0;
    lastEndDate: DateTime;
    lastNumber: string;
    // total number of objects in the DB
    objectCount = 0;

    isSmall$: Observable<boolean>;


    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }

        this.isSmall$ = this.breakpointObserver.observe('(max-width: 599px)')
            .pipe(map(result => result.matches));

        // url: /reports/roasts/fixed
        this.isOpenReport = this.router.url.indexOf('fixed') < 0;
        this.pageSize = this.userService.getPageSize('roastreports', this.pageSize);

        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
            if (this.currentUser.account.company) {
                this.company = { name: this.currentUser.account.company.name, number: this.currentUser.account.company.number };
            }
        }
        this.showHint = !(this.currentUser.dsa & Enumerations.DIALOG.REPORTSDESCRIPTION);

        this.loadSubscription
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe(
                () => {
                    this.readOnly = this.userService.isReadOnly();
                    this.idToHighlight = this.route.snapshot.params.id;
                    // this.currentSort = { active: 'date', direction: 'desc' };
                    // this.lastSortOrder = 'date';
                    // this.lastDirection = 'desc';
                    this.getExistingReportsMeta(undefined, undefined, this.idToHighlight);
                }
            );

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: unknown) => {
                if (e instanceof NavigationEnd && e.url.indexOf('reports') >= 0) {
                    // only pass and debounce interesting events
                    this.loadSubscription.next('reload');
                }
            });

        this.loadSubscription.next('init');

        // preload all stores and machines for editing
        this.getAllStores();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    localSort(sortInfo: Sort): void {
        if (!this.isOpenReport) {
            return;
        }
        // in case the "fixed" reports should be sortable, too
        // one would need to sort the missingRoasts array accordingly
        // see commented solution below

        const sortOrder = sortInfo?.active || 'date';
        // see reportAPI -> getSortOrder
        //  * @param {boolean} inverse if false, sorts according to the expected order (date, lastmodified, organic: desc, others: asc)
        const inverse = ['date', 'lastmodified', 'organic'].includes(sortInfo.active) ? sortInfo?.direction === 'asc' : sortInfo?.direction === 'desc';
        const inversenum = inverse ? 2 : 0;

        if (!this.displayCache) this.displayCache = [];
        this.displayCache.sort((r1, r2) => {
            if (!r1) return r2 ? 1 : 0;
            if (!r2) return -1;
            let diff = 0;
            switch (sortOrder) {
                case undefined:
                case 'lastmodified':
                    if (!r1.updated_at && !r2.updated_at) { return 0; }
                    if (!r2.updated_at) { return 1 - inversenum; }
                    if (!r1.updated_at) { return -1 + inversenum; }
                    diff = new Date(r1.updated_at).getTime() - new Date(r2.updated_at).getTime();
                    if (diff < 0) { return 1 - inversenum; }
                    if (diff > 0) { return -1 + inversenum; }
                    diff = r1.startDate.valueOf() - r2.startDate.valueOf();
                    return diff < 0 ? 1 - inversenum : (diff > 0 ? -1 + inversenum : 0);

                case 'label':
                    if (!r1.label) { r1.label = ''; }
                    if (!r2.label) { r2.label = ''; }
                    if (r1.label.toUpperCase() < r2.label.toUpperCase()) { return -1 + inversenum; }
                    if (r1.label.toUpperCase() > r2.label.toUpperCase()) { return 1 - inversenum; }
                    diff = r1.startDate.valueOf() - r2.startDate.valueOf();
                    return diff < 0 ? 1 - inversenum : (diff > 0 ? -1 + inversenum : 0);

                case 'date':
                    if (!r1.startDate && !r2.startDate) { return 0; }
                    if (!r2.startDate) { return -1 + inversenum; }
                    if (!r1.startDate) { return 1 - inversenum; }
                    diff = +r1.startDate - +r2.startDate;
                    if (diff < 0) { return 1 - inversenum; }
                    if (diff > 0) { return -1 + inversenum; }
                    diff = r1.endDate.valueOf() - r2.endDate.valueOf();
                    return diff < 0 ? 1 - inversenum : (diff > 0 ? -1 + inversenum : 0);

                case 'organic':
                    if (!r1.certInfoOnCreation && !r2.certInfoOnCreation) {
                        diff = r1.startDate.valueOf() - r2.startDate.valueOf();
                        return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);
                    }
                    if (!r2.certInfoOnCreation) { return -1 + inversenum; }
                    if (!r1.certInfoOnCreation) { return 1 - inversenum; }
                    diff = r1.certInfoOnCreation - r2.certInfoOnCreation;
                    if (diff > 0) { return 1 - inversenum; }
                    if (diff < 0) { return -1 + inversenum; }
                    diff = r1.startDate.valueOf() - r2.startDate.valueOf();
                    return diff > 0 ? 1 - inversenum : (diff < 0 ? -1 + inversenum : 0);
            }
        });
        this.roastreports = this.displayCache.slice(this.pageSize * this.pageIndex, this.pageSize * (this.pageIndex + 1));

        // for sorting "fixed" roast reports:
        // const indexOrder = this.roastreports
        //     .map((r, i) => [r, i])
        //     .sort((rr1, rr2) => {
        //         const r1 = rr1[0] as RoastReport;
        //         const r2 = rr2[0] as RoastReport;
        //         if (!r1) return r2 ? 1 : 0;
        //         if (!r2) return -1;
        //         let diff = 0;
        //         switch (sortOrder) {
        //             case undefined:
        //             case 'lastmodified':
        //                 if (!r1.updated_at && !r2.updated_at) { return 0; }
        //                 if (!r2.updated_at) { return 1 - inversenum; }
        //                 if (!r1.updated_at) { return -1 + inversenum; }
        //                 diff = new Date(r1.updated_at).getTime() - new Date(r2.updated_at).getTime();
        //                 if (diff < 0) { return 1 - inversenum; }
        //                 if (diff > 0) { return -1 + inversenum; }
        //                 diff = r1.startDate.valueOf() - r2.startDate.valueOf();
        //                 return diff < 0 ? 1 - inversenum : (diff > 0 ? -1 + inversenum : 0);

        //             case 'label':
        //                 if (!r1.label) { r1.label = ''; }
        //                 if (!r2.label) { r2.label = ''; }
        //                 if (r1.label.toUpperCase() < r2.label.toUpperCase()) { return -1 + inversenum; }
        //                 if (r1.label.toUpperCase() > r2.label.toUpperCase()) { return 1 - inversenum; }
        //                 diff = r1.startDate.valueOf() - r2.startDate.valueOf();
        //                 return diff < 0 ? 1 - inversenum : (diff > 0 ? -1 + inversenum : 0);

        //             case 'date':
        //                 if (!r1.startDate && !r2.startDate) { return 0; }
        //                 if (!r2.startDate) { return 1 - inversenum; }
        //                 if (!r1.startDate) { return -1 + inversenum; }
        //                 diff = new Date(r1.startDate).getTime() - new Date(r2.startDate).getTime();
        //                 if (diff < 0) { return 1 - inversenum; }
        //                 if (diff > 0) { return -1 + inversenum; }
        //                 diff = r1.endDate.valueOf() - r2.endDate.valueOf();
        //                 return diff < 0 ? 1 - inversenum : (diff > 0 ? -1 + inversenum : 0);
        //         }
        //     })
        //     .map(r => r[1] as number);

        // this.roastreports = indexOrder.map(i => this.roastreports[i]);
        // this.missingRoasts = indexOrder.map(i => this.missingRoasts[i]);
    }

    /**
     * Called when the user chooses a new sort order (or wants to invert it).
     * Does nothing for fixed reports.
     */
    sortOrderChanged(): void {
        if (!this.isOpenReport || this.editReportMode >= 0) {
            return;
        }
        if (this.lastSortOrder === this.currentSort.active) {
            this.currentSort.direction = this.currentSort.direction === 'asc' ? 'desc' : 'asc';
        } else {
            if (['date', 'lastmodified', 'organic'].includes(this.currentSort.active)) {
                this.currentSort.direction = 'desc';
            } else {
                this.currentSort.direction = 'asc';
            }
        }
        // if (this.firstSort && this.currentSort.active === 'date') {
        //     this.currentSort.direction = 'desc';
        // } else {
        //     if (this.lastSortOrder === this.currentSort.active) {
        //         this.currentSort.direction = this.currentSort.direction === 'desc' ? 'asc' : 'desc';
        //     } else {
        //         this.currentSort.direction = 'asc';
        //     }
        // }
        this.logger.debug('sort', this.currentSort.active, this.currentSort.direction);
        this.getExistingReportsMeta(undefined, this.currentSort);
        this.lastSortOrder = this.currentSort.active;
        this.lastDirection = this.currentSort.direction;
        // this.firstSort = false;
    }

    moveFromFixed(makeCopy = false): void {
        this.copying = true;
        this.reportService.moveFixedRoastReports(this.NROFROASTSPERREPORT, makeCopy)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.displayCache = this.utils.dateifyRoastReports(response.result);
                        if (!this.displayCache) this.displayCache = [];
                        this.roastreports = this.displayCache.slice(this.pageSize * this.pageIndex, this.pageSize * (this.pageIndex + 1));
                        if (!response.result?.length) {
                            this.alertService.success(this.tr.anslate('Nothing to change'));
                        }

                    } else {
                        this.utils.handleError('error retrieving reports', response.error);
                    }
                    this.copying = false;
                },
                error: error => {
                    this.utils.handleError('error retrieving reports', error);
                    this.copying = false;
                }
            });
    }

    // the old way of receiving all reports independent of paging:

    // // checks whether the requested page is in the displayCache
    // getExistingReports(pagingInfo?: { pageIndex?: number, pageSize?: number }): void {
    //     if (pagingInfo && (
    //         typeof pagingInfo.pageIndex !== 'undefined' && pagingInfo.pageIndex !== this.pageIndex
    //         || typeof pagingInfo.pageSize !== 'undefined' && pagingInfo.pageSize !== this.pageSize)) {
    //         // paging changed
    //         // TODO retrieve from cache/server instead of caching all locally
    //         if (typeof pagingInfo.pageIndex !== 'undefined') {
    //             this.pageIndex = pagingInfo.pageIndex;
    //         }
    //         if (typeof pagingInfo.pageSize !== 'undefined') {
    //             this.pageSize = pagingInfo.pageSize;
    //         }
    //         if (this.displayCache[this.pageSize * this.pageIndex] && this.displayCache[this.pageSize * (this.pageIndex + 1) - 1]) {
    //             this.roastreports = this.displayCache.slice(this.pageSize * this.pageIndex, this.pageSize * (this.pageIndex + 1));
    //             return;
    //         }
    //     }

    //     this.reportService.getRoastReports(this.pageSize, this.pageIndex, this.NROFROASTSPERREPORT, this.isOpen)
    //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
    //         .subscribe(
    //             response => {
    //                 if (response.success === true) {
    //                     this.objectCount = response.count || 0;
    //                     this.displayCache = this.utils.dateifyRoastReports(response.result);
    //                     this.roastreports = this.displayCache.slice(this.pageSize * this.pageIndex, this.pageSize * (this.pageIndex + 1));
    //                     this.lastEndDate = this.displayCache?.[0]?.endDate;
    //                     if (!this.isOpen) {
    //                         this.getMissingRoasts(this.lastEndDate);
    //                     } else if (!this.roastreports?.length) {
    //                         // see if there are any "fixed" reports to copy / move
    //                         this.reportService.getRoastReports(undefined, undefined, 1, false)
    //                             .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
    //                             .subscribe(
    //                                 response2 => {
    //                                     if (response2.success === true && response2.result?.length) {
    //                                         // offer to copy / move "fixed" reports
    //                                         this.suggestCopyFromFixed = true;
    //                                     }
    //                                 },
    //                             );
    //                     }
    //                 } else {
    //                     this.utils.handleError('error retrieving reports', response.error);
    //                 }
    //             },
    //             error => {
    //                 this.utils.handleError('error retrieving reports', error);
    //             }
    //         );
    // }

    trackById = (_index: number, pack: RoastReport): string => pack?._id;

    private countCurrentCached(): number {
        let cnt = 0;
        for (let cr = 0; cr < this.displayCache?.length; cr++) {
            if (this.displayCache[cr]) {
                cnt++;
            }
        }
        return cnt;
    }

    private hasSortingChanged(sortInfo?: Sort) {
        return sortInfo && (sortInfo.active !== this.lastSortOrder || sortInfo.direction !== (this.lastDirection ? 'desc' : 'asc'));
    }

    /**
     * Gets a list of existing reports.
     * Checks whether the requested page is in the displayCache;
     * if not, returns first all most relevant info for the current page of reports
     * then retrieves all info for that page
     * @param pagingInfo current page size and index
     * @param sortInfo current sort label and direction
     * @param reportLabel if defined, will return the page on which the report with this label is found
     */
    getExistingReportsMeta(
        pagingInfo?: { pageIndex?: number, pageSize?: number },
        sortInfo = this.currentSort,
        reportLabel?: string): void {

        if (this.objectCount === 0) {
            // if the only report has been deleted, start new
            this.lastEndDate = undefined;
        }
        this.pageIndex = typeof pagingInfo?.pageIndex !== 'undefined' ? pagingInfo.pageIndex : this.pageIndex;
        this.pageSize = pagingInfo?.pageSize || this.pageSize;
        const curCached = this.countCurrentCached();
        // if all or the current page are cached, use the displayCache unless sorting changed
        if ((this.displayCache && this.objectCount === curCached) ||
            (!this.hasSortingChanged(sortInfo)
                && (this.displayCache?.[this.pageSize * this.pageIndex]
                    && this.displayCache[Math.min(this.objectCount - 1, this.pageSize * (this.pageIndex + 1) - 1)]))) {
            // have all in cache; or at least the page and sorting didn't change
            this.localSort(sortInfo);
            this.roastreports = this.displayCache.slice(this.pageSize * this.pageIndex, this.pageSize * (this.pageIndex + 1));
            // update last end date after a potential delete
            this.lastEndDate = this.roastreports[0]?.endDate;
            return;
        }

        this.reportService.getRoastReportsMeta(this.pageSize, this.pageIndex, this.isOpenReport, reportLabel, sortInfo)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (response.result?.reports?.length) {
                            response.result.reports = this.utils.dateifyRoastReports(response.result.reports);
                        }
                        let lastNotReconciledDate: DateTime;
                        if (response.result?.lastEndDate) {
                            this.lastEndDate = typeof response.result.lastEndDate === 'number' ? DateTime.fromMillis(response.result.lastEndDate) : DateTime.fromISO(response.result.lastEndDate);
                            for (let r = response.result.reports?.length - 1; r >= 0; r--) {
                                const report = response.result.reports[r];
                                if (report?.reconciled) {
                                    lastNotReconciledDate = report.endDate;
                                } else {
                                    break;
                                }
                            }
                        }
                        this.lastNumber = response.result?.lastNumber || this.lastNumber;
                        this.objectCount = response.count || 0;
                        if (typeof response.result?.pageIndex !== 'undefined') {
                            this.pageIndex = response.result.pageIndex - 1;
                        }
                        if (!this.displayCache) this.displayCache = [];
                        for (let i = 0; i < response.result?.reports.length; i++) {
                            this.displayCache[this.pageSize * this.pageIndex + i] = response.result.reports[i];
                        }
                        this.roastreports = this.displayCache.slice(this.pageSize * this.pageIndex, this.pageSize * (this.pageIndex + 1));
                        if (!this.isOpenReport) {
                            this.getMissingRoasts(this.lastEndDate, lastNotReconciledDate);
                        } else if (!this.roastreports?.length) {
                            // see if there are _any_ "fixed" reports to copy / move
                            this.reportService.getRoastReportsMeta()
                                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                                .subscribe(
                                    response2 => {
                                        if (response2.success === true && response2.result?.reports.length) {
                                            // offer to copy / move "fixed" reports
                                            this.suggestCopyFromFixed = true;
                                        }
                                    },
                                );
                        }
                        // don't load all data in the background; load it on click (openUp)
                        // // retrieve all infos in the meantime
                        // if (this.objectCount) {
                        //     this.reportService.getRoastReports(this.pageSize, this.pageIndex, this.NROFROASTSPERREPORT, this.isOpenReport, this.sortOrder, this.inverse)
                        //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                        //         .subscribe({
                        //             next: response => {
                        //                 if (response.success === true) {
                        //                     if (!this.displayCache) this.displayCache = [];
                        //                     for (let i = 0; i < response.result.length; i++) {
                        //                         this.displayCache[this.pageSize * this.pageIndex + i] = response.result[i];
                        //                     }
                        //                     this.roastreports = this.displayCache.slice(this.pageSize * this.pageIndex, this.pageSize * (this.pageIndex + 1));
                        //                 } else {
                        //                     this.utils.handleError('error retrieving reports', response.error);
                        //                 }
                        //             },
                        //             error: error => {
                        //                 this.utils.handleError('error retrieving reports', error);
                        //             }
                        //         });
                        // }
                    } else {
                        this.utils.handleError('error retrieving reports', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving reports', error);
                }
            });
    }

    getMissingRoasts(until?: DateTime, from?: DateTime): void {
        if (!this.displayCache?.length) {
            return;
        }

        // restrict to the earliest startDate of the displayCache ...
        let searchFrom = until;
        this.displayCache?.forEach(report => {
            if (report.startDate < from) {
                from = report.startDate;
            }
        });
        // ... unless a later "from" date is given
        if (from > searchFrom) {
            searchFrom = from;
        }

        this.reportService.getMissingRoasts(until, from !== until ? from : undefined)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        const mroasts = this.utils.dateifyRoasts(response.result);
                        this.missingRoasts = [];
                        if (!mroasts?.length) {
                            return;
                        }
                        // for each existing report search those not-assigned roasts that fall within the report's period
                        // better use forEach since some enrties in displayCache might not be set
                        // for (let rep = 0; rep < this.displayCache?.length; rep++) {
                        //     const report = this.displayCache[rep];
                        //     this.missingRoasts[rep] = mroasts.filter(r => r.date > report.startDate && r.date <= report.endDate);
                        // }
                        this.displayCache?.forEach((report, r) => {
                            this.missingRoasts[r] = mroasts.filter(roast => roast.date > report.startDate && roast.date <= report.endDate);
                        });

                    } else {
                        this.utils.handleError('error retrieving reports', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving reports', error);
                }
            });
    }

    add(): void {
        this.newReportMode = true;
        this.reportNames = (this.displayCache || []).map(rep => rep?.label);
    }

    onNewReportModeChanged(mode: boolean): void {
        this.newReportMode = mode;
    }

    onReportDeleted(deletedReport: RoastReport): void {
        if (deletedReport?._id && this.displayCache) {
            this.displayCache = this.displayCache.filter(r => (r._id || r).toString() !== (deletedReport._id || deletedReport).toString());
            this.objectCount -= 1;
            this.getExistingReportsMeta({ pageIndex: 0 });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onReportReconciled(idx: number, report: RoastReport): void {
        this.roastreports[idx] = report;
    }

    onNewReport(info: { newReport: RoastReport, newReportOverview: RoastReportOverview }): void {
        if (info?.newReport) {
            if (!this.displayCache) this.displayCache = [];
            this.displayCache.unshift(info.newReport);
            this.objectCount += 1;
            this.lastEndDate = info.newReport.endDate;
            this.lastNumber = info.newReport.number;
            // reset sort display as we insert the new report in the beginning
            this.lastSortOrder = 'lastmodified';
            this.currentSort = { active: 'lastmodified', direction: 'desc' };
            this.getExistingReportsMeta({ pageIndex: 0 });
            if (!this.isOpenReport) {
                this.getMissingRoasts(this.lastEndDate);
            }
        }
        if (info?.newReportOverview) {
            this.roastreportOverviews[0] = info.newReportOverview;
        }
    }

    /**
     * Called when the user goes to the next/previous page or changed the page size.
     * Stores potentially new page size and call getExistingReportsMeta
     * @param $event new paging data
     */
    pagingChanged($event: PageEvent): void {
        if (this.pageSize !== $event.pageSize) {
            this.pageSize = $event.pageSize;
            this.userService.setPageSize('roastreports', this.pageSize);
        }
        this.getExistingReportsMeta($event);
    }

    removeHint(): void {
        this.showHint = false;
        if (!this.readOnly) {
            this.utils.storeDontShownAgain(Enumerations.DIALOG.REPORTSDESCRIPTION);
        }
    }

    getAllStores(): void {
        this.standardService.getAll<Location>('stores')
            // TODO .pipe(finalize(() => /* duplicate code block */))
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stores = response.result.map(s => {
                            return { _id: s._id, hr_id: s.hr_id, label: s.label }
                        });
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                        if (!this.stores?.length) {
                            this.stores = [];
                        }
                    }
                    this.stores.sort((s1, s2) => s1.hr_id > s2.hr_id ? -1 : s1.hr_id > s2.hr_id ? 1 : 0);
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                    if (!this.stores?.length) {
                        this.stores = [];
                    }
                }
            });
    }
}

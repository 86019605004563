import { TranslatorService } from 'src/app/util/services/translator.service';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose, MatDialogTitle } from '@angular/material/dialog';
import { Coffee } from 'src/app/models/Coffee';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CurrencyPipe, DecimalPipe, NgTemplateOutlet } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { Blend } from 'src/app/models/Blend';
import { OrganicIconComponent } from '../organicicon.component';
import { Utils } from 'src/app/util/utils';
import { MatOption, MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatInput } from '@angular/material/input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatButton } from '@angular/material/button';
import { StandardService } from 'src/app/util/services/standard.service';
import { throttleTime, takeUntil, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-beans-search-dialog',
    templateUrl: './beans-search-dialog.component.html',
    styleUrl: './beans-search-dialog.component.scss',
    standalone: true,
    // eslint-disable-next-line max-len
    imports: [OrganicIconComponent, MatFormField, FormsModule, MatDialogContent, RouterLink, DecimalPipe, CurrencyPipe, MatDialogActions, MatIcon, MatLabel, MatSelect, MatSelectTrigger, MatOption, MatProgressSpinner, MatInput, NgxMatSelectSearchModule, MatButton, MatDialogClose, MatDialogTitle, NgTemplateOutlet, ],
})
export class BeansSearchDialogComponent implements OnInit, OnDestroy {

    constructor(
        private standardService: StandardService,
        public tr: TranslatorService,
        public utils: Utils,
        private dialogRef: MatDialogRef<BeansSearchDialogComponent, { isBlend: boolean, item: Coffee | Blend}>,
        @Inject(MAT_DIALOG_DATA) public data: {
            coffees: Coffee[],
            blends: Blend[],
            searchCoffees: boolean,
            searchBlends: boolean,
        },
    ) { }

    @HostListener('window:keyup.Enter', ['$event'])
    onDialogClick(): void {
        this.doSearch();
    }

    isLoading = false;
    isLoadingTimer: ReturnType<typeof setTimeout>;
    isLoadingB = false;
    isLoadingTimerB: ReturnType<typeof setTimeout>;

    coffee: Coffee;
    coffees: Coffee[];
    filteredCoffees: Coffee[];
    blend: Blend;
    blends: Blend[];
    filteredBlends: Blend[];
    searchText: string = '';
    lastSearchText: string = '';

    private ngUnsubscribe = new Subject();


    ngOnInit(): void {
        this.coffees = this.data?.coffees ?? [];
        this.blends = this.data?.blends ?? [];
        if (this.data.searchBlends == null) {
            this.data.searchBlends = true;
        }
        if (this.data.searchCoffees == null) {
            this.data.searchCoffees = true;
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        clearTimeout(this.isLoadingTimer);
    }

    selectBean(): void {
        this.dialogRef.close({ isBlend: false, item: this.coffee });
    }

    selectBlend(): void {
        this.dialogRef.close({ isBlend: true, item: this.blend });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    doSearch(): void {
        if (this.searchText.length < 3 || this.searchText === this.lastSearchText) {
            return;
        }
        this.lastSearchText = this.searchText;

        // locally
        if (this.data?.searchCoffees) {
            this.filteredCoffees = this.coffees.filter(coffee => coffee?.label?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(this.searchText));
        }
        if (this.data?.searchBlends) {
            this.filteredBlends = this.blends.filter(blend => blend?.label?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(this.searchText));
        }

        // server
        // avoid searching for 20kg etc. when searching for 20
        const srchTxt = `"${this.searchText}"`;

        if (this.data?.searchCoffees) {
            this.isLoadingTimer = setTimeout(() => {
                this.isLoading = true;
            }, 600);
            this.standardService.getPage<Coffee>('coffees', 10, 0, undefined, false, undefined, srchTxt, 'true')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        clearTimeout(this.isLoadingTimer);
                        this.isLoading = false;
                        if (response.success === true) {
                            this.coffees = response.result;
                            this.filteredCoffees = response.result;
                            if (this.filteredCoffees?.length) {
                                this.coffee = this.filteredCoffees[0];
                            }
                        } else {
                            this.utils.handleError('error retrieving all beans', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving all beans', error);
                        clearTimeout(this.isLoadingTimer);
                        this.isLoading = false;
                    }
                });
        }

        if (this.data?.searchBlends) {
            this.isLoadingTimerB = setTimeout(() => {
                this.isLoadingB = true;
            }, 600);
            this.standardService.getPage<Blend>('blends', 10, 0, undefined, false, undefined, srchTxt, 'true')
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        clearTimeout(this.isLoadingTimerB);
                        this.isLoadingB = false;
                        if (response.success === true) {
                            this.blends = response.result;
                            this.filteredBlends = response.result;
                            if (this.filteredBlends?.length) {
                                this.blend = this.filteredBlends[0];
                            }
                        } else {
                            this.utils.handleError('error retrieving all blends', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving all blends', error);
                        clearTimeout(this.isLoadingTimerB);
                        this.isLoadingB = false;
                    }
                });
        }
    }
}

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field {
  margin-bottom: 15px;
}

.hr-spacer {
  margin: 30px 20px;
}

.inner-action-row {
  display: flex;
  justify-content: flex-end;
}

.mainframe {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.flex-center {
  justify-content: space-between;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
}

.space-left {
  margin-left: 5px;
}

.single-button {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.minor-header {
  margin-bottom: 5px;
}

mat-spinner.in-text-spinner {
  display: initial;
  left: 0;
  margin-left: 5px;
  float: right;
}

.oneline-formfields {
  display: flex;
  justify-content: space-between;
}

.settings-header {
  text-align: left;
  padding-bottom: 10px;
  padding-top: 25px;
  font-weight: bold;
}

.half-formfield {
  width: 48%;
}

.mat-icon.helpicon.bigicon {
  height: 24px;
  width: 24px;
  font-size: 24px;
}

.toggle-settings {
  text-align: center;
  margin: 10px 0 15px 0;
}

.only-left-margin {
  margin: 0 0 0 5px;
}

.h3space {
  margin-top: 25px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Injectable, Inject, LOCALE_ID } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {

    constructor(
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    /**
     * Returns the translation of the term with the given ID in the current language
     * @param id id of the translation
     * @param params key value pair of parameters that will be replaced in the translation
     */
    anslate(id: string, params?: { [key: string]: string | number }): string {
        if (!id) {
            return '';
        }
        if (!params) {
            if (!this.locale || this.locale.substring(0, 2) === 'en') {
                // shortcut for English without {{}}
                if (id.substring(0, 7) === 'plural_') {
                    // special treatment for 'plural_bag' etc.
                    if (id === 'plural_box') {
                        return 'boxes';
                    } else {
                        return id.substring(7) + 's';
                    }
                } else if (id.substring(0, 13) === '(every n>=2) ') {
                    // special treatment for '(every n>=2) days' etc.
                    return id.substring(13);
                }
                if (!this.locale || this.locale !== 'en-GB') {
                // shortcut for default English without {{}}
                    return id;
                }
            }
        }

        let msg = [`:@@${id}:${id}`];
        // anfang {{startIndex}} - {{endIndex}} of {{length}} ende
        // => :@@anfang {{startIndex}} - {{endIndex}} of {{length}} ende:anfang
        // => :INTERPOLATION: - 
        // => :INTERPOLATION_1: of
        // => :INTERPOLATION_2: ende
        if (id.indexOf('{{') >= 0) {
            const msgInt = id.replace(/\{\{.*?\}\}/g, '§§§:INTERPOLATION:');
            let cnt = 0;
            msg = msgInt.split('§§§').filter(m => m);
            for (let i = 0; i < msg.length; i++) {
                if (msg[i].indexOf('INTERPOLATION') >= 0) {
                    if (cnt > 0) {
                        msg[i] = msg[i].replace('INTERPOLATION', `INTERPOLATION_${cnt}`);
                    }
                    cnt += 1;
                }
            };
            if (msg[0].substring(0, ':INTERPOLATION:'.length) === ':INTERPOLATION:') {
                msg.unshift('');
            }
            msg[0] = `:@@${id}:${msg[0]}`;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const idarr = msg as any;
        idarr.raw = msg;
        if (params) {
            // TODO! necessary only for the extract-i18n call
            // return id;
            return !id ? '' : $localize(idarr as TemplateStringsArray, ...Object.values(params));
        } else {
            // TODO! necessary only for the extract-i18n call
            // return id;
            return !id ? '' : $localize(idarr as TemplateStringsArray, {});
        }
        // return id;
        // return !id ? '' : this.i18n({ id, value: id }, params);
    }
}

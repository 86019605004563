import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { TranslatorService } from "src/app/util/services/translator.service";

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    constructor(
        private tr: TranslatorService,
        @Inject(LOCALE_ID) public locale: string,
    ) {}

    translateAttr(attr: string): string {
        let str: string;
        switch (attr) {
            case 'AUC base':
                str = this.tr.anslate('AUC');
                return `${str} base`;
            case 'DEV ratio':
                str = this.tr.anslate('DEV');
                return `${str} ${this.tr.anslate('Ratio')}`;
            case 'DEV time':
                str = this.tr.anslate('DEV');
                return `${str} ${this.tr.anslate('time')}`;
            case 'CHARGE BT':
                str = this.tr.anslate('CHARGE');
                return `${str} ${this.tr.anslate('BT')}`;
            case 'DROP BT':
                str = this.tr.anslate('DROP');
                return `${str} ${this.tr.anslate('BT')}`;
            case 'FC RoR':
                str = this.tr.anslate('FC');
                return `${str} RoR`;
            case 'FC BT':
                str = this.tr.anslate('FC');
                return `${str} ${this.tr.anslate('BT')}`;
            case 'Batch prefix':
                str = this.tr.anslate('batch');
                str = str[0].toLocaleUpperCase(this.locale) + str.slice(1);
                return `${str} ${this.tr.anslate('Prefix')}`;
            case 'Batch number':
                str = this.tr.anslate('batch');
                str = str[0].toLocaleUpperCase(this.locale) + str.slice(1);
                return `${str} ${this.tr.anslate('Number')}`;
            case 'Density roasted':
                str = this.tr.anslate('Density');
                return str[0].toLocaleUpperCase(this.locale) + str.slice(1);
            case 'Discarded':
                str = this.tr.anslate('discarded');
                return str[0].toLocaleUpperCase(this.locale) + str.slice(1);
            case 'Reconciled':
                str = this.tr.anslate('Finalized');
                return str[0].toLocaleUpperCase(this.locale) + str.slice(1);
            case 'CO2':
            case 'CM BTD':
            case 'CM ETD':
                return attr;
            default:
                break;
        }
        str = this.tr.anslate(attr) ?? attr;
        return str[0].toLocaleUpperCase(this.locale) + str.slice(1);
    }
}
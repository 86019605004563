import { Component, OnInit, Input, Inject, LOCALE_ID, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { FileUploadComponent } from './file-upload.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-ap-file',
    templateUrl: './file-show.component.html',
    styleUrls: ['./file-show.component.scss']
})
export class FileShowComponent implements OnInit {

    constructor(
        private dialog: MatDialog,
        private logger: NGXLogger,
        private sanitizer: DomSanitizer,
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    @Input() files: string[];
    @Input() belongsTo: string | number;
    @Input() belongsToType: string;
    @Input() readOnly = false;

    @Output() filesChanged = new EventEmitter<string[]>();

    ngOnInit(): void {
        this.locale = this.locale ? this.locale.substring(0, 2) : 'en';
    }

    openUploadDialog(): void {
        const dialogRef = this.dialog.open(FileUploadComponent, {
            width: '50%',
            height: '50%',
            closeOnNavigation: true,
            data: { belongsTo: this.belongsTo, belongsToType: this.belongsToType }
        });

        dialogRef.afterClosed().subscribe(fileNames => {
            if (fileNames?.length > 0) {
                for (let f = 0; f < fileNames.length; f++) {
                    const fileInfo = fileNames[f];
                    if (!this.files) {
                        this.files = [];
                    }
                    if (environment.BASE_API_URL.indexOf('localhost') >= 0) {
                        this.files.push(fileInfo.fileName + '#' + '/' + (this.locale === 'en-GB' ? 'gb' : this.locale) + fileInfo.path);
                    } else {
                        this.files.push(fileInfo.fileName + '#' + fileInfo.path);
                    }
                    this.logger.debug('using file at ' + this.files[this.files.length - 1]);
                }
                this.filesChanged.emit(this.files);
            }
        });
    }

    removeFile(file: string): void {
        const idx = this.files.indexOf(file);
        if (idx >= 0) {
            this.files.splice(idx, 1);
            this.filesChanged.emit(this.files);
        }
    }

    getFileName(path: string): string {
        if (!path) {
            return '';
        }
        const spl = path.split('#', 2);
        return spl[0];
    }

    getFileLink(path: string): string {
        if (!path) {
            return '';
        }
        const spl = path.split('#', 2);
        return spl[1];
    }

    getFileAlog(path: string): SafeResourceUrl {
        if (path.match(/\.alog$/)) {
            const file = this.getFileLink(path);
            let domain = 'https://artisan.plus';
            if (location?.origin) {
                domain = location.origin;
            }
            return this.sanitizer.bypassSecurityTrustResourceUrl(`artisan://profile?url=${domain}${file}`);
        }
        return '';
    }
}

// based on https://whiteduck.de/en/how-to-approach-angular-internationalization-i18n-in-2022/

import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { loadTranslations } from '@angular/localize';

@Injectable({
    providedIn: 'root',
})
class I18n {
    locale = 'en';

    constructor(
        @Inject(APP_BASE_HREF) private baseHref: string
    ) {
        // const userLocale = localStorage.getItem('locale'); 
        const url = this.baseHref;
        const loc = /\/(..)\//.exec(url)?.[1];
        this.locale = loc || 'en';
        if (this.locale === 'gb') {
            this.locale = 'en-GB';
        }
        if (this.locale === 'zz' || this.locale === 'xy') {
            this.locale = 'en';
        }
    }

    async setLocale() {
        if (this.locale !== 'en' && this.locale !== 'zz' && this.locale !== 'xy') {
            // Use web pack magic string to only include required locale data
            const localeModule = await import(
                /* webpackInclude: /(de|en|es|zh|fr|it|pt|en-GB)\.mjs$/ */
                `/node_modules/@angular/common/locales/${this.locale}.mjs`
            );

            // Set locale for built in pipes, etc.
            registerLocaleData(localeModule.default);

            // Load translation file
            const localeTranslationsModule = await import(
                `src/app/locale/${this.locale}.json`
            );

            // Load translations for the current locale at run-time
            loadTranslations(localeTranslationsModule.default);
        }
    }
}

// Load locale data at app start-up
function setLocale() {
    return {
        provide: APP_INITIALIZER,
        useFactory: (i18n: I18n) => () => i18n.setLocale(),
        deps: [I18n],
        multi: true,
    };
}

// Set the runtime locale for the app
function setLocaleId() {
    return {
        provide: LOCALE_ID,
        useFactory: (i18n: I18n) => i18n.locale,
        deps: [I18n],
    };
}

export const I18nModule = {
    setLocale: setLocale,
    setLocaleId: setLocaleId,
};

import { Utils } from 'src/app/util/utils';
import { Component, AfterViewInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { HttpClient } from '@angular/common/http';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { DateTime } from 'luxon';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    standalone: true,
    selector: 'app-image-upload2',
    templateUrl: './image-upload2.component.html',
    styleUrls: ['./image-upload2.component.scss'],
    imports: [ImageCropperComponent, MatIconModule, MatProgressSpinnerModule, NgClass, MatButtonModule],
})
export class ImageUpload2Component implements AfterViewInit, OnDestroy {

    constructor(
        private logger: NGXLogger,
        private dialogRef: MatDialogRef<ImageUpload2Component>,
        private alertService: AlertService,
        private tr: TranslatorService,
        private http: HttpClient,
        private utils: Utils,
        @Inject(MAT_DIALOG_DATA) public data: {
            url: string,
            avatar: boolean,
            default: string,
        }
    ) { }

    @ViewChild('file', { static: true }) file: ElementRef;
    imageChangedEvent: Event | null = null;
    croppedImage = '';
    imageFileChanged: File;
    isReady = false;
    loading = false;
    errorLoading = false;
    originalFile: File;

    private ngUnsubscribe = new Subject();

    ngAfterViewInit(): void {
        let url: string;
        if (this.data?.url && !this.data.url.toUpperCase().endsWith('SVG')) {
            url = this.data.url;
        } else {
            url = this.data.default;
        }
        if (url) {
            setTimeout(() => this.loading = true);
            this.http.get(url, { responseType: 'blob' })
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: blob => {
                        if (blob) {
                            // this.imageFileChanged = blob;
                            let filename = 'upload';
                            let lastModified = DateTime.now().toMillis();
                            let type = 'image/jpeg';
                            if (this.originalFile) {
                                filename = this.originalFile.name;
                                lastModified = this.originalFile.lastModified;
                                type = this.originalFile.type;
                            }
                            this.imageFileChanged = new File([blob], filename, { lastModified, type });
                            this.errorLoading = false;
                        } else {
                            this.utils.handleError('Error loading image. Please choose a new one!', undefined);
                            this.loading = false;
                            this.errorLoading = true;
                        }
                    },
                    error: error => {
                        this.utils.handleError('Error loading image. Please choose a new one!', error);
                        this.loading = false;
                        this.errorLoading = true;
                    }
                });
            // const request = new XMLHttpRequest();
            // request.open('GET', url, true);
            // request.responseType = 'blob';
            // const that = this;
            // request.onload = function(): void {
            //     that.imageFileChanged = request.response;
            // };
            // request.send();
        } else if (this.file?.nativeElement) {
            this.file.nativeElement.click();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    removeImage(): void {
        this.imageChangedEvent = null;
        this.croppedImage = null;
        this.loading = false;
        this.errorLoading = false;
        this.isReady = true;
        this.logger.trace('removeImage');
    }

    fileChangeEvent(event: Event): void {
        this.imageChangedEvent = event;
        this.originalFile = event.target?.['files']?.[0];
        this.loading = true;
        this.logger.trace('fileChangeEvent');
    }
    imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
        this.loading = false;
        this.logger.trace('imageCropped');
    }
    imageLoaded(): void {
        this.logger.trace('imageLoaded');
        // show cropper
    }
    cropperReady(): void {
        this.logger.trace('cropperReady');
        this.loading = false;
        this.isReady = true;
    }
    loadImageFailed(): void {
        this.loading = false;
        this.logger.debug('loadImageFailed');
        this.alertService.error(this.tr.anslate('invalid image'));
    }

    cancel(): void {
        this.loading = false;
        this.dialogRef.close();
    }

    saveImage(): void {
        this.loading = false;
        this.dialogRef.close(this.croppedImage);
    }
}

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop {
  z-index: -1;
  width: 100%;
  height: 100%;
  /* filter: blur(10px) grayscale(30%) opacity(80%); */
  left: 0;
  background-image: url('backdrop.5e6d8c47d5309184.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.background-mask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-color: rgba(0, 0, 0, 0.3);
}

.main-center {
  max-width: 1140px;
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  align-self: center;
}

.main-center .main-svg {
  width: 35%;
  min-width: 233px;
  display: block;
  margin: auto;
}

.main-center hr {
  border-top: 3px solid white;
  width: 80px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.main-center .main-text {
  letter-spacing: 3px;
  color: white;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.main-row {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.main-image {
  width: 80%;
}

.main-explanation {
  margin-bottom: 2%;
  font-size: 150%;
  font-weight: 500;
  letter-spacing: 1px;
}

.main-features {
  padding-top: 25px;
  margin-bottom: 2%;
  font-size: 130%;
  font-weight: 400;
  text-align: left;
  display: flex;
  justify-content: center;
}

.main-features ul li {
  padding: 2% 0px;
}

body {
  background-color: #614d37;
}

.page-scroll {
  bottom: 0;
  position: absolute;
  left: 50%;
  width: 100px;
  margin-left: -50px;
}

.page-scroll .btn-circle mat-icon.animated {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 2s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 2s;
  transition-duration: 2s;
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -webkit-animation-duration: 2.5s;
  -moz-animation-duration: 2.5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-name: pulse;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.btn-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.6);
  background: none;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
}

.btn-circle mat-icon {
  font-size: 60px;
  width: 60px;
  height: 60px;
  margin-top: 10px;
}

.main-row.back mat-icon {
  color: #928d85;
}

.main-row mat-icon {
  color: #928d85;
}

.main-row {
  position: relative;
}

.small-button {
  font-size: 13px;
}

.partner-button {
  display: block;
  text-align: center;
  margin-bottom: 0;
  margin-top: 40px;
}

.partner-button-landscape {
  display: none;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 0;
}

@media (max-height: 550px) {
  .main-center {
    top: 30%;
  }
  .partner-button-landscape {
    display: block;
  }
  .partner-button {
    display: none;
  }
}
@media (max-height: 410px) {
  .page-scroll {
    display: none;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

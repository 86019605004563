export class Queue<T> {

    running = false;
    queue: T[] = [];
    callback = null;

    constructor(callback: (val: T) => void) {
        this.queue = [];
        this.callback = callback;
    }

    add(data: T): void {
        this.queue.push(data);

        if (!this.running) {
            this.dequeue();
        }
    }

    dequeue(): void {
        this.running = true;

        let val: T;
        while (val = this.queue.shift()) {
            this.callback(val);
        }

        this.running = false;
    }

    next(): () => void {
        return this.dequeue;
    }
}

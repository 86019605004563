import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FilterComponent } from '../roast/filters/filter.component';
import { EMPTYTAG } from '../roast/filters/filter-utils';
import { FilterService } from '../roast/filters/filter.service';
import { NgClass, NgStyle } from '@angular/common';
import { MatFormField } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatChip, MatChipOption, MatChipListbox, MatChipSelectionChange } from '@angular/material/chips';

@Component({
    selector: 'app-chips-filter',
    templateUrl: './chips-filter.component.html',
    styleUrls: ['./chips-filter.component.scss'],
    standalone: true,
    imports: [MatChip, MatChipOption, MatChipListbox, NgClass, NgStyle, MatFormField, FormsModule],
})
export class ChipsFilterComponent implements FilterComponent {

    constructor(
        private filterService: FilterService,
    ) { }

    @Input() disabled = false;
    
    @Input() multiple = true;
    @Input() atLeastOne = false;
    @Input() warnNonSelected = true;

    private _allOptions: string[];
    get allOptions(): string[] {
        return this._allOptions;
    }
    @Input() set allOptions(op: string[]) {
        if (!op) {
            op = [];
        }
        this.noValueFound = false;
        if (!op.length) {
            this.noValueFound = true;
            this.selected = [];
        } else if (this._allOptions?.length !== op.length) {
            // only select default if new (at least length-wise) options are set
            if (op.length === 1) {
                this.selected = [true];
            } else if (op.length) {
                if (this.multiple) {
                    // select all by default
                    this.selected.length = op.length;
                    for (let i = 1; i < op.length; i++) {
                        this.selected[i] = true;
                    }
                } else {
                    // select first by default
                    this.selected = [true];
                }
            }
        }
        this._allOptions = op;
        this.selectedOptions = this._allOptions.filter((_option, idx) => this.selected[idx]);

        // setTimeout(() => { this.emitChanges(false); }, 0);
    }

    @Input() nrDigits: number;
    @Input() unit: string;
    @Input() additionalValues: string[];
    @Input() additionalValueWarns: boolean[] = [];

    i18nPlaceholder: string;
    @Input() set placeholder(ph: string) {
        this.i18nPlaceholder = this.filterService.translateAttr(ph);
    }

    @Input() margin_top = -7;
    @Input() chipWidth: string;
    @Input() mainWidth: string;

    @Output() selectChanged = new EventEmitter<string[]>();

    selectedOptions: string[] = [];
    selected: boolean[] = [];
    noValueFound = false;

    EMPTYTAG = EMPTYTAG;
    JSON = JSON;

    optionChanged(idx: number, event: MatChipSelectionChange) {
        if (this.multiple) {
            if (!event.selected && this.atLeastOne) {
                const cntSelected = this.selected.reduce((prev, cur) => prev + (cur ? 1 : 0), 0);
                if (cntSelected === 1) {
                    // don't allow deselecting the only selected item
                    this.selected[idx] = false;
                    setTimeout(() => {
                        this.selected[idx] = true;
                    }, 0);
                    return;
                }
            }
            this.selected[idx] = event.selected;
        } else {
            if (event.selected && !this.selected[idx]) {
                // deselect others if any
                for (let i = 0; i < this._allOptions.length; i++) {
                    this.selected[i] = i === idx;
                }
            } else if (!event.selected && this.selected[idx]) {
                if (this.atLeastOne) {
                    // don't allow deselecting
                    this.selected[idx] = false;
                    setTimeout(() => {
                        this.selected[idx] = true;
                    }, 0);
                    return;
                }
                this.selected[idx] = false;
            }
        }

        this.selectedOptions = this.allOptions.filter((_option, idx) => this.selected[idx]);
        this.noValueFound = !this.selectedOptions.length;

        if (event.isUserInput) {
            this.selectChanged.emit(this.selectedOptions);
        }
    }

    reset(): void {
        // this is the case for 'discarded' / 'reconciled'
        this.selected.length = this._allOptions.length;
        for (let i = 0; i < this._allOptions.length; i++) {
            this.selected[i] = false;
        }
    }

    setValues(options: string[]): void {
        this.noValueFound = false;
        this.reset();
        if (options?.['length'] === 1 && options[0] == null) {
            this.noValueFound = true;
            return;
        }
        let found = false;
        for (let o1 = 0; o1 < options?.length && !found; o1++) {
            const option = options[o1];
            for (let o2 = 0; o2 < this._allOptions.length; o2++) {
                const allOption = this._allOptions[o2];
                if (option?.toString() === allOption?.toString()) {
                    this.selected[o2] = true;
                    if (!this.multiple) {
                        found = true;
                        break;
                    }
                }
            }
        }
        if (!found) {
            this.noValueFound = true;
        }

        // this.emitChanges(false);
    }
}

import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Alert, AlertMessageInfo, AlertType } from './alert';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;

    clearTimer: ReturnType<typeof setTimeout>;
    clearErrorTimer: ReturnType<typeof setTimeout>;
    errorTimer: ReturnType<typeof setTimeout>;
    errorCanBeRemoved = false;

    constructor(
        router: Router
    ) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear(true);
                }
            }
        });
    }

    getAlert(): Observable<Alert> {
        return this.subject.asObservable();
    }

    success(message: string, msgObj?: string | AlertMessageInfo, keepAfterRouteChange = false, clear = true): void {
        this.alert(AlertType.Success, message, msgObj, keepAfterRouteChange);
        if (clear) {
            this.clearTimer = setTimeout(() => {
                this.clear(this.errorCanBeRemoved);
            }, 4000);
        }
    }

    error(message: string, msgObj?: string | AlertMessageInfo, keepAfterRouteChange = false): void {
        this.clear(true);
        this.alert(AlertType.Error, message, msgObj, keepAfterRouteChange);
        this.clearErrorTimer = setTimeout(() => {
            this.clear(true);
        }, 15000);
        this.errorCanBeRemoved = false;
        if (!this.errorTimer) {
            this.errorTimer = setTimeout(() => {
                this.errorCanBeRemoved = true;
                this.errorTimer = undefined;
            }, 3000);
        }
    }

    info(message: string, msgObj?: string | AlertMessageInfo, keepAfterRouteChange = false): void {
        this.alert(AlertType.Info, message, msgObj, keepAfterRouteChange);
        this.clearTimer = setTimeout(() => {
            this.clear(this.errorCanBeRemoved);
        }, 15000);
    }

    warn(message: string, msgObj?: string | AlertMessageInfo, keepAfterRouteChange = false): void {
        this.alert(AlertType.Warning, message, msgObj, keepAfterRouteChange);
        this.clearTimer = setTimeout(() => {
            this.clear(this.errorCanBeRemoved);
        }, 15000);
    }

    private alert(type: AlertType, alertMessage: string, msgObj?: string | AlertMessageInfo, keepAfterRouteChange = false): void {
        if (this.clearTimer) {
            clearTimeout(this.clearTimer);
            this.clearTimer = undefined;
        }
        if (this.errorTimer) {
            clearTimeout(this.errorTimer);
            this.errorTimer = undefined;
        }
        // setTimeout(() => {
            this.clear();
            this.keepAfterRouteChange = keepAfterRouteChange;
            let link: string;
            let linkText: string;
            let msgText = '';
            if (typeof msgObj === 'string') {
                msgText = msgObj;
            } else {
                if (msgObj?.error) {
                    const obj = msgObj.error;
                    if (obj instanceof ProgressEvent) {
                        msgText = 'Could not reach server.';
                    } else if (typeof obj !== 'string' && obj?.error) {
                        msgText = obj.error;
                    } else if (typeof obj !== 'string' && obj?.message) {
                        msgText = obj.message;
                    }
                } else if (msgObj?.message && msgObj.link && msgObj.linkText) {
                    link = msgObj.link;
                    linkText = msgObj.linkText;
                    msgText = msgObj.message;
                } else if (msgObj?.message) {
                    msgText = msgObj.message;
                }
            }
            this.subject.next(<Alert>{ type, message: alertMessage + (msgText ? (': ' + msgText) : ''), link, linkText });
        // });
    }

    clear(alsoErrors = false): void {
        // clear alerts
        this.subject.next(<Alert>{ type: alsoErrors ? AlertType.ClearAlsoErrors : AlertType.Clear });
    }

    removeAlert(): void {
        // if (this.clearTimer) {
        //     clearTimeout(this.clearTimer);
        //     this.clearTimer = undefined;
        // }
        if (this.errorTimer) {
            clearTimeout(this.errorTimer);
            this.errorTimer = undefined;
        }
    }
}

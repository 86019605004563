// source: beans.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var location_pb = require('./location_pb.js');
goog.object.extend(proto, location_pb);
var producer_pb = require('./producer_pb.js');
goog.object.extend(proto, producer_pb);
goog.exportSymbol('proto.artisanplus.APBeans', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Ageing', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Altitude', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Certification', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.CoffeeUnit', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.CropDate', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Cultivation', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Decaffeination', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.DefectsUnit', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Drying', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Harvesting', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.ICO', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Month', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Origin', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Packaging', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.PackagingUnit', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Processing', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.ScreenSize', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Selection', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.Species', null, global);
goog.exportSymbol('proto.artisanplus.APBeans.YearMonth', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.artisanplus.APBeans = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.artisanplus.APBeans.repeatedFields_, null);
};
goog.inherits(proto.artisanplus.APBeans, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.artisanplus.APBeans.displayName = 'proto.artisanplus.APBeans';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.artisanplus.APBeans.CoffeeUnit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.artisanplus.APBeans.CoffeeUnit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.artisanplus.APBeans.CoffeeUnit.displayName = 'proto.artisanplus.APBeans.CoffeeUnit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.artisanplus.APBeans.ScreenSize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.artisanplus.APBeans.ScreenSize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.artisanplus.APBeans.ScreenSize.displayName = 'proto.artisanplus.APBeans.ScreenSize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.artisanplus.APBeans.YearMonth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.artisanplus.APBeans.YearMonth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.artisanplus.APBeans.YearMonth.displayName = 'proto.artisanplus.APBeans.YearMonth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.artisanplus.APBeans.CropDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.artisanplus.APBeans.CropDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.artisanplus.APBeans.CropDate.displayName = 'proto.artisanplus.APBeans.CropDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.artisanplus.APBeans.ICO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.artisanplus.APBeans.ICO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.artisanplus.APBeans.ICO.displayName = 'proto.artisanplus.APBeans.ICO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.artisanplus.APBeans.Altitude = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.artisanplus.APBeans.Altitude, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.artisanplus.APBeans.Altitude.displayName = 'proto.artisanplus.APBeans.Altitude';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.artisanplus.APBeans.repeatedFields_ = [4,10,18,33];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.artisanplus.APBeans.prototype.toObject = function(opt_includeInstance) {
  return proto.artisanplus.APBeans.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.artisanplus.APBeans} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    origin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    regionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    cropDate: (f = msg.getCropDate()) && proto.artisanplus.APBeans.CropDate.toObject(includeInstance, f),
    lot: jspb.Message.getFieldWithDefault(msg, 6, ""),
    reference: jspb.Message.getFieldWithDefault(msg, 7, ""),
    location: (f = msg.getLocation()) && location_pb.APLocation.toObject(includeInstance, f),
    producer: (f = msg.getProducer()) && producer_pb.APProducer.toObject(includeInstance, f),
    certificationsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    defaultUnit: (f = msg.getDefaultUnit()) && proto.artisanplus.APBeans.CoffeeUnit.toObject(includeInstance, f),
    ico: (f = msg.getIco()) && proto.artisanplus.APBeans.ICO.toObject(includeInstance, f),
    grade: jspb.Message.getFieldWithDefault(msg, 13, ""),
    altitude: (f = msg.getAltitude()) && proto.artisanplus.APBeans.Altitude.toObject(includeInstance, f),
    screenSize: (f = msg.getScreenSize()) && proto.artisanplus.APBeans.ScreenSize.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    species: jspb.Message.getFieldWithDefault(msg, 17, 0),
    varietalsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    cultivation: jspb.Message.getFieldWithDefault(msg, 19, 0),
    harvesting: jspb.Message.getFieldWithDefault(msg, 20, 0),
    processing: jspb.Message.getFieldWithDefault(msg, 21, 0),
    fermentation: jspb.Message.getFieldWithDefault(msg, 22, ""),
    drying: jspb.Message.getFieldWithDefault(msg, 23, 0),
    decaffeination: jspb.Message.getFieldWithDefault(msg, 24, 0),
    ageing: jspb.Message.getFieldWithDefault(msg, 25, 0),
    packaging: jspb.Message.getFieldWithDefault(msg, 26, 0),
    selection: jspb.Message.getFieldWithDefault(msg, 27, ""),
    density: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    moisture: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    waterActivity: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    defects: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    defectsUnit: jspb.Message.getFieldWithDefault(msg, 32, 0),
    flavorsList: (f = jspb.Message.getRepeatedField(msg, 33)) == null ? undefined : f,
    cropYield: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.artisanplus.APBeans}
 */
proto.artisanplus.APBeans.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.artisanplus.APBeans;
  return proto.artisanplus.APBeans.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.artisanplus.APBeans} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.artisanplus.APBeans}
 */
proto.artisanplus.APBeans.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {!proto.artisanplus.APBeans.Origin} */ (reader.readEnum());
      msg.setOrigin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addRegions(value);
      break;
    case 5:
      var value = new proto.artisanplus.APBeans.CropDate;
      reader.readMessage(value,proto.artisanplus.APBeans.CropDate.deserializeBinaryFromReader);
      msg.setCropDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLot(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 8:
      var value = new location_pb.APLocation;
      reader.readMessage(value,location_pb.APLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 9:
      var value = new producer_pb.APProducer;
      reader.readMessage(value,producer_pb.APProducer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.artisanplus.APBeans.Certification>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCertifications(values[i]);
      }
      break;
    case 11:
      var value = new proto.artisanplus.APBeans.CoffeeUnit;
      reader.readMessage(value,proto.artisanplus.APBeans.CoffeeUnit.deserializeBinaryFromReader);
      msg.setDefaultUnit(value);
      break;
    case 12:
      var value = new proto.artisanplus.APBeans.ICO;
      reader.readMessage(value,proto.artisanplus.APBeans.ICO.deserializeBinaryFromReader);
      msg.setIco(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrade(value);
      break;
    case 14:
      var value = new proto.artisanplus.APBeans.Altitude;
      reader.readMessage(value,proto.artisanplus.APBeans.Altitude.deserializeBinaryFromReader);
      msg.setAltitude(value);
      break;
    case 15:
      var value = new proto.artisanplus.APBeans.ScreenSize;
      reader.readMessage(value,proto.artisanplus.APBeans.ScreenSize.deserializeBinaryFromReader);
      msg.setScreenSize(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 17:
      var value = /** @type {!proto.artisanplus.APBeans.Species} */ (reader.readEnum());
      msg.setSpecies(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addVarietals(value);
      break;
    case 19:
      var value = /** @type {!proto.artisanplus.APBeans.Cultivation} */ (reader.readEnum());
      msg.setCultivation(value);
      break;
    case 20:
      var value = /** @type {!proto.artisanplus.APBeans.Harvesting} */ (reader.readEnum());
      msg.setHarvesting(value);
      break;
    case 21:
      var value = /** @type {!proto.artisanplus.APBeans.Processing} */ (reader.readEnum());
      msg.setProcessing(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setFermentation(value);
      break;
    case 23:
      var value = /** @type {!proto.artisanplus.APBeans.Drying} */ (reader.readEnum());
      msg.setDrying(value);
      break;
    case 24:
      var value = /** @type {!proto.artisanplus.APBeans.Decaffeination} */ (reader.readEnum());
      msg.setDecaffeination(value);
      break;
    case 25:
      var value = /** @type {!proto.artisanplus.APBeans.Ageing} */ (reader.readEnum());
      msg.setAgeing(value);
      break;
    case 26:
      var value = /** @type {!proto.artisanplus.APBeans.Packaging} */ (reader.readEnum());
      msg.setPackaging(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelection(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDensity(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMoisture(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWaterActivity(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDefects(value);
      break;
    case 32:
      var value = /** @type {!proto.artisanplus.APBeans.DefectsUnit} */ (reader.readEnum());
      msg.setDefectsUnit(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.addFlavors(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCropYield(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.artisanplus.APBeans.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.artisanplus.APBeans.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.artisanplus.APBeans} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRegionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getCropDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.artisanplus.APBeans.CropDate.serializeBinaryToWriter
    );
  }
  f = message.getLot();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      location_pb.APLocation.serializeBinaryToWriter
    );
  }
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      producer_pb.APProducer.serializeBinaryToWriter
    );
  }
  f = message.getCertificationsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getDefaultUnit();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.artisanplus.APBeans.CoffeeUnit.serializeBinaryToWriter
    );
  }
  f = message.getIco();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.artisanplus.APBeans.ICO.serializeBinaryToWriter
    );
  }
  f = message.getGrade();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAltitude();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.artisanplus.APBeans.Altitude.serializeBinaryToWriter
    );
  }
  f = message.getScreenSize();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.artisanplus.APBeans.ScreenSize.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getSpecies();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getVarietalsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getCultivation();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getHarvesting();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getProcessing();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getFermentation();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getDrying();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getDecaffeination();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getAgeing();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getPackaging();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getSelection();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getDensity();
  if (f !== 0.0) {
    writer.writeFloat(
      28,
      f
    );
  }
  f = message.getMoisture();
  if (f !== 0.0) {
    writer.writeFloat(
      29,
      f
    );
  }
  f = message.getWaterActivity();
  if (f !== 0.0) {
    writer.writeFloat(
      30,
      f
    );
  }
  f = message.getDefects();
  if (f !== 0.0) {
    writer.writeFloat(
      31,
      f
    );
  }
  f = message.getDefectsUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      32,
      f
    );
  }
  f = message.getFlavorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      33,
      f
    );
  }
  f = message.getCropYield();
  if (f !== 0.0) {
    writer.writeFloat(
      34,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.artisanplus.APBeans.PackagingUnit = {
  PACKAGING_UNIT_UNSPECIFIED: 0,
  PACKAGING_UNIT_NONE: 1,
  PACKAGING_UNIT_BAG: 2,
  PACKAGING_UNIT_BARREL: 3,
  PACKAGING_UNIT_BOX: 4
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.DefectsUnit = {
  DEFECTS_UNIT_UNSPECIFIED: 0,
  DEFECTS_UNIT_300G: 1,
  DEFECTS_UNIT_350G: 2,
  DEFECTS_UNIT_500G: 3
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Species = {
  SPECIES_UNSPECIFIED: 0,
  SPECIES_ARABICA: 1,
  SPECIES_CACAO_THEOBROMA: 2,
  SPECIES_CHARRIERIANA: 3,
  SPECIES_CONGENSIS: 4,
  SPECIES_LANCIFOLIA: 5,
  SPECIES_LIBERICA: 6,
  SPECIES_MAURITANIA: 7,
  SPECIES_RACEMOSA: 8,
  SPECIES_ROBUSTA_COFFEA_CANEPHORA: 9,
  SPECIES_STENOPHYLLA: 10
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Cultivation = {
  CULTIVATION_UNSPECIFIED: 0,
  CULTIVATION_SUN_GROWN_PLANTATION: 1,
  CULTIVATION_SUN_GROWN_GARDEN: 2,
  CULTIVATION_SHADE_GROWN_FOREST: 3,
  CULTIVATION_SHADE_GROWN_GARDEN: 4,
  CULTIVATION_SHADE_GROWN_WILD_FOREST: 5
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Processing = {
  PROCESSING_UNSPECIFIED: 0,
  PROCESSING_WET_DOUBLE_WASHED: 1,
  PROCESSING_WET_FULLY_WASHED: 2,
  PROCESSING_WET_FARMER_WASHED: 3,
  PROCESSING_HYBRID_SEMI_WASHED: 4,
  PROCESSING_HYBRID_TERMICO: 5,
  PROCESSING_HYBRID_WET_HULLED: 6,
  PROCESSING_HYBRID_GILLING_BASAH: 7,
  PROCESSING_HYBRID_HONEY: 8,
  PROCESSING_HYBRID_WHITE_HONEY: 9,
  PROCESSING_HYBRID_YELLOW_HONEY: 10,
  PROCESSING_HYBRID_GOLD_HONEY: 11,
  PROCESSING_HYBRID_RED_HONEY: 12,
  PROCESSING_HYBRID_BLACK_HONEY: 13,
  PROCESSING_HYBRID_PULPED_NATURAL: 14,
  PROCESSING_DRY_NATURAL: 15
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Ageing = {
  AGEING_UNSPECIFIED: 0,
  AGEING_BARREL_AGED: 1,
  AGEING_MONSOONED: 2,
  AGEING_WAREHOUSE_AGED: 3,
  AGEING_AGED: 4
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Decaffeination = {
  DECAFFEINATION_UNSPECIFIED: 0,
  DECAFFEINATION_ORGANIC_SOLVENT_NATURAL_DECAFFEINATION_PROCESS_EA: 1,
  DECAFFEINATION_ORGANIC_SOLVENT_METHYLENE_CHLORIDE_PROCESS: 2,
  DECAFFEINATION_ORGANIC_SOLVENT_TRIGLYCERIDE_PROCESS: 3,
  DECAFFEINATION_WATER_FILTERED_SWISS_WATER_PROCESS: 4,
  DECAFFEINATION_WATER_FILTERED_MOUNTAIN_WATER_PROCESS: 5,
  DECAFFEINATION_SUPERCRITICAL_FLUID_CARBON_DIOXIDE_PROCESS: 6,
  DECAFFEINATION_OTHERS_DECAFFEINATED: 7
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Drying = {
  DRYING_UNSPECIFIED: 0,
  DRYING_SUN_DRIED: 1,
  DRYING_ON_PATIO: 2,
  DRYING_RAISED_BEDS: 3,
  DRYING_PARABOLIC: 4,
  DRYING_MECHANICAL_DRYER: 5
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Harvesting = {
  HARVESTING_UNSPECIFIED: 0,
  HARVESTING_SELECTIVE_PICKING: 1,
  HARVESTING_MECHANICAL_PICKING: 2,
  HARVESTING_MANUAL_STRIPPING: 3,
  HARVESTING_MECHANICAL_STRIPPING: 4
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Packaging = {
  PACKAGING_UNSPECIFIED: 0,
  PACKAGING_GRAINPRO_ECOTACT_SC: 1,
  PACKAGING_JUTE_SISAL: 2,
  PACKAGING_PAPER: 3,
  PACKAGING_POLYPROPYLENE: 4,
  PACKAGING_VACUUM_SEALED: 5
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Selection = {
  SELECTION_UNSPECIFIED: 0,
  SELECTION_PEABERRY: 1,
  SELECTION_TRIAGE: 2,
  SELECTION_EUROPEAN_PREPARATION: 3,
  SELECTION_AMERICAN_PREPARATION: 4
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Origin = {
  ORIGIN_UNSPECIFIED: 0,
  ORIGIN_ANGOLA: 1,
  ORIGIN_BENIN: 2,
  ORIGIN_BURUNDI: 3,
  ORIGIN_CAMEROON: 4,
  ORIGIN_CAPE_VERDE: 5,
  ORIGIN_CENTRAL_AFRICAN_REPUBLIC: 6,
  ORIGIN_COMOROS: 7,
  ORIGIN_CONGO_DR: 8,
  ORIGIN_CONGO_REPUBLIC: 9,
  ORIGIN_EQUATORIAL_GUINEA: 10,
  ORIGIN_ERITREA: 11,
  ORIGIN_ETHIOPIA: 12,
  ORIGIN_GABON: 13,
  ORIGIN_GHANA: 14,
  ORIGIN_GUINEA: 15,
  ORIGIN_IVORY_COAST: 16,
  ORIGIN_KENYA: 17,
  ORIGIN_LIBERIA: 18,
  ORIGIN_MADAGASCAR: 19,
  ORIGIN_MALAWI: 20,
  ORIGIN_MAURITIUS: 21,
  ORIGIN_MOZAMBIQUE: 22,
  ORIGIN_NIGERIA: 23,
  ORIGIN_REUNION: 24,
  ORIGIN_RWANDA: 25,
  ORIGIN_SEYCHELLES: 26,
  ORIGIN_SIERRA_LEONE: 27,
  ORIGIN_SOUTH_AFRICA: 28,
  ORIGIN_ST_HELENA: 29,
  ORIGIN_SAO_TOME: 30,
  ORIGIN_SUDAN: 31,
  ORIGIN_TANZANIA: 32,
  ORIGIN_TOGO: 33,
  ORIGIN_TRINIDAD_AND_TOBAGO: 34,
  ORIGIN_UGANDA: 35,
  ORIGIN_ZAMBIA: 36,
  ORIGIN_ZIMBABWE: 37,
  ORIGIN_ANGUILLA: 38,
  ORIGIN_CUBA: 39,
  ORIGIN_DOMINICA: 40,
  ORIGIN_DOMINICAN_REPUBLIC: 41,
  ORIGIN_GUADELOUPE: 42,
  ORIGIN_HAITI: 43,
  ORIGIN_JAMAICA: 44,
  ORIGIN_PUERTO_RICO: 45,
  ORIGIN_BELIZE: 46,
  ORIGIN_COSTA_RICA: 47,
  ORIGIN_EL_SALVADOR: 48,
  ORIGIN_GUATEMALA: 49,
  ORIGIN_HONDURAS: 50,
  ORIGIN_MEXICO: 51,
  ORIGIN_NICARAGUA: 52,
  ORIGIN_PANAMA: 53,
  ORIGIN_CANARY_ISLANDS: 54,
  ORIGIN_BOLIVIA: 55,
  ORIGIN_BRAZIL: 56,
  ORIGIN_CHILE: 57,
  ORIGIN_COLOMBIA: 58,
  ORIGIN_ECUADOR: 59,
  ORIGIN_FRENCH_GUIANA: 60,
  ORIGIN_GUYANA: 61,
  ORIGIN_MARTINIQUE: 62,
  ORIGIN_GALAPAGOS: 63,
  ORIGIN_PARAGUAY: 64,
  ORIGIN_PERU: 65,
  ORIGIN_ST_VINCENT: 66,
  ORIGIN_SURINAME: 67,
  ORIGIN_VENEZUELA: 68,
  ORIGIN_ANI_INDIA: 69,
  ORIGIN_INDONESIA: 70,
  ORIGIN_BALI: 71,
  ORIGIN_BORNEO: 72,
  ORIGIN_CAMBODIA: 73,
  ORIGIN_CHINA: 74,
  ORIGIN_FLORES: 75,
  ORIGIN_INDIA: 76,
  ORIGIN_JAVA: 77,
  ORIGIN_LAOS: 78,
  ORIGIN_MALAYSIA: 79,
  ORIGIN_MYANMAR: 80,
  ORIGIN_NEPAL: 81,
  ORIGIN_OMAN: 82,
  ORIGIN_PAPUA: 83,
  ORIGIN_PHILIPPINES: 84,
  ORIGIN_SRI_LANKA: 85,
  ORIGIN_ST_LUCIA: 86,
  ORIGIN_SULAWESI: 87,
  ORIGIN_SUMATRA: 88,
  ORIGIN_SUMBAWA: 89,
  ORIGIN_TIMOR: 90,
  ORIGIN_THAILAND: 91,
  ORIGIN_TIMOR_EAST: 92,
  ORIGIN_VIETNAM: 93,
  ORIGIN_YEMEN: 94,
  ORIGIN_AUSTRALIA: 95,
  ORIGIN_COOK_ISLANDS: 96,
  ORIGIN_FIJI: 97,
  ORIGIN_FRENCH_POLYNESIA: 98,
  ORIGIN_HAWAII: 99,
  ORIGIN_NEW_CALEDONIA: 100,
  ORIGIN_PNG: 101,
  ORIGIN_SAMOA: 102,
  ORIGIN_TONGA: 103,
  ORIGIN_VANUATU: 104
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Certification = {
  CERTIFICATION_UNSPECIFIED: 0,
  CERTIFICATION_SMBC: 1,
  CERTIFICATION_USDA_ORGANIC: 2,
  CERTIFICATION_GCP: 3,
  CERTIFICATION_ACO: 4,
  CERTIFICATION_NASAA: 5,
  CERTIFICATION_COR: 6,
  CERTIFICATION_CCOF_ORGANIC: 7,
  CERTIFICATION_COE: 8,
  CERTIFICATION_GLOBALGAP: 9,
  CERTIFICATION_BIO: 10,
  CERTIFICATION_EU_ORGANIC: 11,
  CERTIFICATION_BIOLAND: 12,
  CERTIFICATION_DEMETER: 13,
  CERTIFICATION_FFF: 14,
  CERTIFICATION_FAIRPLUS: 15,
  CERTIFICATION_NATURLAND: 16,
  CERTIFICATION_NATURLAND_FAIR: 17,
  CERTIFICATION_AB: 18,
  CERTIFICATION_ORGANIC_JAS: 19,
  CERTIFICATION_OCIA: 20,
  CERTIFICATION_RFA: 21,
  CERTIFICATION_FAIRTRADE: 22,
  CERTIFICATION_UTZ: 23,
  CERTIFICATION_4C: 24,
  CERTIFICATION_BIO_SUISSE: 25,
  CERTIFICATION_DEBIO: 26,
  CERTIFICATION_KRAV: 27,
  CERTIFICATION_FAIR_TRADE_USA: 28,
  CERTIFICATION_CMC: 29,
  CERTIFICATION_EOS: 30
};

/**
 * @enum {number}
 */
proto.artisanplus.APBeans.Month = {
  MONTH_UNSPECIFIED: 0,
  MONTH_JANUARY: 1,
  MONTH_FEBRUARY: 2,
  MONTH_MARCH: 3,
  MONTH_APRIL: 4,
  MONTH_MAY: 5,
  MONTH_JUNE: 6,
  MONTH_JULY: 7,
  MONTH_AUGUST: 8,
  MONTH_SEPTEMBER: 9,
  MONTH_OCTOBER: 10,
  MONTH_NOVEMBER: 11,
  MONTH_DECEMBER: 12
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.artisanplus.APBeans.CoffeeUnit.prototype.toObject = function(opt_includeInstance) {
  return proto.artisanplus.APBeans.CoffeeUnit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.artisanplus.APBeans.CoffeeUnit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.CoffeeUnit.toObject = function(includeInstance, msg) {
  var f, obj = {
    packagingUnit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    weightPerUnit: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.artisanplus.APBeans.CoffeeUnit}
 */
proto.artisanplus.APBeans.CoffeeUnit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.artisanplus.APBeans.CoffeeUnit;
  return proto.artisanplus.APBeans.CoffeeUnit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.artisanplus.APBeans.CoffeeUnit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.artisanplus.APBeans.CoffeeUnit}
 */
proto.artisanplus.APBeans.CoffeeUnit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.artisanplus.APBeans.PackagingUnit} */ (reader.readEnum());
      msg.setPackagingUnit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightPerUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.artisanplus.APBeans.CoffeeUnit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.artisanplus.APBeans.CoffeeUnit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.artisanplus.APBeans.CoffeeUnit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.CoffeeUnit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackagingUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getWeightPerUnit();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional PackagingUnit packaging_unit = 1;
 * @return {!proto.artisanplus.APBeans.PackagingUnit}
 */
proto.artisanplus.APBeans.CoffeeUnit.prototype.getPackagingUnit = function() {
  return /** @type {!proto.artisanplus.APBeans.PackagingUnit} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.PackagingUnit} value
 * @return {!proto.artisanplus.APBeans.CoffeeUnit} returns this
 */
proto.artisanplus.APBeans.CoffeeUnit.prototype.setPackagingUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float weight_per_unit = 2;
 * @return {number}
 */
proto.artisanplus.APBeans.CoffeeUnit.prototype.getWeightPerUnit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans.CoffeeUnit} returns this
 */
proto.artisanplus.APBeans.CoffeeUnit.prototype.setWeightPerUnit = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.artisanplus.APBeans.ScreenSize.prototype.toObject = function(opt_includeInstance) {
  return proto.artisanplus.APBeans.ScreenSize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.artisanplus.APBeans.ScreenSize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.ScreenSize.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFieldWithDefault(msg, 1, 0),
    max: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.artisanplus.APBeans.ScreenSize}
 */
proto.artisanplus.APBeans.ScreenSize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.artisanplus.APBeans.ScreenSize;
  return proto.artisanplus.APBeans.ScreenSize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.artisanplus.APBeans.ScreenSize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.artisanplus.APBeans.ScreenSize}
 */
proto.artisanplus.APBeans.ScreenSize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.artisanplus.APBeans.ScreenSize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.artisanplus.APBeans.ScreenSize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.artisanplus.APBeans.ScreenSize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.ScreenSize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 min = 1;
 * @return {number}
 */
proto.artisanplus.APBeans.ScreenSize.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans.ScreenSize} returns this
 */
proto.artisanplus.APBeans.ScreenSize.prototype.setMin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max = 2;
 * @return {number}
 */
proto.artisanplus.APBeans.ScreenSize.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans.ScreenSize} returns this
 */
proto.artisanplus.APBeans.ScreenSize.prototype.setMax = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.artisanplus.APBeans.YearMonth.prototype.toObject = function(opt_includeInstance) {
  return proto.artisanplus.APBeans.YearMonth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.artisanplus.APBeans.YearMonth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.YearMonth.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.artisanplus.APBeans.YearMonth}
 */
proto.artisanplus.APBeans.YearMonth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.artisanplus.APBeans.YearMonth;
  return proto.artisanplus.APBeans.YearMonth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.artisanplus.APBeans.YearMonth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.artisanplus.APBeans.YearMonth}
 */
proto.artisanplus.APBeans.YearMonth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {!proto.artisanplus.APBeans.Month} */ (reader.readEnum());
      msg.setMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.artisanplus.APBeans.YearMonth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.artisanplus.APBeans.YearMonth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.artisanplus.APBeans.YearMonth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.YearMonth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional uint32 year = 1;
 * @return {number}
 */
proto.artisanplus.APBeans.YearMonth.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans.YearMonth} returns this
 */
proto.artisanplus.APBeans.YearMonth.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Month month = 2;
 * @return {!proto.artisanplus.APBeans.Month}
 */
proto.artisanplus.APBeans.YearMonth.prototype.getMonth = function() {
  return /** @type {!proto.artisanplus.APBeans.Month} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.Month} value
 * @return {!proto.artisanplus.APBeans.YearMonth} returns this
 */
proto.artisanplus.APBeans.YearMonth.prototype.setMonth = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.artisanplus.APBeans.CropDate.prototype.toObject = function(opt_includeInstance) {
  return proto.artisanplus.APBeans.CropDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.artisanplus.APBeans.CropDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.CropDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    picked: (f = msg.getPicked()) && proto.artisanplus.APBeans.YearMonth.toObject(includeInstance, f),
    landed: (f = msg.getLanded()) && proto.artisanplus.APBeans.YearMonth.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.artisanplus.APBeans.CropDate}
 */
proto.artisanplus.APBeans.CropDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.artisanplus.APBeans.CropDate;
  return proto.artisanplus.APBeans.CropDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.artisanplus.APBeans.CropDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.artisanplus.APBeans.CropDate}
 */
proto.artisanplus.APBeans.CropDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.artisanplus.APBeans.YearMonth;
      reader.readMessage(value,proto.artisanplus.APBeans.YearMonth.deserializeBinaryFromReader);
      msg.setPicked(value);
      break;
    case 2:
      var value = new proto.artisanplus.APBeans.YearMonth;
      reader.readMessage(value,proto.artisanplus.APBeans.YearMonth.deserializeBinaryFromReader);
      msg.setLanded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.artisanplus.APBeans.CropDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.artisanplus.APBeans.CropDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.artisanplus.APBeans.CropDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.CropDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPicked();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.artisanplus.APBeans.YearMonth.serializeBinaryToWriter
    );
  }
  f = message.getLanded();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.artisanplus.APBeans.YearMonth.serializeBinaryToWriter
    );
  }
};


/**
 * optional YearMonth picked = 1;
 * @return {?proto.artisanplus.APBeans.YearMonth}
 */
proto.artisanplus.APBeans.CropDate.prototype.getPicked = function() {
  return /** @type{?proto.artisanplus.APBeans.YearMonth} */ (
    jspb.Message.getWrapperField(this, proto.artisanplus.APBeans.YearMonth, 1));
};


/**
 * @param {?proto.artisanplus.APBeans.YearMonth|undefined} value
 * @return {!proto.artisanplus.APBeans.CropDate} returns this
*/
proto.artisanplus.APBeans.CropDate.prototype.setPicked = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.artisanplus.APBeans.CropDate} returns this
 */
proto.artisanplus.APBeans.CropDate.prototype.clearPicked = function() {
  return this.setPicked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.artisanplus.APBeans.CropDate.prototype.hasPicked = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional YearMonth landed = 2;
 * @return {?proto.artisanplus.APBeans.YearMonth}
 */
proto.artisanplus.APBeans.CropDate.prototype.getLanded = function() {
  return /** @type{?proto.artisanplus.APBeans.YearMonth} */ (
    jspb.Message.getWrapperField(this, proto.artisanplus.APBeans.YearMonth, 2));
};


/**
 * @param {?proto.artisanplus.APBeans.YearMonth|undefined} value
 * @return {!proto.artisanplus.APBeans.CropDate} returns this
*/
proto.artisanplus.APBeans.CropDate.prototype.setLanded = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.artisanplus.APBeans.CropDate} returns this
 */
proto.artisanplus.APBeans.CropDate.prototype.clearLanded = function() {
  return this.setLanded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.artisanplus.APBeans.CropDate.prototype.hasLanded = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.artisanplus.APBeans.ICO.prototype.toObject = function(opt_includeInstance) {
  return proto.artisanplus.APBeans.ICO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.artisanplus.APBeans.ICO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.ICO.toObject = function(includeInstance, msg) {
  var f, obj = {
    origin: jspb.Message.getFieldWithDefault(msg, 1, 0),
    exporter: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lot: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.artisanplus.APBeans.ICO}
 */
proto.artisanplus.APBeans.ICO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.artisanplus.APBeans.ICO;
  return proto.artisanplus.APBeans.ICO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.artisanplus.APBeans.ICO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.artisanplus.APBeans.ICO}
 */
proto.artisanplus.APBeans.ICO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrigin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExporter(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.artisanplus.APBeans.ICO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.artisanplus.APBeans.ICO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.artisanplus.APBeans.ICO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.ICO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrigin();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExporter();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLot();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 origin = 1;
 * @return {number}
 */
proto.artisanplus.APBeans.ICO.prototype.getOrigin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans.ICO} returns this
 */
proto.artisanplus.APBeans.ICO.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 exporter = 2;
 * @return {number}
 */
proto.artisanplus.APBeans.ICO.prototype.getExporter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans.ICO} returns this
 */
proto.artisanplus.APBeans.ICO.prototype.setExporter = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 lot = 3;
 * @return {number}
 */
proto.artisanplus.APBeans.ICO.prototype.getLot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans.ICO} returns this
 */
proto.artisanplus.APBeans.ICO.prototype.setLot = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.artisanplus.APBeans.Altitude.prototype.toObject = function(opt_includeInstance) {
  return proto.artisanplus.APBeans.Altitude.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.artisanplus.APBeans.Altitude} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.Altitude.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFieldWithDefault(msg, 1, 0),
    max: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.artisanplus.APBeans.Altitude}
 */
proto.artisanplus.APBeans.Altitude.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.artisanplus.APBeans.Altitude;
  return proto.artisanplus.APBeans.Altitude.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.artisanplus.APBeans.Altitude} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.artisanplus.APBeans.Altitude}
 */
proto.artisanplus.APBeans.Altitude.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.artisanplus.APBeans.Altitude.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.artisanplus.APBeans.Altitude.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.artisanplus.APBeans.Altitude} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.artisanplus.APBeans.Altitude.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 min = 1;
 * @return {number}
 */
proto.artisanplus.APBeans.Altitude.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans.Altitude} returns this
 */
proto.artisanplus.APBeans.Altitude.prototype.setMin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max = 2;
 * @return {number}
 */
proto.artisanplus.APBeans.Altitude.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans.Altitude} returns this
 */
proto.artisanplus.APBeans.Altitude.prototype.setMax = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.artisanplus.APBeans.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Origin origin = 3;
 * @return {!proto.artisanplus.APBeans.Origin}
 */
proto.artisanplus.APBeans.prototype.getOrigin = function() {
  return /** @type {!proto.artisanplus.APBeans.Origin} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.Origin} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string regions = 4;
 * @return {!Array<string>}
 */
proto.artisanplus.APBeans.prototype.getRegionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setRegionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.addRegions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.clearRegionsList = function() {
  return this.setRegionsList([]);
};


/**
 * optional CropDate crop_date = 5;
 * @return {?proto.artisanplus.APBeans.CropDate}
 */
proto.artisanplus.APBeans.prototype.getCropDate = function() {
  return /** @type{?proto.artisanplus.APBeans.CropDate} */ (
    jspb.Message.getWrapperField(this, proto.artisanplus.APBeans.CropDate, 5));
};


/**
 * @param {?proto.artisanplus.APBeans.CropDate|undefined} value
 * @return {!proto.artisanplus.APBeans} returns this
*/
proto.artisanplus.APBeans.prototype.setCropDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.clearCropDate = function() {
  return this.setCropDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.artisanplus.APBeans.prototype.hasCropDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string lot = 6;
 * @return {string}
 */
proto.artisanplus.APBeans.prototype.getLot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setLot = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string reference = 7;
 * @return {string}
 */
proto.artisanplus.APBeans.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional APLocation location = 8;
 * @return {?proto.artisanplus.APLocation}
 */
proto.artisanplus.APBeans.prototype.getLocation = function() {
  return /** @type{?proto.artisanplus.APLocation} */ (
    jspb.Message.getWrapperField(this, location_pb.APLocation, 8));
};


/**
 * @param {?proto.artisanplus.APLocation|undefined} value
 * @return {!proto.artisanplus.APBeans} returns this
*/
proto.artisanplus.APBeans.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.artisanplus.APBeans.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional APProducer producer = 9;
 * @return {?proto.artisanplus.APProducer}
 */
proto.artisanplus.APBeans.prototype.getProducer = function() {
  return /** @type{?proto.artisanplus.APProducer} */ (
    jspb.Message.getWrapperField(this, producer_pb.APProducer, 9));
};


/**
 * @param {?proto.artisanplus.APProducer|undefined} value
 * @return {!proto.artisanplus.APBeans} returns this
*/
proto.artisanplus.APBeans.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.artisanplus.APBeans.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated Certification certifications = 10;
 * @return {!Array<!proto.artisanplus.APBeans.Certification>}
 */
proto.artisanplus.APBeans.prototype.getCertificationsList = function() {
  return /** @type {!Array<!proto.artisanplus.APBeans.Certification>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.artisanplus.APBeans.Certification>} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setCertificationsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.artisanplus.APBeans.Certification} value
 * @param {number=} opt_index
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.addCertifications = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.clearCertificationsList = function() {
  return this.setCertificationsList([]);
};


/**
 * optional CoffeeUnit default_unit = 11;
 * @return {?proto.artisanplus.APBeans.CoffeeUnit}
 */
proto.artisanplus.APBeans.prototype.getDefaultUnit = function() {
  return /** @type{?proto.artisanplus.APBeans.CoffeeUnit} */ (
    jspb.Message.getWrapperField(this, proto.artisanplus.APBeans.CoffeeUnit, 11));
};


/**
 * @param {?proto.artisanplus.APBeans.CoffeeUnit|undefined} value
 * @return {!proto.artisanplus.APBeans} returns this
*/
proto.artisanplus.APBeans.prototype.setDefaultUnit = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.clearDefaultUnit = function() {
  return this.setDefaultUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.artisanplus.APBeans.prototype.hasDefaultUnit = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ICO ico = 12;
 * @return {?proto.artisanplus.APBeans.ICO}
 */
proto.artisanplus.APBeans.prototype.getIco = function() {
  return /** @type{?proto.artisanplus.APBeans.ICO} */ (
    jspb.Message.getWrapperField(this, proto.artisanplus.APBeans.ICO, 12));
};


/**
 * @param {?proto.artisanplus.APBeans.ICO|undefined} value
 * @return {!proto.artisanplus.APBeans} returns this
*/
proto.artisanplus.APBeans.prototype.setIco = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.clearIco = function() {
  return this.setIco(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.artisanplus.APBeans.prototype.hasIco = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string grade = 13;
 * @return {string}
 */
proto.artisanplus.APBeans.prototype.getGrade = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setGrade = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional Altitude altitude = 14;
 * @return {?proto.artisanplus.APBeans.Altitude}
 */
proto.artisanplus.APBeans.prototype.getAltitude = function() {
  return /** @type{?proto.artisanplus.APBeans.Altitude} */ (
    jspb.Message.getWrapperField(this, proto.artisanplus.APBeans.Altitude, 14));
};


/**
 * @param {?proto.artisanplus.APBeans.Altitude|undefined} value
 * @return {!proto.artisanplus.APBeans} returns this
*/
proto.artisanplus.APBeans.prototype.setAltitude = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.clearAltitude = function() {
  return this.setAltitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.artisanplus.APBeans.prototype.hasAltitude = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ScreenSize screen_size = 15;
 * @return {?proto.artisanplus.APBeans.ScreenSize}
 */
proto.artisanplus.APBeans.prototype.getScreenSize = function() {
  return /** @type{?proto.artisanplus.APBeans.ScreenSize} */ (
    jspb.Message.getWrapperField(this, proto.artisanplus.APBeans.ScreenSize, 15));
};


/**
 * @param {?proto.artisanplus.APBeans.ScreenSize|undefined} value
 * @return {!proto.artisanplus.APBeans} returns this
*/
proto.artisanplus.APBeans.prototype.setScreenSize = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.clearScreenSize = function() {
  return this.setScreenSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.artisanplus.APBeans.prototype.hasScreenSize = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional float score = 16;
 * @return {number}
 */
proto.artisanplus.APBeans.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional Species species = 17;
 * @return {!proto.artisanplus.APBeans.Species}
 */
proto.artisanplus.APBeans.prototype.getSpecies = function() {
  return /** @type {!proto.artisanplus.APBeans.Species} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.Species} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setSpecies = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * repeated string varietals = 18;
 * @return {!Array<string>}
 */
proto.artisanplus.APBeans.prototype.getVarietalsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setVarietalsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.addVarietals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.clearVarietalsList = function() {
  return this.setVarietalsList([]);
};


/**
 * optional Cultivation cultivation = 19;
 * @return {!proto.artisanplus.APBeans.Cultivation}
 */
proto.artisanplus.APBeans.prototype.getCultivation = function() {
  return /** @type {!proto.artisanplus.APBeans.Cultivation} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.Cultivation} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setCultivation = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional Harvesting harvesting = 20;
 * @return {!proto.artisanplus.APBeans.Harvesting}
 */
proto.artisanplus.APBeans.prototype.getHarvesting = function() {
  return /** @type {!proto.artisanplus.APBeans.Harvesting} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.Harvesting} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setHarvesting = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional Processing processing = 21;
 * @return {!proto.artisanplus.APBeans.Processing}
 */
proto.artisanplus.APBeans.prototype.getProcessing = function() {
  return /** @type {!proto.artisanplus.APBeans.Processing} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.Processing} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setProcessing = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional string fermentation = 22;
 * @return {string}
 */
proto.artisanplus.APBeans.prototype.getFermentation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setFermentation = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional Drying drying = 23;
 * @return {!proto.artisanplus.APBeans.Drying}
 */
proto.artisanplus.APBeans.prototype.getDrying = function() {
  return /** @type {!proto.artisanplus.APBeans.Drying} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.Drying} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setDrying = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional Decaffeination decaffeination = 24;
 * @return {!proto.artisanplus.APBeans.Decaffeination}
 */
proto.artisanplus.APBeans.prototype.getDecaffeination = function() {
  return /** @type {!proto.artisanplus.APBeans.Decaffeination} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.Decaffeination} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setDecaffeination = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional Ageing ageing = 25;
 * @return {!proto.artisanplus.APBeans.Ageing}
 */
proto.artisanplus.APBeans.prototype.getAgeing = function() {
  return /** @type {!proto.artisanplus.APBeans.Ageing} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.Ageing} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setAgeing = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional Packaging packaging = 26;
 * @return {!proto.artisanplus.APBeans.Packaging}
 */
proto.artisanplus.APBeans.prototype.getPackaging = function() {
  return /** @type {!proto.artisanplus.APBeans.Packaging} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.Packaging} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setPackaging = function(value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * optional string selection = 27;
 * @return {string}
 */
proto.artisanplus.APBeans.prototype.getSelection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setSelection = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional float density = 28;
 * @return {number}
 */
proto.artisanplus.APBeans.prototype.getDensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setDensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional float moisture = 29;
 * @return {number}
 */
proto.artisanplus.APBeans.prototype.getMoisture = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setMoisture = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional float water_activity = 30;
 * @return {number}
 */
proto.artisanplus.APBeans.prototype.getWaterActivity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setWaterActivity = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional float defects = 31;
 * @return {number}
 */
proto.artisanplus.APBeans.prototype.getDefects = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setDefects = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional DefectsUnit defects_unit = 32;
 * @return {!proto.artisanplus.APBeans.DefectsUnit}
 */
proto.artisanplus.APBeans.prototype.getDefectsUnit = function() {
  return /** @type {!proto.artisanplus.APBeans.DefectsUnit} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {!proto.artisanplus.APBeans.DefectsUnit} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setDefectsUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 32, value);
};


/**
 * repeated string flavors = 33;
 * @return {!Array<string>}
 */
proto.artisanplus.APBeans.prototype.getFlavorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 33));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setFlavorsList = function(value) {
  return jspb.Message.setField(this, 33, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.addFlavors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 33, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.clearFlavorsList = function() {
  return this.setFlavorsList([]);
};


/**
 * optional float crop_yield = 34;
 * @return {number}
 */
proto.artisanplus.APBeans.prototype.getCropYield = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.artisanplus.APBeans} returns this
 */
proto.artisanplus.APBeans.prototype.setCropYield = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


goog.object.extend(exports, proto.artisanplus);

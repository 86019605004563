import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Purchase } from 'src/app/models/Purchase';
import { Coffee } from 'src/app/models/Coffee';
import { StandardService } from 'src/app/util/services/standard.service';
import { Utils } from 'src/app/util/utils';
import { Enumerations } from 'src/app/models/Enumerations';
import { Supplier } from 'src/app/models/Supplier';
import { Location } from 'src/app/models/Location';
import { UserService } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { environment } from 'src/environments/environment';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/util/alert/alert.service';
import { PriceService } from 'src/app/modules/transaction/price.service';
import { Router } from '@angular/router';
import { SupplierPartner } from 'src/app/models/SupplierPartner';
import { TransactionDialogComponent } from './transaction-dialog.component';
import { WaitDialogComponent } from '../../ui/dialog/wait-dialog.component';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-purchase-dialog',
    templateUrl: './purchase-dialog.component.html',
    styleUrls: ['./purchase-dialog.component.scss']
})
export class PurchaseDialogComponent extends TransactionDialogComponent implements OnInit, OnDestroy {

    constructor(
        private standardService: StandardService,
        // used in template
        public tr: TranslatorService,
        private priceService: PriceService,
        protected userService: UserService,
        public utils: Utils,
        // used by "that"
        public dialogRef: MatDialogRef<WaitDialogComponent>,
        // used by "that"
        public alertService: AlertService,
        protected router: Router,
        @Inject(MAT_DIALOG_DATA) public data: {
            stores: Location[],
            transaction: Purchase,
            selectedStore: Location,
            selectedCoffee: Coffee,
        }
    ) {
        super(dialogRef, utils, userService, router);
    }

    purchase: Purchase;

    store: Location;
    supplier: Supplier;
    selectFirstSupplier = true;
    suppliers: Supplier[];
    // TODO:
    externalLocation: Location;

    secondPage = false;
    order_number: string;
    price = 0;
    pricePerUnit = 0;
    tracking_number: string;
    product_id: string;
    reference: string;
    lastPurchasePrices: { coffee_internal_hr_id: number, price: number, amount: number, date: DateTime, type: string }[];

    addNewCoffeeMode = false;
    editNewCoffeeMode = false;
    editingCoffee: Coffee;
    addNewSupplierMode = false;
    editNewSupplierMode = false;
    editingSupplier: Supplier;
    isDarkmode = false;

    DateTime = DateTime;

    // Purchase: buy amount of coffee from supplier on date.
    // The supplier sends it from externalLocation to the location (a warehouse of the user)

    ngOnInit(): void {
        super.ngOnInit();

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm));

        this.stores = this.data.stores;
        if (this.stores?.length) {
            this.store = this.stores[0];
        }

        if (!this.stores) {
            this.getAllStores();
        }
        this.getAllCoffees();
        this.getAllSuppliers();

        if (this.data.transaction) {
            // have a transaction that needs to be edited i.e. loaded
            this.coffee = this.data.transaction.coffee;
            if (this.coffee && typeof this.coffee !== 'string' && !this.coffee.yearLabel) {
                this.coffee.yearLabel = this.utils.createBeansYearLabel(this.coffee);
            }
            this.date = this.data.transaction.date;
            this.externalLocation = this.data.transaction.externalLocation;
            this.order_number = this.data.transaction.order_number;
            this.price = this.data.transaction.price;
            this.product_id = this.data.transaction.product_id;
            this.reference = this.data.transaction.reference;
            this.store = this.data.transaction.location;
            this.supplier = this.data.transaction.supplier;
            this.selectFirstSupplier = !!this.supplier;
            this.tracking_number = this.data.transaction.tracking_number;
            this.unitamount = this.data.transaction.amount;
            // update price per unit
            this.priceChanged(false);
            this.calcAmount(false);
            this.reconciled = this.data.transaction.reconciled;
        }

        this.getLastPurchasePrices();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    protected dateChanged(): void {
        if (this.date) {
            this.date = this.date.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
        }
    }

    protected priceChanged(ppuChanged: boolean): void {
        if (ppuChanged) {
            // pricePerUnit changed
            this.price = this.pricePerUnit * this.utils.convertToUserUnit(this.unitamount, this.unit_system);
        } else {
            // price changed
            this.pricePerUnit = this.unitamount ? this.price / this.utils.convertToUserUnit(this.unitamount, this.unit_system) : 0;
        }
    }

    protected getAllCoffees(): void {
        this.standardService.getAll<Coffee>('coffees')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.coffees = response.result;
                        if (this.coffee?.hidden) {
                            // need to add this current coffee since hidden coffees are not retrieved by getAll
                            this.coffees.unshift(this.coffee);
                        }
                        this.filteredCoffees = this.coffees.slice();
                        // if (!this.data.transaction && this.data.selectedCoffee) {
                        for (let c = 0; c < this.coffees.length; c++) {
                            const cof = this.coffees[c];
                            if (cof) {
                                cof.yearLabel = this.utils.createBeansYearLabel(cof);
                                if (!cof.default_unit?.name) {
                                    cof.default_unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 }
                                }
                                const coffId = (cof._id || cof).toString();
                                if ((this.coffee && (coffId === (this.coffee._id || this.coffee).toString() || cof.internal_hr_id === this.coffee.internal_hr_id))
                                    || (this.data.selectedCoffee && coffId === (this.data.selectedCoffee._id || this.data.selectedCoffee).toString())) {
                                    this.coffee = cof;
                                    this.calcAmount(false);
                                    this.preselectSupplierPartner();
                                    break;
                                }
                            }
                        }
                        // }
                        // if (typeof this.coffee === 'string') {
                        //     // lookup coffee ID
                        //     for (let c = 0; c < this.coffees.length; c++) {
                        //         const coff = this.coffees[c];
                        //         if ((coff._id || coff).toString() === this.coffee.toString()) {
                        //             this.coffee = coff;
                        //             this.calcAmount(false);
                        //             // TODO this creates a strange compiler error?!
                        //             // break;
                        //         }
                        //     }
                        // }
                        if (!this.data.transaction && !this.coffee && this.coffees?.length > 0) {
                            this.coffee = this.coffees[0];
                            this.preselectSupplierPartner();
                        }
                    } else {
                        this.utils.handleError('error retrieving all beans', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                }
            });
    }

    protected preselectSupplierPartner(): void {
        if (this.coffee && this.coffee.imported && this.suppliers?.length) {
            for (let s = 0; s < this.suppliers.length; s++) {
                const supp = this.suppliers[s];
                if ((supp as SupplierPartner).partner === this.coffee.imported.source) {
                    this.supplier = supp;
                    return;
                }
            }
        } else if (this.coffee && this.selectFirstSupplier && !this.supplier && this.suppliers?.length) {
            this.supplier = this.suppliers[0];
        }
    }

    protected getAllSuppliers(): void {
        this.standardService.getAll<Supplier>('suppliers')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (response.result) {
                            this.suppliers = response.result;

                            this.preselectSupplierPartner();
                            // display logo for supplier partners
                            for (let s = 0; s < this.suppliers.length; s++) {
                                const sup = this.suppliers[s];
                                if ((sup as SupplierPartner)?.partner) {
                                    sup['image'] = (sup as SupplierPartner).partner;
                                }
                            }
                        }
                    } else {
                        this.utils.handleError('Are you connected to the Internet?', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Are you connected to the Internet?', error);
                }
            });
    }

    protected getAllStores(): void {
        this.standardService.getAll<Location>('stores')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stores = response.result;
                        if (!this.data.transaction) {
                            if (this.data.selectedStore) {
                                this.store = this.data.selectedStore;
                            } else if (!this.store && this.stores?.length) {
                                this.store = this.stores[0];
                            }
                        } else if (this.data.transaction.location?._id && !this.data.transaction.location.hr_id) {
                            // add attributes if not set in given store
                            // (e.g. if edit is called but location is not populated)
                            for (let s = 0; s < this.stores?.length; s++) {
                                const store = this.stores[s];
                                if (this.data.transaction.location._id.toString() === store._id?.toString()) {
                                    Object.assign(this.data.transaction.location, store);
                                    break;
                                }
                            }
                        }
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                }
            });
    }

    protected getLastPurchasePrices(): void {
        this.priceService.getLastPurchasePrices()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.lastPurchasePrices = this.utils.dateifyObject(response.result, ['date']);
                        // this.lastPurchasePrice.forEach(p => p ? p.date = new Date(p.date) : p);
                        const lpp = this.getLastPurchasePrice();
                        if (lpp && !this.price) {
                            this.pricePerUnit = lpp.price / (lpp.amount * this.utils.getUnitFactor(this.mainUnit));
                            this.priceChanged(true);
                        }
                    }
                }
            });
    }

    protected getLastPurchasePrice(): { coffee_internal_hr_id: number, price: number, amount: number, date: DateTime, type: string } | undefined {
        if (!this.coffee || !this.lastPurchasePrices) {
            return undefined;
        }
        for (let c = 0; c < this.lastPurchasePrices.length; c++) {
            if (this.lastPurchasePrices[c]?.coffee_internal_hr_id === this.coffee.internal_hr_id ||
                this.lastPurchasePrices[c] && ('C' + this.lastPurchasePrices[c].coffee_internal_hr_id) === this.coffee.hr_id) {
                return this.lastPurchasePrices[c];
            }
        }
    }

    protected calcAmount(toUnit: boolean): void {
        if (this.coffee?.default_unit?.size) {
            if (toUnit) {
                // amount to unitamount
                this.unitamount = this.amount * this.coffee.default_unit.size;
            } else {
                // unitamount to amount
                this.amount = this.unitamount / this.coffee.default_unit.size;
            }
        }

        if (typeof this.price === 'undefined' || Number.isNaN(Number(this.price)) || this.price === 0) {
            const lpp = this.getLastPurchasePrice();
            if (lpp) {
                this.pricePerUnit = lpp.price / (lpp.amount * this.utils.getUnitFactor(this.mainUnit));
            }
        }
        this.priceChanged(true);
    }

    protected next(): void {
        if (!this.waitingForChanges) {
            this.secondPage = true;
        }
    }

    protected prev(): void {
        if (!this.waitingForChanges) {
            this.secondPage = false;
        }
    }

    // this is called via utils.checkChanges as callback with parameters
    protected doAdd(that?: PurchaseDialogComponent, changedPrice?: string): void {
        if (!that) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            that = this;
        }
        that.priceChanged(changedPrice === 'pricePerUnit');
        if (!that.submitPressed) {
            return;
        }
        that.submitPressed = false;

        const old_amount = that.data && that.data.transaction && that.data.transaction.amount ? that.data.transaction.amount : 0;
        if (that.utils.isWithinRounding(that.unitamount, old_amount, 3) && !that.hasChanged()) {
            that.alertService.success(that.tr.anslate('Nothing to change'));
            // that.dialogRef.close();
            that.finishDialog(undefined);
            return;
        }

        that.purchase = new Purchase();
        delete that.purchase.reconciled;
        that.purchase.amount = that.unitamount;

        if (that.editingCoffee) {
            that.coffee = that.editingCoffee;
        }
        that.purchase.coffee = that.coffee?._id as unknown as Coffee || that.coffee;
        // that.purchase.coffee = that.coffee;

        that.purchase.date = that.date;
        if (that.editingSupplier) {
            that.purchase.supplier = that.editingSupplier;
        } else {
            that.purchase.supplier = that.supplier?._id as unknown as Supplier || that.supplier;
        }
        that.purchase.location = that.store._id as unknown as Location;
        that.purchase.order_number = that.order_number;
        that.purchase.price = that.price;
        that.purchase.tracking_number = that.tracking_number;
        that.purchase.product_id = that.product_id;
        that.purchase.reference = that.reference;
        that.purchase.type = 'Purchase';
        that.purchase.translated_type = that.tr.anslate('Purchase');

        // that.dialogRef.close(that.purchase);
        that.finishDialog(that.purchase);
    }

    // checks if anything (not including the amount) has changed
    protected hasChanged(): boolean {
        const t = this.data.transaction;
        if (t) {
            if (Math.round(this.price * 100) !== Math.round(t.price * 100)) { return true; }
            if (this.order_number !== t.order_number) { return true; }
            if (this.tracking_number !== t.tracking_number) { return true; }
            if (this.product_id !== t.product_id) { return true; }
            if (this.reference !== t.reference) { return true; }
            if (!this.utils.compareObjectsFn(this.supplier, t.supplier)) { return true; }
            if (!this.utils.compareObjectsFn(this.externalLocation, t.externalLocation)) { return true; }
            if (!this.utils.compareObjectsFn(this.coffee, t.coffee)) { return true; }
            if (!this.utils.compareObjectsFn(this.store, t.location)) { return true; }
            if (this.reconciled !== t.reconciled) { return true; }
            // if (JSON.stringify(this.tags) !== JSON.stringify(t.tags)) return true;
            if (!this.date.hasSame(t.date, 'minute')) { return true; }
        } else {
            // only depends on amount which is checked outside this function
            return true;
        }
        return false;
    }

    protected supplierChanged(): void {
        if (this.supplier?.toString() === '######') {
            this.addNewSupplier();
        } else {
            this.editingSupplier = undefined;
        }
    }

    protected addNewSupplier(): void {
        this.addNewSupplierMode = true;
        this.editingSupplier = new Supplier();
    }

    protected editSupplier(): void {
        this.editNewSupplierMode = true;
    }

    protected closeSupplier(): void {
        const emptySupplier = new Supplier();
        if (this.addNewSupplierMode && JSON.stringify(this.editingSupplier) === JSON.stringify(emptySupplier)) {
            this.editingSupplier = null;
            if (this.suppliers?.length > 0) {
                this.supplier = this.suppliers[0];
            } else {
                this.supplier = null;
            }
        }

        this.addNewSupplierMode = false;
        this.editNewSupplierMode = false;
    }

    protected coffeeChanged(): void {
        if (this.coffee?.toString() === '######') {
            this.addNewCoffee();
        } else {
            this.editingCoffee = undefined;
        }
        if (this.amount === this.unitamount && this.coffee && this.coffee.default_unit && !Number.isNaN(Number(this.coffee.default_unit.size))) {
            this.amount = this.unitamount / this.coffee.default_unit.size;
        } else if (this.coffee?.default_unit?.size && this.coffee.default_unit.size !== 1) {
            // amount to unitamount
            this.unitamount = this.amount * this.coffee.default_unit.size;
        } else {
            // unitamount to amount
            this.amount = this.unitamount;
        }

        const lpp = this.getLastPurchasePrice();
        if (lpp) {
            this.pricePerUnit = lpp.price / (lpp.amount * this.utils.getUnitFactor(this.mainUnit));
        }
        this.priceChanged(true);
        this.preselectSupplierPartner();
    }

    protected addNewCoffee(): void {
        this.addNewCoffeeMode = true;
        this.editingCoffee = new Coffee();
    }

    protected editCoffee(): void {
        this.editNewCoffeeMode = true;
        // const label = this.coffee.label ? this.coffee.label : this.coffee as any as string;
        // for (let p = 0; p < this.coffees.length; p++) {
        //     if (this.coffees[p].label === label) {
        //         this.coffee = this.coffees[p];
        //         this.editingCoffee = this.coffees[p];
        //         break;
        //     }
        // }
        // if (!this.editingCoffee) {
        //     // not found, might happen if editing a deleted coffee
        //     this.editNewCoffeeMode = false;
        //     this.addNewCoffee();
        // }
    }

    protected closeCoffee(): void {
        const emptyCoffee = new Coffee();
        if (this.addNewCoffeeMode && JSON.stringify(this.editingCoffee) === JSON.stringify(emptyCoffee)) {
            this.editingCoffee = null;
            if (this.coffees?.length > 0) {
                this.coffee = this.coffees[0];
            } else {
                this.coffee = null;
            }
        }

        this.addNewCoffeeMode = false;
        this.editNewCoffeeMode = false;
    }

    protected imageLoadError(supplier: Supplier): void {
        supplier['image'] = undefined;
    }
}

import { Component, HostListener, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-textinput-dialog',
    template: `
    <h1 mat-dialog-title>{{data.title}}</h1>
    <mat-dialog-content>
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>{{data.placeholder}}</mat-label>
            <input required matInput data-test="npt_txt" type="text"
                [placeholder]="data.placeholder"
                [(ngModel)]="text">
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button class="edit-button" mat-raised-button type="reset" mat-dialog-close><mat-icon>close</mat-icon></button>
        <button [disabled]="!text" class="add-button action-button" mat-raised-button type="submit" (click)="onYesClick()"><mat-icon>done</mat-icon></button>
    </mat-dialog-actions>
    `
})
export class TextinputDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<TextinputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            placeholder: string,
            text: string,
        }
    ) {
        this.text = this.data.text;
    }

    @Input() width: string;

    @HostListener('window:keyup.Enter', ['$event'])
    onDialogClick(): void {
        this.dialogRef.close(this.text);
    }

    text: string;

    onYesClick(): void {
        this.dialogRef.close(this.text);
    }
}

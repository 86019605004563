import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/util/alert/alert.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter, AfterViewInit, ElementRef } from '@angular/core';
import { RoastReport } from 'src/app/models/RoastReport';
import { MatDialog } from '@angular/material/dialog';
import { Roast } from 'src/app/models/Roast';
import { ReportService } from 'src/app/modules/report/report.service';
import { throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { Subject } from 'rxjs';
import { YesNoDialogComponent } from 'src/app/modules/ui/dialog/yesno-dialog.component';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { EditroastreportComponent } from './editroastreport.component';
import { Enumerations } from 'src/app/models/Enumerations';
import { MatExpansionPanel } from '@angular/material/expansion';
import { RoastreportTableComponent } from './roastreport-table.component';
import { RoastReportOverview } from './RoastReportOverview';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-roastreport',
    templateUrl: './roastreport.component.html',
    styleUrls: ['./roastreport.component.scss']
})
export class RoastreportComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        public utils: Utils,
        public reportService: ReportService,
        private alertService: AlertService,
        private tr: TranslatorService,
        private dialog: MatDialog,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    @Input() currentUser: UserType;
    @Input() isOpenReport = false;
    @Input() readOnly = false;
    @Input() missingRoasts: Roast[];
    @Input() isNewest = false;
    @Input() isOnly = false;
    @Input() canBeReconciled = false;
    @Input() idToHighlight: string;
    @Input() stores: { _id: string, hr_id?: string, label?: string }[];

    private _report: RoastReport;
    get report(): RoastReport { return this._report; }
    @Input() set report(rep: RoastReport) {
        this._report = rep;
        if (rep?.updated_by?.nickname) {
            // remove @... if inside a nickname
            if (rep.updated_by.nickname.indexOf('@') > 0) {
                this.uNickname = rep.updated_by.nickname.split('@')[0];
            } else {
                this.uNickname = rep.updated_by.nickname;
            }
        }
    }
    // @Output() reportChange = new EventEmitter<RoastReport>();

    // reportOverview: RoastReportOverview;
    private _reportOverview: RoastReportOverview;
    get reportOverview(): RoastReportOverview { return this._reportOverview; }
    @Input() set reportOverview(rep: RoastReportOverview) {
        this._reportOverview = rep;
    }

    @Output() reportDeleted = new EventEmitter<RoastReport>();
    @Output() reportReconciled = new EventEmitter<RoastReport>();
    // index of this item in the parent accordion
    @Input() index = -1;
    @Input() editmode = -1;
    @Output() editmodeChanged = new EventEmitter<number>();

    sumIn = 0;
    uNickname: string;

    loading = false;
    deleting = false;

    columnsToDisplay = ['Number', 'Date', 'Roast-ID', 'Coffees', 'In', 'Out', 'Loss', 'References'];

    FIELDSEPARATOR = '\t';
    LINESEPARATOR = '\r\n';
    DECIMALSEPARATOR = '.';

    justCopied = false;
    copyFailed = false;
    creatingPdf = false;
    creatingCsv = false;
    creatingSheet = false;
    creatingXML = false;
    creating1808 = false;
    mainUnit: UnitSystemType = 'kg';

    fullyLoaded = false;

    @ViewChild(MatExpansionPanel) expPanel: MatExpansionPanel;
    @ViewChild(EditroastreportComponent) editComponent: EditroastreportComponent;
    @ViewChild('appRoastReport') roastreportElem: ElementRef;
    @ViewChild('hiddenElems') hiddenElems: ElementRef;
    @ViewChild('reportTableElem') reportTableElem: ElementRef;
    @ViewChild('reportTable') reportTable: RoastreportTableComponent;

    Number = Number;
    DateTime = DateTime;

    private ngUnsubscribe = new Subject();

    ngOnInit(): void {
        if (!this.currentUser) {
            this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
            if (!this.currentUser) {
                this.userService.navigateToLogin(this.router.url);
                return;
            }
        }
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
        }
        if (this.currentUser.export) {
            this.FIELDSEPARATOR = this.currentUser.export.sep;
            this.LINESEPARATOR = this.currentUser.export.linesep;
            this.DECIMALSEPARATOR = this.currentUser.export.decsep;
        }

        if (this.expPanel && this.idToHighlight && (this.report.label === this.idToHighlight || this.report._id === this.idToHighlight || this.report.number === this.idToHighlight)) {
            this.expPanel.open();
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    ngAfterViewInit(): void {
        if (this.idToHighlight && this.report &&
            (this.report.label === this.idToHighlight || this.report._id === this.idToHighlight || this.report.number === this.idToHighlight) &&
            !this.expPanel.expanded) {

            setTimeout(() => {
                this.expPanel.open();
                this.roastreportElem?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            });
        }
    }

    // called from template when the entry is expanded
    // loads the data from the server if not fully cached
    openUp() {
        if (!this.reportOverview) {
            this.reloadOverviewReport();
        }
        if (!this.report?.fullyLoaded || (this.report?.roastsCount && this.report.roastsCount !== this.report.roasts?.length)) {
            this.fullyLoaded = false;
            this.reportService.getRoastReport(this.report._id, this.isOpenReport, this.reportService.STD_LIMIT)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.fullyLoaded = response.count === 0 || response.result.roasts?.length === response.count;
                            response.result.fullyLoaded = this.fullyLoaded;
                            response.result.roastsCount = response.count || 0;
                            this.report = this.utils.dateifyRoastReports([response.result])?.[0];
                            this.report.roasts = this.utils.dateifyRoasts(this.report.roasts);
                        } else {
                            this.utils.handleError('error retrieving information', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving information', error);
                    }
                });
        }
        if (this.report?.roastsCount === 0) {
            this.fullyLoaded = true;
        }
    }

    // called (maybe from children) when the report needs to be reloaded
    reloadOverviewReport() {
        this.reportService.getRoastReportOverview(this.report._id, this.isOpenReport)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.reportOverview = response.result;
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving information', error);
                }
            });
    }

    scrollToMissing(): void {
        this.hiddenElems?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }

    scrollToListOfRoasts(): void {
        this.reportTableElem?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }

    // updates info after a change (sumIn is displayed in the header)
    // if full, also report.roastsTotal etc. are calculated from the current report
    // (this should not (as it cannot be done) be done if the roast[] has not loaded completely)
    // recalcInfo(/*full = false*/): void {
        // if (!full) {
        //     this.sumIn = this.report.roastsTotal;
        //     for (let r = 0; r < this.report.roasts?.length; r++) {
        //         const roast = this.report.roasts[r];
        //         if (typeof roast === 'object') {
        //             roast.dateOutside = this.report.startDate.valueOf() > roast.date?.valueOf() || this.report.endDate.valueOf() < roast.date?.valueOf();
        //         }
        //     }
        // } else {
        //     // calculate sum
        //     this.report.roastsTotal = 0;
        //     this.report.roastsTotalOut = 0;
        //     this.report.roastsCount = this.report.roasts?.length || this.report.roastsCount || 0;
        //     for (let r = 0; r < this.report.roasts?.length; r++) {
        //         const roast = this.report.roasts[r];
        //         this.report.roastsTotal += !Number.isFinite(roast.amount) ? 0 : roast.amount;
        //         this.report.roastsTotalOut += !Number.isFinite(roast.end_weight) ? 0 : roast.end_weight;
        //         roast.dateOutside = this.report.startDate.valueOf() > roast.date?.valueOf() || this.report.endDate.valueOf() < roast.date?.valueOf();
        //     }
        //     this.sumIn = this.report.roastsTotal;

        //     // update certification info
        //     this.updateCertInfo();
        // }
    // }

    // calculates the intersection of the certInfos of all contained roasts
    updateCertInfo(): void {
        if (!this.report?.roasts?.length) {
            this.report.certInfo = 0;
        } else {
            const first = this.report.roasts[0]?.certInfo || 0;
            this.report.certInfo = this.report.roasts.map(r => r.certInfo).reduce((prev, cur) => prev & cur, first);
        }
    }

    deleteReport(): void {
        if (this.readOnly) { return; }

        const dialogRef = this.dialog.open(YesNoDialogComponent, {
            closeOnNavigation: true,
            data: { text: this.tr.anslate('Do you really want to delete {{name}}?', { name: this.report.label }) }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.deleting = true;
                this.reportService.deleteRoastReport(this.report._id, this.isOpenReport)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.alertService.success(this.tr.anslate('Successfully removed'));
                                delete this.report['tooltip'];
                                this.reportDeleted.emit(this.report);

                            } else {
                                this.utils.handleError('error deleting report', response.error);
                            }
                            this.deleting = false;
                        },
                        error: error => {
                            this.utils.handleError('error deleting report', error);
                            this.deleting = false;
                        }
                    });
            }
        });
    }

    /**
     * Allows to edit a report. Currently not for reconciled reports. Needs to load all roasts.
     * If isNewest, allows to edit the endDate.
     * If isOnly, allows to edit the startDate.
     * Always allows to edit the label and re-checks all roasts within the given period.
     * "Open" reports allow changes to everything.
     */
    editReport(): void {
        if (this.readOnly || this.report.reconciled) {
            return;
        }
        this.editmode = this.index;
        this.editmodeChanged.emit(this.editmode);
        // this.loading = true;
        // if (this.reportTable) {
        //     this.reportTable.loadMore((success: boolean) => {
        //         this.loading = false;
        //         this.editmode = success ? this.index : -1;
        //         this.editmodeChanged.emit(this.editmode);
        //     });
        // } else {
        //     // no roasts contained in this report
        //     this.loading = false;
        //     this.editmode = this.index;
        //     this.editmodeChanged.emit(this.editmode);
        // }
    }

    cancelEdit(): void {
        this.editmode = -1;
        this.editmodeChanged.emit(this.editmode);
    }

    saveEditedReport(): void {
        if (this.readOnly) { return; }

        if (this.editComponent) {
            this.editComponent.saveReport();
        } else {
            this.alertService.error(this.tr.anslate('Could not save report. Please try again.'));
        }
    }

    getMissingRoasts(): void {
        this.reportService.getMissingRoasts(this.report.endDate, this.report.startDate)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        const mroasts = response.result;
                        this.missingRoasts = [];
                        if (!mroasts || mroasts.length === 0) {
                            return;
                        }
                        this.missingRoasts = this.utils.dateifyRoasts(mroasts.filter(r => r.date > this.report.startDate && r.date <= this.report.endDate));

                    } else {
                        this.utils.handleError('error retrieving reports', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving reports', error);
                }
            });
    }

    onEditModeChanged(mode: boolean): void {
        this.editmode = mode ? this.index : -1;
        this.editmodeChanged.emit(this.editmode);
    }

    // called via template from EditroastreportComponent on saveReport
    onReportChanged(changedReport: RoastReport): void {
        // if the change means the report has 0 roasts now, the empty roast property will
        // have been deleted (i.e. not present) => re-set it to empty array
        if (!changedReport.roasts) {
            changedReport.roasts = [];
        }
        this.report = changedReport;
        // this.utils.updateObject(this.report, changedReport);
        // this.reportChange.emit(this.report);
        if (!this.isOpenReport) {
            this.getMissingRoasts();
        }
        // this.recalcInfo(/*true*/);
    }

    // called via template from EditroastreportComponent on saveReport
    onReportOverviewChanged(changedReportOverview: RoastReportOverview): void {
        if (!changedReportOverview) {
            return;
        }
        if (!changedReportOverview.blends) {
            changedReportOverview.blends = [];
        }
        if (!changedReportOverview.coffees) {
            changedReportOverview.coffees = [];
        }
        this.reportOverview = changedReportOverview;
    }

    reconcileReport(): void {
        if (this.readOnly) { return; }

        const dialogRef = this.dialog.open(YesNoDialogComponent, {
            closeOnNavigation: true,
            data: {
                text: this.tr.anslate('Do you really want to finalize this report and all contained roasts?'),
                okIcon: 'done'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.reportService.reconcileRoastReport(this.report._id)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.alertService.success(this.tr.anslate('Successfully finalized'));
                                this.utils.updateObject(this.report, this.utils.dateifyRoastReports([response.result])?.[0]);
                                // this.reportChange.emit(this.report);
                                this.reportTable?.generatePDF();
                                this.reportReconciled.emit(this.report);
                            } else {
                                this.utils.handleError('error reconciling report', response.error);
                            }
                        },
                        error: error => {
                            this.utils.handleError('error reconciling report', error);
                        }
                    });
            }
        });
    }

    // getCoffeeStrCSV(roast: Roast): string {
    //     let str = '';
    //     if (roast.coffee) {
    //         str += this.reportService.createCoffeeLabel(roast.coffee, true, this.FIELDSEPARATOR === ',' ? ' ' : ',', true, this.FIELDSEPARATOR);
    //     }
    //     if (roast.blend) {
    //         if (roast.blend.label) {
    //             str += roast.blend.label + ': ';
    //         }
    //         const sep = this.FIELDSEPARATOR.indexOf('/') >= 0 ? '; ' : ' / ';
    // str += this.reportService.createBlendLabel(roast.blend, true, this.FIELDSEPARATOR === ',' ? ' ' : ',', sep, this.FIELDSEPARATOR);
    //     }
    //     str.replace(this.FIELDSEPARATOR, ' ');
    //     return str;
    // }
}

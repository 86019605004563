import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF, PathLocationStrategy, PlatformLocation } from '@angular/common';

import { NgModule, LOCALE_ID, MissingTranslationStrategy, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from 'src/app/app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MyMaterialModule } from 'src/app/modules/ui/material.module';
import { PageNotFoundComponent } from 'src/app/util/ui/page-not-found.component';
import { AlertComponent } from 'src/app/util/alert/alert.component';
import { AppRoutingModule } from 'src/app/app-routing.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel, NGXLogger } from 'ngx-logger';
import { JwtInterceptor } from 'src/app/jwt.interceptor';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material/paginator';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { PaginatorI18n } from 'src/app/util/PaginatorI18n';
import { CoffeeModule } from 'src/app/modules/coffee/coffee.module';
import { StoreModule } from 'src/app/modules/store/store.module';
import { RoastModule } from 'src/app/modules/roast/roast.module';
import { BlendModule } from 'src/app/modules/blend/blend.module';
import { StockModule } from 'src/app/modules/stock/stock.module';
import { ReportModule } from 'src/app/modules/report/report.module';
import { WizardModule } from 'src/app/modules/wizard/wizard.module';
import { TestModule } from 'src/app/modules/test/test.module';
import { ContactModule } from './modules/contact/contact.module';
import { FrameModule } from './modules/frame/frame.module';
import { ApToolbarModule } from './modules/ui/ap-toolbar.module';
import { AccountModule } from './modules/account/account.module';
import { DialogModule } from './modules/ui/dialog/dialog.module';
import { LegalModule } from './modules/frame/legal/legal.module';
import { environment } from 'src/environments/environment';
import { AccountInterceptor } from './account.interceptor';
import { ReadonlyaccountInterceptor } from './readonlyaccount.interceptor';
import { EmptyComponent } from './util/ui/empty.component';
import { UpdateSnackComponent } from './modules/ui/snacks/update-snack.component';
import { MessageSnackComponent } from './modules/ui/snacks/message-snack.component';
import { ClipboardModule } from 'ngx-clipboard';
import { GlobalErrorHandler } from './util/global-error-handler';
import { ExternalModule } from './modules/external/external.module';
import { ReminderModule } from './modules/reminders/reminder.module';
import { SchedulerModule } from './modules/scheduler/scheduler.module';
import { WebClientInterceptor } from './webclient.interceptor';

// for German customs reports, even if main language is different
// import { registerLocaleData } from '@angular/common';
import '@angular/common/locales/global/de';
import { I18nModule } from './locale/i18n.module';
// import localeDE from '@angular/common/locales/global/de';
// registerLocaleData(localeDE);

// declare const require; // Use the require method provided by webpack

export function paginatorIntlFactory(translateService: TranslatorService): MatPaginatorIntl {
    return new PaginatorI18n(translateService).getPaginatorIntl();
}

// diable animations for older iphone OS
const disableAnimations =
    !('animate' in document.documentElement)
    || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        EmptyComponent,
        AlertComponent,
        UpdateSnackComponent,
        MessageSnackComponent,
    ],
    imports: [
        BrowserModule,
        LayoutModule,
        // https://github.com/angular/angular/issues/45016#issuecomment-1050304540
        BrowserAnimationsModule.withConfig({ disableAnimations }),
        MyMaterialModule,
        HttpClientModule,
        FormsModule,
        LoggerModule.forRoot({ level: environment.production ? NgxLoggerLevel.OFF : NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.OFF }),
        ApToolbarModule,
        FrameModule,
        CoffeeModule,
        StoreModule,
        RoastModule,
        BlendModule,
        StockModule,
        ReportModule,
        WizardModule,
        ContactModule,
        AccountModule,
        DialogModule,
        LegalModule,
        ClipboardModule,
        ExternalModule,
        ReminderModule,
        SchedulerModule,
        TestModule,
        // must be last otherwise the ** route catches everything
        AppRoutingModule,
    ],
    providers: [
        {
            // processes all errors
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        PathLocationStrategy,
        NGXLogger,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WebClientInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ReadonlyaccountInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AccountInterceptor,
            multi: true
        },
        {
            provide: APP_BASE_HREF,
            useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
            deps: [PlatformLocation]
        },
        // { provide: LOCALE_ID, useValue: 'fr' },
        // {
        //     provide: TRANSLATIONS,
        //     useFactory: (locale: string) => {
        //         if (locale?.substring(0, 2) !== 'en') {
        //             const myLocale = locale.substring(0, 2);
        //             // eslint-disable-next-line @typescript-eslint/no-var-requires
        //             return require(`raw-loader!./locale/messages.${myLocale}.xlf`).default;
        //         }
        //         return '';
        //     },
        //     deps: [LOCALE_ID],
        // },
        // { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
        // optional, defines how error will be handled
        {
            provide: MissingTranslationStrategy,
            // useValue: MissingTranslationStrategy.Warning,
            useFactory: (locale: string) => {
                if (!locale || locale.substring(0, 2) === 'en') {
                    return MissingTranslationStrategy.Ignore;
                }
                return MissingTranslationStrategy.Warning;
            },
            deps: [LOCALE_ID],
        },
        [I18nModule.setLocale(), I18nModule.setLocaleId()],
        // I18n,
        {
            provide: MatPaginatorIntl, deps: [TranslatorService],
            useFactory: paginatorIntlFactory,
        },
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
            useValue: { formFieldAppearance: 'fill' },
        }
    ],

    bootstrap: [AppComponent]
})

export class AppModule { }

// export class AppModule {
//     // Diagnostic only: inspect router configuration
//     constructor(router: Router) {
//       // Use a custom replacer to display function names in the route configs
//       const replacer = (key, value) => (typeof value === 'function') ? value.name : value;
//       console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
//     }
// }

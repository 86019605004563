// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-group mat-form-field {
  max-width: 355px;
}

.template-search-opendialog-icon {
  margin-left: 10px;
  vertical-align: middle;
}

.beans-search-prefix .mat-mdc-input-element {
  text-align: right;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
